import { v4 as uuid } from 'uuid';
import { Tag } from 'product-types/src/domain/tag/Tag';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { UploadHistoryTagFilter } from '../../../../components/Filters/UploadHistoryTagFilter';
import { NewTagsFilterValue } from '../../../../components/Filters/NewTagsFilter/NewTagsFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { TagsFilterValue, tagsFilterParams } from './TagsFilterValue';

export interface TagsUploadHistoryFilterQueryValue extends QueryValue {
  upload_tags_to_include: Array<Tag>;
}

export class TagsUploadHistoryFilter implements Filter {
  uuid: string;

  label: string;

  value: TagsFilterValue;

  constructor(
    params?: Partial<Pick<TagsUploadHistoryFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || TagsFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [
      ...this.value.uploadTagsToInclude.map((account) => ({
        name: FilterTypeEnum.uploadTagsToInclude,
        value: account,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.uploadTagsToInclude + account.id,
      })),
    ];
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.uploadTagsToInclude) {
      this.value = this.value.removeUploadTagsToInclude(
        removingFilter.value as Tag,
      );
    }
    return new TagsUploadHistoryFilter(this);
  }

  get component() {
    return UploadHistoryTagFilter;
  }

  get displayFilterComponent() {
    return NewTagsFilterValue;
  }

  get queryFilterValue(): TagsUploadHistoryFilterQueryValue {
    return {
      upload_tags_to_include: this.value.uploadTagsToInclude,
    };
  }

  static readFilterFromQuery(props: tagsFilterParams): TagsUploadHistoryFilter {
    return new TagsUploadHistoryFilter({
      value: TagsFilterValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    props: tagsFilterParams,
  ): TagsUploadHistoryFilter {
    return new TagsUploadHistoryFilter({
      value: TagsFilterValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
