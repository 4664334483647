import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ModerateWebsiteRequest extends EndpointRequests {}

const moderateAndReturnWebsite = new Endpoint<ModerateWebsiteRequest, any>({
  url: '/api/me/accounts_moderation/moderate',
  method: 'PUT',
  headers: {},
});

export default moderateAndReturnWebsite;
