import { MessageModel } from './Message';

interface LogoutMessagePayload {
  data: any;
}

export const LogoutMessageType = 'LogoutMessageType';

export class LogoutMessage extends MessageModel {
  type = LogoutMessageType;

  payload?: LogoutMessagePayload;

  constructor(data: Omit<LogoutMessage, 'type'>) {
    super({
      type: LogoutMessageType,
      payload: data.payload,
    });
  }

  toString(): string {
    return super.toString();
  }
}
