import { Endpoint } from 'types/network/Http/endpoint';

export type TopWebsitesResponse = {
  top_websites: Array<{
    count: number;
    label: string;
    value: string | number;
  }>;
};

const topWebsites = new Endpoint<any, TopWebsitesResponse>({
  url: '/api/me/top_websites',
  method: 'GET',
  headers: {},
});

export default topWebsites;
