import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export type WebsiteNamesResponse = {
  results: Array<{
    label: string;
    value: string;
  }>;
};

export interface WebsiteNamesRequest extends EndpointRequests {
  params: {
    ids: Array<string | number>;
  };
}

const websiteNames = new Endpoint<WebsiteNamesRequest, WebsiteNamesResponse>({
  url: '/api/me/website_names',
  method: 'GET',
  headers: {},
});

export default websiteNames;
