import { AccountsPostsStats } from 'product-types/src/domain/account/AccountsPostsStats';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AccountsPostsStatsRequest extends EndpointRequests {
  urlParams: { id: string };
}

interface AccountsPostsStatsResponse {
  stats: Array<AccountsPostsStats>;
}

const accountsPostsStats = new Endpoint<
  AccountsPostsStatsRequest,
  AccountsPostsStatsResponse
>({
  url: '/api/me/accounts/:id/stats',
  method: 'GET',
  headers: {},
});

export default accountsPostsStats;
