import { CanBeQueriable } from '../../network/Query/Query';

export class Pagination implements CanBeQueriable {
  offset: number;

  perpage: number;

  page: number;

  constructor(prop?: Pick<Pagination, 'offset' | 'perpage' | 'page'>) {
    this.offset = prop?.offset || 0;
    this.perpage = prop?.perpage || 30;
    this.page = prop?.page || 1;
  }

  get queryFilterValue() {
    return {
      offset: this.offset,
      perpage: this.perpage,
      page: this.page,
    };
  }
}
