import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import get from './get';
import update from './update';

export interface ProductCategoryEndpoints extends EndpointContainer {
  get: typeof get;
  update: typeof update;
}

const productCategories: ProductCategoryEndpoints = {
  get,
  update,
  injectHTTPClient(client: HttpClient) {
    get.injectHTTPClient(client);
    update.injectHTTPClient(client);
  },
};

export default productCategories;
