import * as Domain from 'product-types/src/domain/Domain';
import { makeArrayUniqueByValue } from 'product-utils/src/array';
import {
  ProductCategory,
  ProductCategoryTree,
} from 'product-types/src/domain/productCategory';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  availableCategories: Domain.ProductCategory.ProductCategoryTree;
}

export type NonCircularCategory = Omit<
  Domain.ProductCategory.ProductCategory,
  'original' | 'parent'
>;

export class ProductFilterValue implements FilterValue {
  categories: Array<NonCircularCategory>;

  displayCategories: Array<
    Omit<NonCircularCategory, 'id'> & {
      id: number | string;
    }
  >;

  constructor(
    params: Pick<ProductFilterValue, 'displayCategories' | 'categories'>,
  ) {
    this.categories = params.categories;
    this.displayCategories = params.displayCategories;
  }

  add(category: Domain.ProductCategory.ProductCategory) {
    const newCategory = new Domain.ProductCategory.ProductCategory({
      ...category,
      original: null,
      parent: null,
    });
    return new ProductFilterValue({
      displayCategories: this.displayCategories,
      categories: this.categories.concat([newCategory]),
    });
  }

  delete(category: Domain.ProductCategory.ProductCategory) {
    return new ProductFilterValue({
      displayCategories: this.displayCategories,
      categories: this.categories.filter((cat) => cat.id !== category.id),
    });
  }

  has(category: Domain.ProductCategory.ProductCategory) {
    return this.categories.some((cat) => cat.id === category.id);
  }

  setCategories(value: Domain.ProductCategory.ProductCategory[]) {
    return new ProductFilterValue({
      displayCategories: this.displayCategories,
      categories: value,
    });
  }

  setDisplayCategories(displayValue: Domain.ProductCategory.ProductCategory[]) {
    return new ProductFilterValue({
      categories: this.categories,
      displayCategories: displayValue,
    });
  }

  removeCategory(value: Domain.ProductCategory.ProductCategory) {
    const id = value.id as number | string;

    if (!id.toString().includes('-')) {
      return new ProductFilterValue({
        displayCategories: this.displayCategories.filter(
          (cat) => cat.id !== id,
        ),
        categories: this.categories.filter((cat) => cat.id !== value.id),
      });
    }

    const [, idCommaSeparated] = id.split('-');
    const idsToDelete = new Set(idCommaSeparated.split(',').map(Number));

    return new ProductFilterValue({
      displayCategories: this.displayCategories.filter((cat) => cat.id !== id),
      categories: this.categories.filter((cat) => !idsToDelete.has(cat.id)),
    });
  }

  static cleanUpCircularDeps(
    categories: Array<Domain.ProductCategory.ProductCategory>,
  ): Array<Domain.ProductCategory.ProductCategory> {
    return categories.map((category) => ({
      ...category,
      path: Domain.ProductCategory.ProductCategory.path(category)
        .map((c) => c.displayName)
        .filter(Boolean)
        .join('/'),
      parent: null,
      original: null,
    })) as [];
  }

  static get defaultValue(): ProductFilterValue {
    return new ProductFilterValue({
      displayCategories: new Array<Domain.ProductCategory.ProductCategory>(),
      categories: new Array<Domain.ProductCategory.ProductCategory>(),
    });
  }

  static handleCollapsedCategories(
    categoryTree: Domain.ProductCategory.ProductCategoryTree,
    categories: Array<Domain.ProductCategory.ProductCategory>,
  ) {
    let collapsedCategories = categories.filter((c) =>
      categoryTree.collapsedLeafIds.includes(c.id),
    );

    return (categoryTree.cache?.collapsed ?? []).reduce(
      (acc, collapsedCategory) => {
        const collapsedCategoryIds = collapsedCategories.map((c) => c.id);
        const isAllCollapsed = collapsedCategory.ids.every((id) =>
          collapsedCategoryIds.includes(id),
        );
        if (isAllCollapsed) {
          acc.displayOthers.push({
            id: collapsedCategory.key,
            displayName: `${
              collapsedCategory.parent.displayName
                ? `${collapsedCategory.parent.displayName} - `
                : ''
            }Others`,
            parent: {
              displayName: collapsedCategory.parent.displayName ?? '',
            } as ProductCategory,
            isLeaf: true,
            original: null!,
          } as unknown as ProductCategory);
        }

        acc.selectedOthers.push(
          ...collapsedCategories.filter((c) =>
            collapsedCategory.ids.includes(c.id),
          ),
        );

        collapsedCategories = collapsedCategories.filter(
          (c) => !collapsedCategory.ids.includes(c.id),
        );

        return acc;
      },
      {
        selectedOthers: [] as Array<ProductCategory>,
        displayOthers: [] as Array<ProductCategory>,
      },
    );
  }

  static readFilterFromCategoryIds(
    categoryIds: Array<number>,
    availableCategories: ProductCategoryTree,
  ) {
    const categories = (categoryIds || [])
      .map((categoryId: number) => availableCategories.find(categoryId)!)
      .filter((item) => item !== null);
    const selectedCategories = categories.filter(
      (c) => c.isSelected && c.isLeaf && !c.isOther,
    );

    const { selectedOthers, displayOthers } =
      ProductFilterValue.handleCollapsedCategories(
        availableCategories,
        categories,
      );

    return new ProductFilterValue({
      displayCategories: ProductFilterValue.cleanUpCircularDeps([
        ...selectedCategories,
        ...displayOthers,
      ]),
      categories: ProductFilterValue.cleanUpCircularDeps([
        ...selectedCategories,
        ...selectedOthers,
      ]),
    });
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
  ): ProductFilterValue {
    const urlParams = new URLSearchParams(window.location.search);
    const categoryIds: Array<number> = makeArrayUniqueByValue(
      urlParams
        .getAll('category_id')
        .map((id) => (/\d+/.test(id) ? parseInt(id, 10) : id)),
    );
    return ProductFilterValue.readFilterFromCategoryIds(
      categoryIds,
      props.availableCategories,
    );
  }

  static readFromSavedFilter(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): ProductFilterValue {
    const categoryIds: Array<number> = makeArrayUniqueByValue(
      (savedFilter.globalCategoryIds || []).map((id) =>
        /\d+/.test(id) ? parseInt(id, 10) : (id as number),
      ) || [],
    );
    return ProductFilterValue.readFilterFromCategoryIds(
      categoryIds,
      props.availableCategories,
    );
  }
}
