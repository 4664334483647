import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { KeywordsGraphResponseModel } from 'product-types/src/domain/dashboard/DashboardKeywordsGraphModel';

interface KeywordsGraphRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams & {
    search_keyword?: Array<string>;
  };
}

const getKeywordsGraph = new Endpoint<
  KeywordsGraphRequestModel,
  KeywordsGraphResponseModel
>({
  url: '/api/me/stats/keywords/graph',
  method: 'GET',
  headers: {},
});

export default getKeywordsGraph;
