import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DeleteCommentRequestModel extends EndpointRequests {
  urlParams: {
    objectId: string;
    commentId: string;
  };
}

interface DeleteCommentResponseModel {}

const deleteComment = new Endpoint<
  DeleteCommentRequestModel,
  DeleteCommentResponseModel
>({
  url: '/api/me/accounts_moderation/:objectId/comments/:commentId',
  method: 'DELETE',
  headers: {},
});

export default deleteComment;
