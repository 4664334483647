export class ItemPrice {
  price: number | null;

  currency: string | null;

  constructor(props?: Partial<Pick<ItemPrice, 'price' | 'currency'>>) {
    this.price = props?.price || null;
    this.currency = props?.currency || null;
  }

  static get defaultValue() {
    return new ItemPrice();
  }
}
