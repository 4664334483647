/*
 *
 * NotificationsPage constants
 *
 */

export const SET_NOTIFICATIONS_AS_VIEWED =
  'app/NotificationsPage/SET_NOTIFICATIONS_AS_VIEWED';
export const LOAD_NOTIFICATIONS = 'app/NotificationsPage/LOAD_NOTIFICATIONS';
export const LOAD_NOTIFICATIONS_HOME =
  'app/NotificationsPage/LOAD_NOTIFICATIONS_HOME';
export const LOAD_MORE_NOTIFICATIONS =
  'app/NotificationsPage/LOAD_MORE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS =
  'app/NotificationsPage/UPDATE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS_HOME =
  'app/NotificationsPage/UPDATE_NOTIFICATIONS_HOME';
export const RESET_STATE = 'app/NotificationsPage/RESET_STATE';
