import { NetworkError } from './NetworkError';

export class BadRequestError extends NetworkError {
  message: string;

  constructor(message: string, code: number = 400) {
    const defaultMessage =
      'Something goes wrong, please retry the fetching later';
    super(message || defaultMessage, code);
    this.message = message || defaultMessage;
    this.name = 'BadRequestError';
  }
}
