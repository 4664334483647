import { RoleModel, RoleRaw } from '../role/Role';

export interface UserLightRawModel {
  email: string;
  id: number;
  is_active: boolean;
  name: string;
  profile_picture_link: string;
  last_login: string | null;
  status: string;
  role: RoleRaw;
}

export class UserLightModel {
  email: string;

  id: number;

  isActive: boolean;

  name: string;

  profilePictureLink: string;

  lastLogin: string | null;

  status: string;

  role: RoleModel | null;

  constructor(
    prop: Pick<
      UserLightModel,
      | 'email'
      | 'id'
      | 'name'
      | 'profilePictureLink'
      | 'isActive'
      | 'lastLogin'
      | 'status'
      | 'role'
    >,
  ) {
    this.email = prop.email;
    this.id = prop.id;
    this.isActive = prop.isActive;
    this.name = prop.name;
    this.profilePictureLink = prop.profilePictureLink;
    this.lastLogin = prop.lastLogin;
    this.status = prop.status;
    this.role = prop.role;
  }

  get initials() {
    if (!this.name) {
      return '';
    }
    const [firstName, lastName] = this.name.split(' ');
    return `${firstName[0]}${lastName?.[0] ?? ''}`;
  }

  static createFromRawModel(rawModel: UserLightRawModel) {
    return new UserLightModel({
      email: rawModel.email,
      id: rawModel.id,
      isActive: rawModel.is_active,
      name: rawModel.name,
      profilePictureLink: rawModel.profile_picture_link,
      status: rawModel.status,
      lastLogin: rawModel.last_login,
      role: rawModel.role ? RoleModel.createFromRawModel(rawModel.role) : null,
    });
  }

  static emptyUser() {
    return new UserLightModel({
      email: '',
      id: 0,
      isActive: false,
      name: 'name',
      profilePictureLink: '',
      lastLogin: null,
      status: '',
      role: null,
    });
  }
}
