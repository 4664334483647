import React from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ConfigProvider } from 'antd';
import Checkbox from '../Checkbox/Checkbox';
import { Labellable } from '../Interfaces/Labellable';
import { Changable } from '../Interfaces/Changable';
import { Disablable } from '../Interfaces/Disablable';
import HasChildren from '../Interfaces/HasChildren';
import { Stylable } from '../Interfaces/Stylable';

interface RadioButtonProps
  extends Changable<boolean, CheckboxChangeEvent>,
    Labellable,
    Disablable,
    HasChildren,
    Stylable {
  id?: string;
  variant?: 'primary' | 'secondary';
  indeterminate?: boolean;
  checked?: boolean;
  blockEventPropagination?: boolean;
}

export const RadioButton = (props: RadioButtonProps) => (
  <ConfigProvider
    theme={{
      components: {
        Checkbox: {
          colorPrimaryHover: 'var(--primary-green)',
          colorBorder: 'var(--primary-border)',
          colorPrimary: 'var(--primary-green)',
          colorPrimaryBorder: 'var(--primary-border)',
          colorWhite: 'var(--primary-black)',
          controlInteractiveSize: 24,
          borderRadius: 150,
          borderRadiusLG: 150,
          borderRadiusSM: 150,
          borderRadiusXS: 150,
          fontFamily: 'Satoshi',
          fontSize: 14,
          lineHeight: 1.428571428571429,
          paddingContentVertical: 8,
          paddingContentHorizontal: 4,
          margin: 8,
        },
      },
    }}
  >
    <Checkbox {...props} />
  </ConfigProvider>
);
