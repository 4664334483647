export enum ModerationMethodEnum {
  all = '',
  directModeration = 'direct_moderation',
  inDirectModeraion = 'indirect_moderation_through_rule',
}

export interface ModerationMethod {
  value: ModerationMethodEnum;
  label: string;
}

export const ModerationMethodDictionary = {
  all: {
    label: 'All',
    value: ModerationMethodEnum.all,
  },
  directModeration: {
    label: 'Manual',
    value: ModerationMethodEnum.directModeration,
  },
  inDirectModeraion: {
    label: 'Automated',
    value: ModerationMethodEnum.inDirectModeraion,
  },
};

export const ModerationMethodOptions: ModerationMethod[] = Object.values(
  ModerationMethodDictionary,
);

export const GetModerationMethodStatusFilterOptionByValue = (value) =>
  ModerationMethodOptions.find(
    (moderationMethod) => moderationMethod.value === value,
  );
