export enum NumberFormatterValueType {
  Currency = 'currency',
  Percent = 'percent',
  Number = 'decimal',
}

interface NumberFormatterProps {
  type?: NumberFormatterValueType;
  maximumFractionDigits?: number;
}

/**
 * number formatter for currency, percent and number types
 * @param {Number} value - value to format. For percent type, function will normilize value by deviding it by 100. F.e. 0.5 will be 50%, 50 will be 5000%.
 * @param {NumberFormatterProps} optional, contains type and maximumFractionDigits.
 */
export default function formatNumber(
  value: number,
  {
    maximumFractionDigits = 2,
    type = NumberFormatterValueType.Number,
  }: NumberFormatterProps = {},
) {
  let normalizedValue = value;
  if (type === NumberFormatterValueType.Percent) {
    normalizedValue /= 100;
  }
  return new Intl.NumberFormat(undefined, {
    style: type,
    localeMatcher: 'best fit',
    maximumFractionDigits,
  }).format(normalizedValue);
}

export function trimNumber(value: number, maximumFractionDigits = 2) {
  return parseFloat(value.toFixed(maximumFractionDigits));
}

export function formatDomain(domain?: unknown) {
  if (!domain) {
    return '';
  }
  if (typeof domain !== 'string') {
    return '';
  }
  return domain
    .replace('https://', '')
    .replace('http://', '')
    .replace('www.', '')
    .replace(/^\//, '');
}

export const boolToString = (booleanValue: any) => {
  if (
    booleanValue === 'null' ||
    booleanValue === null ||
    booleanValue === undefined
  ) {
    return 'Not defined';
  }
  if (booleanValue === 'true' || booleanValue === true) {
    return 'Yes';
  }
  if (booleanValue === 'false' || booleanValue === false) {
    return 'No';
  }
  return 'Not defined';
};

export function isValidHttpUrl(string: string) {
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
}
