import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ImageModerationUpdateRequest extends EndpointRequests {
  data: any;
}

interface ImageModerationResponse {}

const moderateAndReturnImage = new Endpoint<
  ImageModerationUpdateRequest,
  ImageModerationResponse
>({
  url: '/api/me/duplicated_groups_moderation/moderate',
  method: 'PUT',
  headers: {},
});

export default moderateAndReturnImage;
