import { UserModel, UserRawModel } from 'product-types/src/domain/user/User';

export interface CommentFile {
  id: number;
  fileName: string;
  fileType: 'image' | 'other';
  fileUrl: string | null;
  fileExtension: string;
}

export interface CommentRaw {
  comment: string;
  comment_date: string;
  id: number;
  user: UserRawModel;
  files: Array<{
    id: number;
    file_name: string;
    file_type: 'image' | 'other';
    file_url: string;
  }>;
}

export class CommentModel {
  comment: string;

  commentDate: Date;

  id: number;

  user: UserModel;

  files: Array<CommentFile>;

  constructor(
    prop: Pick<
      CommentModel,
      'comment' | 'commentDate' | 'id' | 'user' | 'files'
    >,
  ) {
    this.comment = prop.comment;
    this.commentDate = prop.commentDate;
    this.id = prop.id;
    this.user = prop.user;
    this.files = prop.files;
  }

  static createFromRawModel(prop: CommentRaw) {
    return new CommentModel({
      comment: prop.comment,
      commentDate: new Date(parseFloat(prop.comment_date) * 1000),
      id: prop.id,
      user: UserModel.createFromRaw(prop.user),
      files: prop.files.map((file) => ({
        id: file.id,
        fileName: file.file_name,
        fileType: file.file_type,
        fileUrl: file.file_url,
        fileExtension: file.file_name.split('.').pop()?.toLowerCase() ?? '',
      })),
    });
  }
}
