import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import effectiveTakedowns from './effectiveTakedown';
import moderatedPostsBreakdown from './moderatedPostsBreakdown';
import websiteCategoriesBreakdown from './websiteCategoriesBreakdown';

export interface KeyFiguresEndpoints extends EndpointContainer {
  effectiveTakedowns: typeof effectiveTakedowns;
  moderatedPostsBreakdown: typeof moderatedPostsBreakdown;
  websiteCategoriesBreakdown: typeof websiteCategoriesBreakdown;
}

const keyFigures: KeyFiguresEndpoints = {
  effectiveTakedowns,
  moderatedPostsBreakdown,
  websiteCategoriesBreakdown,
  injectHTTPClient(client: HttpClient) {
    effectiveTakedowns.injectHTTPClient(client);
    moderatedPostsBreakdown.injectHTTPClient(client);
    websiteCategoriesBreakdown.injectHTTPClient(client);
  },
};

export default keyFigures;
