import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ManualUploadRequestModel extends EndpointRequests {
  data: any;
}

export interface PostManualUploadResponse {
  existing_items_number: number | null;
  message: string;
  title: string;
}

const uploadPostManual = new Endpoint<
  ManualUploadRequestModel,
  PostManualUploadResponse
>({
  url: `/api/me/uploads/post/manual`,
  method: 'POST',
  headers: {},
});

export default uploadPostManual;
