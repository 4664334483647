import dayjs from 'dayjs';
import * as Domain from 'product-types/src/domain/Domain';

export const prepareParameters = (action) => ({
  perpage: 10,
  offset: 0,
  start_date:
    action.start_date || undefined
      ? dayjs(action.start_date).format('DD/MM/YYYY')
      : undefined,
  end_date:
    action.end_date || undefined
      ? dayjs(action.end_date).format('DD/MM/YYYY')
      : undefined,
  timezone_offset: new Date().getTimezoneOffset(),
  category_id: (action.category_id || [])?.map(
    (category: Domain.Category.Category) => category.id,
  ),
  sort_by: action.sort_by || undefined,
  reverse: action.reverse || undefined,
  geography_to_include: (action.geography_to_include || [])?.map(
    (geo: Domain.Geographic.Geographic) => geo.value,
  ),
  geography_to_exclude: (action.geography_to_exclude || [])?.map(
    (geo: Domain.Geographic.Geographic) => geo.value,
  ),
  synchronous_computation: action.synchronous_computation ?? false,
});
