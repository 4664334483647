import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { KeywordsSearchedResponseModel } from 'product-types/src/domain/dashboard/DashboardKeywordsSearchedModel';

interface KeywordsSearchedRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const getKeywordsSearched = new Endpoint<
  KeywordsSearchedRequestModel,
  KeywordsSearchedResponseModel
>({
  url: '/api/me/stats/keywords/searched',
  method: 'GET',
  headers: {},
});

export default getKeywordsSearched;
