import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { MostRecurringImagesResponse } from 'product-types/src/domain/dashboard/DashboardImagesStateModel';

interface MostRecurringImagesRequestParams
  extends CommonDashboardRequestParams {
  perpage: number;
  offset: number;
  category_id: Array<number> | null;
}
export interface MostRecurringImagesRequest extends EndpointRequests {
  params: MostRecurringImagesRequestParams;
}

const mostRecurringImages = new Endpoint<
  MostRecurringImagesRequest,
  MostRecurringImagesResponse
>({
  url: '/api/me/duplicated_groups',
  method: 'GET',
  headers: {},
});

export default mostRecurringImages;
