import React from 'react';
import { Select } from 'product-ui/src/components/atoms/Select/Select';

import {
  GetOptionByUserRole,
  UserFilterRoleDictionary,
  UserFilterRoleElement,
  UserFilterRoleEnum,
} from 'product-types/src/domain/user/UserFilterRole';

interface RoleSelectProps {
  value: UserFilterRoleEnum;
  options: Array<UserFilterRoleElement>;
  onChange: (v: UserFilterRoleEnum) => void;
}

export const RoleSelect = (props: RoleSelectProps) => (
  <Select
    dataTestId="role-select"
    className="role-select"
    value={GetOptionByUserRole(props.value)}
    defaultValue={UserFilterRoleDictionary.commenter}
    disabled={props.options.length === 1}
    options={props.options}
    style={{ zIndex: 1, borderTopRightRadius: 0, width: 143 }}
    onChange={(_, v) => props.onChange(v.value)}
  />
);
