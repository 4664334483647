import { createGlobalStyle } from 'styled-components';

// todo: make styles available through dot notation. eg style.color.gray, style.color.gray.light etc
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 16px;
  }

  .user-loading-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

  }

  .hashtag-list, .keyword-list {
    & .ant-pagination-options {
      position: absolute;
      right: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-modal-close {
    top: 15px !important;
    right: 13px !important;
  }

  .ant-select-selection-placeholder {
    inset-inline-end: 22px;
  }
  
  .ant-notification {
    z-index: 2147483639;
  }

  html {
    overflow: hidden !important;
  }

  body {
    font-size: 16px;
    font-family: 'Satoshi';
  }

  body.fontLoaded {
    font-family: 'Satoshi';
  }

  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }

  .spinning {
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
  }


  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
  }

  :root {
    --azure: #00a1ff;
    --pale-grey: #f5f6fa;
    --robin-s-egg: #78e0ff;
    --salmon: #ff6565;
    --white: #ffffff;
    --black-mate: #171717;
    --dusk: #43425d;
    --carnation: #ff7285;
    --wheat: #ffca83;
    --wisteria: #a3a2af;
    --light-greenish-blue: #69e4a6;
    --periwinkle-blue: #a3a0fb;
    --gunmetal: #4d4f5c;
    --blue-75: #6ca4ff;
    --blue-5: #f5f9ff;
    --blue-6: #2bb1ff;
    --dark-10: #ececef;
    --blue-10: #ebf3ff;
    --dark-25: #d0d0d7;
    --dark-50: #A1A0AE;
    --dark-5: #f6f6f7;
    --orange: #ffa177;
    --dodger-blue: #0C8CE9;
    --black-2: #333333;
    --blue-50: #9dc3ff;
    --gray-2: #4f4f4f;
    --yellow: #f2c94c;
    --gray-6: #f2f2f2;
    --placeholder: #a1a0ae;
    --black: #000;
    --mint-green: #6bffb9;
    --mint-green-lighter: #B5FFDC;
    --mint-green-lighter10: #F0FFF8;
    --icon-black: #464646;
    --primary: #9CFFD0;
    --primary-light: #B5FFDC;
    --black-mate-light: rgba(23, 23, 23, 0.1);
    --secondary: #6BFFB9;
    --secondary-light: #B5FFDC;
    --natural-grey: #8C8C8C;
    --light-gray: #F9F9F8;
    --neutral-grey: #2E2E2E;
    --neutral-grey-800: #171717;
    --settings-grey: #dadada;

    /* here will be mapped colors from figma */
    --custom-green: #30DB85;
    --green-tints-green-1: #F0FFF8;
    --green-tints-green-2: #E1FFF1;
    --green-tints-green-3: #CEFFE8;
    --green-tints-green-4: #B5FFDC;
    --green-tints-green-5: #9CFFD0;
    --green-tints-green-6 : #84FFC5;
    --green-tints-green-7: #6BFFB9;
    --green-tints-green-8: #59D49A;
    --green-tints-green-9: #36805D;
    --green-tints-green-10: #153325;

    
    --blue-blue-1: #F5FBFF;
    --blue-blue-2: #CCECFF;
    --blue-blue-3: #AAE0FF;
    --blue-blue-4: #80D0FF;
    --blue-blue-5: #55C0FF;
    --blue-blue-6 : #2BB1FF;
    --blue-blue-7: #00A1FF;
    --blue-blue-8: #006BAA;
    --blue-blue-9: #003655;
    --blue-blue-10: #002033;

    
    --purple-purple-1: #FBFBFF;
    --purple-purple-2: #EDECFE;
    --purple-purple-3: #E0DFFE;
    --purple-purple-4: #D1CFFD;
    --purple-purple-5: #C2C0FC;
    --purple-purple-6 : #B2B0FC;
    --purple-purple-7: #A3A0FB;
    --purple-purple-8: #6D6BA7;
    --purple-purple-9: #363554;
    --purple-purple-10: #212032;

    --salmon-salmon-1: #FFF9F9;
    --salmon-salmon-2: #FFE0E0;
    --salmon-salmon-3: #FCC;
    --salmon-salmon-4: #FFB2B2;
    --salmon-salmon-5: #FF9898;
    --salmon-salmon-6: #FF7F7F;
    --salmon-salmon-7: #FF6565;
    --salmon-salmon-8: #AA4343;
    --salmon-salmon-9: #522;
    --salmon-salmon-10: #331414;

    --orange-orange-1: #FFFBFA;
    --orange-orange-2: #FFECE4;
    --orange-orange-3: #FFE0D2;
    --orange-orange-4: #FFD0BB;
    --orange-orange-5: #FFC0A4;
    --orange-orange-6 : #FFB18E;
    --orange-orange-7: #FFA177;
    --orange-orange-8: #AA6B4F;
    --orange-orange-9: #553628;
    --orange-orange-10: #332018;

    --red-red-1: #FFF9FA;
    --red-red-2: #FFE3E7;
    --red-red-3: #FFD0D6;
    --red-red-4: #FFB8C2;
    --red-red-5: #FFC0A4;
    --red-red-6 : #FF8999;
    --red-red-7: #FF7285;
    --red-red-8: #AA4C59;
    --red-red-9: #55262C;
    --red-red-10: #33171B;

    --yellow-yellow-1: #FFFDFA;
    --yellow-yellow-2: #FFF4E6;
    --yellow-yellow-3: #FFEDD6;
    --yellow-yellow-4: #FFE4C1;
    --yellow-yellow-5: #FFDCAC;
    --yellow-yellow-6 : #FFD398;
    --yellow-yellow-7: #FFCA83;
    --yellow-yellow-8: #AA8757;
    --yellow-yellow-9: #55432C;
    --yellow-yellow-10: #33281A;

    --blue-light-1: #FAFEFF;
    --blue-light-2: #E4F9FF;
    --blue-light-3: #D2F5FF;
    --blue-light-4: #BBEFFF;
    --blue-light-5: #A5EAFF;
    --blue-light-6 : #8EE5FF;
    --blue-light-7: #78E0FF;
    --blue-light-8: #5095AA;
    --blue-light-9: #284B55;
    --blue-light-10: #182D33;

    --neutral-grey-50: #F9F9F8;
    --neutral-grey-70: #7D7D8D;
    --neutral-grey-100: #F4F4F4;
    --neutral-grey-200: #EEECEC;
    --neutral-grey-300: #DADADA;
    --neutral-grey-400: #C7C7C7;
    --neutral-grey-500: #8C8C8C;
    --neutral-grey-600: #464646;
    --neutral-grey-700: #2E2E2E;
    --neutral-grey-800: #171717;

    --orange-orange-7-main: #FFA177;

    --grey-grey-200: #EAEAE7;
    --grey-grey-500: #9AA09E;
    --grey-grey-800: #393F3E;

    --primary-green: #6BFFB9;
    --primary-pressed: #464646;
    --primary-hover: #F0FFF8;
    --primary-bg: #F9F9F8;
    --primary-bg-2: #F9F9F8;
    --primary-bg-3: #F4F4F4;
    --primary-black: #171717;

    --custom-sailor-blue: #495F75;
    --custom-red: #F5222D;

    --additional-white: #FFFFFF;
    --primary-white: #FFF;
    --primary-border: #DADADA;
    --new-branding-orange: #FFB672;
    --new-branding-green-1: #48A679;
    --new-branding-red-main: #FF6B72;
    --link-default: #0C8CE9;
    --link-hover: #0A75C2;

    --primary-focus: rgba(23, 23, 23, .10);
  }

  .ant-select-dropdown-menu-item {
    font-size: 15px;
    font-weight: 300;
    color: var(--dusk);
  }

  .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-vertical {
    width: 4px !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: var(--primary-border, #DADADA) !important;
    width: 4px !important;
  }


  .custom-filter {
    .ant-select-dropdown-menu-item {
      display: flex;
      justify-content: space-between;
      text-transform: capitalize;
      & > div {
        margin-left: 15px;
        color:  #a1a0ae;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
  .ant-select-tree-dropdown {
    position: fixed !important;
  }
  .ant-notification-notice-icon {
    line-height: 16px;
    font-size: 16px;
  }
  .ant-notification-notice-message {
    max-width: calc(100% - 58px);
    color: #43425D;
    font-weight: 300;
  }
  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }
  .ant-dropdown-menu-inline-collapsed-tooltip {
    display: none;
  }
  .personal-submenu,
  .avatar-menu,
  .moderation-menu {
    ul {
      border: 1px solid #EBEBF2;
      box-sizing: border-box;
      width: 210px;

      box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.031);
      border-radius: 10px;

      .ant-dropdown-menu-submenu-title,
      .ant-dropdown-menu-item {
        color: #43425D;
        font-size: 15px;
        font-weight: 300;
        line-height: 15px;
        padding: 15px;
        display: flex;
        align-items: center;
      }
    }
  }
  .moderation-menu.post-status {
    min-width: 320px;
    ul {
      width: unset !important;
    }
  }
  .person-overlay {
    .ant-dropdown-menu-item-active {
      background-color: white;
    }
    .ant-dropdown-menu-item {
      cursor: initial;
    }
  }

  [class$="__suggestions__list"] {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
    max-height: 350px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: blue;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f5f9ff;
      box-shadow: inset 0 0 3px grey;
      border-radius: 10px;
    }
  }

  [class$="__suggestions__item"] {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  [class$="__suggestions__item--focused"] {
    background-color: #ebf3ff;
    padding: 5px 15px;
  }
  .comment__input:focus-visible {
    outline-color: #ebebf2;
    outline-width: 0;
  }

  .header-new-uploads__container {
    display: flex;
    align-items: center
  }

  .header-new-uploads__container p {
    margin: 0px 0px 0px 8px;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.03em;
    color: #43425D;
  }


  .header-upload-menu__container .ant-dropdown-menu {
    padding: 8px 0px;
  }

  .header-upload-menu__container .ant-dropdown-menu-item {
    padding: 8px 18px 8px 16px;
  }

  .navee-sidebar-actions-block {
    .ant-popover-inner {
      padding: 0px;
    }
    .ant-popover-inner-content {
      padding: 0px;
    }
  }

  .goals-dropdown {
    ul {
      li {
        height: 44px;
        font-weight: 600;
        font-size: 14px;
        line-height: 44px;

        &.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
        &.ant-select-dropdown-menu-item-selected {
          background-color: #F4F4F4;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    cursor: pointer;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #EEECEC;
  }
  .base-content-wrapper {
    display: flex;
    & > div {
      flex-grow: 1;
    }
    & .ant-spin-container {
      width: 100%;
    }
  }

  /* todo: these should be refactored and removed */
  .labelRight {
    height: 16px;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    
    &.lablRedNumber {
      color: #ff7285;
    }
    &.lablGreyNumber {
      color: var(--dusk);
      opacity: 0.5;
    }
    &.lablGreenNumber {
      color: #69e4a6;
    }
  }
  a {
    color: var(--link-default, #0C8CE9);
    --current-link-color: var(--link-default, #0C8CE9);
    &:hover {
      color: var(--link-hover, #0A75C2);
      --current-link-color: var(--link-hover, #0A75C2);
      text-decoration: underline;
    }
  }
`;
