import styled from 'styled-components';

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding: 4px 8px;
  background-color: var(--white);
  max-width: 200px;
  box-sizing: border-box;
  border: 1px solid var(--white);
  &.-default {
    background-color: var(--neutral-grey-200);
    border: 1px solid var(--neutral-grey-200);
    &:hover {
      background-color: var(--primary-green);
      border: 1px solid var(--primary-green);
    }
  }
  &.-outlined {
    border: 1px solid var(--neutral-grey-800);
    &:hover {
      background-color: var(--primary-green);
      border: 1px solid var(--primary-green);
    }
  }
  &.-excluded {
    background-color: var(--salmon-salmon-3);
    border: 1px solid var(--salmon-salmon-3);
  }
  width: 100%;
  user-select: none;
  border-radius: 4px;
  height: 28px;
  svg {
    color: black;
    flex-shrink: 0;
  }
  &.-new {
    font-weight: 400;
    background-color: white;
    border-radius: 4px;
    border: 0.5px solid var(--black-mate);
    svg {
      color: var(--black-mate);
    }
    .label {
      color: var(--black-mate);
    }
  }
  &.-excluded {
    background-color: var(--salmon-salmon-3);
    :hover {
      background-color: #fb7f7f;
      svg {
        color: var(--black-mate);
      }
    }
    svg {
      color: #ff7285;
    }
  }
`;
