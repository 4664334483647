export enum UploadStatusValueEnum {
    success = 'success',
    failed = 'failed',
    inProgress = 'in_progress',
}

export interface UploadStatus {
    label: string;
    value: UploadStatusValueEnum;
}

export const UploadStatusDictionary = {
    success: {
        label: 'Success',
        value: UploadStatusValueEnum.success,
    },
    failed: {
        label: 'Failed',
        value: UploadStatusValueEnum.failed,
    },
    inProgress: {
        label: 'In Progress',
        value: UploadStatusValueEnum.inProgress,
    },
}

export const UploadStatusOptions: UploadStatus[] = Object.values(UploadStatusDictionary);


export const GetUploadStatusFilterOptionByValue = (value) => UploadStatusOptions.find(uploadStatusOption => uploadStatusOption.value === value);
