import dayjs from 'dayjs';
import { UserModel, UserRawModel } from '../user/User';

export enum CommentType {
  post = 'post',
  duplicatedGroup = 'duplicated_group',
  cluster = 'cluster',
  account = 'account',
  automated = 'automated',
}

export interface ModerationCommentRaw {
  id: number | string;
  comment: string;
  comment_date: string;
  user: UserRawModel;
  comment_type: string;
  comment_parent_id: string;
}

export class ModerationCommentModel {
  id: number | string;

  comment: string;

  commentDate: dayjs.Dayjs;

  user: UserModel;

  commentType: CommentType;

  commentParentId: string;

  constructor(
    props: Pick<
      ModerationCommentModel,
      | 'comment'
      | 'commentDate'
      | 'commentParentId'
      | 'commentType'
      | 'id'
      | 'user'
    >,
  ) {
    this.comment = props.comment;
    this.commentDate = props.commentDate;
    this.commentParentId = props.commentParentId;
    this.commentType = props.commentType;
    this.id = props.id;
    this.user = props.user;
  }

  static createFromRaw(raw: ModerationCommentRaw): ModerationCommentModel {
    return new ModerationCommentModel({
      id: raw.id,
      comment: raw.comment,
      commentDate: dayjs(raw.comment_date),
      commentParentId: raw.comment_parent_id,
      commentType: raw.comment_type as CommentType,
      user: UserModel.createFromRaw(raw.user),
    });
  }
}
