/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/storeAccess';
import { LinkHandlingState } from '../../../LoginPage/reducer';

interface RouteForUnauthorizedProps {
  component: React.JSXElementConstructor<any>;
  path: string;
  exact?: boolean;
}

export default function RouteForUnauthorized({
  component: Component,
  path,
  exact,
}: RouteForUnauthorizedProps) {
  const loginPage = useSelector((state: AppState) => state.loginPage);
  const organisationUid = useSelector(
    (state: AppState) => state.loginPage?.currentUser?.data?.organisation?.uid,
  );
  const linkHandled = useSelector(
    (state: AppState) => state.loginPage?.linkHandled,
  );

  return (
    <Route
      path={path}
      exact={exact ?? false}
      render={() =>
        linkHandled === LinkHandlingState.handled &&
        loginPage.isAuthenticated ? (
          <Redirect to={`/${organisationUid}/post`} />
        ) : (
          <Component />
        )
      }
    />
  );
}
