import React, { useCallback } from 'react';
import { Flex, Tooltip, TreeDataNode } from 'antd';
import Typography from 'product-ui/src/components/atoms/Typography';

export const useTreeSearch = (searchValue: string) => {
  const loop = useCallback(
    (
      data: Array<
        TreeDataNode & {
          lastPathPart?: null | string;
        }
      >,
    ): Array<
      TreeDataNode & {
        containsSearchQuery: boolean;
      }
    > =>
      data.map((item) => {
        const strTitle = item.title as string;
        const normalizedSearchQuery = searchValue.trim().toLowerCase();
        const index = strTitle.toLowerCase().indexOf(normalizedSearchQuery);
        const originalFoundStr = strTitle.substring(
          index,
          index + normalizedSearchQuery.length,
        );
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + normalizedSearchQuery.length);
        const title =
          index > -1 ? (
            <Typography
              tag="span"
              variant="small"
              color="var(--neutral-grey-800)"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {beforeStr}
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                {originalFoundStr}
              </span>
              {afterStr}
            </Typography>
          ) : (
            <Typography
              tag="span"
              variant="small"
              color="var(--neutral-grey-800)"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {strTitle}
            </Typography>
          );
        const renderedTitle = item.lastPathPart ? (
          <Tooltip title={strTitle}>
            <Flex vertical>
              {title}
              <Typography
                variant="xxsmall"
                color="var(--neutral-grey-500)"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.lastPathPart
                  ?.replace(/_/g, ' ')
                  .toLowerCase()
                  .replace(/^./, (str) => str.toUpperCase())}
              </Typography>
            </Flex>
          </Tooltip>
        ) : (
          title
        );
        return {
          title: renderedTitle,
          key: item.key,
          selectable: false,
          children: loop(item.children ?? []),
          containsSearchQuery: index > -1,
        };
      }),
    [searchValue],
  );

  const nodeOrChildrenContainsSearchQuery = useCallback(
    (node: TreeDataNode & { containsSearchQuery: boolean }): boolean => {
      if (node.containsSearchQuery) {
        return true;
      }
      if (node.children) {
        return node.children.some(nodeOrChildrenContainsSearchQuery);
      }
      return false;
    },
    [],
  );

  const filterOutNodesWithoutSearchQuery = useCallback(
    (data: Array<TreeDataNode>): Array<TreeDataNode> => {
      if (!data.length) {
        return [];
      }
      return data
        .map((item) => {
          const children = filterOutNodesWithoutSearchQuery(
            item.children ?? [],
          );
          if (
            nodeOrChildrenContainsSearchQuery(
              item as TreeDataNode & { containsSearchQuery: boolean },
            ) ||
            children.length > 0
          ) {
            return {
              ...item,
              children,
            };
          }
          return null!;
        })
        .filter((item: TreeDataNode) => !!item);
    },
    [nodeOrChildrenContainsSearchQuery],
  );

  return {
    loop,
    filterOutNodesWithoutSearchQuery,
  };
};
