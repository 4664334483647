import { TranslateHashtag } from 'product-types/src/domain/hashtag/TranslateHashtag';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface TranslateHashtagResponseModel {
  keywords: Array<TranslateHashtag>;
}

export interface TranslateHashtagRequestModel extends EndpointRequests {
  keywords: Array<TranslateHashtag>;
}

const translateHashtag = new Endpoint<
  TranslateHashtagRequestModel,
  TranslateHashtagResponseModel
>({
  url: '/api/me/crawling_config/keywords/translate',
  method: 'POST',
  headers: {},
});

export default translateHashtag;
