import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationImagesLabelsResponseModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsImagesLabelsModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

interface ModerationImagesLabelsRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const getModerationImagesLabels = new Endpoint<
  ModerationImagesLabelsRequestModel,
  ModerationImagesLabelsResponseModel
>({
  url: '/api/me/stats/moderation/images/breakdown/labels',
  method: 'GET',
  headers: {},
});

export default getModerationImagesLabels;
