import { Flex } from 'antd';
import Typography from 'product-ui/src/components/atoms/Typography';
import React, { ReactElement } from 'react';

interface MenuItemProps {
  icon: (props: { active?: boolean }) => ReactElement;
  title: string;
  collapsed: boolean;
  active: boolean;
  onMouseEnter?: () => void;
  onClick: () => void;
  onMouseLeave?: () => void;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
}

export default function MenuItem({
  icon: Icon,
  title,
  collapsed,
  active,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
}: MenuItemProps) {
  return (
    <Flex
      // key={title}
      onClick={() => onClick && onClick()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      title={title}
      gap="0.25rem"
      align="center"
      style={{
        padding: '1rem 1.5rem',
        cursor: 'pointer',
        backgroundColor: active ? 'var(--green-tints-green-1)' : 'transparent',
      }}
    >
      {Icon({ active })}
      <Typography
        textTransform="uppercase"
        variant="xsmall"
        hidden={collapsed}
        fontWeight={active ? 'bold' : 'medium'}
        color={active ? 'var(--primary-black)' : 'var(--neutral-grey-500)'}
      >
        {title}
      </Typography>
    </Flex>
  );
}
