import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import account from './account';
import search from './search';
import create from './create';
import getClusters from './getClusters';
import cluster from './cluster';
import deleteAccount from './deleteAccount';
import updateCluster from './updateCluster';
import addAccount from './addAccount';
import getClusterMembers from './members';
import pictures from './pictures';
import merge from './merge';
import comments from './comments';
import exportClusters from './exportClusters';
import exportMembers from './membersExport';

export interface ClusterEndpoints extends EndpointContainer {
  account: typeof account;
  merge: typeof merge;
  search: typeof search;
  create: typeof create;
  getClusters: typeof getClusters;
  exportClusters: typeof exportClusters;
  exportMembers: typeof exportMembers;
  cluster: typeof cluster;
  deleteAccount: typeof deleteAccount;
  updateCluster: typeof updateCluster;
  addAccount: typeof addAccount;
  getClusterMembers: typeof getClusterMembers;
  comments: typeof comments;
  pictures: typeof pictures;
}

const vendors: ClusterEndpoints = {
  account,
  merge,
  search,
  create,
  getClusters,
  exportClusters,
  exportMembers,
  cluster,
  deleteAccount,
  updateCluster,
  addAccount,
  getClusterMembers,
  comments,
  pictures,
  injectHTTPClient(client: HttpClient) {
    account.injectHTTPClient(client);
    merge.injectHTTPClient(client);
    pictures.injectHTTPClient(client);
    search.injectHTTPClient(client);
    create.injectHTTPClient(client);
    getClusters.injectHTTPClient(client);
    exportClusters.injectHTTPClient(client);
    exportMembers.injectHTTPClient(client);
    cluster.injectHTTPClient(client);
    deleteAccount.injectHTTPClient(client);
    updateCluster.injectHTTPClient(client);
    addAccount.injectHTTPClient(client);
    getClusterMembers.injectHTTPClient(client);
    comments.injectHTTPClient(client);
  },
};

export default vendors;
