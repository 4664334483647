import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface NextAccountToModerateRequest extends EndpointRequests {
  data: FeedRequestParameter;
}

interface NextAccountToModerateResponse {}

const nextAccountToModerate = new Endpoint<
  NextAccountToModerateRequest,
  NextAccountToModerateResponse
>({
  url: '/api/me/accounts_moderation/next_account_to_moderate',
  method: 'POST',
  headers: {},
});

export default nextAccountToModerate;
