export enum DateFilterValueEnum {
  oneDay = 'one_day',
  oneWeek = 'one_week',
  oneMonth = 'one_month',
  threeMonths = 'three_months',
  sixMonths = 'six_months',
  oneYear = 'one_year',
  thisMonth = 'this_month',
  untilToday = 'until_today',
  exactDates = 'exact_dates',
}

export enum DateFilterLabelEnum {
  oneDay = 'One Day',
  oneWeek = 'One Week',
  oneMonth = 'One Month',
  threeMonths = 'Three Months',
  sixMonths = 'Six Months',
  oneYear = 'One Year',
  thisMonth = 'This Month',
  untilToday = 'Until Today',
  exactDates = 'Exact Dates',
}

export interface DateFilterOption {
  label: DateFilterLabelEnum;
  value: DateFilterValueEnum;
}

export const DateFilterOptions: DateFilterOption[] = Object.keys(
  DateFilterValueEnum,
).map((key) => ({
  label: DateFilterLabelEnum[key],
  value: DateFilterValueEnum[key],
}));

export const DateFilterOptionsForSettings: DateFilterOption[] = Object.keys(
  DateFilterValueEnum,
)
  .map((key) => ({
    label: DateFilterLabelEnum[key],
    value: DateFilterValueEnum[key],
  }))
  .filter((e) => e.value !== DateFilterValueEnum.exactDates);

export const GetDateFilterOptionByValue = (value) =>
  DateFilterOptions.find((dateOption) => dateOption.value === value);
