import { EffectiveTakedownGraphResponseModel } from 'product-types/src/domain/dashboard/DashboardEffectiveTakedownGraphModel';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface EffectiveTakedownGraphRequestModel extends EndpointRequests {}

const getEffectiveTakedownGraph = new Endpoint<
  EffectiveTakedownGraphRequestModel,
  EffectiveTakedownGraphResponseModel
>({
  url: '/api/me/stats/takedown/effective/graph',
  method: 'GET',
  headers: {},
});

export default getEffectiveTakedownGraph;
