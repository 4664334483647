import styled from 'styled-components';
import { Flex, Font } from '../../utils/mixins';

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  line-height: 1.5;
  .select__option {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  &.focused {
    background-color: var(--mint-green-lighter);
    .select__option {
      background-color: var(--mint-green-lighter);
    }
  }
  &:focus {
    background-color: var(--mint-green-lighter);
    .select__option {
      background-color: var(--mint-green-lighter);
    }
  }
  .option-count {
    height: 23px;
    margin-right: 5px;
    border-radius: 1rem;
    color: #43425d;
    padding: 0.5rem 0.25rem 0.25rem 0.25rem;
    ${Font({ size: 15, weight: 300 })}
    width: 40%;
    ${Flex({})}
    .option-count-text {
      padding: 2px;
    }
  }
`;
