import React from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
} from '../../../types/filters/AtomicFilters/Filter';

export const NewUploadTypeFilterValue: DisplayFilterComponent = ({
  filterValue: { value },
  onClickRemove,
}: DisplayFilterComponentProps) => (
  <Tag
    tag={{
      id: (value.uploadType as Domain.UploadType.UploadType).value,
      name: (value.uploadType as Domain.UploadType.UploadType).label,
    }}
    iconPrefix={<NaveeIcon.UploadTypeTag width={12} height={12} />}
    onClickRemove={onClickRemove}
  />
);
