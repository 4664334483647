import { useCallback } from 'react';
import { kebabToSnakeClass } from 'product-types/src/transform/kebabToSnake';
import { TypographyProps } from './TypographyProps';

export const useTypographyClasses = (
  props: TypographyProps,
): (() => string) => {
  const { textDecoration, textTransform, variant: fontSize } = props;
  return useCallback(() => {
    const classes: Array<string> = [textDecoration, textTransform, fontSize]
      .map((item: string | undefined) => {
        if (item) {
          return item;
        }
        return '';
      })
      .filter((item: string) => item !== '');
    return classes.join(' ');
  }, [textDecoration, textTransform, fontSize]);
};

export const useGetClassesFromObject = (object: any): (() => string) => {
  const deps = Object.values(object);
  return useCallback(
    () =>
      Array.from(Object.entries(object).values())
        .reduce(
          (totalStyle, [style, isEnabled]) =>
            isEnabled
              ? `${totalStyle} ${kebabToSnakeClass(style)}`.trim()
              : `${totalStyle}`.trim(),
          '',
        )
        .trim(),
    deps,
  );
};
