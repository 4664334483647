import { Category } from 'product-types/src/domain/category/Category';
import { Endpoint } from 'types/network/Http/endpoint';

export type CategoryResponse = Array<Category>;

const categories = new Endpoint<any, CategoryResponse>({
  url: '/api/me/categories',
  method: 'GET',
  headers: {},
});

export default categories;
