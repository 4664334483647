import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadFileRequest extends EndpointRequests {
  params: {
    file_name: string;
  };
  data: FormData;
}

export interface UploadFileResponse {
  file_id: number;
  message: string;
}

const uploadFile = new Endpoint<UploadFileRequest, UploadFileResponse>({
  url: '/api/me/moderation_comment/files',
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadFile;
