import { Flex, Popover } from 'antd';
import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Typography from 'product-ui/src/components/atoms/Typography';
import { useLocation } from 'react-router';
import { UserSettingsBlock } from '../styled';

interface UploadMenuItemProps {
  collapsed: boolean;
  updateLocation: ({ key }: { key: string }) => void;
}
export const UploadMenuItem = ({
  collapsed,
  updateLocation: onChangeLocation,
}: UploadMenuItemProps) => {
  const { pathname } = useLocation();
  return (
    <Popover
      placement="rightBottom"
      content={
        <Flex vertical>
          <UserSettingsBlock
            onClick={() => {
              onChangeLocation({ key: '/upload/add/post' });
            }}
          >
            <NaveeIcon.UploadArrow
              fill="#A1A0AE"
              width={22}
              style={{ marginRight: '0.5rem' }}
            />
            <Typography
              textTransform="uppercase"
              variant="xsmall"
              fontWeight="medium"
            >
              New
            </Typography>
          </UserSettingsBlock>
          <UserSettingsBlock
            onClick={() => {
              onChangeLocation({ key: '/upload/history' });
            }}
          >
            <NaveeIcon.ManageHistory
              width={24}
              height={24}
              color="#A1A0AE"
              style={{ marginRight: '0.5rem' }}
            />
            <Typography
              textTransform="uppercase"
              variant="xsmall"
              fontWeight="medium"
            >
              History
            </Typography>
          </UserSettingsBlock>
        </Flex>
      }
      trigger="hover"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem',
        }}
      >
        <NaveeIcon.UploadArrow className="navee_svg" width={24} height={24} />
        <Typography
          textTransform="uppercase"
          variant="xsmall"
          fontWeight={pathname.includes('upload') ? 'bold' : 'medium'}
          hidden={collapsed}
          color={
            pathname.includes('upload')
              ? 'var(--primary-black)'
              : 'var(--neutral-grey-500)'
          }
        >
          Upload
        </Typography>
      </div>
    </Popover>
  );
};
