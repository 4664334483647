export interface ExportTemplateRawModel {
  name: string;
  display_name: string;
}

export class ExportTemplateModel {
  value: string;

  name: string;

  constructor(props: Pick<ExportTemplateModel, 'name' | 'value'>) {
    this.value = props.value;
    this.name = props.name;
  }

  static createFromRaw(raw: ExportTemplateRawModel): ExportTemplateModel {
    return new ExportTemplateModel({
      name: raw.display_name,
      value: raw.name,
    });
  }
}
