import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import {
  Sorting,
  SortingDirectionEnum,
} from 'product-types/src/common/Sorting/Sorting';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter } from '../../AtomicFilters/Filter';

export interface TableParamsQueryValue extends QueryValue {
  offset: number;
  perpage: number;
  page: number;
  sort_by: string;
  reverse: boolean;
}

export class TableParamsFilter implements Filter {
  uuid: string;

  label: string;

  value: TableParams;

  constructor(
    params?: Partial<Pick<TableParamsFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || TableParams.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [];
  }

  displayFilterComponent() {
    return null;
  }

  removeFilterValue() {}

  component() {
    return null;
  }

  get queryFilterValue(): TableParamsQueryValue {
    return this.value.queryFilterValue;
  }

  static readFilterFromQuery(): TableParamsFilter {
    const urlParams = new URLSearchParams(window.location.search);
    const offset = urlParams.getAll('offset').map((id) => parseInt(id, 10));
    const perpage = urlParams.getAll('perpage').map((id) => parseInt(id, 10));
    const key = urlParams.getAll('sort_by');
    const order = urlParams.getAll('order');
    return new TableParamsFilter({
      value: new TableParams({
        pagination: new Pagination({
          offset: offset[0] ?? new Pagination().offset,
          // ensure that perpage is not over 100 using Math
          perpage: Math.min(perpage[0] ?? new Pagination().perpage, 100),
          page:
            Math.floor((offset[0] || 0) / (perpage[0] || 30) + 1) ??
            new Pagination().page,
        }),
        sorting: new Sorting({
          key: key[0] ?? Sorting.defaultValue.key,
          order:
            (order[0] as SortingDirectionEnum) ?? Sorting.defaultValue.order,
        }),
      }),
    });
  }

  static readFilterFromSavedFitler(props: SavedFilterModel): TableParamsFilter {
    return new TableParamsFilter({
      value: TableParams.readFromSavedFilter(props),
    });
  }
}
