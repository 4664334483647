/* eslint-disable no-useless-escape */
export interface Zone {
  zone_code: string;

  zone_name: string;
}

export interface Country {
  country_code: string;

  country_name: string;

  country_emoji: string;
}

export interface Geographic {
  value: string;

  label: string;

  key: string;

  data: Country | Zone;

  emoji?: string;

  dashboardData?: {
    latitude: number;
    longitude: number;
    name: string;
    x_coord_front: number;
    y_coord_front: number;
  };
}

export const CountryToGeo = (country: Country): Geographic => ({
  value: country.country_code,
  label: country.country_name,
  key: country.country_code,
  emoji: country.country_emoji,
  data: country,
});
export const ZoneToGeo = (zone: Zone): Geographic => ({
  value: zone.zone_code,
  label: zone.zone_name,
  key: zone.zone_code,
  data: zone,
});

export interface GeoReasonRaw {
  country: string;
  country_name: string;
  emoji: string;
  rank: null;
  reasons: string;
  score: null;
  selected: boolean;
}

export class GeoReasonModel {
  country: string;

  countryName: string;

  emoji: string;

  rank: null;

  reasons: string;

  score: null;

  selected: boolean;

  constructor(
    prop: Pick<
      GeoReasonModel,
      | 'country'
      | 'countryName'
      | 'emoji'
      | 'rank'
      | 'reasons'
      | 'score'
      | 'selected'
    >,
  ) {
    this.country = prop.country;
    this.countryName = prop.countryName;
    this.emoji = prop.emoji;
    this.rank = prop.rank;
    this.reasons = prop.reasons;
    this.score = prop.score;
    this.selected = prop.selected;
  }

  static createFromRawModel(prop: GeoReasonRaw) {
    return new GeoReasonModel({
      country: prop.country,
      countryName: prop.country_name,
      emoji: prop.emoji,
      rank: prop.rank,
      reasons: prop.reasons,
      score: prop.score,
      selected: prop.selected,
    });
  }

  static checkIfJsonValid(text: any) {
    return (
      (typeof text === 'string' &&
        text !== 'null' &&
        /^[\],:{}\s]*$/.test(
          text
            .replace(/\\["\\\/bfnrtu]/g, '@')
            .replace(
              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
              ']',
            )
            .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
        )) ||
      false
    );
  }

  static createFromRawString(prop: string): Array<GeoReasonModel> {
    const parsedModel: Array<GeoReasonRaw> = JSON.parse(prop);
    return parsedModel?.map(GeoReasonModel.createFromRawModel) || [];
  }
}

export type GeographySelectOptions = {
  countries: Array<Geographic>;
  zones: Array<Geographic>;
};
