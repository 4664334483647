import React, { useCallback } from 'react';
import {
  TakeDownStatuses,
  TakedownStatus,
} from 'product-types/src/domain/takedownStatus/takedownStatus';
import { Flex } from 'antd';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import { TakedownStatusFilter } from '../../../types/filters/AtomicFiltersImplementation/TakedownStatus/TakedownStatusFilter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';

export interface TakedownStatusFilterProps extends FilterParams {
  value: TakedownStatusFilter;
  onChange: (v: Filter) => void;
}

export const NewTakedownStatusFilter = (props: TakedownStatusFilterProps) => {
  const onSelectFilter = useCallback(
    (checked: boolean, takedown: TakedownStatus) => {
      if (checked) {
        props.onChange(
          new TakedownStatusFilter({
            ...props.value,
            value: props.value.value.addTakeDown(takedown),
          }),
        );
      } else {
        props.onChange(
          new TakedownStatusFilter({
            ...props.value,
            value: props.value.value.removeTakedown(takedown),
          }),
        );
      }
    },
    [props.value.value],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        style={{
          padding: '1rem 0.25rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 244,
          minHeight: 60,
        }}
      >
        {TakeDownStatuses.map((val: TakedownStatus) => (
          <Flex
            key={val.value}
            style={{
              padding: '0.25rem 0.75rem',
            }}
          >
            <StyledCheckbox>
              <Checkbox
                onChange={(checked) => onSelectFilter(checked, val)}
                id={val.value}
                checked={props.value.value.takenDown.some(
                  (takedown) => takedown.value === val.value,
                )}
              >
                {val.label}
              </Checkbox>
            </StyledCheckbox>
          </Flex>
        ))}
      </Flex>
    ),
    [props.value.value.takenDown, onSelectFilter],
  );
  return (
    <FilterWithMenuWrapper
      text="Takedown Status"
      renderer={renderer}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
    />
  );
};
