import { HashtagRaw } from 'product-types/src/domain/hashtag/Hashtag';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface UploadHashtagsFileResponseModel {
  keywords: Array<HashtagRaw>;
}

interface UploadHashtagsFileRequestModel extends EndpointRequests {
  data: FormData;
}

const uploadHashtagsFile = new Endpoint<
  UploadHashtagsFileRequestModel,
  UploadHashtagsFileResponseModel
>({
  url: '/api/me/crawling_config/keywords/file',
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadHashtagsFile;
