/**
 *
 * StyledSlider
 *
 */

import React from 'react';
import { Flex } from 'antd';
import { InputNumber } from 'product-ui/src/components/atoms/Input/InputNumber';
import { Input } from 'product-ui/src/components/atoms/Input/Input';

interface StyledSliderProps {
  ids?: [string, string];
  max?: number;
  value: [number | null, number | null];
  onChange: (value: [string | number | null, string | number | null]) => void;
  suffix: string;
  step: number;
  precision?: number;
}

function StyledSlider({
  ids,
  value,
  suffix,
  onChange,
  max,
  step = 1,
  precision,
}: StyledSliderProps) {
  return window.Cypress ? (
    <>
      <Input
        id={ids ? ids[0] : 'from-range-input'}
        type="number"
        value={value[0] || ''}
        placeholder="0"
        addonBefore={<Flex style={{ width: '35px' }}>From</Flex>}
        addonAfter={suffix}
        onChange={(event) => {
          onChange([+event.target.value, value[1]]);
        }}
      />
      <Input
        id={ids ? ids[1] : 'to-range-input'}
        type="number"
        value={value[1] || ''}
        placeholder="0"
        addonBefore={<Flex style={{ width: '35px' }}>To</Flex>}
        addonAfter={suffix}
        onChange={(event) => {
          onChange([value[0], +event.target.value]);
        }}
      />
    </>
  ) : (
    <>
      <InputNumber
        id={ids ? ids[0] : 'from-range-input'}
        addonBefore={<Flex style={{ width: '35px' }}>From</Flex>}
        addonAfter={suffix}
        value={value[0]}
        type="number"
        max={max ?? 1000}
        min={0}
        step={step}
        precision={precision}
        placeholder="0"
        onChange={(event: number) => {
          onChange([event, value[1]]);
        }}
      />
      <InputNumber
        id={ids ? ids[1] : 'to-range-input'}
        addonBefore={<Flex style={{ width: '35px' }}>To</Flex>}
        addonAfter={suffix}
        value={value[1]}
        type="number"
        max={max ?? 1000}
        min={value[0] ?? 0}
        step={step}
        precision={precision}
        placeholder="0"
        onChange={(event) => {
          onChange([value[0], event]);
        }}
      />
    </>
  );
}

export default StyledSlider;
