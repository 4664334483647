import { DuplicatedGroupResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { Endpoint, EndpointRequests } from '../../../../endpoint';
import { FeedRequestParameter } from '../../../../../Feed/Feed';

interface ImageFeedRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

const getImages = new Endpoint<
  ImageFeedRequestModel,
  DuplicatedGroupResponseModel
>({
  url: '/api/me/duplicated_groups',
  method: 'POST',
  headers: {},
});

export default getImages;
