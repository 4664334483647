import React from 'react';
import Typography from 'product-ui/src/components/atoms/Typography';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

const style = {
  fill: 'var(--primary-black)',
  width: 16,
  height: 16,
};

const simpleTagDim = 12.5;

const tagPostIcon = <NaveeIcon.TagPost {...style} />;
const tagImageIcon = <NaveeIcon.TagImage {...style} />;
const tagAccountIcon = <NaveeIcon.TagAccount {...style} />;
const tagSimpleIcon = (
  <NaveeIcon.Tag width={simpleTagDim} height={simpleTagDim} />
);

const tagPrefixMap = {
  [FilterTypeEnum.postTagsInclude]: tagPostIcon,
  [FilterTypeEnum.postTagsExclude]: tagPostIcon,
  [FilterTypeEnum.duplicatedGroupTagsInclude]: tagImageIcon,
  [FilterTypeEnum.duplicatedGroupTagsExclude]: tagImageIcon,
  [FilterTypeEnum.accountTagsInclude]: tagAccountIcon,
  [FilterTypeEnum.accountTagsExclude]: tagAccountIcon,
  [FilterTypeEnum.vendorrTagsInclude]: tagSimpleIcon,
  [FilterTypeEnum.vendorTagsExclude]: tagSimpleIcon,
  [FilterTypeEnum.uploadTagsToInclude]: tagSimpleIcon,
};

export const NewTagsFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, name, value },
  onClickRemove,
}: DisplayFilterComponentProps) => {
  const excluded = [
    FilterTypeEnum.postTagsExclude,
    FilterTypeEnum.accountTagsExclude,
    FilterTypeEnum.duplicatedGroupTagsExclude,
    FilterTypeEnum.vendorTagsExclude,
  ];
  const feedTagsTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      key={uuid}
      onClickRemove={() => {
        onClickRemove();
      }}
      excluded={excluded.includes(name)}
      iconPrefix={tagPrefixMap[name]}
    />
  );
  const postTagsSearchTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      key={uuid}
      label={
        <>
          <Typography
            tag="span"
            fontWeight="thin"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            Post tag:
          </Typography>
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
          <Typography
            tag="span"
            fontWeight="thin"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {value.name}
          </Typography>
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
        </>
      }
      onClickRemove={() => {
        onClickRemove();
      }}
      tooltipText={`Post tag contains: ${value.name}`}
      excluded={FilterTypeEnum.postTagsExcludeSearch === name}
      iconPrefix={tagPostIcon}
    />
  );
  const duplicatedGroupTagsSearchTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      key={uuid}
      label={
        <>
          <Typography
            tag="span"
            fontWeight="thin"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            Image tag:
          </Typography>
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
          <Typography
            tag="span"
            fontWeight="thin"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {value.name}
          </Typography>
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
        </>
      }
      onClickRemove={() => {
        onClickRemove();
      }}
      tooltipText={`Image tag contains: ${value.name}`}
      excluded={FilterTypeEnum.duplicatedGroupTagsExcludeSearch === name}
      iconPrefix={tagImageIcon}
    />
  );
  const accountTagsSearchTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      label={
        <>
          <Typography
            tag="span"
            fontWeight="thin"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            Account tag:
          </Typography>
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
          <Typography
            tag="span"
            fontWeight="thin"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {value.name}
          </Typography>
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
        </>
      }
      tooltipText={`Account tag contains: ${value.name}`}
      key={uuid}
      onClickRemove={() => {
        onClickRemove();
      }}
      excluded={FilterTypeEnum.accountTagsExcludeSearch === name}
      iconPrefix={tagAccountIcon}
    />
  );
  const vendorTagsSearchTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      label={
        <>
          <Typography
            tag="span"
            fontWeight="thin"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            Vendor tag:
          </Typography>
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
          <Typography
            tag="span"
            fontWeight="thin"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {value.name}
          </Typography>
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
        </>
      }
      tooltipText={`Cluster tag contains: ${value.name}`}
      key={uuid}
      onClickRemove={() => {
        onClickRemove();
      }}
      excluded={FilterTypeEnum.vendorTagsExcludeSearch === name}
      iconPrefix={tagSimpleIcon}
    />
  );
  const filterTypeTagMap = {
    [FilterTypeEnum.postTagsInclude]: feedTagsTag,
    [FilterTypeEnum.postTagsExclude]: feedTagsTag,
    [FilterTypeEnum.accountTagsInclude]: feedTagsTag,
    [FilterTypeEnum.duplicatedGroupTagsInclude]: feedTagsTag,
    [FilterTypeEnum.vendorrTagsInclude]: feedTagsTag,
    [FilterTypeEnum.uploadTagsToInclude]: feedTagsTag,
    [FilterTypeEnum.accountTagsExclude]: feedTagsTag,
    [FilterTypeEnum.duplicatedGroupTagsExclude]: feedTagsTag,
    [FilterTypeEnum.vendorTagsExclude]: feedTagsTag,
    [FilterTypeEnum.postTagsIncludeSearch]: postTagsSearchTag,
    [FilterTypeEnum.postTagsExcludeSearch]: postTagsSearchTag,
    [FilterTypeEnum.duplicatedGroupTagsIncludeSearch]:
      duplicatedGroupTagsSearchTag,
    [FilterTypeEnum.duplicatedGroupTagsExcludeSearch]:
      duplicatedGroupTagsSearchTag,
    [FilterTypeEnum.accountTagsIncludeSearch]: accountTagsSearchTag,
    [FilterTypeEnum.accountTagsExcludeSearch]: accountTagsSearchTag,
    [FilterTypeEnum.vendorrTagsIncludeSearch]: vendorTagsSearchTag,
    [FilterTypeEnum.vendorTagsExcludeSearch]: vendorTagsSearchTag,
  };

  return filterTypeTagMap[name] || null;
};
