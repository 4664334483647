import styled from 'styled-components';
import { Tree } from 'antd';
import {
  CheckboxBaseStyles,
  SecondaryCheckboxStyles,
} from '../Checkbox/StyledCheckbox';

export const TreeStyled = styled(Tree)`
  .ant-tree-treenode {
    padding-bottom: 0;

    &:hover {
      background-color: var(--primary-hover);
    }

    &-selected {
      background-color: var(--primary-green) !important;
    }

    .ant-tree-checkbox {
      margin-top: 0;
      align-self: center;
    }

    ${CheckboxBaseStyles('ant-tree')};

    ${SecondaryCheckboxStyles('ant-tree')};

    .ant-tree-checkbox-inner {
      &:hover,
      &:focus {
        background: #fff;
      }
    }

    .ant-tree-checkbox-checked {
      .ant-tree-checkbox-inner {
        &:hover,
        &:focus {
          background: #6bffb9;
        }
      }
    }

    .ant-tree-indent:empty + .ant-tree-switcher-noop {
      visibility: hidden !important;
    }

    .ant-tree-indent {
      visibility: hidden !important;
      &:empty {
        padding-left: 0.5rem;
      }

      .ant-tree-indent-unit {
        width: 2.25rem;

        &::before {
          border-inline-end: 1px solid var(--neutral-grey-200);
        }

        &:nth-child(1) {
          &::before {
            inset-inline-start: 16px;
            inset-inline-end: unset;
          }
        }

        &:nth-child(n + 2) {
          width: 1.75rem;
          &::before {
            inset-inline-start: 8px;
            inset-inline-end: unset;
          }
        }
      }
    }

    .ant-tree-switcher-leaf-line {
      &::before {
        border-inline-end: 1px solid var(--neutral-grey-200);
        inset-inline-end: 7px;
      }

      &::after {
        border-bottom: 1px solid var(--neutral-grey-200);
      }
    }

    &.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
      top: -3px !important;
      height: 22px !important;
    }

    .ant-tree-switcher {
      display: flex;
      align-self: center;
      height: 1rem;
      width: 1rem;
      margin-right: 0.5rem;
      background-color: transparent !important;

      &::before {
        content: none !important;
      }

      &.ant-tree-switcher-noop {
        height: 2.3rem;
      }
    }

    .ant-tree-node-content-wrapper {
      width: 100%;
      padding-left: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
