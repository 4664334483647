import { Endpoint, EndpointRequests } from '../../../../endpoint';

interface SwitchOrganisationModel extends EndpointRequests {
  params: {
    organisation_uid: string;
  };
}

interface SwitchOrganisationModel {}

const switchOrganisation = new Endpoint<
  SwitchOrganisationModel,
  SwitchOrganisationModel
>({
  url: '/api/user/switch_organisation',
  method: 'GET',
  headers: {},
});

export default switchOrganisation;
