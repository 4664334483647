import React from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
} from '../../../types/filters/AtomicFilters/Filter';

const iconsDictionary = {
  'contact_type:instagram': <NaveeIcon.Instagram width={12} height={12} />,
  'contact_type:wechat': <NaveeIcon.WeChat width={12} height={12} />,
  'contact_type:whatsapp': <NaveeIcon.Whatsapp width={12} height={12} />,
  'contact_type:facebook': <NaveeIcon.Facebook width={12} height={12} />,
  'contact_type:email_address': <NaveeIcon.Envelop width={12} height={12} />,
  'contact_type:phone_number': (
    <NaveeIcon.Phone width={12} height={12} fill="#171717" />
  ),
};
export const NewInsightFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, value },
  onClickRemove,
}: DisplayFilterComponentProps) => (
  <Tag
    tag={{
      name: (value as Domain.Insight.Insight).label,
      id: (value as Domain.Insight.Insight).value,
    }}
    iconPrefix={
      iconsDictionary[(value as Domain.Insight.Insight).value] ?? (
        <NaveeIcon.Warning width={12} height={12} />
      )
    }
    key={uuid}
    onClickRemove={onClickRemove}
  />
);
