export class ImageModerationVendorModel {
  id: number;

  name: string;

  constructor(prop: Pick<ImageModerationVendorModel, 'id' | 'name'>) {
    this.id = prop.id;
    this.name = prop.name;
  }
}
