import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SetPasswordRequestModel extends EndpointRequests {
  data: {
    new_password: string;
    new_password_confirmation: string;
  };
}

export interface SetPasswordResponse {
  redirect_url: string;
}

const setPassword = new Endpoint<SetPasswordRequestModel, SetPasswordResponse>({
  url: '/api/me/password',
  method: 'PATCH',
  headers: {},
});

export default setPassword;
