import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CrawlingDomainRaw } from 'product-types/src/domain/crawlingDomain/CrawlingDomain';

export interface UpdateDomainResponseModel {}

interface UpdateDomainRequestModel extends EndpointRequests {
  data: {
    domains: Array<
      Omit<
        CrawlingDomainRaw,
        | 'is_scrapable'
        | 'is_searchable'
        | 'country_code'
        | 'update_time'
        | 'website_category_name'
      >
    >;
  };
}

const updateDomains = new Endpoint<
  UpdateDomainRequestModel,
  UpdateDomainResponseModel
>({
  url: '/api/me/crawling_config/domains',
  method: 'POST',
  headers: {},
});

export default updateDomains;
