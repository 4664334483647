/* eslint-disable react/static-property-placement */
import React from 'react';
import { ReactReduxContext } from 'react-redux';

import { AppState } from 'store/storeAccess';
import getInjectors from '../utils/sagaInjectors';

export interface InjectSagaParameter {
  key: keyof AppState & 'personalize_labels';
  saga: any;
  mode: any;
}

export const useInjectSaga = ({ key, saga, mode }: InjectSagaParameter) => {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    const injectors = getInjectors(context.store);
    injectors.injectSaga(key, { saga, mode });

    return () => {
      injectors.ejectSaga(key);
    };
  }, []);
};
