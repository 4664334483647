import { HttpClient } from '../../../client/client';
import { EndpointContainer } from '../../../endpoint';
import getAll from './getAll';
import getUser from './user';
import userStats from './userStats';
import getAllOrganisations from './getAllOrganisations';
import switchOrganisation from './switchOrganisations';
import getNaveeUsers from './getNaveeUsers';
import invite from './invite';
import update from './update';
import deleteUser from './delete';

export interface UsersEndpoints extends EndpointContainer {
  getAll: typeof getAll;
  getUser: typeof getUser;
  userStats: typeof userStats;
  getNaveeUsers: typeof getNaveeUsers;
  getAllOrganisations: typeof getAllOrganisations;
  switchOrganisation: typeof switchOrganisation;
  invite: typeof invite;
  update: typeof update;
  deleteUser: typeof deleteUser;
}

const users: UsersEndpoints = {
  getAll,
  getUser,
  userStats,
  getNaveeUsers,
  getAllOrganisations,
  switchOrganisation,
  invite,
  update,
  deleteUser,
  injectHTTPClient(client: HttpClient) {
    getAll.injectHTTPClient(client);
    getUser.injectHTTPClient(client);
    userStats.injectHTTPClient(client);
    getNaveeUsers.injectHTTPClient(client);
    getAllOrganisations.injectHTTPClient(client);
    switchOrganisation.injectHTTPClient(client);
    invite.injectHTTPClient(client);
    update.injectHTTPClient(client);
    deleteUser.injectHTTPClient(client);
  },
};

export default users;
