export interface LoginOrganisationRaw {
  id: number;
  uid: string;
  name: string;
  logo_url: string;
}

export class LoginOrganisation {
  id: number;

  uid: string;

  name: string;

  logoUrl: string;

  constructor(raw: Pick<LoginOrganisation, 'id' | 'uid' | 'logoUrl' | 'name'>) {
    this.id = raw.id;
    this.uid = raw.uid;
    this.name = raw.name;
    this.logoUrl = raw.logoUrl;
  }

  static fromRaw(raw: LoginOrganisationRaw): LoginOrganisation {
    return new LoginOrganisation({
      id: raw.id,
      uid: raw.uid,
      name: raw.name,
      logoUrl: raw.logo_url,
    });
  }
}
