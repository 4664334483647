import { SavedFilterRaw } from 'product-types/src/domain/savedFilters/SavedFilters';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SaveFilterRequest extends EndpointRequests {
  data: Partial<SavedFilterRaw>;
}

type SaveFilterResponse = SavedFilterRaw;

const saveFilter = new Endpoint<SaveFilterRequest, SaveFilterResponse>({
  url: '/api/me/filter_presets',
  method: 'POST',
  headers: {},
});

export default saveFilter;
