import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import * as Domain from 'product-types/src/domain/Domain';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { NewInsightFilter } from '../../../../components/Filters/NewInsightFilter/index';
import { NewInsightFilterValue } from '../../../../components/Filters/NewInsightFilter/NewInsightFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { InsightFilterValue, readFilterFromQueryProps } from './InsightValue';

export interface InsightFilterQueryValue extends QueryValue {
  label_type_to_include: Array<Domain.Insight.Insight>;
}

export class InsightFilter implements Filter {
  uuid: string;

  label: string;

  value: InsightFilterValue;

  constructor(
    params?: Partial<Pick<InsightFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || InsightFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return this.value.selectedInsightsWithChildren.map(
      (insight: Domain.Insight.Insight) => ({
        name: FilterTypeEnum.labelTypeInclude,
        value: insight,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.labelTypeInclude + insight.value,
      }),
    );
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    this.value = this.value.removeInsight(
      removingFilter.value as Domain.Insight.Insight,
    );
    return new InsightFilter(this);
  }

  get component() {
    return NewInsightFilter;
  }

  get displayFilterComponent() {
    return NewInsightFilterValue;
  }

  get queryFilterValue(): InsightFilterQueryValue {
    return {
      label_type_to_include: this.value.selectedInsightsWithChildren,
    };
  }

  static readFilterFromQuery(props: readFilterFromQueryProps): InsightFilter {
    return new InsightFilter({
      value: InsightFilterValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): InsightFilter {
    return new InsightFilter({
      value: InsightFilterValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
