/*
 *
 * SettingsPage actions
 *
 */

import { DateFilterValueEnum } from 'product-types/src/domain/date/Date';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { ProductCategoryTree } from 'product-types/src/domain/productCategory';
import { UserWithPermissionsKpis } from 'product-types/src/domain/user/UserWithPermissionsKpis';
import dayjs from 'dayjs';
import { formatDate } from 'product-utils/src/date';
import { ModerationStatistics } from 'product-types/src/domain/ModerationStatistics/ModerationStatistics';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import {
  CHANGE_EMAIL,
  CHANGE_EMAIL_FREQUENCY,
  CHANGE_EMAIL_WHEN_NEW_COUNTERFEIT,
  CHANGE_NAME,
  CHANGE_NEW_PASSWORD,
  CHANGE_NEW_PASSWORD_CONFIRM,
  LOAD_PRODUCT_CATEGORIES,
  PROFILE_PICTURE_UPDATE,
  PROFILE_UPDATE,
  UPDATE_SELECTED_USER_PROFILE,
  UPDATE_DEFAULT_DATE_RANGE,
  UPDATE_ORGANIZATION,
  UPDATE_PRODUCT_CATEGORIES,
  UPDATE_PRODUCT_CATEGORIES_DATA,
  UPDATE_TUTORIAL_MODE,
  LOAD_USER_PROFILE,
  LOAD_USER_STATS,
  UPDATE_USER_STATS,
  LOAD_NAVEE_USERS,
  UPDATE_NAVEE_USERS,
  INVITE_USER,
  UPDATE_INVITATION,
  UPDATE_USER,
  REMOVE_USER,
  UPDATE_USER_REMOVING,
} from './constants';
import { DateFilterPeriod } from '../../layout/Navbar/types';
import { InviteUserModel } from '../../types/network/Http/productMonitor/endpoints/users/invite';

export function changeNameAction(name) {
  return {
    type: CHANGE_NAME,
    name,
  };
}

export function changeEmailAction(email) {
  return {
    type: CHANGE_EMAIL,
    email,
  };
}

export function changeEmailFrequencyAction(frequency) {
  return {
    type: CHANGE_EMAIL_FREQUENCY,
    frequency,
  };
}

export function changeEmailWhenNewCounterfeitAction(sendEmail) {
  return {
    type: CHANGE_EMAIL_WHEN_NEW_COUNTERFEIT,
    sendEmail,
  };
}

export function changeNewPasswordAction(new_password) {
  return {
    type: CHANGE_NEW_PASSWORD,
    new_password,
  };
}

export function changeNewPasswordConfirmAction(new_password_confirmation) {
  return {
    type: CHANGE_NEW_PASSWORD_CONFIRM,
    new_password_confirmation,
  };
}

export function updateProfileAction(payload?: { role_id: number }) {
  return {
    type: PROFILE_UPDATE,
    payload,
  };
}
export function updateProfilePictureAction(file) {
  return {
    type: PROFILE_PICTURE_UPDATE,
    file,
  };
}

export function updateTutorialMode(tutorialMode: boolean) {
  return {
    type: UPDATE_TUTORIAL_MODE,
    tutorialMode,
  };
}

export function updateOrganizationAction(payload: {
  totalModeration: number;
  totalTakedown: number;
  percentageOfSocialMediaModeration: number;
  percentageOfSocialMediaTakedowns: number;
  percentageOfAutomaticModeration: number;
  displayName: string;
  logo: string;
}) {
  return {
    type: UPDATE_ORGANIZATION,
    payload,
  };
}

export function updateDefaultDateRange(dateRange: DateFilterValueEnum) {
  return {
    type: UPDATE_DEFAULT_DATE_RANGE,
    dateRange,
  };
}

export function loadNaveeUsers() {
  return {
    type: LOAD_NAVEE_USERS,
  };
}

export function updateNaveeUsers(
  naveeUsers: FetchableData<Array<UserLightModel>>,
) {
  return {
    type: UPDATE_NAVEE_USERS,
    naveeUsers,
  };
}

export function inviteUserAction(
  payload: InviteUserModel['data'] & { successCb?: () => void },
) {
  return {
    type: INVITE_USER,
    payload,
  };
}

export function updateUserInvitation(payload: { loading: boolean }) {
  return {
    type: UPDATE_INVITATION,
    payload,
  };
}

export function loadProductCategories() {
  return {
    type: LOAD_PRODUCT_CATEGORIES,
  };
}

export function updateProductCategoriesData(
  productCategories: FetchableData<ProductCategoryTree>,
) {
  return {
    type: UPDATE_PRODUCT_CATEGORIES_DATA,
    productCategories,
  };
}

export function updateProductCategories(
  productCategoryTree: ProductCategoryTree,
) {
  return {
    type: UPDATE_PRODUCT_CATEGORIES,
    productCategoryTree,
  };
}

export function loadUserProfile({
  userId,
  period,
  startDate,
  endDate,
}: {
  period: DateFilterPeriod;
  userId: number;
  startDate: Date | null;
  endDate: Date | null;
}) {
  let since;
  let toDate;
  switch (period) {
    case DateFilterPeriod.TODAY:
      since = formatDate(dayjs().startOf('day'));
      break;
    case DateFilterPeriod.SEVEN_DAYS:
      since = formatDate(dayjs().subtract(7, 'day'));
      break;
    case DateFilterPeriod.THIRTY_DAYS:
      since = formatDate(dayjs().subtract(30, 'day'));
      break;
    case DateFilterPeriod.YEAR:
      since = formatDate(dayjs().startOf('year'));
      break;
    case DateFilterPeriod.ALL_TIME:
      since = formatDate(dayjs().subtract(100, 'year'));
      break;
    case DateFilterPeriod.DATES:
      since = formatDate(dayjs(startDate));
      toDate = formatDate(dayjs(endDate));
      break;
    default:
      break;
  }
  return {
    type: LOAD_USER_PROFILE,
    userId,
    since,
    toDate,
  };
}

export function loadUserStats({ period, userId, startDate, endDate }) {
  let since;
  let to_date;
  switch (period) {
    case DateFilterPeriod.TODAY:
      since = formatDate(dayjs().startOf('day'));
      break;
    case DateFilterPeriod.SEVEN_DAYS:
      since = formatDate(dayjs().subtract(7, 'day'));
      break;
    case DateFilterPeriod.THIRTY_DAYS:
      since = formatDate(dayjs().subtract(30, 'day'));
      break;
    case DateFilterPeriod.YEAR:
      since = formatDate(dayjs().startOf('year'));
      break;
    case DateFilterPeriod.ALL_TIME:
      since = formatDate(dayjs().subtract(100, 'year'));
      break;
    case DateFilterPeriod.DATES:
      since = formatDate(dayjs(startDate));
      to_date = formatDate(dayjs(endDate));
      break;
    default:
      break;
  }
  return {
    type: LOAD_USER_STATS,
    id: userId,
    fromDate: since,
    toDate: to_date,
  };
}

export function updateUserStats(stats: FetchableData<ModerationStatistics>) {
  return {
    type: UPDATE_USER_STATS,
    stats,
  };
}

export function updateSelectedUserProfile(
  data: FetchableData<UserWithPermissionsKpis>,
) {
  return {
    type: UPDATE_SELECTED_USER_PROFILE,
    data,
  };
}

export function removeUser(data: { userId: number }) {
  return {
    type: REMOVE_USER,
    data,
  };
}

export function updateUserRemoving(payload: { isRemoving: boolean }) {
  return {
    type: UPDATE_USER_REMOVING,
    payload,
  };
}

export function updateUserProfileAction(payload: {
  userId: number;
  roleId: number;
  name: string;
  period: DateFilterPeriod;
  startDate: any;
  endDate: any;
}) {
  return {
    type: UPDATE_USER,
    payload,
  };
}
