export class Followers {
  min: number | null;

  max: number | null;

  constructor(props?: Partial<Followers>) {
    this.min = props?.min ?? null;
    this.max = props?.max ?? null;
  }

  static get defaultValue() {
    return new Followers();
  }
}
