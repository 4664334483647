import React, {
  ChangeEvent,
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button, {
  ButtonSize,
} from 'product-ui/src/components/atoms/Button/Button';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import { Flex, TreeDataNode } from 'antd';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Typography from 'product-ui/src/components/atoms/Typography';
import { Tree } from 'product-ui/src/components/atoms/Tree';
import { ProductFilter } from 'types/filters/AtomicFiltersImplementation/Product/ProductFilter';
import { Filter, FilterParams } from 'types/filters/AtomicFilters/Filter';
import { useSelector } from 'react-redux';
import { ProductCategory } from 'product-types/src/domain/productCategory';
import { AppState } from 'store/storeAccess';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { ProductFilterValue } from '../../../types/filters/AtomicFiltersImplementation/Product/ProductValue';
import './styles.css';
import {
  ctrlKey,
  enterKey,
  useKeyboardInteraction,
} from '../../../hooks/keyboardActions/useKeyboardInteraction';

const getParentKey = (key: Key, tree: TreeDataNode[]): Key => {
  let parentKey: Key;
  for (let i = 0; i < tree.length; i += 1) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

export interface ProductFilterProps extends FilterParams {
  value: ProductFilter;
  onChange: (v: Filter) => void;
}

export const NewProductFilter = (props: ProductFilterProps) => {
  const categoryTree = useSelector(
    (state: AppState) =>
      state.filters_bar.optionsLoadedData.availableCategories,
  );
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);

  const [forceClose, updateForceClose] = useState({});
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const dataListRef = useRef<Array<{ key: Key; title: string }>>([]);

  const [treeData, noElementsFound] = useMemo(() => {
    let countNodesInternal = categoryTree.data?.children.length ?? 0;
    const loop = (
      data: Array<TreeDataNode>,
    ): Array<TreeDataNode & { containsSearchQuery: boolean }> =>
      data.map((item) => {
        const strTitle = item.title as string;
        const normalizedSearchQuery = searchValue.trim().toLowerCase();
        const index = strTitle.toLowerCase().indexOf(normalizedSearchQuery);
        const originalFoundStr = strTitle.substring(
          index,
          index + normalizedSearchQuery.length,
        );
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + normalizedSearchQuery.length);
        if (index > -1) {
          countNodesInternal -= 1;
        }
        const title =
          index > -1 ? (
            <Typography
              tag="span"
              variant="small"
              color="var(--neutral-grey-800)"
            >
              {beforeStr}
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                {originalFoundStr}
              </span>
              {afterStr}
            </Typography>
          ) : (
            <Typography
              tag="span"
              variant="small"
              color="var(--neutral-grey-800)"
            >
              {strTitle}
            </Typography>
          );
        return {
          title,
          key: item.key,
          selectable: false,
          children: loop(item.children ?? []),
          containsSearchQuery: index > -1,
        };
      });

    const nodeOrChildrenContainsSearchQuery = (
      node: TreeDataNode & { containsSearchQuery: boolean },
    ): boolean => {
      if (node.containsSearchQuery) {
        return true;
      }
      if (node.children) {
        return node.children.some(nodeOrChildrenContainsSearchQuery);
      }
      return false;
    };
    const filterOutNodesWithoutSearchQuery = (
      data: Array<TreeDataNode>,
    ): Array<TreeDataNode> => {
      if (!data.length) {
        return [];
      }
      return data
        .map((item) => {
          const children = filterOutNodesWithoutSearchQuery(
            item.children ?? [],
          );
          if (nodeOrChildrenContainsSearchQuery(item) || children.length > 0) {
            return {
              ...item,
              children,
            };
          }
          return null;
        })
        .filter((item): item is TreeDataNode => !!item);
    };

    return [
      filterOutNodesWithoutSearchQuery(
        loop(categoryTree.data?.toTreeData ?? []),
      ),
      countNodesInternal === categoryTree.data?.children.length &&
        searchValue.length > 0,
    ];
  }, [categoryTree, searchValue]);

  useEffect(() => {
    const generateList = (data: TreeDataNode[]) => {
      for (let i = 0; i < data.length; i += 1) {
        const node = data[i];
        const { key } = node;
        dataListRef.current.push({ key, title: node.title as string });
        if (node.children) {
          generateList(node.children);
        }
      }
    };

    generateList(categoryTree.data?.toTreeData ?? []);
  }, [categoryTree]);

  const onExpand = useCallback((newExpandedKeys: Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  }, []);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const newExpandedKeys = dataListRef.current
        .map(({ key, title }) => {
          const searchMatched = title
            .toLowerCase()
            .includes(value.trim().toLowerCase());
          if (searchMatched && !!value.trim()) {
            return getParentKey(key, categoryTree.data?.toTreeData ?? []);
          }
          return null;
        })
        .filter(
          (item, i, self): item is Key => !!(item && self.indexOf(item) === i),
        );
      setExpandedKeys(newExpandedKeys);
      setSearchValue(value);
      setAutoExpandParent(true);
    },
    [searchValue, categoryTree.data?.toTreeData],
  );

  const onCheck = useCallback(
    (newCheckedKeys: Array<Key>) => {
      const displayCategories = ProductFilterValue.cleanUpCircularDeps(
        newCheckedKeys
          .map((key) => {
            const category = context.availableCategories?.find(key);
            if (category) {
              return category;
            }
            if ((key as string).endsWith('others')) {
              const [parentDisplayName] = (key as string).split('-');
              return {
                id: key,
                displayName: `${
                  parentDisplayName !== 'undefined'
                    ? `${parentDisplayName} - `
                    : ''
                }Others`,
                parent: {
                  displayName:
                    parentDisplayName !== 'undefined' ? parentDisplayName : '',
                } as ProductCategory,
                isLeaf: true,
                original: null!,
              } as unknown as ProductCategory;
            }
            throw new Error(`Invalid key: ${key}`);
          })
          .filter((category) => category.isLeaf),
      );
      const categories = ProductFilterValue.cleanUpCircularDeps(
        newCheckedKeys
          .flatMap((key) => {
            const category = context.availableCategories?.find(key);
            if (category) {
              return category;
            }
            if ((key as string).endsWith('others')) {
              const [, idString] = (key as string).split('-');
              const ids = idString.split(',').map(Number);
              return (
                context.availableCategories?.cache.others.filter((c) =>
                  ids.includes(c.id),
                ) ?? []
              );
            }
            throw new Error(`Invalid key: ${key}`);
          })
          .filter((category) => category.isLeaf),
      );

      props.onChange(
        new ProductFilter({
          ...props.value,
          value: props.value.value
            .setDisplayCategories(displayCategories as any)
            .setCategories(categories as any),
        }),
      );
    },
    [context.availableCategories, props.onChange, props.value],
  );

  const applyFilters = () => {
    updateForceClose(() => ({}));
    context.applyFilters?.();
  };

  useKeyboardInteraction({
    observable: [context.applyFilters],
    subscriptions: [
      {
        conditions: [ctrlKey, enterKey],
        callback: () => {
          if (context.applyFilters) {
            updateForceClose(() => ({}));
          }
        },
      },
    ],
  });

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          padding: '0.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 240,
          minHeight: 60,
          maxHeight: '50vh',
          overflowY: 'auto',
        }}
      >
        <Flex vertical gap="8px" style={{ padding: '1rem 0.5rem 0' }}>
          <Input
            value={searchValue}
            onChange={onSearch}
            placeholder="Search"
            prefix={<NaveeIcon.SearchIcon />}
          />
          {noElementsFound ? (
            <Flex
              align="center"
              justify="center"
              style={{
                height: '42px',
                backgroundColor: 'var(--neutral-grey-50)',
              }}
            >
              <Typography variant="small" color="var(--neutral-grey-800)">
                No data available
              </Typography>
            </Flex>
          ) : null}
        </Flex>
        <Tree
          checkable
          blockNode
          multiple
          showLine
          defaultExpandAll
          treeData={treeData}
          checkedKeys={props.value.value.displayCategories.map((c) => c.id!)}
          expandedKeys={expandedKeys}
          onCheck={onCheck}
          onExpand={onExpand}
          switcherIcon={({ expanded }) =>
            expanded ? (
              <NaveeIcon.MinusOutlined width={16} height={16} />
            ) : (
              <NaveeIcon.PlusOutlined width={16} height={16} />
            )
          }
          autoExpandParent={autoExpandParent}
        />
        {context.applyFilters && context.showApplyButton ? (
          <Button
            onClick={applyFilters}
            size={ButtonSize.Small}
            label="Apply"
          />
        ) : null}
      </Flex>
    ),
    [
      props.value.value,
      treeData,
      context.applyFilters,
      onCheck,
      onExpand,
      onSearch,
      expandedKeys,
      searchValue,
      context.showApplyButton,
      autoExpandParent,
    ],
  );

  return (
    <FilterWithMenuWrapper
      className="product-categories-filter"
      text="Product Category"
      forceClose={forceClose}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
      renderer={renderer}
    ></FilterWithMenuWrapper>
  );
};
