import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { DateFilterValueEnum } from 'product-types/src/domain/date/Date';

dayjs.extend(localizedFormat);

export const DATE_FORMAT_INTERNAL = 'YYYY-MM-DD';
export const DATE_FORMAT_READABLE = 'DD MMM YYYY';
export const DATETIME_FORMAT_READABLE = 'DD MMM YYYY - LT ([GMT]Z)';
const TIME_FORMAT = 'HH:mm';
export const formatDateReadable = (date, withTime = false) =>
  dayjs(date).format(
    DATE_FORMAT_READABLE + (withTime ? ` ${TIME_FORMAT}` : ''),
  );
export const formatDate = (dayjsInstance: Dayjs) =>
  dayjs(dayjsInstance).format(DATE_FORMAT_INTERNAL);

export const formatDateWithTimezoneLocalFormat = (
  date: string | Date | dayjs.Dayjs,
) => dayjs(date).format(DATETIME_FORMAT_READABLE);

export function get_start_date_based_on_interval(
  interval: DateFilterValueEnum,
) {
  switch (interval) {
    case DateFilterValueEnum.oneDay:
      return formatDate(dayjs().subtract(1, 'day'));
    case DateFilterValueEnum.oneWeek:
      return formatDate(dayjs().subtract(1, 'week'));
    case DateFilterValueEnum.oneMonth:
      return formatDate(dayjs().subtract(1, 'month'));
    case DateFilterValueEnum.threeMonths:
      return formatDate(dayjs().subtract(3, 'month'));
    case DateFilterValueEnum.sixMonths:
      return formatDate(dayjs().subtract(6, 'month'));
    case DateFilterValueEnum.oneYear:
      return formatDate(dayjs().subtract(1, 'year'));
    case DateFilterValueEnum.thisMonth:
      return formatDate(dayjs().startOf('month'));
    default:
      return null;
  }
}
