import { LabelRaw } from 'product-types/src/domain/label/Label';
import { Endpoint } from 'types/network/Http/endpoint';

export type AccountLabelsResponse = Array<LabelRaw>;

const accountLabels = new Endpoint<any, AccountLabelsResponse>({
  url: '/api/me/account_labels',
  method: 'GET',
  headers: {},
});

export default accountLabels;
