import { MessageModel } from './Message';

interface GetCurrentOrganisationPayload {
  data:
    | {
        type: 'question';
      }
    | {
        type: 'questionWorker';
      }
    | {
        type: 'answerTab';
        organisationId: number | null;
      }
    | {
        type: 'answerWorker';
        organisationId: Array<number | null>;
      };
}

export const GetCurrentOrganisationType = 'GetCurrentOrganisationType';

export class GetCurrentOrganisation extends MessageModel {
  type = GetCurrentOrganisationType;

  payload?: GetCurrentOrganisationPayload;

  constructor(data: Omit<GetCurrentOrganisation, 'type'>) {
    super({
      type: GetCurrentOrganisationType,
      payload: data.payload,
    });
  }

  toString(): string {
    return super.toString();
  }
}
