import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface PostModerationUpdateRequest extends EndpointRequests {
  data: any;
}

interface PostModerationUpdateResponse {}

const moderateAndReturnPost = new Endpoint<
  PostModerationUpdateRequest,
  PostModerationUpdateResponse
>({
  url: '/api/me/post_moderation/moderate',
  method: 'PUT',
  headers: {},
});

export default moderateAndReturnPost;
