export enum UploadTypeType {
  Post = 'Post',
  Image = 'Image',
  Account = 'Account',
}

export class UploadType {
  id: number;

  type: UploadTypeType;

  url: string;

  constructor(prop: Pick<UploadType, 'id' | 'type' | 'url'>) {
    this.id = prop.id;
    this.type = prop.type;
    this.url = prop.url;
  }
}
