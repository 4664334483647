import { AxiosRequestConfig } from 'axios';
import { NetworkError } from 'product-types/src/common/Error/NetworkError';
import { HttpClient } from './client/client';

export interface EndpointContainer {
  injectHTTPClient: (client: HttpClient) => void;
}

export type EndpointRequests = Pick<
  AxiosRequestConfig,
  'data' | 'params' | 'signal' | 'cancelToken'
> & {
  urlParams?: Record<string, string>;
  suppressToastrOnError?: boolean;
  cache?: boolean;
};

export class Endpoint<RequestType extends EndpointRequests, Response> {
  url: string;

  method: AxiosRequestConfig['method'];

  headers: any;

  client?: HttpClient;

  numberOfRetries: number = 0;

  constructor(
    prop: Pick<
      Endpoint<RequestType, Response>,
      'url' | 'method' | 'headers'
    > & { numberOfRetries?: number },
  ) {
    this.url = prop.url;
    this.method = prop.method;
    this.numberOfRetries = prop.numberOfRetries ?? 0;
    this.headers = prop.headers || {};
  }

  async call(prop: RequestType): Promise<Response> {
    if (!this.client) {
      throw new Error('Client is not injected');
    }
    if (!prop) {
      throw new Error('Parameter Request is not provided');
    }
    try {
      const response = await this.client.client.request<Response>({
        url: this.url,
        method: this.method,
        headers: this.headers,
        ...prop,
        suppressToastrOnError: prop.suppressToastrOnError || false,
      });
      return response.data;
    } catch (error: unknown) {
      console.dir(error);
      if (this.numberOfRetries > 0) {
        const result = await this.retry(prop, this.numberOfRetries);
        return result;
      }
      throw error;
    }
  }

  async download(prop: RequestType): Promise<BlobPart> {
    if (!this.client) {
      throw new Error('Client is not injected');
    }
    if (!prop) {
      throw new Error('Parameter Request is not provided');
    }
    try {
      const response = await this.client.client.request<BlobPart>({
        url: this.url,
        method: this.method,
        headers: this.headers,
        responseType: 'blob',
        cache: false,
        ...prop,
      });
      const newUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = newUrl;
      const fileName = response.headers['content-disposition']
        .split('filename=')[1]
        .replace(/^"/, '')
        .replace(/"$/, '');
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      return response.data;
    } catch (error) {
      console.dir(error);
      throw new NetworkError(error as string, error.code);
    }
  }

  async retry(prop: RequestType, availableRetries: number): Promise<Response> {
    if (!this.client) {
      throw new Error('Client is not injected');
    }
    if (!prop) {
      throw new Error('Parameter Request is not provided');
    }
    try {
      const response = await this.client.client.request<Response>({
        url: this.url,
        method: this.method,
        headers: this.headers,
        ...prop,
      });
      return response.data;
    } catch (error: unknown) {
      if (availableRetries > 0) {
        const result = await this.retry(prop, availableRetries - 1);
        return result;
      }
      throw error;
    }
  }

  injectHTTPClient(client: HttpClient) {
    this.client = client;
  }
}
