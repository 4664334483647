export interface PosterPostFeed {
  id: number;
  name: string;
  number_of_posts: number;
  number_of_moderated_posts_globally: number;
  recidivism: null | any;
  geo: null | any;
  label_id?: number;
  local_infringement: null | number;
  other_organisations_infringement: number;
  cluster_infringement: number;
  followers_count: number;
}

export class Poster {
  id: number;

  name: string;

  numberOfPosts: number;

  numberOfModeratedPostsGlobally: number;

  recidivism: null | any;

  geo: null | any;

  labelId?: number;

  localInfringement: number;

  otherOrganisationsInfringement: number;

  clusterInfringement: number;

  followersCount: number;

  priceDiscrepancy: number;

  constructor(prop: Poster) {
    this.id = prop.id;
    this.name = prop.name;
    this.numberOfPosts = prop.numberOfPosts;
    this.labelId = prop.labelId;
    this.numberOfModeratedPostsGlobally = prop.numberOfModeratedPostsGlobally;
    this.localInfringement = prop.localInfringement;
    this.otherOrganisationsInfringement = prop.otherOrganisationsInfringement;
    this.clusterInfringement = prop.clusterInfringement;
    this.followersCount = prop.followersCount;
    this.recidivism = prop.recidivism;
    this.geo = prop.geo;
    this.priceDiscrepancy = prop.priceDiscrepancy;
  }
}
