import React, { useCallback } from 'react';
import {
  UploadStatus,
  UploadStatusOptions,
} from 'product-types/src/domain/uploadStatus/UploadStatus';
import { Flex } from 'antd';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import { UploadStatusFilter } from '../../../types/filters/AtomicFiltersImplementation/UploadStatus/UploadStatusFilter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';

export interface NewestUploadStatusFilterProps extends FilterParams {
  value: UploadStatusFilter;
  onChange: (v: Filter) => void;
}

export const UploadStatusFilterC = (props: NewestUploadStatusFilterProps) => {
  const onSelectFilter = (checked: boolean, statusType: UploadStatus) => {
    if (checked) {
      props.onChange(
        new UploadStatusFilter({
          ...props.value,
          value: props.value.value.addUploadStatus(statusType),
        }),
      );
    } else {
      props.onChange(
        new UploadStatusFilter({
          ...props.value,
          value: props.value.value.removeUploadStatus(statusType),
        }),
      );
    }
  };

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        style={{
          padding: '1rem 0.25rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 160,
          minHeight: 60,
        }}
      >
        {UploadStatusOptions.map((val: UploadStatus) => (
          <Flex
            key={val.value}
            style={{
              padding: '0.25rem 0.75rem',
            }}
          >
            <StyledCheckbox>
              <Checkbox
                onChange={(checked) => onSelectFilter(checked, val)}
                id={val.value}
                checked={props.value.value.selectedUploadStatuses.some(
                  (takedown) => takedown.value === val.value,
                )}
              >
                {val.label}
              </Checkbox>
            </StyledCheckbox>
          </Flex>
        ))}
      </Flex>
    ),
    [props.value.value.selectedUploadStatuses, onSelectFilter],
  );

  return (
    <FilterWithMenuWrapper
      text="Upload Status"
      renderer={renderer}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
    />
  );
};
