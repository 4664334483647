import { Endpoint } from 'types/network/Http/endpoint';

const downloadHashtagKeywordExcelFile = new Endpoint<
  NonNullable<unknown>,
  NonNullable<unknown>
>({
  url: '/api/me/crawling_config/keywords/file',
  method: 'GET',
  headers: {},
});

export default downloadHashtagKeywordExcelFile;
