import dayjs from 'dayjs';
import {
  SELECT_ALL_TRACKED_WEBSITES,
  SELECT_ALL_UNTRACKED_WEBSITES,
} from 'store/constants';
import * as Domain from 'product-types/src/domain/Domain';
import { DateFilterValueEnum } from 'product-types/src/domain/date/Date';

const websiteValuesToExclude = [
  SELECT_ALL_TRACKED_WEBSITES,
  SELECT_ALL_UNTRACKED_WEBSITES,
];

// todo: check how this medot is used
export const prepareParameters = (action) => ({
  start_date:
    action.interval !== DateFilterValueEnum.untilToday &&
    dayjs(action.start_date).isValid()
      ? dayjs(action.start_date).format('YYYY-MM-DD')
      : null,
  end_date: dayjs(action.end_date).isValid()
    ? dayjs(action.end_date).format('YYYY-MM-DD')
    : null,
  timezone_offset: new Date().getTimezoneOffset(),
  category_id: action.categoryId?.map(
    (category: Domain.Category.CategoryModel) => category.id,
  ),
  geography_to_include: action.geography_to_include?.map(
    (geo: Domain.Geographic.Geographic) => geo.value,
  ),
  geography_to_exclude: action.geography_to_exclude?.map(
    (geo: Domain.Geographic.Geographic) => geo.value,
  ),
  website_id_to_exclude: (
    (action.website_id_to_exclude || []) as Domain.Website.WebsiteSuggestion[]
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes(value.id.toString()),
    )
    .map((val) => val.id),
  website_id: ((action.website_id || []) as Domain.Website.WebsiteSuggestion[])
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes(value.id.toString()),
    )
    .map((val) => val.id)
    .filter((value: any) => value !== 'NaN'),
  website_category_id: (
    (action.website_category_id || []) as Domain.Website.WebsiteSuggestion[]
  ).map((val) => val.id),
  website_category_id_to_exclude: (
    (action.website_category_id_to_exclude ||
      []) as Domain.Website.WebsiteSuggestion[]
  ).map((val) => val.id),
  synchronous_computation: action.synchronous_computation ?? false,
});
