import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ProductCategoryRaw } from 'product-types/src/domain/productCategory';

export interface CategoryRequest extends EndpointRequests {}

export type CategoryResponse = Array<ProductCategoryRaw>;

const getProductCategories = new Endpoint<CategoryRequest, CategoryResponse>({
  url: '/api/me/categories/product',
  method: 'GET',
  headers: {},
});

export default getProductCategories;
