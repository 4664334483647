import { v4 as uuidv4 } from 'uuid';
import { Label } from '../label/Label';

export interface SearchImagesResponse {
  duplicated_group_id: null | string | number;
  image_found: boolean;
  image_id: string | number;
  image_link: string;
}

export class SearchedImageModel {
  uid: string;

  duplicatedGroupId: number | string | null;

  imageFound: boolean;

  imageId: string | number;

  imageLink: string;

  fileName?: string;

  imageLabel?: Label;

  selected?: boolean;

  constructor(
    data: Pick<
      SearchedImageModel,
      | 'duplicatedGroupId'
      | 'imageFound'
      | 'imageId'
      | 'imageLink'
      | 'fileName'
      | 'selected'
      | 'imageLabel'
    > &
      Partial<SearchedImageModel>,
  ) {
    this.duplicatedGroupId = data.duplicatedGroupId;
    this.imageFound = data.imageFound;
    this.imageId = data.imageId;
    this.imageLink = data.imageLink;
    this.fileName = data.fileName;
    this.imageLabel = data.imageLabel;
    this.selected = data.selected ?? false;
    this.uid = data?.uid ?? uuidv4();
  }

  static fromRaw(response: SearchImagesResponse): SearchedImageModel {
    return new SearchedImageModel({
      duplicatedGroupId: response.duplicated_group_id,
      imageFound: response.image_found,
      imageId: response.image_id,
      imageLink: response.image_link,
    });
  }
}
