import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ExportFeedRequestParameter } from 'types/network/Feed/Feed';

interface ExportPostsRequestModel extends EndpointRequests {
  data: ExportFeedRequestParameter;
}

const exportPosts = new Endpoint<ExportPostsRequestModel, NonNullable<unknown>>(
  {
    url: '/api/me/posts/export/xls',
    method: 'POST',
    headers: {},
  },
);

export default exportPosts;
