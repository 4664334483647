import { v4 as uuid } from 'uuid';
import { Label } from '../label/Label';

export interface UploadPostImageRaw {
  label: string;
  tags: Array<string>;
  url: string;
}
export class UploadPostImage {
  uid: string;

  url: string;

  label: Label | null;

  tags: Array<null | any>;

  constructor(
    prop: Pick<UploadPostImage, 'label' | 'tags' | 'url'> &
      Partial<UploadPostImage>,
  ) {
    this.uid = prop?.uid ?? uuid();
    this.label = prop.label;
    this.tags = prop.tags;
    this.url = prop.url;
  }

  toRaw(): UploadPostImageRaw {
    return {
      label: this.label?.name ?? '',
      tags: this.tags.filter((t) => t !== null).map((tag) => tag.value),
      url: this.url,
    };
  }
}
