import { KeywordRaw } from 'product-types/src/domain/keyword/Keyword';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface SaveKeywoardResponseModel {
  keywords: Array<KeywordRaw>;
}

interface SaveKeywordRequestModel extends EndpointRequests {
  data: {
    id: number;
  };
}

const removeKeyword = new Endpoint<
  SaveKeywordRequestModel,
  SaveKeywoardResponseModel
>({
  url: '/api/me/crawling_config/keywords',
  method: 'DELETE',
  headers: {},
});

export default removeKeyword;
