import { snakeToCamel } from './snakeToCamel';

export function snakeToCamelNested<T>(
  object: T,
): Transform.SnakeToCamelCaseNested<T> {
  return Object.keys(object as any).reduce((acc, key) => {
    acc[snakeToCamel(key)] = object[key];
    return acc;
  }, {}) as Transform.SnakeToCamelCaseNested<T>;
}
