import { v4 as uuid } from 'uuid';
import { Filter } from 'types/filters/AtomicFilters/Filter';
import { DisplayingFilterValue } from 'types/filters/AtomicFilters/DisplayingFilterValue';
import { QueryValue } from 'product-types/src/network/Query/Query';

export interface DashboardSyncComputationQueryValue extends QueryValue {
  synchronous_computation: boolean;
}

export class DashboardSyncComputation implements Filter {
  uuid: string;

  label: string;

  value: boolean;

  constructor(
    params?: Partial<
      Pick<DashboardSyncComputation, 'uuid' | 'label' | 'value'>
    >,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || false;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [];
  }

  removeFilterValue() {
    return new DashboardSyncComputation({
      uuid: this.uuid,
      label: this.label,
      value: !this.value,
    });
  }

  displayFilterComponent() {
    return null;
  }

  component() {
    return null;
  }

  get queryFilterValue(): DashboardSyncComputationQueryValue {
    return {
      synchronous_computation: this.value,
    };
  }

  static readFilterFromQuery(): DashboardSyncComputation {
    return new DashboardSyncComputation();
  }

  static readFilterFromSavedFitler(): DashboardSyncComputation {
    return new DashboardSyncComputation();
  }
}
