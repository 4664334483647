import { Endpoint, EndpointRequests } from '../../../../endpoint';

export interface InviteUserModel extends EndpointRequests {
  data: {
    role_id: number;
    organisation_id: number;
    email: string;
  };
}

export interface InviteUserResponseModel {
  message: string;
}

const inviteUser = new Endpoint<InviteUserModel, InviteUserResponseModel>({
  url: '/api/users',
  method: 'POST',
  headers: {},
});

export default inviteUser;
