import { Divider, Flex, Input } from 'antd';
import * as Domain from 'product-types/src/domain/Domain';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { RadioButton } from 'product-ui/src/components/atoms/RadioButton/RadioButton';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import {
  FeatureLabelTypes,
  FeatureLabelTypesOptions,
} from '../../../types/filters/AtomicFiltersImplementation/FeatureLabel/FeatureLabelValue';
import { FeatureLabelFilter } from '../../../types/filters/AtomicFiltersImplementation/FeatureLabel/FeatureLabelFilter';
import { Filter } from '../../../types/filters/AtomicFilters/Filter';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';

export interface ImageFeatureLabelProp {
  onChange: (v: Filter) => void;
  value: FeatureLabelFilter;
}
export const PostFeatureLabel = (props: ImageFeatureLabelProp) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const [inputValue, setInputValue] = useState('');

  const currentElements = useMemo(
    () =>
      (context.moderationReasons ?? []).filter(
        (reason) => reason.type.toLowerCase() === 'post',
      ),
    [context.moderationReasons],
  );

  const filteredElements = useMemo(
    () =>
      currentElements.filter((reason) =>
        reason.name.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    [currentElements, inputValue],
  );

  const handleChangeCheckbox = useCallback(
    (moderationReasonId, value) => {
      const reason = filteredElements.find(
        (reas) => reas.moderationReasonId === moderationReasonId,
      ) as Domain.ModerationReason.ModerationReason;
      if (value) {
        if (
          props.value.value.imageReasons.length ===
          currentElements.length - 1
        ) {
          props.onChange(
            new FeatureLabelFilter({
              ...props.value,
              value: props.value.value.addPostReason(
                reason,
                FeatureLabelTypes.All,
              ),
            }),
          );
        } else {
          props.onChange(
            new FeatureLabelFilter({
              ...props.value,
              value: props.value.value.addPostReason(reason),
            }),
          );
        }
      } else {
        props.onChange(
          new FeatureLabelFilter({
            ...props.value,
            value: props.value.value.removePostReason(reason),
          }),
        );
      }
    },
    [props.onChange, props.value, filteredElements, currentElements],
  );

  const handleClickAll = useCallback(
    (value) => {
      if (value) {
        props.onChange(
          new FeatureLabelFilter({
            ...props.value,
            value: props.value.value.setPostReason(
              currentElements,
              FeatureLabelTypes.All,
            ),
          }),
        );
      } else {
        props.onChange(
          new FeatureLabelFilter({
            ...props.value,
            value: props.value.value.setPostReason([]),
          }),
        );
      }
    },
    [currentElements, props.value, props.onChange],
  );

  const handleLabelChange = useCallback(
    (e: FeatureLabelTypes) => {
      props.onChange(
        new FeatureLabelFilter({
          ...props.value,
          value: props.value.value.setPostLabelType(e),
        }),
      );
    },
    [props.onChange, props.value],
  );

  const labelForAllFeaturesCheckbox =
    props.value.value.postReasons.length !== currentElements.length &&
    props.value.value.postReasons.length !== 0
      ? `${props.value.value.postReasons.length} items selected`
      : 'All features';

  return (
    <Flex
      vertical
      style={{
        padding: '0 1.5rem 1.5rem',
      }}
    >
      {FeatureLabelTypesOptions.map((o) => (
        <RadioButton
          label={o.label}
          onChange={() => handleLabelChange(o.value)}
          checked={props.value.value.labelTypePost === o.value}
        />
      ))}
      <Divider
        style={{
          margin: '10px 0',
        }}
      />
      <Input
        value={inputValue}
        onInput={(e) => setInputValue(e.target.value)}
        placeholder="Search"
        prefix={<NaveeIcon.SearchIcon />}
      />
      <StyledCheckbox>
        <Checkbox
          label={labelForAllFeaturesCheckbox}
          onChange={handleClickAll}
          indeterminate={
            props.value.value.postReasons.length !== currentElements.length &&
            props.value.value.postReasons.length !== 0
          }
          checked={
            (props.value.value.postReasons.length === currentElements.length &&
              currentElements.length !== 0) ||
            props.value.value.labelTypePost === FeatureLabelTypes.All
          }
          style={{
            marginTop: '10px',
          }}
        />
      </StyledCheckbox>
      <Divider
        style={{
          margin: '10px 0',
        }}
      />
      <div
        style={{
          maxHeight: '300px',
          overflowY: 'auto',
          padding: '10px 0',
        }}
      >
        {filteredElements.map((reason) => (
          <>
            <StyledCheckbox>
              <Checkbox
                key={`${reason.id}-checkbox`}
                label={reason.name}
                checked={props.value.value.hasPostReason(reason)}
                onChange={(e: boolean) =>
                  handleChangeCheckbox(reason.moderationReasonId, e)
                }
              />
            </StyledCheckbox>
            <Divider
              key={`${reason.id}-divider`}
              style={{
                margin: '10px 0',
              }}
            />
          </>
        ))}
      </div>
    </Flex>
  );
};
