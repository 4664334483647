import * as AdvancedStatistics from './AdvancedStatistics/AdvancedStatistics';
import * as Vendors from './Clusters/Clusters';
import * as Feed from './Feed/Feed';
import * as Post from './Post/Post';
import * as Account from './Account/Account';
import * as Website from './Website/Website';
import * as Dashboard from './Dashboard/index';
import * as UploadHistory from './UploadHistory/UploadHistory';

const Network = {
  AdvancedStatistics,
  Vendors,
  Website,
  Feed,
  Post,
  Account,
  Dashboard,
  UploadHistory,
};

export default Network;
