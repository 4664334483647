import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { CategoryModel } from 'product-types/src/domain/category/Category';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import {
  CategoryFilterValue,
  readFilterFromQueryProps,
} from './CategoryFilterValue';

export interface CategoryFilterQueryValue extends QueryValue {
  category_id: Array<CategoryModel>;
}

export class CategoryFilter implements Filter {
  uuid: string;

  label: string;

  value: CategoryFilterValue;

  constructor(
    params?: Partial<Pick<CategoryFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || CategoryFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [
      ...this.value.selectedCategories.map((category) => ({
        name: FilterTypeEnum.categoryId,
        value: category,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.categoryId + category.id,
      })),
    ];
  }

  displayFilterComponent() {
    return null;
  }

  component() {
    return null;
  }

  get queryFilterValue(): CategoryFilterQueryValue {
    return {
      category_id: this.value.selectedCategories,
    };
  }

  removeFilterValue() {
    this.value = CategoryFilterValue.defaultValue;
  }

  static readFilterFromQuery(props: readFilterFromQueryProps): CategoryFilter {
    return new CategoryFilter({
      value: CategoryFilterValue.readFilterFromQuery(props),
    });
  }
}
