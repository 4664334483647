/*
 *
 * Moderation actions
 *
 */

import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { PostModerationModel } from 'product-types/src/domain/post/PostModerationModel';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import {
  ADD_COMMENT,
  DELETE_COMMENT,
  LOAD_NEXT_POST_TO_MODERATE,
  LOAD_PREV_POST_TO_MODERATE,
  UPDATE_POST_FOR_MODERATE,
  REFRESH_POST,
  UPDATE_MODERATION_REASON,
  RESET_STATE,
  UPDATE_FILTERS_RELATED_POSTS,
  MODERATE_POST_AND_UPDATE_IN_PLACE,
  LOAD_POST_RESOURCES,
  FINISH_MODERATION,
  PERFORM_MODERATION_MULTIPLE,
} from './constants';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function updateRelatedPostFilters(payload: TableParams) {
  return {
    type: UPDATE_FILTERS_RELATED_POSTS,
    payload,
  };
}

export function finishModerationAction() {
  return {
    type: FINISH_MODERATION,
  };
}

export function loadNextPostToModerateAction(state: QueryValue) {
  return {
    type: LOAD_NEXT_POST_TO_MODERATE,
    ...state,
  };
}

export function loadPrevPostToModerateAction(state, set_loading = () => {}) {
  return {
    type: LOAD_PREV_POST_TO_MODERATE,
    ...state,
    set_loading,
  };
}

export function updatePostForModeration(
  post: FetchableData<PostModerationModel>,
  number_of_posts_to_moderate?: number,
  post_moderation_index?: number,
  total_number_of_posts?: number,
) {
  return {
    type: UPDATE_POST_FOR_MODERATE,
    post,
    number_of_posts_to_moderate,
    post_moderation_index,
    total_number_of_posts,
  };
}

export function addCommentAction({
  comment,
  group,
  objectId,
  fileIds,
  success_action,
}) {
  return {
    type: ADD_COMMENT,
    data: { comment, file_ids: fileIds },
    group,
    objectId,
    success_action,
  };
}

export function deleteCommentAction({
  group,
  commentId,
  objectId,
  success_action,
}) {
  return {
    type: DELETE_COMMENT,
    group,
    commentId,
    objectId,
    success_action,
  };
}

export function loadPostResourcesAction(postId: number) {
  return {
    type: LOAD_POST_RESOURCES,
    postId,
  };
}

export function refreshPostAction(post_id: number) {
  return {
    type: REFRESH_POST,
    post_id,
  };
}

interface ModereatePostParams {
  postIds: Array<number | string>;
  status?: string;
  checked?: boolean;
  validated?: boolean;
  global_category_id?: number;
  success_action?: () => void;
}

export function performPostArrayModerationAction(prop: ModereatePostParams) {
  let successMessage = 'updated';
  const posts = prop.postIds.map((post_id) => {
    const to_return: any = { id: post_id };
    if (prop.global_category_id) {
      successMessage = 'category changed';
      to_return.global_category_id = prop.global_category_id;
    }
    if (prop.status) {
      to_return.status = prop.status;
      if (prop.status && prop.status !== '-') {
        successMessage = 'moderated';
      } else {
        successMessage = 'unmoderated';
      }
    }
    if (prop.checked !== null && prop.checked !== undefined) {
      to_return.qa_checked = prop.checked;
      if (prop.checked) {
        successMessage = 'checked';
      } else {
        successMessage = 'unchecked';
      }
    }
    if (prop.validated !== null && prop.validated !== undefined) {
      to_return.validated = prop.validated;
      if (prop.validated) {
        successMessage = 'validated';
      } else {
        successMessage = 'unvalidated';
      }
    }
    return to_return;
  });
  return {
    type: PERFORM_MODERATION_MULTIPLE,
    data: { posts },
    success_action: prop.success_action,
    successMessage,
  };
}

export function moderatePostAndUpdateInPlaceAction(
  prop: Omit<ModereatePostParams, 'postIds' | 'success_action'> & {
    postId: number;
  },
) {
  let successMessage = 'updated';
  const post: any = { id: prop.postId };
  if (prop.global_category_id) {
    successMessage = 'category changed';
    post.global_category_id = prop.global_category_id;
  }
  if (prop.status) {
    post.status = prop.status;
    if (prop.status && prop.status !== '-') {
      successMessage = 'moderated';
    } else {
      successMessage = 'unmoderated';
    }
  }
  if (prop.checked !== null && prop.checked !== undefined) {
    post.qa_checked = prop.checked;
    if (prop.checked) {
      successMessage = 'checked';
    } else {
      successMessage = 'unchecked';
    }
  }
  if (prop.validated !== null && prop.validated !== undefined) {
    post.validated = prop.validated;
    if (prop.validated) {
      successMessage = 'validated';
    } else {
      successMessage = 'unvalidated';
    }
  }
  return {
    type: MODERATE_POST_AND_UPDATE_IN_PLACE,
    data: post,
    successMessage,
  };
}

export function updateModerationReason({
  reasonId,
  objectId,
  moderationReasonId,
  regions,
  value,
  success,
}: {
  reasonId?: number;
  objectId: number;
  moderationReasonId: number;
  regions?: Array<any>;
  value?: boolean;
  success?: () => void;
}) {
  return {
    type: UPDATE_MODERATION_REASON,
    reasonId,
    moderationReasonId,
    objectId,
    regions,
    value,
    success,
  };
}
