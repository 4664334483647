/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidGen } from 'uuid';
import { ScrollContext } from 'product-ui/src/providers/ScrollProvider';
import { Dropdown, Flex } from 'antd';
import { Select } from 'product-ui/src/components/atoms/Select';
import Typography from 'product-ui/src/components/atoms/Typography';
import { Badge } from 'product-ui/src/components/atoms/Badge/Badge';
import { useOnClickOutside } from 'product-utils/src/hooks/useOnClickOutside';

interface FilterWithMenuWrapperProps {
  text: string;
  className?: string;
  badgeText?: string | number;
  dataTestId?: string;
  preventClosing?: boolean;
  forceClose?: any;
  renderer?: ((originNode: React.ReactNode) => React.ReactNode) | undefined;
}

const onClickDoNothing = (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  return false;
};

function FilterWithMenuWrapper({
  text,
  className = '',
  renderer,
  badgeText = undefined,
  dataTestId = '',
  preventClosing = false,
  forceClose,
}: FilterWithMenuWrapperProps) {
  const [uuid] = useState(uuidGen());
  const popupContentRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [canOpen, setCanOpen] = useState(true);
  const {
    addReference,
    removeReference,
    lockScroll,
    unLockScroll,
    references,
  } = useContext(ScrollContext);

  useEffect(() => {
    addReference(uuid);
    return () => removeReference(uuid);
  }, []);

  useEffect(() => {
    if (forceClose) {
      if (references[uuid] !== undefined) {
        unLockScroll(uuid);
      }
      setOpen(false);
    }
  }, [forceClose]);

  useOnClickOutside(popupContentRef, () => {
    if (references[uuid] && !preventClosing) {
      unLockScroll(uuid);
    }
  });

  const onOpenChange = useCallback(
    (op: boolean) => {
      if (op) {
        lockScroll(uuid);
        setOpen(() => true);
      } else {
        unLockScroll(uuid);
        setCanOpen(false);
        setTimeout(() => {
          setCanOpen(true);
        }, 150);
        setOpen(() => false);
      }
    },
    [canOpen, uuid, lockScroll, uuid, unLockScroll, setCanOpen],
  );

  const placeholder = useMemo(
    () => (
      <Flex
        align="center"
        justify="space-between"
        style={{
          maxWidth: '158px',
          overflow: 'hidden',
        }}
        gap={8}
      >
        <Typography
          variant="small"
          color="var(--primary-black)"
          fontWeight="medium"
          style={{
            textOverflow: 'ellipsis',
            flexGrow: 1,
            color: 'var(--primary-black)',
            flexShrink: 1,
            overflow: 'hidden',
          }}
        >
          {text}
        </Typography>
        {badgeText && (
          <Badge
            style={{
              height: 32,
              minWidth: 32,
              color: 'var(--primary-black)',
              fontWeight: 700,
              flexShrink: 0,
            }}
          >
            {badgeText}
          </Badge>
        )}
      </Flex>
    ),
    [text, badgeText],
  );
  return (
    <Dropdown
      dropdownRender={renderer}
      destroyPopupOnHide
      trigger={['click']}
      onOpenChange={onOpenChange}
      open={preventClosing || open}
    >
      <Select
        className={className}
        placeholder={placeholder}
        data-testid={dataTestId}
        menuItemSelectedIcon={null}
        dropdownRender={() => <></>}
        open={open}
        onClick={onClickDoNothing}
      />
    </Dropdown>
  );
}

export default FilterWithMenuWrapper;
