/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import { Tabs } from 'product-ui/src/components/atoms/Tabs/Tabs';
import { Flex } from 'antd';
import { useSelector } from 'react-redux';
import { AppState } from 'store/storeAccess';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import { ProductInformationFilter as ProductInformationFilterModel } from '../../../types/filters/AtomicFiltersImplementation/ProductInformation/ProductInformation';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import StyledSlider from './StyledSlider';

export interface ProductInformationFilterProps extends FilterParams {
  value: ProductInformationFilterModel;
  onChange: (v: Filter) => void;
}

export const ProductInformationFilter = (
  props: ProductInformationFilterProps,
) => {
  const currency = useSelector(
    (state: AppState) =>
      state.loginPage?.currentUser?.data?.organisation?.currency,
  );
  const onSelectPriceFilter = useCallback(
    (price: [string | number | null, string | number | null]) => {
      const minPriceValue =
        typeof price[0] === 'number' ? price[0] : parseFloat(price[0] ?? '');
      const maxPriceValue =
        typeof price[1] === 'number' ? price[1] : parseFloat(price[1] ?? '');
      props.onChange(
        new ProductInformationFilterModel({
          ...props.value,
          value: props.value.value.setPrice(
            new Domain.Price.Price({
              min: Math.abs(minPriceValue) || null,
              max: Math.abs(maxPriceValue) || null,
            }),
          ),
        }),
      );
    },
    [props.value, props.onChange],
  );

  const onSelectStockFilter = useCallback(
    (stock: [string | number | null, string | number | null]) => {
      const minFolowersCount =
        typeof stock[0] === 'number' ? stock[0] : parseFloat(stock[0] ?? '');
      const maxStockCount =
        typeof stock[1] === 'number' ? stock[1] : parseFloat(stock[1] ?? '');
      props.onChange(
        new ProductInformationFilterModel({
          ...props.value,
          value: props.value.value.setStock(
            new Domain.Stock.Stock({
              min: Math.abs(minFolowersCount) || null,
              max: Math.abs(maxStockCount) || null,
            }),
          ),
        }),
      );
    },
    [props.value, props.onChange],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          width: 415,
          minHeight: 60,
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <Tabs
          id="price-filter-container"
          defaultActiveKey="1"
          style={{ width: '100%' }}
          animated={false}
          items={[
            {
              key: '1',
              label: 'PRICE',
              children: (
                <Flex
                  vertical
                  gap="1rem"
                  style={{
                    padding: '0 1.5rem 1.5rem',
                  }}
                >
                  <StyledSlider
                    ids={['price-from-range-input', 'price-to-range-input']}
                    suffix={new Intl.NumberFormat('en-us', {
                      style: 'currency',
                      currency: currency ?? 'EUR',
                      maximumSignificantDigits: 1,
                    })
                      .format()
                      .replace('NaN', '')
                      .trim()}
                    value={[
                      props.value.value.price.min,
                      props.value.value.price.max,
                    ]}
                    max={5000}
                    onChange={onSelectPriceFilter}
                  />
                </Flex>
              ),
            },
            {
              key: '2',
              label: 'STOCK',
              children: (
                <Flex
                  vertical
                  gap="1rem"
                  style={{
                    padding: '0 1.5rem 1.5rem',
                  }}
                >
                  <StyledSlider
                    ids={['stock-from-range-input', 'stock-to-range-input']}
                    value={[
                      props.value.value.stock.min,
                      props.value.value.stock.max,
                    ]}
                    suffix=""
                    step={1}
                    precision={0}
                    onChange={onSelectStockFilter}
                    max={1000000}
                  />
                </Flex>
              ),
            },
          ]}
        />
      </Flex>
    ),
    [props.value.value, onSelectPriceFilter, onSelectStockFilter],
  );

  return (
    <FilterWithMenuWrapper
      text="Product Information"
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
      renderer={renderer}
      dataTestId="priceStockFilter"
    />
  );
};
