import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import websiteCategories from './websiteCategories';

export interface CommonEndpoitns extends EndpointContainer {
  websiteCategories: typeof websiteCategories;
}

const common: CommonEndpoitns = {
  websiteCategories,
  injectHTTPClient(client: HttpClient) {
    websiteCategories.injectHTTPClient(client);
  },
};

export default common;
