import { TagRaw } from 'product-types/src/domain/tag/Tag';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export type TagNamesResponse = {
  results: Array<TagRaw>;
};

export interface TagNamesRequest extends EndpointRequests {
  params: {
    ids: Array<string | number>;
  };
}

const tagNames = new Endpoint<TagNamesRequest, TagNamesResponse>({
  url: '/api/me/organisation/tags',
  method: 'GET',
  headers: {},
});

export default tagNames;
