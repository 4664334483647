/*
 *
 * DashboardPage actions
 *
 */

import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { DashboardWebsiteStateModel } from 'product-types/src/domain/dashboard/DashboardWebsiteStateModel';
import { DashboardCategoriesStateModel } from 'product-types/src/domain/dashboard/DashboardCategoriesStateModel';
import { DashboardImagesStateModel } from 'product-types/src/domain/dashboard/DashboardImagesStateModel';
import { DashboardTakedownStateModel } from 'product-types/src/domain/dashboard/DashboardTakedownStateModel';
import { DashboardRealValueSavedStateModel } from 'product-types/src/domain/dashboard/DashboardRealValueSavedStateModel';
import { DashboardCounterfeitAndPostsStateModel } from 'product-types/src/domain/dashboard/DashboardCounterfeitAndPostsStateModel';
import { DashboardMedianLifetimeStateModel } from 'product-types/src/domain/dashboard/DasboardMedianLifetimeStateModel';
import { DashboardAverageResultsStateModel } from 'product-types/src/domain/dashboard/DashboardAverageResultsStateModel';
import { DashboardGeographicalDestribution } from 'product-types/src/domain/dashboard/DashboardGeographicalDestribution';
import { DashboardKeywordModel } from 'product-types/src/domain/dashboard/DashboardKeywordModel';
import { DashboardWebsiteCategoryStat } from 'product-types/src/domain/dashboard/DashboardWebsiteCategoryStat';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import { DashboardValueMonitorModel } from 'product-types/src/domain/dashboard/DashboardValueMonitorModel';
import { DashboardEffectiveTakedownStatsModel } from 'product-types/src/domain/dashboard/DashboardEffectiveTakedownStatsModel';
import { DashboardEffectiveTakedownGraphModel } from 'product-types/src/domain/dashboard/DashboardEffectiveTakedownGraphModel';
import { DashboardModeratedPostsBreakdownModel } from 'product-types/src/domain/dashboard/DashboardModeratedPostsBreakdownModel';
import { DashboardWebsiteCategoriesBreakdownModel } from 'product-types/src/domain/dashboard/websites/DashboardWebsiteCategoriesBreakdownModel';
import { DashboardTakedownSentModel } from 'product-types/src/domain/dashboard/DashboardTakedownSentModel';
import { DashboardGeographyFeed } from 'product-types/src/domain/dashboard/geography/DashboardGeographyFeed';
import { DashboardKeywordsFoundModel } from 'product-types/src/domain/dashboard/DashboardKeywordsFoundModel';
import { DashboardKeywordsSearchedModel } from 'product-types/src/domain/dashboard/DashboardKeywordsSearchedModel';
import { DashboardKeywordsGraphModel } from 'product-types/src/domain/dashboard/DashboardKeywordsGraphModel';
import { DashboardMapGeographyModel } from 'product-types/src/domain/dashboard/mapGeography/DashboardMapGeographyModel';
import { DashboardModerationStatisticsPostsModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsPostsModel';
import { DashboardModerationStatisticsImagesModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsImagesModel';
import { DashboardModerationStatisticsAccountsModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsAccountsModel';
import { DashboardModerationStatisticsAccountsLabelsModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsAccountsLabelsModel';
import { DashboardModerationStatisticsImagesLabelsModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsImagesLabelsModel';
import { DashboardModerationStatisticsPostsLabelsModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsPostsLabelsModel';
import { DashboardTakedownSplitModel } from 'product-types/src/domain/dashboard/DashboardTakedownSplit';
import {
  CLEAR_SCHEDULE,
  LOAD_DUPLICATED_GROUPS,
  LOAD_GEOGRAPHICAL_DISTRIBUTION,
  LOAD_IMAGE_LABELS,
  LOAD_KEYWORDS,
  LOAD_KEY_FIGURES,
  LOAD_LABELS,
  LOAD_MODERATED_POSTS,
  LOAD_MORE_DUPLICATED_GROUPS,
  LOAD_MORE_WEBSITES,
  LOAD_MOST_COUNTERFEITED_CATEGORIES_DATA,
  LOAD_TAKEDOWN_STATE,
  LOAD_VALUE_MONITOR,
  LOAD_WEBSITES,
  LOAD_WEBSITE_CATEGORY_STAT,
  RESET_STATE,
  RUN_SEARCH,
  SCHEDULE_CALL,
  SELECT_FILTER,
  UPDATE_CATEGORIES,
  UPDATE_DUPLICATED_GROUPS,
  UPDATE_GEOGRAPHICAL_DISTRIBUTION,
  UPDATE_INFRIGING_IMAGES,
  UPDATE_INFRIGING_POSTS,
  UPDATE_KEYWORDS,
  UPDATE_KEY_FIGURES,
  UPDATE_MODERATED_IMAGES,
  UPDATE_MODERATED_POSTS,
  UPDATE_MODERATED_POSTS_BY_RULES,
  UPDATE_POSTS_TO_MODERATE,
  UPDATE_TAKEDOWN_STATE,
  UPDATE_VALUE_MONITOR,
  UPDATE_WEBSITES,
  UPDATE_WEBSITE_CATEGORY_STAT,
  LOAD_EFFECTIVE_TAKEDOWN_STATS,
  UPDATE_EFFECTIVE_TAKEDOWN_STATS,
  LOAD_EFFECTIVE_TAKEDOWN_GRAPH,
  UPDATE_EFFECTIVE_TAKEDOWN_GRAPH,
  LOAD_MODERATED_POSTS_BREAKDOWN,
  LOAD_WEBSITE_CATEGORIES_BREAKDOWN,
  UPDATE_MODERATED_POSTS_BREAKDOWN,
  UPDATE_WEBSITE_CATEGORIES_BREAKDOWN,
  LOAD_TAKEDOWN_SENT,
  UPDATE_TAKEDOWN_SENT,
  LOAD_GEOGRAPHY,
  UPDATE_GEOGRAPHY,
  LOAD_MODERATION_STATISTICS_POSTS,
  LOAD_MODERATION_STATISTICS_IMAGES,
  LOAD_MODERATION_STATISTICS_ACCOUNTS,
  UPDATE_MODERATION_STATISTICS_POSTS,
  UPDATE_MODERATION_STATISTICS_IMAGES,
  UPDATE_MODERATION_STATISTICS_ACCOUNTS,
  UPDATE_MODERATION_STATISTICS_ACCOUNTS_LABELS,
  UPDATE_MODERATION_STATISTICS_IMAGES_LABELS,
  UPDATE_MODERATION_STATISTICS_POSTS_LABELS,
  LOAD_MODERATION_STATISTICS_ACCOUNTS_LABELS,
  LOAD_MODERATION_STATISTICS_IMAGES_LABELS,
  LOAD_MODERATION_STATISTICS_POSTS_LABELS,
  LOAD_WORLD_GRAPH,
  UPDATE_WORLD_MAP,
  LOAD_WORLD_MAP,
  UPDATE_WORLD_GRAPH,
  LOAD_KEYWORDS_SEARCHED,
  LOAD_KEYWORDS_FOUND,
  LOAD_KEYWORDS_GRAPH,
  UPDATE_KEYWORDS_SEARCHED,
  UPDATE_KEYWORDS_FOUND,
  UPDATE_KEYWORDS_GRAPH,
  UPDATE_LATEST_DATE,
  LOAD_LATEST_DATE,
  UPDATE_TAKEDOWN_SPLIT,
  LOAD_TAKEDOWN_SPLIT,
} from './constants';
import {
  DashboardLoadFunction,
  DashboardLoadFunctionContract,
} from './reducer';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function loadLabelsAction() {
  return {
    type: LOAD_LABELS,
  };
}

export function scheduleCall(p: {
  callback: DashboardLoadFunction;
  props: DashboardLoadFunctionContract & { [key: string]: any };
  id: string;
}) {
  return {
    type: SCHEDULE_CALL,
    prop: p,
  };
}

export function runSearch() {
  return {
    type: RUN_SEARCH,
  };
}

export function clearSchedule() {
  return {
    type: CLEAR_SCHEDULE,
  };
}

export function loadImageLabelsAction(
  successAction,
  route = '/api/me/image_labels',
  set_loading = () => {},
) {
  return {
    type: LOAD_IMAGE_LABELS,
    successAction,
    route,
    set_loading,
  };
}

export function loadCategoriesSuccessAction(type, availableCategories) {
  return {
    type,
    availableCategories,
  };
}

export function loadLabelsSuccessAction(type, availableLabels) {
  return {
    type,
    availableLabels,
  };
}

export function loadImageLabelsSuccessAction(type, availableImageLabels) {
  return {
    type,
    availableImageLabels,
  };
}

export function selectFilterAction(field, value, type = SELECT_FILTER) {
  return {
    type,
    field,
    value,
  };
}

export function loadWebsites({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_WEBSITES,
    filter,
    abortController,
  };
}

export function loadMoreWebsites({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MORE_WEBSITES,
    filter,
    abortController,
  };
}

export function loadWorldGraph({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_WORLD_GRAPH,
    filter,
    abortController,
  };
}

export function loadLatestDate({
  abortController,
}: {
  abortController: AbortController;
}) {
  return {
    type: LOAD_LATEST_DATE,
    abortController,
  };
}

export function updateLatestDate(latestRefreshDate: FetchableData<string>) {
  return {
    type: UPDATE_LATEST_DATE,
    latestRefreshDate,
  };
}

export function loadWorldMap({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_WORLD_MAP,
    filter,
    abortController,
  };
}

export function updateWorldMap(
  map: FetchableData<Array<DashboardMapGeographyModel>>,
) {
  return {
    type: UPDATE_WORLD_MAP,
    map,
  };
}

export function updateWorldGraph(
  graph: FetchableData<Array<DashboardWorldGraphModel>>,
) {
  return {
    type: UPDATE_WORLD_GRAPH,
    graph,
  };
}

export function updateWebsites(
  websites: FetchableData<DashboardWebsiteStateModel>,
) {
  return {
    type: UPDATE_WEBSITES,
    websites,
  };
}

export function updateCategories(
  categories: FetchableData<DashboardCategoriesStateModel>,
) {
  return {
    type: UPDATE_CATEGORIES,
    categories,
  };
}

export function loadCategories({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MOST_COUNTERFEITED_CATEGORIES_DATA,
    filter,
    abortController,
  };
}

export function loadDuplicatedGroups({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_DUPLICATED_GROUPS,
    filter,
    abortController,
  };
}

export function loadMoreDuplicatedGroups({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MORE_DUPLICATED_GROUPS,
    filter,
    abortController,
  };
}

export function updateDuplicateGroups(
  duplicatedGroups: FetchableData<DashboardImagesStateModel>,
) {
  return {
    type: UPDATE_DUPLICATED_GROUPS,
    duplicatedGroups,
  };
}

export function loadValueMonitor({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_VALUE_MONITOR,
    filter,
    abortController,
  };
}

export function updateValueMonitor(
  valueMonitor: FetchableData<DashboardValueMonitorModel>,
) {
  return {
    type: UPDATE_VALUE_MONITOR,
    valueMonitor,
  };
}

export function loadTakedownState({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_TAKEDOWN_STATE,
    filter,
    abortController,
  };
}

export function loadTakedownSplit({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_TAKEDOWN_SPLIT,
    filter,
    abortController,
  };
}

export function loadEffectiveTakedownStats({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_EFFECTIVE_TAKEDOWN_STATS,
    filter,
    abortController,
  };
}

export function loadEffectiveTakedownGraph({
  abortController,
}: {
  abortController: AbortController;
}) {
  return {
    type: LOAD_EFFECTIVE_TAKEDOWN_GRAPH,
    abortController,
  };
}

export function loadModeratedPostsBreakdown({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MODERATED_POSTS_BREAKDOWN,
    filter,
    abortController,
  };
}

export function loadWebsiteCategoriesBreakdown({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_WEBSITE_CATEGORIES_BREAKDOWN,
    filter,
    abortController,
  };
}

export function loadKeywordsSearched({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_KEYWORDS_SEARCHED,
    filter,
    abortController,
  };
}

export function loadKeywordsFound({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_KEYWORDS_FOUND,
    filter,
    abortController,
  };
}

export function loadKeywordsGraph({
  filter,
  searchKeyword = undefined,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  searchKeyword?: Array<string>;
  abortController: AbortController;
}) {
  return {
    type: LOAD_KEYWORDS_GRAPH,
    filter,
    searchKeyword,
    abortController,
  };
}

export function loadTakedownSent({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_TAKEDOWN_SENT,
    filter,
    abortController,
  };
}

export function loadModerationStatisticsPosts({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MODERATION_STATISTICS_POSTS,
    filter,
    abortController,
  };
}

export function loadModerationStatisticsImages({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MODERATION_STATISTICS_IMAGES,
    filter,
    abortController,
  };
}

export function loadModerationStatisticsAccounts({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MODERATION_STATISTICS_ACCOUNTS,
    filter,
    abortController,
  };
}

export function loadModerationStatisticsAccountsLabels({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MODERATION_STATISTICS_ACCOUNTS_LABELS,
    filter,
    abortController,
  };
}

export function loadModerationStatisticsImagesLabels({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MODERATION_STATISTICS_IMAGES_LABELS,
    filter,
    abortController,
  };
}

export function loadModerationStatisticsPostsLabels({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MODERATION_STATISTICS_POSTS_LABELS,
    filter,
    abortController,
  };
}

export function loadKeyFigures({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_KEY_FIGURES,
    filter,
    abortController,
  };
}

export function loadGeographicDistribution({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_GEOGRAPHICAL_DISTRIBUTION,
    filter,
    abortController,
  };
}

export function loadGeography({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_GEOGRAPHY,
    filter,
    abortController,
  };
}

export function updateGeography(
  geography: FetchableData<DashboardGeographyFeed>,
) {
  return {
    type: UPDATE_GEOGRAPHY,
    geography,
  };
}
// todo: make 4 different actions for each key figure
export function updateKeyFigures({
  realValueSaved,
  counterfeitAndPosts,
  medianLifetime,
  averageResults,
}: {
  realValueSaved: FetchableData<DashboardRealValueSavedStateModel>;
  counterfeitAndPosts: FetchableData<DashboardCounterfeitAndPostsStateModel>;
  medianLifetime: FetchableData<DashboardMedianLifetimeStateModel>;
  averageResults: FetchableData<DashboardAverageResultsStateModel>;
}) {
  return {
    type: UPDATE_KEY_FIGURES,
    realValueSaved,
    counterfeitAndPosts,
    medianLifetime,
    averageResults,
  };
}

export function updateTakedwonState(
  takedownState: FetchableData<DashboardTakedownStateModel>,
) {
  return {
    type: UPDATE_TAKEDOWN_STATE,
    takedownState,
  };
}

export function updateEffectiveTakedownStats(
  effectiveTakedownStats: FetchableData<DashboardEffectiveTakedownStatsModel>,
) {
  return {
    type: UPDATE_EFFECTIVE_TAKEDOWN_STATS,
    effectiveTakedownStats,
  };
}

export function updateEffectiveTakedownGraph(
  effectiveTakedownGraph: FetchableData<DashboardEffectiveTakedownGraphModel>,
) {
  return {
    type: UPDATE_EFFECTIVE_TAKEDOWN_GRAPH,
    effectiveTakedownGraph,
  };
}

export function updateModeratedPostsBreakdown(
  moderatedPostsBreakdown: FetchableData<DashboardModeratedPostsBreakdownModel>,
) {
  return {
    type: UPDATE_MODERATED_POSTS_BREAKDOWN,
    moderatedPostsBreakdown,
  };
}

export function updateWebsiteCategoriesBreakdown(
  websiteCategoriesBreakdown: FetchableData<DashboardWebsiteCategoriesBreakdownModel>,
) {
  return {
    type: UPDATE_WEBSITE_CATEGORIES_BREAKDOWN,
    websiteCategoriesBreakdown,
  };
}

export function updateKeywordsSearched(
  keywordsSearched: FetchableData<DashboardKeywordsSearchedModel>,
) {
  return {
    type: UPDATE_KEYWORDS_SEARCHED,
    keywordsSearched,
  };
}

export function updateKeywordsFound(
  keywordsFound: FetchableData<DashboardKeywordsFoundModel>,
) {
  return {
    type: UPDATE_KEYWORDS_FOUND,
    keywordsFound,
  };
}

export function updateKeywordsGraph(
  keywordsGraph: FetchableData<DashboardKeywordsGraphModel>,
) {
  return {
    type: UPDATE_KEYWORDS_GRAPH,
    keywordsGraph,
  };
}

export function updateTakedownSent(
  takedownSent: FetchableData<DashboardTakedownSentModel>,
) {
  return {
    type: UPDATE_TAKEDOWN_SENT,
    takedownSent,
  };
}

export function updateTakedownSplit(
  takedownSplitByType: FetchableData<DashboardTakedownSplitModel>,
) {
  return {
    type: UPDATE_TAKEDOWN_SPLIT,
    takedownSplitByType,
  };
}

export function updateModerationStatisticsPosts(
  moderationStatisticsPosts: FetchableData<DashboardModerationStatisticsPostsModel>,
) {
  return {
    type: UPDATE_MODERATION_STATISTICS_POSTS,
    moderationStatisticsPosts,
  };
}

export function updateModerationStatisticsImages(
  moderationStatisticsImages: FetchableData<DashboardModerationStatisticsImagesModel>,
) {
  return {
    type: UPDATE_MODERATION_STATISTICS_IMAGES,
    moderationStatisticsImages,
  };
}

export function updateModerationStatisticsAccounts(
  moderationStatisticsAccounts: FetchableData<DashboardModerationStatisticsAccountsModel>,
) {
  return {
    type: UPDATE_MODERATION_STATISTICS_ACCOUNTS,
    moderationStatisticsAccounts,
  };
}

export function updateModerationStatisticsAccountsLabels(
  moderationStatisticsAccountsLabels: FetchableData<DashboardModerationStatisticsAccountsLabelsModel>,
) {
  return {
    type: UPDATE_MODERATION_STATISTICS_ACCOUNTS_LABELS,
    moderationStatisticsAccountsLabels,
  };
}

export function updateModerationStatisticsImagesLabels(
  moderationStatisticsImagesLabels: FetchableData<DashboardModerationStatisticsImagesLabelsModel>,
) {
  return {
    type: UPDATE_MODERATION_STATISTICS_IMAGES_LABELS,
    moderationStatisticsImagesLabels,
  };
}

export function updateModerationStatisticsPostsLabels(
  moderationStatisticsPostsLabels: FetchableData<DashboardModerationStatisticsPostsLabelsModel>,
) {
  return {
    type: UPDATE_MODERATION_STATISTICS_POSTS_LABELS,
    moderationStatisticsPostsLabels,
  };
}

export function updateGeographicalDestribution(
  geographicalDistribution: FetchableData<
    Array<DashboardGeographicalDestribution>
  >,
) {
  return {
    type: UPDATE_GEOGRAPHICAL_DISTRIBUTION,
    geographicalDistribution,
  };
}

export function updateKeywords(
  keywords: FetchableData<Array<DashboardKeywordModel>>,
) {
  return {
    type: UPDATE_KEYWORDS,
    keywords,
  };
}

export function loadKeywords({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_KEYWORDS,
    filter,
    abortController,
  };
}

export function updateInfrigingPosts(
  infrigingPostsNumber: FetchableData<number>,
) {
  return {
    type: UPDATE_INFRIGING_POSTS,
    infrigingPostsNumber,
  };
}

export function updateInfrigingImages(
  infrigingImagesNumber: FetchableData<number>,
) {
  return {
    type: UPDATE_INFRIGING_IMAGES,
    infrigingImagesNumber,
  };
}

export function updateModeratedImages(
  moderatedImagesNumber: FetchableData<number>,
) {
  return {
    type: UPDATE_MODERATED_IMAGES,
    moderatedImagesNumber,
  };
}

export function updatePostsToModerate(postsToModerate: FetchableData<number>) {
  return {
    type: UPDATE_POSTS_TO_MODERATE,
    postsToModerate,
  };
}

export function loadModeratedPosts({
  filter,
  abortController,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
}) {
  return {
    type: LOAD_MODERATED_POSTS,
    filter,
    abortController,
  };
}

export function loadWebsiteCategoryStats({
  filter,
  abortController,
  websiteCategories,
}: {
  filter: DashboardFiltersQueryValue;
  abortController: AbortController;
  websiteCategories: Array<WebsiteCategory>;
}) {
  return {
    type: LOAD_WEBSITE_CATEGORY_STAT,
    filter,
    websiteCategories,
    abortController,
  };
}

export function updateModeratedPosts(
  moderatedPostsNumber: FetchableData<number>,
) {
  return {
    type: UPDATE_MODERATED_POSTS,
    moderatedPostsNumber,
  };
}

export function updateModeratedPostsByRules(
  moderatedPostsByRulesNumber: FetchableData<number>,
) {
  return {
    type: UPDATE_MODERATED_POSTS_BY_RULES,
    moderatedPostsByRulesNumber,
  };
}

export function updateWebsiteCategoryStat(
  websiteCategoryStat: FetchableData<DashboardWebsiteCategoryStat>,
) {
  return {
    type: UPDATE_WEBSITE_CATEGORY_STAT,
    websiteCategoryStat,
  };
}
