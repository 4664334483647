import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { AverageResultsResponse } from 'product-types/src/domain/dashboard/DashboardAverageResultsStateModel';

interface AverageResultsRequestParams extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface AverageResultsRequest extends EndpointRequests {
  params: AverageResultsRequestParams;
}

const averageResults = new Endpoint<
  AverageResultsRequest,
  AverageResultsResponse
>({
  url: '/api/me/stats/average_results',
  method: 'GET',
  headers: {},
});

export default averageResults;
