/* eslint-disable no-param-reassign */
import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import { notification } from 'antd';
import { captureException } from '@sentry/react';
import { NetworkError } from 'product-types/src/common/Error/NetworkError';
import { AuthenticationError } from 'product-types/src/common/Error/AuthenticationError';
import { TimeoutError } from 'product-types/src/common/Error/TimeoutError';
import { BadRequestError } from 'product-types/src/common/Error/BadRequestError';
import { InternalServerError } from 'product-types/src/common/Error/InternalServerError';
import { ConflictError } from 'product-types/src/common/Error/ConflictError';
import { NotFoundError } from 'product-types/src/common/Error/NotFound';
import { UnprocessableEntityError } from 'product-types/src/common/Error/UnprocessableEntityError';
import { EndpointRequests } from '../endpoint';

export class HttpClient {
  client: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      timeout: 3 * 60 * 1000,
      validateStatus: (status) => status >= 200 && status < 300,
      baseURL: `${window.location.origin}/api`,
      maxRedirects: 0,
      paramsSerializer: (params) => queryString.stringify(params),
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    this.client.interceptors.request.use(
      this.requestSuccessInterceptor as any,
      this.requestErrorInterceptor,
    );
    this.client.interceptors.response.use(
      this.responseSuccessInterceptor,
      this.responseErrorInterceptor,
    );
  }

  get requestSuccessInterceptor() {
    return (config: EndpointRequests & AxiosRequestConfig) => {
      if (!config.url) {
        return config;
      }
      const currentUrl = new URL(config.url, config.baseURL);
      if (!config.headers) {
        config.headers = {};
      }
      config.headers = {
        ...config.headers,
        Accept: '*/*',
        'Content-Type': config.headers['Content-Type'] || 'application/json',
        'x-csrf-token':
          document
            ?.querySelector("meta[name='csrf-token']")
            ?.getAttribute('content') || undefined,
      };
      Object.entries(config.urlParams || {}).forEach(([k, v]) => {
        currentUrl.pathname = currentUrl.pathname.replace(`:${k}`, v);
      });
      return {
        ...config,
        url: currentUrl.pathname,
      };
    };
  }

  get requestErrorInterceptor() {
    return (error) => {
      captureException(error);
      return Promise.reject(error);
    };
  }

  get responseSuccessInterceptor() {
    return (config) => {
      if (config.cached) return config;
      const responseUrl = new URL(config.request.responseURL);
      const requestUrl = new URL(
        `${config.config.baseURL}${config.config.url}`,
      );
      if (requestUrl.pathname !== responseUrl.pathname) {
        window.location.href = responseUrl.pathname;
        throw new NetworkError('Redirect is not allowed', 302);
      }
      return config;
    };
  }

  get responseErrorInterceptor() {
    return (error: AxiosError) => {
      if (error.name === 'CanceledError') {
        return Promise.reject(error);
      }
      if (error && error.response && error.response.status === 401) {
        window.location.href = '/login';
        throw new AuthenticationError();
      }
      if (!error.config!.suppressToastrOnError) {
        notification.error({
          message:
            error.response.data!['error message'] ??
            error.response.data?.message ??
            `Error when making a call: ${error.message}`,
          placement: 'bottomRight',
          duration: 5,
        });
      }

      if (error && error.response) {
        const { status } = error.response;
        if (status === 400) {
          throw new BadRequestError(
            error.response.data!['error message'] ??
              error.response.data?.message,
          );
        }
        if (status === 404) {
          throw new NotFoundError(
            error.response.data!['error message'] ??
              error.response.data?.message,
          );
        }
        if (status === 409) {
          throw new ConflictError(
            error.response.data!['error message'] ??
              error.response.data?.message,
          );
        }
        if (status === 422) {
          throw new UnprocessableEntityError(
            error.response.data!['error message'] ??
              error.response.data?.message,
          );
        }
        if (status === 500) {
          throw new InternalServerError();
        }
        if (status === 504) {
          throw new TimeoutError();
        }
      }

      return Promise.reject(error);
    };
  }
}

const httpClient = new HttpClient();

export default httpClient;
