import BackendService from '../backendService';
import httpClient from '../client/client';
import endpoints, { ProductMonitorEndpoints } from './endpoints';

const ProductMonitor = new BackendService<ProductMonitorEndpoints>({
  client: httpClient,
  endpoints,
});

export default ProductMonitor;
