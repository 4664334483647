import {
  NotificationRawModel,
  NotificationType,
} from 'product-types/src/domain/notification/Notification';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface NotificationsRequestModel extends EndpointRequests {
  params: {
    notification_type?: NotificationType;
    viewed: boolean;
    offset: number;
    perpage: number;
  };
}

const getNotifications = new Endpoint<
  NotificationsRequestModel,
  Array<NotificationRawModel>
>({
  url: '/api/me/notifications',
  method: 'GET',
  headers: {},
});

export default getNotifications;
