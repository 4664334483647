import dayjs from 'dayjs';

export interface HistoricalPriceRaw {
  date: string;
  price: number;
}

export class HistoricalPrice {
  date: Date;

  price: number;

  constructor(prop: Pick<HistoricalPrice, 'date' | 'price'>) {
    this.date = prop.date;
    this.price = prop.price;
  }

  get formattedDate() {
    return dayjs(this.date).format('DD MMM YY');
  }
}
