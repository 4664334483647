import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface NextWebsiteToModerateRequest extends EndpointRequests {
  data: FeedRequestParameter;
}

interface NextWebsiteToModerateResponse {}

const nextWebsiteToModerate = new Endpoint<
  NextWebsiteToModerateRequest,
  NextWebsiteToModerateResponse
>({
  url: '/api/me/accounts_moderation/next_account_to_moderate',
  method: 'POST',
  headers: {},
});

export default nextWebsiteToModerate;
