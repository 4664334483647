import React, { forwardRef } from 'react';
import { InputNumberProps } from 'antd/es/input-number';
import { InputNumber as AntdInputNumber } from 'antd';
import styled from 'styled-components';

const StyledInputNumber = styled(AntdInputNumber)`
  .ant-input-number-input {
    line-height: 1.714286;
  }
  .ant-input-number-group .ant-input-number-group-addon {
    font-size: 16px;
  }
`;

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (props, ref) => <StyledInputNumber ref={ref} {...props} />,
);
