/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Flex } from 'antd';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from 'providers/NewFilterProvider/NewFilterProvider';
import { SavedFilter } from '../../../types/filters/AtomicFiltersImplementation';
import { FilterParams } from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { SuggestionPortalSavedFilters } from './SuggestionPortal';

export interface SavedFiltersProps extends FilterParams {
  value: SavedFilter;
  setSavedFilterOpen: (open: boolean) => void;
  savedFilterOpen: boolean;
}

function SavedFiltersFilter(props: SavedFiltersProps) {
  const [savedFilterOpen, setSavedFilterOpen] = useState(false);
  const { savedFilters, filters } = useContext<NewFilterProviderContext>(
    FilterProviderContext,
  );

  const defaultIsSelected = useMemo(
    () =>
      savedFilters?.find((filter) => filter.isDefault)?.id ===
      filters?.currentFilterIdApplied,
    [filters, savedFilters],
  );

  const defaultFilter = useMemo(
    () => savedFilters?.find((filter) => filter.isDefault),
    [filters, savedFilters],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          padding: '1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <SuggestionPortalSavedFilters
          {...props}
          setSavedFilterOpen={setSavedFilterOpen}
          savedFilterOpen={savedFilterOpen}
        />
      </Flex>
    ),
    [props.value, props.onChange, defaultFilter, savedFilters],
  );

  const badgeText: string | undefined = useMemo(() => {
    if (defaultIsSelected) {
      return 'Default';
    }
    if (
      filters?.currentFilterIdApplied &&
      savedFilters?.find((f) => f.id === filters.currentFilterIdApplied)
    ) {
      return '1';
    }
    return undefined;
  }, [defaultIsSelected, filters]);

  return (
    <FilterWithMenuWrapper
      text="Saved Filters"
      renderer={renderer}
      preventClosing={savedFilterOpen}
      badgeText={badgeText}
    />
  );
}

export default SavedFiltersFilter;
