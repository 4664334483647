import { takeLatest, call, put, select } from 'redux-saga/effects';
import CryptoAES from 'crypto-js/aes';
import Gleap from 'gleap';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { LoginOrganisation } from 'product-types/src/domain/organisation/LoginOrganisation';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import { push } from 'connected-react-router';
import { UserLoginModel } from 'product-types/src/domain/user/UserLoginModel';
import { formatDate } from 'product-utils/src/date';
import dayjs from 'dayjs';
import { DateFilterValueEnum } from 'product-types/src/domain/date/Date';
import { NetworkError } from 'product-types/src/common/Error/NetworkError';
import { base64ToJson } from 'product-utils/src/object';
import {
  UserComplete,
  UserCompleteRaw,
} from 'product-types/src/domain/user/UserComplete';
import { Permission } from '../../providers/PermissionManager/Permission';
import {
  resetAllFilters,
  resetAllFiltersExceptOne,
  setDefaultDateAction,
} from '../../layout/FiltersBar/actions';
import { NOT_HANDLED_LINKS } from '../App/Router';
import { Message } from '../../types/sharedWorker/messages/Message';
import { GetCurrentOrganisation } from '../../types/sharedWorker/messages/GetCurrentOrganisation';
import {
  changeEmailFrequencyAction,
  changeEmailWhenNewCounterfeitAction,
  updateDefaultDateRange,
  updateTutorialMode,
} from '../SettingsPage/actions';
import { sharedWorkerClient } from '../../workers/shared';
import { SwitchOrganisationMessage } from '../../types/sharedWorker/messages/SwitchOrganisationMessage';
import { buildALinkWithOrgId } from '../../hooks/useNavigation';
import { AllOrganisationsResponseModel } from '../../types/network/Http/productMonitor/endpoints/users/getAllOrganisations';
import { TokenResponseModel } from '../../types/network/Http/productMonitor/endpoints/token/token';
import {
  FETCH_ORGANISATIONS,
  FETCH_USER_MODEL,
  INITIALIZE_APP_STATE,
  LOGIN_REQUEST,
  LOGOUT,
  PROCESS_LINK,
  SWITCH_ORGANISATION,
} from './constants';
import makeSelectLoginPage from './selectors';
import {
  loginError,
  updateOrganisations,
  processLink as processLinkAction,
  updateUserInfo,
  updateAuthentification,
  switchOrganisation as switchOrganisationAction,
  updateUserModel,
  fetchUserModel as fetchUserModelAction,
  initializeAppState as initializeAppStateAction,
  updateLinkHandled,
  updateTargetOrganisation,
} from './actions';
import ProductMonitor from '../../types/network/Http/productMonitor';
import { LinkHandlingState, LoginPageState } from './reducer';
import { permissionManager } from '../../providers/PermissionManager/PermissionManager';

/**
 * Github repos request/response handler
 */
export function* performLogin(action) {
  const { email, password } = yield select(makeSelectLoginPage());
  yield put(
    updateOrganisations(
      new FetchableData<Array<LoginOrganisation>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateUserInfo(
      new FetchableData<UserLoginModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const key = process.env.ENCRYPTION_KEY;
    const encrypted_email = CryptoAES.encrypt(email, key).toString();
    const encrypted_password = CryptoAES.encrypt(password, key).toString();
    const result: TokenResponseModel = yield call(
      ProductMonitor.endpoints.token.getToken.call.bind(
        ProductMonitor.endpoints.token.getToken,
      ),
      {
        data: { email: encrypted_email, password: encrypted_password },
      },
    );

    yield put(
      updateOrganisations(
        new FetchableData<Array<LoginOrganisation>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: result.user_organisations.map(LoginOrganisation.fromRaw),
          error: null,
        }),
      ),
    );
    yield put(
      updateUserInfo(
        new FetchableData<UserLoginModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: UserLoginModel.createFromRawModel(result),
          error: null,
        }),
      ),
    );

    yield put(updateAuthentification());
    if (result.user_organisations.length === 1) {
      sharedWorkerClient.sendMessage(
        new SwitchOrganisationMessage({
          payload: {
            data: {
              organisationId: result.user_organisations[0].uid,
            },
          },
        }),
      );
      if (!action.redirectTo) {
        yield put(
          push(buildALinkWithOrgId(result.user_organisations[0].uid, '/post')),
        );
        return;
      }
      yield put(fetchUserModelAction({ redirectTo: action.redirectTo }));
      return;
    }
    if (action.redirectTo && !NOT_HANDLED_LINKS.includes(action.redirectTo)) {
      yield put(updateLinkHandled(LinkHandlingState.pendingHandling));
      const organisationIdFromLink = action.redirectTo.split('/')[1];
      const organisation = result.user_organisations.find(
        (org) => org.uid === organisationIdFromLink,
      );
      if (organisation === undefined || organisation === null) {
        yield put(updateLinkHandled(LinkHandlingState.handled));
        yield put(push('/notFound'));
        return;
      }
      yield put(
        switchOrganisationAction({
          orgId: organisation.uid,
          redirectTo: action.redirectTo,
        }),
      );
    } else {
      yield put(updateLinkHandled(LinkHandlingState.noNeedToHandle));
      yield put(
        push({
          pathname: '/select-organisation',
        }),
      );
    }
  } catch (err) {
    yield put(
      updateOrganisations(
        new FetchableData<Array<LoginOrganisation>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
    yield put(
      updateUserInfo(
        new FetchableData<UserLoginModel>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
    yield put(loginError(err));
  }
}

function* switchOrganisation(
  action: ReturnType<typeof switchOrganisationAction>,
) {
  const loginPage: LoginPageState = yield select(makeSelectLoginPage());
  try {
    const newOrganisation =
      loginPage.organisations.data?.find((org) => org.uid === action.orgId) ??
      null;
    yield put(updateTargetOrganisation(newOrganisation));
    yield call(
      ProductMonitor.endpoints.users.switchOrganisation.call.bind(
        ProductMonitor.endpoints.users.switchOrganisation,
      ),
      {
        params: { organisation_uid: action.orgId },
      },
    );
    yield put(
      fetchUserModelAction({
        redirectTo: action.redirectTo,
      }),
    );
  } catch (err) {
    console.error(err);
  }
}

function* fetchUserModel({
  redirectTo,
}: ReturnType<typeof fetchUserModelAction>) {
  yield put(
    updateUserModel(
      new FetchableData<UserComplete>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    let result: UserCompleteRaw | null = null;
    const element = document.querySelector('[name="cachedRequest.me"]');
    if (element) {
      if (
        // eslint-disable-next-line
        element.getAttribute('content') !== '${me}' &&
        element.getAttribute('content') !== 'null'
      ) {
        result = base64ToJson(element.getAttribute('content'));
      }
      element.remove();
    }
    if (!result) {
      result = yield call(
        ProductMonitor.endpoints.me.getMe.call.bind(
          ProductMonitor.endpoints.me.getMe,
        ),
        {
          cache: false,
          params: {
            timezone_offset: new Date().getTimezoneOffset(),
            start_date: formatDate(
              dayjs(new Date()).subtract(1, 'minute').utc(),
            ),
            end_date: formatDate(dayjs(new Date()).utc()),
          },
        },
      );
    }
    result = result as UserCompleteRaw;

    const userModel = UserComplete.createFromRawModel(result);
    permissionManager.replacePermissions(
      userModel.organisation.uid,
      userModel.permissions as Array<Permission>,
    );

    yield put(
      updateUserModel(
        new FetchableData<UserComplete>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: userModel,
          error: null,
        }),
      ),
    );
    yield put(updateTutorialMode(result.tutorial_mode));
    yield put(
      updateDefaultDateRange(
        result.organisation.default_feed_view_date_range as DateFilterValueEnum,
      ),
    );
    yield put(
      changeEmailWhenNewCounterfeitAction(result.email_when_new_counterfeit),
    );
    yield put(changeEmailFrequencyAction(result.email_recap_frequency));
    yield put(initializeAppStateAction());
    yield put(
      processLinkAction({
        user_organisations: result.user_organisations,
        redirectTo,
      }),
    );
  } catch (err) {
    if ((err as NetworkError).code === 302) {
      yield put(
        push({
          pathname: '/select-organisation',
          state: {
            redirectTo: window.location.pathname + window.location.search,
          },
        }),
      );
    } else {
      console.error(err);
      yield put(
        updateUserModel(
          new FetchableData<UserComplete>({
            abortController: null,
            state: FetchableDataState.ERROR,
            data: null,
            error: CustomErrorFactory.create(err),
          }),
        ),
      );
    }
  } finally {
    yield put(updateTargetOrganisation(null));
    const { currentUser }: LoginPageState = yield select(makeSelectLoginPage());
    if (currentUser?.data?.organisation?.uid) {
      sharedWorkerClient.sendMessage(
        new SwitchOrganisationMessage({
          payload: {
            data: {
              organisationId: currentUser?.data?.organisation?.uid,
            },
          },
        }),
      );
    }
  }
}

function* initializeAppState() {
  const loginPage: LoginPageState = yield select(makeSelectLoginPage());
  try {
    if (
      loginPage.currentUser.state !== FetchableDataState.LOADED ||
      !loginPage.currentUser.data
    ) {
      throw new Error('User model not loaded');
    }
    Gleap.identify(loginPage.currentUser.data.id.toString(), {
      customData: {
        userId: loginPage.currentUser.data.id,
      },
    });
    // @ts-expect-error this is a global varaible
    heap?.identify?.(loginPage.currentUser.data.id.toString());

    yield put(
      setDefaultDateAction(
        loginPage.currentUser.data.organisation.defaultFeedViewDateRange,
      ),
    );

    const query = new URLSearchParams(window.location.search);
    if (query.get('interval')) {
      const getCurrentPage = window.location.pathname.slice(1);
      if (getCurrentPage.startsWith('dashboard')) {
        yield put(resetAllFiltersExceptOne('dashboard'));
      } else if (getCurrentPage.startsWith('cluster')) {
        yield put(resetAllFiltersExceptOne('cluster'));
      } else if (getCurrentPage.startsWith('upload/history')) {
        yield put(resetAllFiltersExceptOne('uploadHistory'));
      } else if (
        getCurrentPage.startsWith('post') ||
        getCurrentPage.startsWith('image') ||
        getCurrentPage.startsWith('account')
      ) {
        yield put(resetAllFiltersExceptOne('feed'));
      }
    } else {
      yield put(resetAllFilters());
    }
  } catch (err) {
    console.error(err);
  }
}

function* processLink(action: ReturnType<typeof processLinkAction>) {
  let loginPage: LoginPageState = yield select(makeSelectLoginPage());
  if (
    loginPage.currentUser.state !== FetchableDataState.LOADED ||
    !loginPage.currentUser.data
  ) {
    throw new Error('User model not loaded');
  }
  yield put(updateLinkHandled(LinkHandlingState.handling));
  const { redirectTo } = action;
  try {
    const organisationIdFromLink = action.redirectTo.split('/')[1];

    if (organisationIdFromLink.length !== 3) {
      if (NOT_HANDLED_LINKS.includes(redirectTo)) {
        yield put(
          push(
            buildALinkWithOrgId(
              loginPage.currentUser?.data?.organisation?.uid,
              '/post',
            ),
            { navigation: true },
          ),
        );
        yield put(updateLinkHandled(LinkHandlingState.handled));
        return;
      }
      if (redirectTo === '/') {
        yield put(
          push(
            buildALinkWithOrgId(
              loginPage.currentUser?.data?.organisation?.uid,
              '/post',
            ),
            { navigation: true },
          ),
        );
        yield put(updateLinkHandled(LinkHandlingState.handled));
        return;
      }
      yield put(
        push(
          buildALinkWithOrgId(
            loginPage.currentUser?.data?.organisation?.uid,
            redirectTo,
          ),
          { navigation: true },
        ),
      );
      yield put(updateLinkHandled(LinkHandlingState.handled));
      return;
    }
    if (loginPage.organisations.state === FetchableDataState.NOT_LOADED) {
      yield put(
        updateOrganisations(
          new FetchableData<Array<LoginOrganisation>>({
            abortController: null,
            state: FetchableDataState.LOADED,
            data: action.user_organisations.map(LoginOrganisation.fromRaw),
            error: null,
          }),
        ),
      );
      loginPage = (yield select(makeSelectLoginPage())) as LoginPageState;
      if (
        loginPage.currentUser.state !== FetchableDataState.LOADED ||
        !loginPage.currentUser.data
      ) {
        throw new Error('User model not loaded');
      }
    }

    if (loginPage.organisations.data?.length === 1) {
      if (loginPage.organisations.data?.[0]?.uid === organisationIdFromLink) {
        yield put(
          push(
            redirectTo ??
              buildALinkWithOrgId(
                loginPage.currentUser?.data?.organisation?.uid,
                '/post',
              ),
          ),
        );
        yield put(updateLinkHandled(LinkHandlingState.handled));
        return;
      }
      yield put(push('/notFound'));
      yield put(updateLinkHandled(LinkHandlingState.handled));
      return;
    }
    const organisation = loginPage.organisations.data?.find(
      (org) => org.uid === organisationIdFromLink,
    );
    if (organisation === undefined || organisation === null) {
      yield put(updateLinkHandled(LinkHandlingState.handled));
      yield put(push('/notFound'));
      return;
    }

    if (
      loginPage.currentUser?.data?.organisation?.uid !== organisationIdFromLink
    ) {
      const answer: Array<string | null> = yield call(
        () =>
          new Promise((resolve) => {
            const listener = (message: Message) => {
              if (message.payload?.data?.type === 'answerWorker') {
                sharedWorkerClient.removeMessageListener(listener);
                resolve(message.payload?.data?.organisationId?.filter(Boolean));
              }
            };
            sharedWorkerClient.removeMessageListener(listener);
            setTimeout(() => {
              sharedWorkerClient.removeMessageListener(listener);
              resolve([]);
            }, 200);
            sharedWorkerClient.addMessageListener(listener);
            sharedWorkerClient.sendMessage(
              new GetCurrentOrganisation({
                payload: {
                  data: {
                    type: 'question',
                  },
                },
              }),
            );
          }),
      );

      if (answer.length === 1 || answer.every((a) => a === organisation?.uid)) {
        yield put(
          switchOrganisationAction({ orgId: organisation.uid, redirectTo }),
        );
      } else if (answer.includes(organisation?.uid)) {
        yield put(push(redirectTo));
      } else {
        yield put(
          push({
            pathname: '/confirm-select-organisation',
            state: {
              organisationId: organisation?.uid,
              redirectTo,
            },
          }),
        );
      }
    } else if (window.location.pathname !== redirectTo) {
      yield put(push(redirectTo));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(updateLinkHandled(LinkHandlingState.handled));
  }
}

function* fetchOrganisations() {
  yield put(
    updateOrganisations(
      new FetchableData<Array<LoginOrganisation>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  try {
    const response: AllOrganisationsResponseModel = yield call(
      ProductMonitor.endpoints.users.getAllOrganisations.call.bind(
        ProductMonitor.endpoints.users.getAllOrganisations,
      ),
      {},
    );

    yield put(
      updateOrganisations(
        new FetchableData<Array<LoginOrganisation>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.user_organisations.map(LoginOrganisation.fromRaw),
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateOrganisations(
        new FetchableData<Array<LoginOrganisation>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* logout() {
  try {
    Gleap.clearIdentity();
    yield call(
      ProductMonitor.endpoints.logout.call.bind(
        ProductMonitor.endpoints.logout,
        {},
      ),
    );
  } catch (err) {
    console.error(err);
  }
}

// Individual exports for testing
export default function* loginPageSaga() {
  yield takeLatest(LOGIN_REQUEST, performLogin);
  yield takeLatest(PROCESS_LINK, processLink);
  yield takeLatest(FETCH_ORGANISATIONS, fetchOrganisations);
  yield takeLatest(SWITCH_ORGANISATION, switchOrganisation);
  yield takeLatest(FETCH_USER_MODEL, fetchUserModel);
  yield takeLatest(INITIALIZE_APP_STATE, initializeAppState);
  yield takeLatest(LOGOUT, logout);
}
