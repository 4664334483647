/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useMemo } from 'react';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { Tabs } from 'product-ui/src/components/atoms/Tabs/Tabs';
import { Flex, Tooltip } from 'antd';
import WebsiteFilter from 'components/WebsiteFilter';
import { prioritizeSearchResults } from 'product-ui/src/components/atoms/CreatableTagSelect/CreatableTagSelect';
import { TagsFilter } from '../../../types/filters/AtomicFiltersImplementation/Tags/TagsFilter';
import { Filter } from '../../../types/filters/AtomicFilters/Filter';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';

export interface NewTagsFilterProps {
  value: TagsFilter;
  onChange: (v: Filter) => void;
}

const include = 'inlcude';
const exclude = 'exclude';

export const NewTagsFilter = (props: NewTagsFilterProps) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const tags = context.tags || {
    account: [],
    post: [],
    duplicatedGroup: [],
    vendor: [],
  };

  const onSelectPost = useCallback(
    (operation, post) => {
      if (operation === include) {
        if (typeof post === 'string') {
          if (!post.trim().length) return;
          if (props.value.value.postInclude.some((t) => t.id === post)) return;
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.addPostInclude([
                new TagModel({
                  id: post.trim(),
                  name: post.trim(),
                  tagType: 'post',
                  __isNew__: true,
                }),
              ]),
            }),
          );
        } else {
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.setPostInclude(post),
            }),
          );
        }
      } else if (operation === exclude) {
        if (typeof post === 'string') {
          if (!post.trim().length) return;
          if (props.value.value.postExclude.some((t) => t.id === post)) return;
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.addPostExclude([
                new TagModel({
                  id: post.trim(),
                  name: post.trim(),
                  tagType: 'post',
                  __isNew__: true,
                }),
              ]),
            }),
          );
        } else {
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.setPostExclude(post),
            }),
          );
        }
      }
    },
    [props.value, props.value.value, props.onChange],
  );

  const onSelectAccount = useCallback(
    (operation, account) => {
      if (operation === include) {
        if (typeof account === 'string') {
          if (!account.trim().length) return;
          if (props.value.value.accountInclude.some((t) => t.id === account))
            return;
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.addAccountInclude([
                new TagModel({
                  id: account.trim(),
                  name: account.trim(),
                  tagType: 'account',
                  __isNew__: true,
                }),
              ]),
            }),
          );
        } else {
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.setAccountInclude(account),
            }),
          );
        }
      } else if (operation === exclude) {
        if (typeof account === 'string') {
          if (!account.trim().length) return;
          if (props.value.value.accountExclude.some((t) => t.id === account))
            return;
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.addAccountExclude([
                new TagModel({
                  id: account.trim(),
                  name: account.trim(),
                  tagType: 'account',
                  __isNew__: true,
                }),
              ]),
            }),
          );
        } else {
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.setAccountExclude(account),
            }),
          );
        }
      }
    },
    [props.value, props.value.value, props.onChange],
  );

  const onSelectDuplicatedGroup = useCallback(
    (operation, duplicatedGroup) => {
      if (operation === include) {
        if (typeof duplicatedGroup === 'string') {
          if (!duplicatedGroup.trim().length) return;
          if (
            props.value.value.duplicatedGroupInclude.some(
              (t) => t.id === duplicatedGroup,
            )
          )
            return;
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.addDuplicatedGroupInclude([
                new TagModel({
                  id: duplicatedGroup.trim(),
                  name: duplicatedGroup.trim(),
                  tagType: 'post',
                  __isNew__: true,
                }),
              ]),
            }),
          );
        } else {
          props.onChange(
            new TagsFilter({
              ...props.value,
              value:
                props.value.value.setDuplicatedGroupInclude(duplicatedGroup),
            }),
          );
        }
      } else if (operation === exclude) {
        if (typeof duplicatedGroup === 'string') {
          if (!duplicatedGroup.trim().length) return;
          if (
            props.value.value.duplicatedGroupExclude.some(
              (t) => t.id === duplicatedGroup,
            )
          )
            return;
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.addDuplicatedGroupExclude([
                new TagModel({
                  id: duplicatedGroup.trim(),
                  name: duplicatedGroup.trim(),
                  tagType: 'post',
                  __isNew__: true,
                }),
              ]),
            }),
          );
        } else {
          props.onChange(
            new TagsFilter({
              ...props.value,
              value:
                props.value.value.setDuplicatedGroupExclude(duplicatedGroup),
            }),
          );
        }
      }
    },
    [props.value, props.value.value, props.onChange],
  );

  const onSelectVendor = useCallback(
    (operation, vendor) => {
      if (operation === include) {
        if (typeof vendor === 'string') {
          if (!vendor.trim().length) return;
          if (props.value.value.vendorInclude.some((t) => t.id === vendor))
            return;
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.addVendorInclude([
                new TagModel({
                  id: vendor.trim(),
                  name: vendor.trim(),
                  tagType: 'vendor',
                  __isNew__: true,
                }),
              ]),
            }),
          );
        } else {
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.setVendorInclude(vendor),
            }),
          );
        }
      } else if (operation === exclude) {
        if (typeof vendor === 'string') {
          if (!vendor.trim().length) return;
          if (props.value.value.vendorExclude.some((t) => t.id === vendor))
            return;
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.addVendorExclude([
                new TagModel({
                  id: vendor.trim(),
                  name: vendor.trim(),
                  tagType: 'vendor',
                  __isNew__: true,
                }),
              ]),
            }),
          );
        } else {
          props.onChange(
            new TagsFilter({
              ...props.value,
              value: props.value.value.setVendorExclude(vendor),
            }),
          );
        }
      }
    },
    [props.value, props.value.value, props.onChange],
  );

  const postIncludeOptions = useMemo(
    () =>
      props.value.value.postInclude
        .filter((t) => t.__isNew__)
        .concat(tags.post || [])
        .filter((t) => !t.isHidden),
    [tags.post, props.value.value.postInclude],
  );
  const postExcludeOptions = useMemo(
    () =>
      props.value.value.postExclude
        .filter((t) => t.__isNew__)
        .concat(tags.post || [])
        .filter((t) => !t.isHidden),
    [tags.post, props.value.value.postExclude],
  );

  const duplicatedGrouptIncludeOptions = useMemo(
    () =>
      props.value.value.duplicatedGroupInclude
        .filter((t) => t.__isNew__)
        .concat(tags.duplicatedGroup || [])
        .filter((t) => !t.isHidden),
    [tags.duplicatedGroup, props.value.value.duplicatedGroupInclude],
  );
  const duplicatedGroupExcludeOptions = useMemo(
    () =>
      props.value.value.duplicatedGroupExclude
        .filter((t) => t.__isNew__)
        .concat(tags.duplicatedGroup || [])
        .filter((t) => !t.isHidden),
    [tags.duplicatedGroup, props.value.value.duplicatedGroupExclude],
  );

  const accountIncludeOptions = useMemo(
    () =>
      props.value.value.accountInclude
        .filter((t) => t.__isNew__)
        .concat(tags.account || [])
        .filter((t) => !t.isHidden),
    [tags.account, props.value.value.accountInclude],
  );

  const accountExcludeOptions = useMemo(
    () =>
      props.value.value.accountExclude
        .filter((t) => t.__isNew__)
        .concat(tags.account || [])
        .filter((t) => !t.isHidden),
    [tags.account, props.value.value.accountExclude],
  );

  const vendorIncludeOptions = useMemo(
    () =>
      props.value.value.vendorInclude
        .filter((t) => t.__isNew__)
        .concat(tags.vendor || [])
        .filter((t) => !t.isHidden),
    [tags.vendor, props.value.value.vendorInclude],
  );

  const vendorExcludeOptions = useMemo(
    () =>
      props.value.value.vendorExclude
        .filter((t) => t.__isNew__)
        .concat(tags.vendor || [])
        .filter((t) => !t.isHidden),
    [tags.vendor, props.value.value.vendorExclude],
  );

  const debouncedLoadTagsPosts = useCallback(
    (search: string) => {
      if (search.length < 3) return Promise.resolve(postIncludeOptions);
      return Promise.resolve(
        prioritizeSearchResults(
          (tags.post ?? []).filter((option) =>
            option.label.toLowerCase().includes(search),
          ),
          search,
        ),
      );
    },
    [tags.post],
  );

  const debouncedLoadTagsImages = useCallback(
    (search: string) => {
      if (search.length < 3)
        return Promise.resolve(duplicatedGrouptIncludeOptions);
      return Promise.resolve(
        prioritizeSearchResults(
          (tags.duplicatedGroup ?? []).filter((option) =>
            option.label.toLowerCase().includes(search),
          ),
          search,
        ),
      );
    },
    [tags.duplicatedGroup],
  );

  const debouncedLoadTagsAccounts = useCallback(
    (search: string) => {
      if (search.length < 3) return Promise.resolve(accountIncludeOptions);
      return Promise.resolve(
        prioritizeSearchResults(
          (tags.account ?? []).filter((option) =>
            option.label.toLowerCase().includes(search),
          ),
          search,
        ),
      );
    },
    [tags.account],
  );

  const debouncedLoadTagsVendors = useCallback(
    (search: string) => {
      if (search.length < 3) return Promise.resolve(vendorIncludeOptions);
      return Promise.resolve(
        prioritizeSearchResults(
          (tags.vendor ?? []).filter((option) =>
            option.label.toLowerCase().includes(search),
          ),
          search,
        ),
      );
    },
    [tags.vendor],
  );

  const onTagSelect = useCallback(
    (selectedIds, type: 'post' | 'image' | 'account' | 'cluster', action) => {
      const actionMap = {
        post: onSelectPost,
        image: onSelectDuplicatedGroup,
        account: onSelectAccount,
        cluster: onSelectVendor,
      };
      const optionMap = {
        post: {
          [include]: postIncludeOptions,
          [exclude]: postExcludeOptions,
        },
        image: {
          [include]: duplicatedGrouptIncludeOptions,
          [exclude]: duplicatedGroupExcludeOptions,
        },
        account: {
          [include]: accountIncludeOptions,
          [exclude]: accountExcludeOptions,
        },
        cluster: {
          [include]: vendorIncludeOptions,
          [exclude]: vendorExcludeOptions,
        },
      };
      const newSelectedTags = optionMap[type][action].filter((t) =>
        selectedIds.includes(t.id),
      );
      actionMap[type](action, newSelectedTags);
    },
    [
      postIncludeOptions,
      postExcludeOptions,
      duplicatedGrouptIncludeOptions,
      duplicatedGroupExcludeOptions,
      accountIncludeOptions,
      accountExcludeOptions,
      vendorIncludeOptions,
      vendorExcludeOptions,
    ],
  );

  const popupContainerRef = React.useRef<HTMLDivElement>(undefined!);

  const maxTagCount = 25;
  const maxTagPlaceholder = (omittedValues) => (
    <Tooltip
      overlayStyle={{ pointerEvents: 'none' }}
      title={omittedValues.map(({ label }) => label).join(', ')}
    >
      <span>+{omittedValues.length}</span>
    </Tooltip>
  );
  const renderer = useCallback(
    () => (
      <Flex
        id="tags-tabs-container"
        vertical
        gap="1rem"
        style={{
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 472,
          minHeight: 60,
          padding: '0 0 1rem',
        }}
      >
        <Tabs
          defaultActiveKey="post-tags"
          style={{ width: '100%' }}
          animated={false}
          items={[
            {
              key: 'post-tags',
              label: 'POST',
              children: (
                <Flex
                  vertical
                  gap={20}
                  style={{
                    padding: '0 1rem 1rem',
                  }}
                >
                  <WebsiteFilter
                    getPopupContainer={() => popupContainerRef.current}
                    debounceTime={300}
                    fieldNames={{ label: 'name', value: 'id' }}
                    selected_website_sider={props.value.value.postInclude}
                    onChange={(_, selectedIds) =>
                      onTagSelect(selectedIds, 'post', include)
                    }
                    noOptionsMessage="No options"
                    hint={null}
                    websitesSiderData={postIncludeOptions}
                    placeholder="Included Post Tags..."
                    select_menu_width="100%"
                    concat={false}
                    async
                    isMulti
                    onSelectAll={(posts) => onSelectPost(include, posts)}
                    showSelectAll
                    loadOptions={debouncedLoadTagsPosts as any}
                    options_to_remove={props.value.value.postExclude}
                    maxTagCount={maxTagCount}
                    maxTagPlaceholder={maxTagPlaceholder}
                  />
                  <WebsiteFilter
                    getPopupContainer={() => popupContainerRef.current}
                    debounceTime={300}
                    fieldNames={{ label: 'name', value: 'id' }}
                    selected_website_sider={props.value.value.postExclude}
                    onChange={(_, selectedIds) =>
                      onTagSelect(selectedIds, 'post', exclude)
                    }
                    noOptionsMessage="No options"
                    hint={null}
                    websitesSiderData={postExcludeOptions}
                    placeholder="Excluded Post Tags..."
                    select_menu_width="100%"
                    concat={false}
                    async
                    isMulti
                    onSelectAll={(posts) => onSelectPost(exclude, posts)}
                    showSelectAll
                    loadOptions={debouncedLoadTagsPosts as any}
                    options_to_remove={props.value.value.postInclude}
                    maxTagCount={maxTagCount}
                    maxTagPlaceholder={maxTagPlaceholder}
                  />
                </Flex>
              ),
            },
            {
              key: 'image-tags',
              label: 'IMAGE',
              children: (
                <Flex
                  vertical
                  gap={20}
                  style={{
                    padding: '0 1rem 1rem',
                  }}
                >
                  <WebsiteFilter
                    getPopupContainer={() => popupContainerRef.current}
                    debounceTime={300}
                    fieldNames={{ label: 'name', value: 'id' }}
                    selected_website_sider={
                      props.value.value.duplicatedGroupInclude
                    }
                    onChange={(_, selectedIds) =>
                      onTagSelect(selectedIds, 'image', include)
                    }
                    noOptionsMessage="No options"
                    hint={null}
                    websitesSiderData={duplicatedGrouptIncludeOptions}
                    placeholder="Included Image Tags..."
                    select_menu_width="100%"
                    concat={false}
                    async
                    isMulti
                    onSelectAll={(images) =>
                      onSelectDuplicatedGroup(include, images)
                    }
                    showSelectAll
                    loadOptions={debouncedLoadTagsImages as any}
                    options_to_remove={props.value.value.duplicatedGroupExclude}
                    maxTagCount={maxTagCount}
                    maxTagPlaceholder={maxTagPlaceholder}
                  />
                  <WebsiteFilter
                    getPopupContainer={() => popupContainerRef.current}
                    debounceTime={300}
                    fieldNames={{ label: 'name', value: 'id' }}
                    selected_website_sider={
                      props.value.value.duplicatedGroupExclude
                    }
                    onChange={(_, selectedIds) =>
                      onTagSelect(selectedIds, 'image', exclude)
                    }
                    noOptionsMessage="No options"
                    hint={null}
                    websitesSiderData={duplicatedGroupExcludeOptions}
                    placeholder="Excluded Image Tags..."
                    select_menu_width="100%"
                    concat={false}
                    async
                    isMulti
                    onSelectAll={(posts) =>
                      onSelectDuplicatedGroup(exclude, posts)
                    }
                    showSelectAll
                    loadOptions={debouncedLoadTagsImages as any}
                    options_to_remove={props.value.value.duplicatedGroupInclude}
                    maxTagCount={maxTagCount}
                    maxTagPlaceholder={maxTagPlaceholder}
                  />
                </Flex>
              ),
            },
            {
              key: 'account-tags',
              label: 'ACCOUNT/WEBSITE',
              children: (
                <Flex
                  vertical
                  gap={20}
                  style={{
                    padding: '0 1rem 1rem',
                  }}
                >
                  <WebsiteFilter
                    getPopupContainer={() => popupContainerRef.current}
                    debounceTime={300}
                    fieldNames={{ label: 'name', value: 'id' }}
                    selected_website_sider={props.value.value.accountInclude}
                    onChange={(_, selectedIds) =>
                      onTagSelect(selectedIds, 'account', include)
                    }
                    noOptionsMessage="No options"
                    hint={null}
                    websitesSiderData={accountIncludeOptions}
                    placeholder="Included Account/Website Tags..."
                    select_menu_width="100%"
                    concat={false}
                    async
                    isMulti
                    onSelectAll={(accounts) =>
                      onSelectAccount(include, accounts)
                    }
                    showSelectAll
                    loadOptions={debouncedLoadTagsAccounts as any}
                    options_to_remove={props.value.value.accountExclude}
                    maxTagCount={maxTagCount}
                    maxTagPlaceholder={maxTagPlaceholder}
                  />
                  <WebsiteFilter
                    getPopupContainer={() => popupContainerRef.current}
                    debounceTime={300}
                    fieldNames={{ label: 'name', value: 'id' }}
                    selected_website_sider={props.value.value.accountExclude}
                    onChange={(_, selectedIds) =>
                      onTagSelect(selectedIds, 'account', exclude)
                    }
                    noOptionsMessage="No options"
                    hint={null}
                    websitesSiderData={accountExcludeOptions}
                    placeholder="Excluded Account/Website Tags..."
                    select_menu_width="100%"
                    concat={false}
                    async
                    isMulti
                    onSelectAll={(accounts) =>
                      onSelectAccount(exclude, accounts)
                    }
                    showSelectAll
                    loadOptions={debouncedLoadTagsAccounts as any}
                    options_to_remove={props.value.value.accountInclude}
                    maxTagCount={maxTagCount}
                    maxTagPlaceholder={maxTagPlaceholder}
                  />
                </Flex>
              ),
            },
            {
              key: 'cluster-tags',
              label: 'CLUSTER',
              children: (
                <Flex
                  vertical
                  gap={20}
                  style={{
                    padding: '0 1rem 1rem',
                  }}
                >
                  <WebsiteFilter
                    getPopupContainer={() => popupContainerRef.current}
                    debounceTime={300}
                    fieldNames={{ label: 'name', value: 'id' }}
                    selected_website_sider={props.value.value.vendorInclude}
                    onChange={(_, selectedIds) =>
                      onTagSelect(selectedIds, 'cluster', include)
                    }
                    noOptionsMessage="No options"
                    hint={null}
                    websitesSiderData={vendorIncludeOptions}
                    placeholder="Included Cluster Tags..."
                    select_menu_width="100%"
                    concat={false}
                    async
                    isMulti
                    onSelectAll={(clusters) =>
                      onSelectVendor(include, clusters)
                    }
                    showSelectAll
                    loadOptions={debouncedLoadTagsVendors as any}
                    options_to_remove={props.value.value.vendorExclude}
                    maxTagCount={maxTagCount}
                    maxTagPlaceholder={maxTagPlaceholder}
                  />
                  <WebsiteFilter
                    getPopupContainer={() => popupContainerRef.current}
                    debounceTime={300}
                    fieldNames={{ label: 'name', value: 'id' }}
                    selected_website_sider={props.value.value.vendorExclude}
                    onChange={(_, selectedIds) =>
                      onTagSelect(selectedIds, 'cluster', exclude)
                    }
                    noOptionsMessage="No options"
                    hint={null}
                    websitesSiderData={vendorExcludeOptions}
                    placeholder="Excluded Cluster Tags..."
                    select_menu_width="100%"
                    concat={false}
                    async
                    isMulti
                    onSelectAll={(clusters) =>
                      onSelectVendor(exclude, clusters)
                    }
                    showSelectAll
                    loadOptions={debouncedLoadTagsVendors as any}
                    options_to_remove={props.value.value.vendorInclude}
                    maxTagCount={maxTagCount}
                    maxTagPlaceholder={maxTagPlaceholder}
                  />
                </Flex>
              ),
            },
          ]}
        />
      </Flex>
    ),
    [
      props.value,
      postIncludeOptions,
      postExcludeOptions,
      duplicatedGrouptIncludeOptions,
      duplicatedGroupExcludeOptions,
      accountIncludeOptions,
      accountExcludeOptions,
      vendorIncludeOptions,
      vendorExcludeOptions,
      onTagSelect,
      onSelectPost,
      onSelectDuplicatedGroup,
      onSelectAccount,
      onSelectVendor,
      debouncedLoadTagsPosts,
      debouncedLoadTagsImages,
      debouncedLoadTagsAccounts,
      debouncedLoadTagsVendors,
    ],
  );
  return (
    <FilterWithMenuWrapper
      text="Tags"
      renderer={renderer}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
      dataTestId="tags-filter-container"
    />
  );
};
