import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { FeedRequestParameter } from '../../../../../../Feed/Feed';

interface GetPrevPostToModerateRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

interface GetPrevPostToModerateResponse {}

const prevPostToModerate = new Endpoint<
  GetPrevPostToModerateRequestModel,
  GetPrevPostToModerateResponse
>({
  url: '/api/me/post_moderation/previous',
  method: 'POST',
  headers: {},
});

export default prevPostToModerate;
