import { v4 as uuid } from 'uuid';
import { NewUploadTypeFilter } from 'components/Filters/NewUploadTypeFilter';
import { NewUploadTypeFilterValue } from 'components/Filters/NewUploadTypeFilter/NewUploadTypeFilterValue';
import { UploadTypeEnum } from 'product-types/src/domain/uploadType/UploadType';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { UploadTypeFilterValue } from './UploadTypeFilterValue';

export interface UploadTypeFilterQueryValue extends QueryValue {
  upload_type?: UploadTypeEnum;
}

export class UploadTypeFilter implements Filter {
  uuid: string;

  label: string;

  value: UploadTypeFilterValue;

  constructor(
    params?: Partial<Pick<UploadTypeFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || UploadTypeFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    if (this.value.uploadType.value === UploadTypeEnum.all) {
      return [];
    }
    return [
      {
        name: FilterTypeEnum.uploadType,
        value: this.value,
        uuid: this.uuid,
        key: this.uuid + this.value.uploadType.value,
      },
    ];
  }

  get component() {
    return NewUploadTypeFilter;
  }

  get displayFilterComponent() {
    return NewUploadTypeFilterValue;
  }

  get queryFilterValue(): UploadTypeFilterQueryValue {
    if (this.value.uploadType.value === UploadTypeEnum.all) {
      return {};
    }
    return {
      upload_type: this.value.uploadType.value,
    };
  }

  removeFilterValue() {
    this.value = UploadTypeFilterValue.defaultValue;
  }

  static readFilterFromQuery(): UploadTypeFilter {
    return new UploadTypeFilter({
      value: UploadTypeFilterValue.readFilterFromQuery,
    });
  }
}
