import * as Domain from 'product-types/src/domain/Domain';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import { makeArrayUniqueByValue } from 'product-utils/src/array';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export const AllContactTypesOption: ContactMainInfo = {
  label: 'All contact types',
  value: 'contact_type:all',
  children: [],
};

export interface readFilterFromQueryProps {
  contactTypes: Array<ContactMainInfo>;
}

export class AccountInformationValue implements FilterValue {
  followers: Domain.Followers.Followers;

  contactTypes: Array<ContactMainInfo>;

  constructor(
    params?: Pick<AccountInformationValue, 'followers' | 'contactTypes'>,
  ) {
    this.followers =
      params?.followers || Domain.Followers.Followers.defaultValue;
    this.contactTypes = params?.contactTypes || [];
  }

  static get defaultValue(): AccountInformationValue {
    return new AccountInformationValue();
  }

  hasAllContactTypes(): boolean {
    return this.contactTypes.some(
      (ct) => ct.value === AllContactTypesOption.value,
    );
  }

  setFollowers(followers: Domain.Followers.Followers): AccountInformationValue {
    return new AccountInformationValue({
      followers,
      contactTypes: this.contactTypes,
    });
  }

  setContactTypes(
    contactTypes: Array<ContactMainInfo>,
  ): AccountInformationValue {
    return new AccountInformationValue({
      followers: this.followers,
      contactTypes,
    });
  }

  hasContactType(contactType: ContactMainInfo): boolean {
    return this.contactTypes.some((type) => type.value === contactType.value);
  }

  protected static calcIntValue(value, defaultValue) {
    if (value === null || value === undefined) {
      return defaultValue;
    }
    if (Number.isNaN(parseInt(value, 10))) {
      return defaultValue;
    }
    return parseInt(value, 10);
  }

  protected static calcFloatValue(value, defaultValue) {
    if (value === null || value === undefined) {
      return defaultValue;
    }
    if (Number.isNaN(parseFloat(value))) {
      return defaultValue;
    }
    return parseFloat(value);
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
  ): AccountInformationValue {
    const minFollowers = new URLSearchParams(window.location.search).get(
      'minimum_followers_count',
    );
    const maxFollowers = new URLSearchParams(window.location.search).get(
      'maximum_followers_count',
    );
    const contactTypes = makeArrayUniqueByValue(
      new URLSearchParams(window.location.search).getAll(
        'label_type_to_include',
      ),
    );
    return new AccountInformationValue({
      followers: {
        max: this.calcIntValue(
          maxFollowers,
          AccountInformationValue.defaultValue.followers.max,
        ),
        min: this.calcIntValue(
          minFollowers,
          AccountInformationValue.defaultValue.followers.min,
        ),
      },
      contactTypes: contactTypes.reduce((acc, contactType) => {
        if (contactType === AllContactTypesOption.value) {
          acc.push(AllContactTypesOption);
          acc.push(...props.contactTypes);
        } else {
          const ct = props.contactTypes.find((c) => c.value === contactType);
          if (ct) {
            acc.push(ct);
          }
        }
        return acc;
      }, [] as Array<ContactMainInfo>),
    });
  }

  static readFromSavedFilter(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): AccountInformationValue {
    const minFollowers = savedFilter.followersCount[0];
    const maxFollowers = savedFilter.followersCount[1];
    return new AccountInformationValue({
      followers: {
        max: this.calcIntValue(
          maxFollowers,
          AccountInformationValue.defaultValue.followers.max,
        ),
        min: this.calcIntValue(
          minFollowers,
          AccountInformationValue.defaultValue.followers.min,
        ),
      },
      contactTypes: (savedFilter.labelTypeToInclude || [])
        .map((contactType) => {
          if (contactType === AllContactTypesOption.value) {
            return AllContactTypesOption;
          }
          return props.contactTypes.find((ct) => ct.value === contactType);
        })
        .filter((ct) => ct !== undefined) as Array<ContactMainInfo>,
    });
  }
}
