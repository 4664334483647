import { Occurrences } from '../occurences/Occurences';
import { DuplicatedGroup } from './index';

export class AssociatedDuplicatedGroup {
  id: DuplicatedGroup.Id;

  occurrences: Occurrences;

  constructor(props: AssociatedDuplicatedGroup) {
    this.id = props.id;
    this.occurrences = props.occurrences;
  }
}
