import { CanBeQueriable } from '../../network/Query/Query';

export enum SortingDirectionEnum {
  asc = 'asc',
  desc = 'desc',
}

export class Sorting implements CanBeQueriable {
  key: string;

  order: SortingDirectionEnum;

  constructor(prop?: Pick<Sorting, 'key' | 'order'>) {
    this.key = prop?.key || '';
    this.order = prop?.order || SortingDirectionEnum.asc;
  }

  get sortOrder() {
    return this.order === SortingDirectionEnum.asc ? 'ascend' : 'descend';
  }

  get queryFilterValue() {
    return {
      sort_by: this.key,
      reverse: this.order === SortingDirectionEnum.desc,
    };
  }

  static get presetPost() {
    return new Sorting({
      key: 'impact_score',
      order: SortingDirectionEnum.desc,
    });
  }

  static get presetAccount() {
    return new Sorting({
      key: 'total_posts',
      order: SortingDirectionEnum.desc,
    });
  }

  static get presetCluster() {
    return new Sorting({
      key: 'total_posts',
      order: SortingDirectionEnum.desc,
    });
  }

  static get presetUploadHistory() {
    return new Sorting({
      key: 'upload_date',
      order: SortingDirectionEnum.desc,
    });
  }

  static getValueByPathname(pathname: string) {
    if (pathname.includes('/post')) {
      return Sorting.presetPost;
    }
    if (pathname.includes('/account')) {
      return Sorting.presetAccount;
    }
    if (pathname.includes('/cluster')) {
      return Sorting.presetCluster;
    }
    if (pathname.includes('/upload/history')) {
      return Sorting.presetUploadHistory;
    }
    return new Sorting({
      key: '',
      order: SortingDirectionEnum.desc,
    });
  }

  static get defaultValue() {
    if (window.location.pathname.includes('/post')) {
      return Sorting.presetPost;
    }
    if (window.location.pathname.includes('/account')) {
      return Sorting.presetAccount;
    }
    if (window.location.pathname.includes('/cluster')) {
      return Sorting.presetCluster;
    }
    if (window.location.pathname.includes('/upload/history')) {
      return Sorting.presetUploadHistory;
    }
    return new Sorting({
      key: '',
      order: SortingDirectionEnum.desc,
    });
  }
}
