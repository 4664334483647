import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getPosts from './getPosts';
import historicalAnalysis from './historicalAnalysis';
import postModeration from './moderation';
import getPost from './getPost';
import updatePost from './updatePost';
import comments from './comments';
import exportPosts from './exportPosts';
import exportTemplates from './exportTemplates';
import updateTakedownStatus from './updateTakedownStatus';

export interface PostEndpoints extends EndpointContainer {
  getPost: typeof getPost;
  exportTemplates: typeof exportTemplates;
  updatePost: typeof updatePost;
  comments: typeof comments;
  getPosts: typeof getPosts;
  exportPosts: typeof exportPosts;
  historicalAnalysis: typeof historicalAnalysis;
  postModeration: typeof postModeration;
  updateTakedownStatus: typeof updateTakedownStatus;
}

const posts: PostEndpoints = {
  getPost,
  exportTemplates,
  updatePost,
  comments,
  getPosts,
  exportPosts,
  historicalAnalysis,
  postModeration,
  updateTakedownStatus,
  injectHTTPClient(client: HttpClient) {
    getPost.injectHTTPClient(client);
    exportTemplates.injectHTTPClient(client);
    updatePost.injectHTTPClient(client);
    comments.injectHTTPClient(client);
    getPosts.injectHTTPClient(client);
    exportPosts.injectHTTPClient(client);
    historicalAnalysis.injectHTTPClient(client);
    postModeration.injectHTTPClient(client);
    updateTakedownStatus.injectHTTPClient(client);
  },
};

export default posts;
