const bucketUrlToTransform =
  'https://s3-eu-west-1.amazonaws.com/counterfeit-platform-post-images/';
const bucketUrl = 'https://dd6gwn8wk279r.cloudfront.net/';
const bucketUrlToTransformToNewBucket1 =
  'https://s3-eu-west-1.amazonaws.com/counterfeit-platform-post-images-v2/uuid/';
const bucketUrlToTransformToNewBucket2 =
  'https://counterfeit-platform-post-images-v2.s3.eu-west-1.amazonaws.com/uuid/';
const bucketUrlNewUrl = 'https://dd6gwn8wk279r.cloudfront.net/uuid-v2/';
export const transformUrl = (url: string) =>
  url
    .replace(bucketUrlToTransform, bucketUrl)
    .replace(bucketUrlToTransformToNewBucket1, bucketUrlNewUrl)
    .replace(bucketUrlToTransformToNewBucket2, bucketUrlNewUrl);

export function getDomainFromUrl(url) {
  try {
    const domain = new URL(url).hostname;
    return domain;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}
