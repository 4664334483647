import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getImage from './getImage';
import imageModeration from './moderation';
import searchImages from './search';
import associatedPictures from './associatedPictures';
import comments from './comments';
import getImages from './getImages';
import deleteModerationReason from './deleteModerationReason';
import exportImages from './exportImages/exportImages';

export interface ImageEndpoints extends EndpointContainer {
  getImage: typeof getImage;
  getImages: typeof getImages;
  exportImages: typeof exportImages;
  searchImages: typeof searchImages;
  imageModeration: typeof imageModeration;
  associatedPictures: typeof associatedPictures;
  comments: typeof comments;
  deleteModerationReason: typeof deleteModerationReason;
}

const images: ImageEndpoints = {
  getImage,
  getImages,
  exportImages,
  searchImages,
  imageModeration,
  associatedPictures,
  comments,
  deleteModerationReason,
  injectHTTPClient(client: HttpClient) {
    getImage.injectHTTPClient(client);
    getImages.injectHTTPClient(client);
    exportImages.injectHTTPClient(client);
    searchImages.injectHTTPClient(client);
    imageModeration.injectHTTPClient(client);
    associatedPictures.injectHTTPClient(client);
    comments.injectHTTPClient(client);
    deleteModerationReason.injectHTTPClient(client);
  },
};

export default images;
