import { Endpoint, EndpointRequests } from '../../../../endpoint';

interface CreateOrganisationRequestModel extends EndpointRequests {
  data: {
    name: string;
    legal_name: string;
    logo_url: string;
    currency: string;
    organisation_status: string;
  };
}

interface CreateOrganisationResponse {}

const createOrganisation = new Endpoint<
  CreateOrganisationRequestModel,
  CreateOrganisationResponse
>({
  url: '/api/organisation/',
  method: 'POST',
  headers: {},
});

export default createOrganisation;
