import { MessageModel } from './Message';
import { Permission } from '../../../providers/PermissionManager/Permission';

interface SharePermissionsMessagePayload {
  data: {
    orgUid: string;
    newPermissions: Array<Permission>;
  };
}

export const SharePermissionsMessageType = 'SharePermissionsMessage';

export class SharePermissionsMessage extends MessageModel {
  type = SharePermissionsMessageType;

  payload?: SharePermissionsMessagePayload;

  constructor(data: Omit<SharePermissionsMessage, 'type'>) {
    super({
      type: SharePermissionsMessageType,
      payload: data.payload,
    });
  }

  toString(): string {
    return super.toString();
  }
}
