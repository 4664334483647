import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadAccountFileManualRequest extends EndpointRequests {
  data: FormData;
}

interface UploadAccountFileManualResponse {}

const uploadAccountFileManual = new Endpoint<
  UploadAccountFileManualRequest,
  UploadAccountFileManualResponse
>({
  url: '/api/me/uploads/account/manual/file',
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadAccountFileManual;
