import { UploadImageRaw } from 'product-types/src/domain/uploads/uploadImage';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadImageRequest extends EndpointRequests {
  data: {
    images: Array<UploadImageRaw>;
    global_tags: Array<string>;
    global_label: string;
  };
}

interface UploadImageResponse {
  existing_items_number: number | null;
  message: string;
  title: string;
}

const uploadImage = new Endpoint<UploadImageRequest, UploadImageResponse>({
  url: `/api/me/uploads/image`,
  method: 'POST',
  headers: {},
});

export default uploadImage;
