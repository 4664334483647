import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ImageModerationRawModel } from 'product-types/src/domain/image/ImageModerationModel';

interface GetImageRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
}

const getImage = new Endpoint<GetImageRequestModel, ImageModerationRawModel>({
  url: '/api/me/duplicated_groups/:id',
  method: 'GET',
  headers: {},
});

export default getImage;
