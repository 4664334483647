import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import assignTags from './assignTags';

export interface NaveeAdminEndpoints extends EndpointContainer {
  assignTags: typeof assignTags;
}

const naveeAdmin: NaveeAdminEndpoints = {
  assignTags,
  injectHTTPClient(client: HttpClient) {
    assignTags.injectHTTPClient(client);
  },
};

export default naveeAdmin;
