import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import * as Domain from 'product-types/src/domain/Domain';
import { Tag as TagModel } from 'product-types/src/domain/tag/Tag';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

export const formatModerationStatus = (
  moderationStatus: Domain.ModerationStatus.ModerationStatus,
  filterType: FilterTypeEnum,
): TagModel => {
  if (filterType === FilterTypeEnum.postModerationStatus) {
    return {
      ...moderationStatus,
      id: moderationStatus.value,
      name: `Post Status: ${moderationStatus.label}`,
    };
  }
  if (filterType === FilterTypeEnum.imageModerationStatus) {
    return {
      ...moderationStatus,
      id: moderationStatus.value,
      name: `Image Status: ${moderationStatus.label}`,
    };
  }
  if (filterType === FilterTypeEnum.accountModerationStatus) {
    return {
      ...moderationStatus,
      id: moderationStatus.value,
      name: `Account Status: ${moderationStatus.label}`,
    };
  }
  throw new Error('filter type not specified');
};

export const NewModerationFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, name, value },
  onClickRemove,
}: DisplayFilterComponentProps) => {
  if (name === FilterTypeEnum.moderationMethod && value.method) {
    return (
      <Tag
        tag={{
          name: `${
            (value.method as Domain.ModerationMethod.ModerationMethod).label
          } Moderation`,
          id: value,
        }}
        iconPrefix={<NaveeIcon.SearchInDocIcon />}
        onClickRemove={onClickRemove}
      />
    );
  }
  const postTag = (
    <Tag
      key={uuid}
      tag={formatModerationStatus(
        value as Domain.ModerationStatus.ModerationStatus,
        name,
      )}
      iconPrefix={<NaveeIcon.DocIcon />}
      onClickRemove={onClickRemove}
    />
  );
  const imageTag = (
    <Tag
      key={uuid}
      tag={formatModerationStatus(
        value as Domain.ModerationStatus.ModerationStatus,
        name,
      )}
      iconPrefix={<NaveeIcon.CardImage width={12} height={12} />}
      onClickRemove={onClickRemove}
    />
  );
  const accountTag = (
    <Tag
      key={uuid}
      tag={formatModerationStatus(
        value as Domain.ModerationStatus.ModerationStatus,
        name,
      )}
      iconPrefix={<NaveeIcon.AccountModIcon />}
      onClickRemove={onClickRemove}
    />
  );
  const filterTypeTagMap = {
    [FilterTypeEnum.postModerationStatus]: postTag,
    [FilterTypeEnum.imageModerationStatus]: imageTag,
    [FilterTypeEnum.accountModerationStatus]: accountTag,
  };
  return filterTypeTagMap[name] || null;
};
