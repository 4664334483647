import * as Domain from '../Domain';

export interface AccountFromContact {
  accountName: string;
  id: Domain.Account.Id;
  websiteName: string;
}
export interface ExtractedContacts {
  contact: string;
  count: number;
  contactType: Domain.Contact.ContactType;
}

export class AdvancedStatistics {
  accounts: Domain.Account.Id[];

  accountsFromContacts: AccountFromContact[];

  extractedContacts: ExtractedContacts[];

  images: Domain.Image.Image.Id[];

  posts: Domain.Post.Post.Id[];

  websites: Domain.Website.Id[];

  constructor(props: AdvancedStatistics) {
    this.accounts = props.accounts;
    this.extractedContacts = props.extractedContacts;
    this.posts = props.posts;
    this.images = props.images;
    this.websites = props.websites;
    this.accountsFromContacts = props.accountsFromContacts;
  }
}
