export const LOAD_POSTS_DATA = 'app/PostView/LOAD_POSTS_DATA';
export const LOAD_HISTORICAL_ANALYSIS_DATA =
  'app/PostView/LOAD_HISTORICAL_ANALYSIS_DATA';
export const LOAD_RELATED_POSTS_DATA = 'app/PostView/LOAD_RELATED_POSTS_DATA';
export const LOAD_DUPLICATED_GROUP_DATA =
  'app/PostView/LOAD_DUPLICATED_GROUP_DATA';
export const LOAD_POST_DATA_SUCCESS = 'app/PostView/LOAD_POST_DATA_SUCCESS';
export const UPDATE_POSTS_COUNT = 'app/PostView/UPDATE_POSTS_COUNT';
export const LOAD_POSTS_COUNT = 'app/PostView/LOAD_POSTS_COUNT';
export const UPDATE_POST_MODERATION_STATE =
  'app/PostView/UPDATE_POST_MODERATION_STATE';
export const RESET_STATE = 'app/PostView/RESET_STATE';
export const RECRAWL_POST = 'app/PostView/RECRAWL_POST';
export const UPDATE_TAKEN_DOWN_STATUS = 'app/PostView/UPDATE_TAKEN_DOWN_STATUS';
