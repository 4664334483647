import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { GeographicalDestributionResponse } from 'product-types/src/domain/dashboard/DashboardGeographicalDestribution';

export interface GeographicalDestributionRequestParams
  extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface GeographicalDestributionRequest extends EndpointRequests {
  params: GeographicalDestributionRequestParams;
}

const geographicalDestribution = new Endpoint<
  GeographicalDestributionRequest,
  GeographicalDestributionResponse
>({
  url: '/api/me/stats/geographical_distribution',
  method: 'GET',
  headers: {},
});

export default geographicalDestribution;
