import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { TakedownSentResponseModel } from 'product-types/src/domain/dashboard/DashboardTakedownSentModel';

interface TakedownSentRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const getTakedownSent = new Endpoint<
  TakedownSentRequestModel,
  TakedownSentResponseModel
>({
  url: '/api/me/stats/takedown/sent',
  method: 'GET',
  headers: {},
});

export default getTakedownSent;
