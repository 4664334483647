export class Text {
  title: string;

  titleTranslated: string;

  description: string;

  descriptionTranslated: string;

  sourceLanguage?: string;

  constructor(
    prop: Pick<
      Text,
      'description' | 'descriptionTranslated' | 'title' | 'titleTranslated'
    > & { sourceLanguage?: string },
  ) {
    this.description = prop.description;
    this.descriptionTranslated = prop.descriptionTranslated;
    this.title = prop.title;
    this.titleTranslated = prop.titleTranslated;
    this.sourceLanguage = prop.sourceLanguage;
  }

  get hasTranslation() {
    return this.sourceLanguage && this.sourceLanguage !== 'en';
  }
}
