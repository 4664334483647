export const LOAD_CLUSTERS = 'app/ClusterView/LOAD_CLUSTERS';
export const LOAD_CLUSTERS_SUCCESS = 'app/ClusterView/LOAD_CLUSTERS_SUCCESS';
export const LOAD_CLUSTER = 'app/ClusterView/LOAD_CLUSTER';
export const LOAD_CLUSTER_SUCCESS = 'app/ClusterView/LOAD_CLUSTER_SUCCESS';

export const SAVE_CLUSTER_TITLE = 'app/ClusterView/SAVE_CLUSTER_TITLE';
export const LOAD_COMMENTS_CLUSTER = 'app/ClusterView/LOAD_COMMENTS_CLUSTER';
export const ADD_COMMENT = 'app/ClusterView/ADD_COMMENT';
export const UPDATE_CLUSTER_COMMENTS =
  'app/ClusterView/UPDATE_CLUSTER_COMMENTS';
export const DELETE_COMMENT = 'app/ClusterView/DELETE_COMMENT';
export const LOAD_RELATED_POSTS_CLUSTER_SUCCESS =
  'app/ClusterView/LOAD_RELATED_POSTS_CLUSTER_SUCCESS';
export const LOAD_VENDORS_CLUSTER = 'app/ClusterView/LOAD_VENDORS_CLUSTER';
export const LOAD_VENDORS_CLUSTER_SUCCESS =
  'app/ClusterView/LOAD_VENDORS_CLUSTER_SUCCESS';
export const LOAD_CONTACT_INFO_CLUSTER =
  'app/ClusterView/LOAD_CONTACT_INFO_CLUSTER';
export const START_DELETE_VENDORS_CLUSTER =
  'app/ClusterView/START_DELETE_VENDORS_CLUSTER';
export const DELETE_VENDORS_CLUSTER_SUCCESS =
  'app/ClusterView/DELETE_VENDORS_CLUSTER_SUCCESS';
export const DELETE_VENDORS_CLUSTER_ERROR =
  'app/ClusterView/DELETE_VENDORS_CLUSTER_ERROR';
export const MERGE_CLUSTERS = 'app/ClusterView/MERGE_CLUSTERS';
export const START_ADD_ACCOUNTS = 'app/ClusterView/START_ADD_ACCOUNTS';
export const START_ADD_WEBSITES = 'app/ClusterView/START_ADD_WEBSITES';
export const ADD_WEBSITES_ERROR = 'app/ClusterView/ADD_WEBSITES_ERROR';
export const ADD_WEBSITES_SUCCESS = 'app/ClusterView/ADD_WEBSITES_SUCCESS';
export const START_ADD_VENDOR = 'app/ClusterView/START_ADD_VENDOR';
export const UPDATE_LOADING = 'app/ClusterView/UPDATE_LOADING';
export const ADD_VENDOR_SUCCESS = 'app/ClusterView/ADD_VENDOR_SUCCESS';
export const ADD_VENDOR_ERROR = 'app/ClusterView/ADD_VENDOR_ERROR';
export const RESET_STATE = 'app/ClusterView/RESET_STATE';
export const LOAD_ASSOCIATED_IMAGES = 'app/ClusterView/LOAD_ASSOCIATED_IMAGES';
export const UPDATE_ASSOCIATED_IMAGES =
  'app/ClusterView/UPDATE_ASSOCIATED_IMAGES';
export const UPDATE_FILTERS_RELATED_POSTS =
  'app/ClusterView/UPDATE_FILTERS_RELATED_POSTS';
export const UPDATE_FILTERS_ASSOCIATED_POSTERS_WEBSITES =
  'app/ClusterView/UPDATE_FILTERS_ASSOCIATED_POSTERS_WEBSITES';
