import { css } from 'styled-components';

export const Font = ({ size, weight = 500, lineHeight = 0 }) => css`
  font-size: ${size}px;
  line-height: ${lineHeight || size}px;
  font-weight: ${weight};
`;

export const Flex = ({ justify = 'center', align = 'center' }) => css`
  display: flex;
  justify-content: ${justify};
  align-items: ${align};
`;

export const ButtonAsLink = ({
  size = 15,
  weight = 300,
  color = ' #3B86FF',
}) => css`
  color: ${color};
  border: none;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
  ${Font({ size, weight })}
`;

export const LineClampWithEllipsis = ({ lines }) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
`;
