import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AccountResponseModel {
  message: string;
}

interface AccountRequestModel extends EndpointRequests {
  data: {
    account_ids: Array<number>;
    taken_down: boolean;
  };
}

const updateTakedownStatus = new Endpoint<
  AccountRequestModel,
  AccountResponseModel
>({
  url: '/api/me/accounts/update_takedown_status',
  method: 'POST',
  headers: {},
});

export default updateTakedownStatus;
