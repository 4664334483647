import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DeleteModerationReasonResponseModel {
  message: string;
  code: string;
}

interface DeleteModerationReasonModel extends EndpointRequests {
  data: {
    moderation_reason_id: number;
    duplicated_group_id: number;
  };
}

const deleteModerationReason = new Endpoint<
  DeleteModerationReasonModel,
  DeleteModerationReasonResponseModel
>({
  url: '/api/me/moderation_reason/remove_by_duplicated_group_id',
  method: 'POST',
  headers: {},
});

export default deleteModerationReason;
