import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import get from './get';
import update from './update';
import checkImageReason from './checkImageReason';
import unCheckImageReason from './unCheckImageReason';
import checkPostReason from './checkPostReason';
import unCheckPostReason from './unCheckPostReason';

export interface ModerationReasonEndpoints extends EndpointContainer {
  get: typeof get;
  update: typeof update;
  checkImageReason: typeof checkImageReason;
  unCheckImageReason: typeof unCheckImageReason;
  checkPostReason: typeof checkPostReason;
  unCheckPostReason: typeof unCheckPostReason;
}

const moderationReason: ModerationReasonEndpoints = {
  get,
  update,
  checkImageReason,
  unCheckImageReason,
  checkPostReason,
  unCheckPostReason,
  injectHTTPClient(client: HttpClient) {
    get.injectHTTPClient(client);
    update.injectHTTPClient(client);
    checkImageReason.injectHTTPClient(client);
    unCheckImageReason.injectHTTPClient(client);
    checkPostReason.injectHTTPClient(client);
    unCheckPostReason.injectHTTPClient(client);
  },
};

export default moderationReason;
