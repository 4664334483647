import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadNewLogoRequestModel extends EndpointRequests {
  data: FormData;
}

interface UploadNewLogoResponse {
  object: {
    logo_url: string;
  };
}

const uploadNewLogo = new Endpoint<
  UploadNewLogoRequestModel,
  UploadNewLogoResponse
>({
  url: '/api/organisation/logo',
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadNewLogo;
