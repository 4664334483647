import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { WebsiteModerationRawModel } from 'product-types/src/domain/website/WebsiteModerationModel';

interface GetModerationWebsiteRequestModel extends EndpointRequests {
  urlParams: { id: string };
}

const getModerationWebsite = new Endpoint<
  GetModerationWebsiteRequestModel,
  WebsiteModerationRawModel
>({
  url: '/api/me/accounts_moderation/:id',
  method: 'GET',
  headers: {},
});

export default getModerationWebsite;
