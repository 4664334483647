export class PostModerationReason {
  isProductUsed: boolean;

  isForSale: boolean;

  isDescCounterfeit: boolean;

  constructor(
    prop: Pick<
      PostModerationReason,
      'isDescCounterfeit' | 'isForSale' | 'isProductUsed'
    >,
  ) {
    this.isDescCounterfeit = prop.isDescCounterfeit;
    this.isProductUsed = prop.isProductUsed;
    this.isForSale = prop.isForSale;
  }
}
