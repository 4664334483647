import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { DashboardWorldGraphRaw } from 'product-types/src/domain/dashboard/worldGraph/DashboardWorldGraphModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

export interface DashboardWorldGraphResponse {
  posts_per_country_per_month: Array<DashboardWorldGraphRaw>;
}

export interface DashboardWorldGraphParams
  extends CommonDashboardRequestParams {
  limit: number;
  category_id: Array<number> | null;
}

export interface WorldGraph extends EndpointRequests {
  params: DashboardWorldGraphParams;
}

const worldGraph = new Endpoint<WorldGraph, DashboardWorldGraphResponse>({
  url: '/api/me/stats/geographical_distribution/graph',
  method: 'GET',
  headers: {},
});

export default worldGraph;
