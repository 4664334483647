import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface PostResponseModel {
  message: string;
}

interface PostRequestModel extends EndpointRequests {
  data: {
    post_ids: Array<number>;
    taken_down: boolean;
  };
}

const updateTakedownStatus = new Endpoint<PostRequestModel, PostResponseModel>({
  url: '/api/me/posts/update_takedown_status',
  method: 'POST',
  headers: {},
});

export default updateTakedownStatus;
