/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SELECT_ALL_TRACKED_WEBSITES,
  SELECT_ALL_UNTRACKED_WEBSITES,
} from 'store/constants';
import * as Domain from 'product-types/src/domain/Domain';
import { CrawlingDateValueEnum } from 'product-types/src/domain/date/CrawlingDate';
import { mapTakedownValue } from 'product-types/src/domain/takedownStatus/takedownStatus';
import { removeEmptyProperties } from 'product-utils/src/object';
import { FeedFilterQueryValue } from 'types/filters/MoleculesFilterImplementation/FeedFilterModel';
import { NonCircularCategory } from 'types/filters/AtomicFiltersImplementation/Product/ProductValue';

export interface FeedRequestParameter {
  perpage: number | undefined;
  offset: number | undefined;
  start_date: string;
  end_date: string;
  domain_names?: Array<string>;
  timezone_offset?: number;
  category_id?: Array<number | string>;
  post_moderation_status?: Array<string>;
  account_moderation_status?: Array<string>;
  image_moderation_status?: Array<string>;
  status_type?: Array<string>;
  hise?: Array<string>;
  related_to_account_id?: Array<number>;
  account_status_type?: Array<string>;
  geography_to_include?: Array<string>;
  geography_to_exclude?: Array<string>;
  label_type_to_include?: Array<string>;
  sort_by?: string;
  reverse?: boolean;
  post_id?: number;
  account_website_id?: number;
  account_poster_id?: number;
  post_moderation_reasons?: Array<number>;
  image_moderation_reasons?: Array<number>;
  current_post_id?: number;
  website_id?: Array<number | string>;
  poster_id?: number;
  cluster_id?: number;
  minimum_stock?: number;
  maximum_stock?: number;
  post_url?: string;
  sort_images_duplicated_group_first?: boolean;
  vendor_names?: Array<string>;
  poster_names?: Array<string>;
  keywords_to_include?: Array<string>;
  keywords_to_exclude?: Array<string>;
  comments_to_include?: Array<string>;
  contact_info_to_include?: Array<string>;
  comments_to_exclude?: Array<string>;
  taken_down?: Array<string>;
  duplicated_group_id?: Array<number | string>;
  infringement_type?: string;
  website_category_id?: Array<number | string>;
  website_category_id_to_exclude?: Array<number | string>;
  post_tags_to_include?: Array<number | string>;
  post_tags_to_exclude?: Array<number | string>;
  account_tags_to_exclude?: Array<number | string>;
  account_tags_to_include?: Array<number | string>;
  duplicated_group_tags_to_exclude?: Array<number | string>;
  duplicated_group_tags_to_include?: Array<number | string>;
  cluster_tags_to_exclude?: Array<number | string>;
  cluster_tags_to_include?: Array<number | string>;
  website_id_to_exclude?: Array<number | string>;
  moderation_method?: Array<string>;
  poster_urls?: Array<string>;
  minimum_followers_count?: number;
  maximum_followers_count?: number;
  label_types_logic?: string;
  maximum_price?: number;
  minimum_price?: number;
  interval?: string;
  all_tracked_websites?: string;
  all_untracked_websites?: string;
  view_type?: string;
  date_filter_type?: string;
  maximum_percentage_of_original_price?: number;
  search_keyword?: string;
  account_type?: string;
  retrieve_count?: boolean;
  retrieve_objects?: boolean;
}

export interface ExportFeedRequestParameter extends FeedRequestParameter {
  destination_email?: string;
  referrer?: string;
  items_name: 'posts' | 'accounts' | 'images' | 'clusters' | 'uploads';
}

export function formatImageCrawlingDate(crawlingDate: CrawlingDateValueEnum) {
  if (crawlingDate === CrawlingDateValueEnum.validationDate) {
    return 'image_validation_date';
  }
  if (crawlingDate === CrawlingDateValueEnum.checkDate) {
    return 'image_check_date';
  }
  if (crawlingDate === CrawlingDateValueEnum.moderationDate) {
    return 'image_moderation_date';
  }
  if (crawlingDate === CrawlingDateValueEnum.crawlingDate) {
    return 'image_first_crawling_date';
  }
  return crawlingDate ?? 'image_first_crawling_date';
}

export function getRequestParameter(
  action: FeedFilterQueryValue,
): FeedRequestParameter {
  const websiteValuesToExclude = [
    SELECT_ALL_TRACKED_WEBSITES,
    SELECT_ALL_UNTRACKED_WEBSITES,
  ];

  const { minimum_followers_count, maximum_followers_count, interval } = action;

  const includeTracked =
    (action.website_id || []).filter(
      (value) => value === SELECT_ALL_TRACKED_WEBSITES,
    ).length === 1;

  const includeUntracked =
    (action.website_id || []).filter(
      (value) => value === SELECT_ALL_UNTRACKED_WEBSITES,
    ).length === 1;

  const website_id = (
    (action.website_id || []) as Array<Domain.Website.WebsiteSuggestion>
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes(value.id.toString()),
    )
    .map((val) => val.id)
    .filter((value: any) => value !== 'NaN');

  const website_id_to_exclude = (
    (action.website_id_to_exclude ||
      []) as Array<Domain.Website.WebsiteSuggestion>
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes(value.id.toString()),
    )
    .map((val) => val.id);

  const website_category_id = (
    (action.website_category_id ||
      []) as Array<Domain.Website.WebsiteSuggestion>
  ).map((val) => val.id);
  const website_category_id_to_exclude = (
    (action.website_category_id_to_exclude ||
      []) as Array<Domain.Website.WebsiteSuggestion>
  ).map((val) => val.id);

  return removeEmptyProperties<FeedRequestParameter>({
    current_post_id: action.current_post_id,
    poster_id: action.account_poster_id,
    perpage: action.perpage ?? 10,
    offset: action.offset,
    post_moderation_reasons: (action.post_moderation_reasons || []).map(
      ({ moderationReasonId }) => moderationReasonId,
    ),
    image_moderation_reasons: (action.image_moderation_reasons || []).map(
      ({ moderationReasonId }) => moderationReasonId,
    ),
    start_date: action.start_date,
    cluster_id: action.cluster_id,
    end_date: action.end_date,
    timezone_offset: new Date().getTimezoneOffset(),
    category_id: ((action.categoryId || []) as NonCircularCategory[]).map(
      ({ id }) => id,
    ),
    post_moderation_status: (
      (action.post_moderation_status ||
        []) as Array<Domain.ModerationStatus.ModerationStatus>
    ).map(({ value }) => value),
    account_moderation_status: (
      (action.account_moderation_status ||
        []) as Array<Domain.ModerationStatus.ModerationStatus>
    ).map(({ value }) => value),
    image_moderation_status: (
      (action.image_moderation_status ||
        []) as Array<Domain.ModerationStatus.ModerationStatus>
    ).map(({ value }) => value),
    status_type: ((action.status_type || []) as Array<Domain.Label.Label>).map(
      ({ name }) => name,
    ),
    hise: ((action.hise || []) as Array<Domain.Label.Label>).map(
      ({ name }) => name,
    ),
    account_status_type: (
      (action.account_status_type || []) as Array<Domain.Label.Label>
    ).map(({ name }) => name),
    geography_to_include: (
      (action.geography_to_include || []) as Array<Domain.Geographic.Geographic>
    ).map(({ value }) => value),
    geography_to_exclude: (
      (action.geography_to_exclude || []) as Array<Domain.Geographic.Geographic>
    ).map(({ value }) => value),
    label_type_to_include: (
      (action.label_type_to_include || []) as Domain.Insight.Insight[]
    )
      .map(({ value }) => value)
      .concat((action.contact_types || []).map(({ value }) => value)),
    sort_by: 'occurrences',
    vendor_names: action.vendor_names,
    domain_names: action.domain_names,
    poster_names: action.poster_names,
    reverse: true,
    related_to_account_id: action.related_to_account_id,
    post_id: action.post_id,
    post_url: action.post_url,
    poster_urls: action.poster_urls,
    minimum_stock: action.minimum_stock,
    maximum_stock: action.maximum_stock,
    keywords_to_include: action.keywords_to_include,
    keywords_to_exclude: action.keywords_to_exclude,
    comments_to_include: action.comments_to_include,
    moderator_id: action.moderator_id,
    qa_checker_id: action.qa_checker_id,
    validator_id: action.validator_id,
    commenter_id: action.commenter_id,
    contact_info_to_include: action.contact_info_to_include,
    comments_to_exclude: action.comments_to_exclude,
    account_website_id: action.account_website_id,
    website_id,
    taken_down: (
      (action.taken_down || []) as Array<Domain.TakedownStatus.TakedownStatus>
    ).map(({ value }) => mapTakedownValue(value)),
    duplicated_group_id: action.duplicated_group_id,
    website_category_id,
    website_category_id_to_exclude,
    post_tags_to_include: (
      (action.post_tags_to_include || []) as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    post_tags_to_exclude: (
      (action.post_tags_to_exclude || []) as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    account_tags_to_exclude: (
      (action.account_tags_to_exclude || []) as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    account_tags_to_include: (
      (action.account_tags_to_include || []) as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    duplicated_group_tags_to_exclude: (
      (action.duplicated_group_tags_to_exclude || []) as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    duplicated_group_tags_to_include: (
      (action.duplicated_group_tags_to_include || []) as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    cluster_tags_to_exclude: (
      (action.cluster_tags_to_exclude || []) as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    cluster_tags_to_include: (
      (action.cluster_tags_to_include || []) as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    post_tag_name_contains_to_include: (
      (action.post_tag_name_contains_to_include || []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    account_tag_name_contains_to_include: (
      (action.account_tag_name_contains_to_include ||
        []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    image_tag_name_contains_to_include: (
      (action.image_tag_name_contains_to_include || []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    cluster_tag_name_contains_to_include: (
      (action.cluster_tag_name_contains_to_include ||
        []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    post_tag_name_contains_to_exclude: (
      (action.post_tag_name_contains_to_exclude || []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    image_tag_name_contains_to_exclude: (
      (action.image_tag_name_contains_to_exclude || []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    account_tag_name_contains_to_exclude: (
      (action.account_tag_name_contains_to_exclude ||
        []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    cluster_tag_name_contains_to_exclude: (
      (action.cluster_tag_name_contains_to_exclude ||
        []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    website_id_to_exclude,
    moderation_method: action.moderation_method,
    minimum_followers_count,
    maximum_followers_count,
    maximum_price: action.maximum_price,
    minimum_price: action.minimum_price,
    interval,
    label_types_logic: 'AND',
    all_tracked_websites: includeTracked ? 'true' : undefined,
    all_untracked_websites: includeUntracked ? 'true' : undefined,
    date_filter_type: action.date_filter_type,
    retrieve_count: action.retrieve_count,
    retrieve_objects: action.retrieve_objects,
  });
}
