import { ImageModerationRawModel } from 'product-types/src/domain/image/ImageModerationModel';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DuplicatedGroupRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
}

const duplicatedGroup = new Endpoint<
  DuplicatedGroupRequestModel,
  ImageModerationRawModel
>({
  url: `/api/me/duplicated_groups/:id`,
  method: 'GET',
  headers: {},
});

export default duplicatedGroup;
