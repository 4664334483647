import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationAccountsResponseModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsAccountsModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

interface ModerationAccountsRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const getModerationAccounts = new Endpoint<
  ModerationAccountsRequestModel,
  ModerationAccountsResponseModel
>({
  url: '/api/me/stats/moderation/accounts',
  method: 'GET',
  headers: {},
});

export default getModerationAccounts;
