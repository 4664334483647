import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModalFilters = styled(Modal)`
  width: 550px !important;
  padding-bottom: 0 !important;
  border-radius: 15px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--white);
  z-index: 10000000000000;

  .label {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: var(--dusk);
    margin-bottom: 8px;
    text-transform: uppercase;
    display: block;
  }

  input {
    height: 44px;
    border-radius: 7px;
    border: solid 2px #ebebf2;
  }

  .ant-tabs {
    margin-top: -69px;
  }

  .ant-modal-body {
    margin-top: 49px;
  }

  .ant-modal-header,
  .ant-tabs-bar {
    border-bottom: 0;
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 300;
    color: var(--dusk);
  }

  .ant-modal-title {
    margin-left: 0 !important;
    padding-right: 1rem;
    line-height: 1.25;
  }
`;
