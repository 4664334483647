import React, { ReactElement, useMemo } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import Typography from '../Typography';
import { useGetClassesFromObject } from '../Typography/hooks';

type ChipStatusProps = {
  label: ReactElement | string;
  icon?: (props: { fill: string }) => ReactElement;
  variant: 'default' | 'active' | 'unknown' | 'danger';
  style?: React.CSSProperties;
};

const StyledChipStatus = styled(Space)`
  border-radius: 4px;
  padding: 5px 8px;
  &-default {
    background: var(--neutral-grey-100);
  }
  &.-active {
    outline: 1px solid var(--custom-green);
    background: rgba(48, 219, 133, 0.1);
  }
  &.-unknown {
    outline: 1px solid var(--orange);
    background: rgba(255, 161, 119, 0.1);
  }
  &.-danger {
    background: rgba(255, 114, 133, 0.1);
  }
`;

export const ChipStatus = ({
  label,
  icon: Icon,
  variant,
  style = {},
}: ChipStatusProps) => {
  const classNames = useGetClassesFromObject({
    [variant]: true,
  });
  const fontColor = useMemo(
    () =>
      ({
        default: 'var(--primary-black)',
        danger: 'var(--primary-black)',
        active: 'var(--custom-green)',
        unknown: 'var(--orange)',
      })[variant],
    [variant],
  );
  return (
    <StyledChipStatus className={classNames()} size="small" style={style}>
      {Icon && (
        <div className="icon-box">
          <Icon fill="red" />
        </div>
      )}
      <Typography
        style={{
          lineHeight: '12px',
        }}
        variant="xxsmall"
        fontWeight="bold"
        color={fontColor}
      >
        {label}
      </Typography>
    </StyledChipStatus>
  );
};
