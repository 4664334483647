import { useEffect, useState } from 'react';

export function useTextHighlight(): boolean {
  const [isTextSelected, setIsTextSelected] = useState(false);

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      if (selection && selection.toString().length > 0) {
        setIsTextSelected(true);
      } else {
        setIsTextSelected(false);
      }
    };

    window.addEventListener('mouseup', handleSelectionChange);
    window.addEventListener('keydown', handleSelectionChange);

    return () => {
      window.removeEventListener('mouseup', handleSelectionChange);
      window.removeEventListener('keydown', handleSelectionChange);
    };
  }, []);

  return isTextSelected;
}
