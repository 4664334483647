import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { FeedRequestParameter } from '../../../../../../Feed/Feed';

interface GetNextPostToModerateRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

interface GetNextPostToModerateResponse {}

const nextPostToModerate = new Endpoint<
  GetNextPostToModerateRequestModel,
  GetNextPostToModerateResponse
>({
  url: '/api/me/post_moderation/next',
  method: 'POST',
  headers: {},
});

export default nextPostToModerate;
