import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ResetPasswordRequestModel extends EndpointRequests {
  data: {
    email: string;
  };
}

export interface ResetPasswordResponse {
  message: string;
}

const resetPassword = new Endpoint<
  ResetPasswordRequestModel,
  ResetPasswordResponse
>({
  url: '/api/reset_password',
  method: 'POST',
  headers: {},
});

export default resetPassword;
