import { Endpoint } from 'types/network/Http/endpoint';

const downloadImageExcelFile = new Endpoint<
  NonNullable<unknown>,
  NonNullable<unknown>
>({
  url: '/api/me/uploads/image/file',
  method: 'GET',
  headers: {},
});

export default downloadImageExcelFile;
