import React from 'react';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
} from '../../../types/filters/AtomicFilters/Filter';

export const CrawlingDateFilterValue: DisplayFilterComponent = ({
  filterValue: { value },
}: DisplayFilterComponentProps) => (
  <Tag
    tag={{
      name: value.label,
    }}
    iconPrefix={value.icon && <value.icon />}
    showRemoveIcon={false}
  />
);
