import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import saveKeywords from './saveKeywords';
import removeKeyword from './removeKeyword';
import getKeywords from './getKeywords';
import translateKeyword from './translateKeyword';
import uploadKeywordsFile from './uploadKeywordsFile';
import saveHashtags from './saveHashtags';
import removeHashtag from './removeHashtags';
import getHashtags from './getHashtags';
import translateHashtag from './translateHashtags';
import uploadHashtagsFile from './uploadHashtagsFile';
import getDomains from './domains';
import downloadHashtagKeywordExcelFile from './downloadHashtagKeywordExcelFile';
import updateDomains from './updateDomains';

export interface CrawlingConfigEndpoints extends EndpointContainer {
  saveKeywords: typeof saveKeywords;
  downloadHashtagKeywordExcelFile: typeof downloadHashtagKeywordExcelFile;
  saveHashtags: typeof saveHashtags;
  uploadKeywordsFile: typeof uploadKeywordsFile;
  uploadHashtagsFile: typeof uploadHashtagsFile;
  removeKeyword: typeof removeKeyword;
  removeHashtag: typeof removeHashtag;
  getKeywords: typeof getKeywords;
  getHashtags: typeof getHashtags;
  translateKeyword: typeof translateKeyword;
  translateHashtag: typeof translateHashtag;
  getDomains: typeof getDomains;
  updateDomains: typeof updateDomains;
}

const cralwingConfig: CrawlingConfigEndpoints = {
  saveKeywords,
  getKeywords,
  downloadHashtagKeywordExcelFile,
  uploadKeywordsFile,
  removeKeyword,
  translateKeyword,
  saveHashtags,
  getHashtags,
  removeHashtag,
  translateHashtag,
  uploadHashtagsFile,
  getDomains,
  updateDomains,
  injectHTTPClient(client: HttpClient) {
    saveKeywords.injectHTTPClient(client);
    downloadHashtagKeywordExcelFile.injectHTTPClient(client);
    saveHashtags.injectHTTPClient(client);
    uploadKeywordsFile.injectHTTPClient(client);
    uploadHashtagsFile.injectHTTPClient(client);
    translateKeyword.injectHTTPClient(client);
    translateHashtag.injectHTTPClient(client);
    getKeywords.injectHTTPClient(client);
    getHashtags.injectHTTPClient(client);
    removeKeyword.injectHTTPClient(client);
    removeHashtag.injectHTTPClient(client);
    getDomains.injectHTTPClient(client);
    updateDomains.injectHTTPClient(client);
  },
};

export default cralwingConfig;
