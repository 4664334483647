import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommentRaw } from 'product-types/src/domain/comment/Comment';

interface CommentsRequest extends EndpointRequests {
  urlParams: { id: string };
}

interface CommentsResponse {
  comments: Array<CommentRaw>;
}

const comments = new Endpoint<CommentsRequest, CommentsResponse>({
  url: '/api/me/accounts_moderation/:id/comments',
  method: 'GET',
  headers: {},
});

export default comments;
