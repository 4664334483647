import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import moderateWebsite from './moderateWebsite';
import prevWebsiteToModerate from './prevWebsiteToModerate';
import nextWebsiteToModerate from './nextWebsiteToModerate';
import moderateAndReturnWebsite from './moderateAndReturnWebsite';

export interface WebsitesModerationEndpoints extends EndpointContainer {
  moderateWebsite: typeof moderateWebsite;
  moderateAndReturnWebsite: typeof moderateAndReturnWebsite;
  nextWebsiteToModerate: typeof nextWebsiteToModerate;
  prevWebsiteToModerate: typeof prevWebsiteToModerate;
}

const websitesModeration: WebsitesModerationEndpoints = {
  moderateWebsite,
  moderateAndReturnWebsite,
  nextWebsiteToModerate,
  prevWebsiteToModerate,
  injectHTTPClient(client: HttpClient) {
    moderateWebsite.injectHTTPClient(client);
    moderateAndReturnWebsite.injectHTTPClient(client);
    nextWebsiteToModerate.injectHTTPClient(client);
    prevWebsiteToModerate.injectHTTPClient(client);
  },
};

export default websitesModeration;
