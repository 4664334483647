import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AccountResponseModel {
  message: string;
  code: string;
}

interface AccountRequestModel extends EndpointRequests {
  data: {
    cluster_ids: Array<number>;
  };
}

const merge = new Endpoint<AccountRequestModel, AccountResponseModel>({
  url: '/api/me/clusters/merge',
  method: 'POST',
  headers: {},
});

export default merge;
