import { Endpoint } from 'types/network/Http/endpoint';
import { UserLightRawModel } from 'product-types/src/domain/user/UserLightModel';

export type UsersResponse = Array<UserLightRawModel>;

const users = new Endpoint<any, UsersResponse>({
  url: '/api/me/users',
  method: 'GET',
  headers: {},
});

export default users;
