import { HttpClient } from '../client/client';
import { EndpointContainer } from '../endpoint';

class BackendService<
  EndpointContainerImplementation extends EndpointContainer,
> {
  client: HttpClient;

  endpoints: EndpointContainerImplementation;

  constructor(
    prop: Pick<
      BackendService<EndpointContainerImplementation>,
      'client' | 'endpoints'
    >,
  ) {
    this.client = prop.client;
    this.endpoints = prop.endpoints;
    this.endpoints.injectHTTPClient(this.client);
  }
}

export default BackendService;
