import { LabelRaw } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface OrganisationPostLabelsRequestModel extends EndpointRequests {}

export type OrganisationPostLabelsResponse = Array<LabelRaw>;

const post = new Endpoint<
  OrganisationPostLabelsRequestModel,
  OrganisationPostLabelsResponse
>({
  url: '/api/me/organisation/labels/post',
  method: 'GET',
  headers: {},
});

export default post;
