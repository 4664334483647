import { ImageFeedModel } from 'product-types/src/domain/image/ImageFeedModel';
import { AssociatedImage } from 'product-types/src/domain/image/AssociatedImage';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { ImageModerationModel } from 'product-types/src/domain/image/ImageModerationModel';
import {
  DELETE_MODERATION_REASON,
  LOAD_ASSOCIATED_PICTURES,
  LOAD_DUPLICATED_GROUP_RESOURCES,
  LOAD_NEXT_DUPLICATE_GROUP_TO_MODERATE,
  LOAD_PREV_DUPLICATE_GROUP_TO_MODERATE,
  MODERATE_IMAGE_AND_UPDATE_IN_PLACE,
  MODERATE_SUGGESTED_FEATURE,
  PERFORM_DUPLICATED_GROUP_MODERATION,
  REFRESH_DUPLICATED_GROUP,
  UPDATE_ASSOCIATED_PICTURES,
  UPDATE_DUPLICATE_GROUP_FOR_MODERATION,
} from './constants';

export function performDuplicatedGroupModerationAction(
  duplicated_group_id_array: Array<
    ImageModerationModel | ImageFeedModel | AssociatedImage
  >,
  status,
  success_message = '',
  refresh_action = (() => {}) as any,
) {
  const image_statuses = duplicated_group_id_array.map(
    (duplicated_group_id) => {
      const data: any = {
        duplicated_group_id: duplicated_group_id.idForModeration,
        status,
      };
      return data;
    },
  );

  return {
    type: PERFORM_DUPLICATED_GROUP_MODERATION,
    data: { image_statuses },
    success_message,
    refresh_action,
  };
}

export function moderateDuplicatedGroupAndUpdateInPlaceAction(
  duplicatedGroup: ImageModerationModel | AssociatedImage,
  status,
  success_message = '',
) {
  const data = {
    duplicated_group_id: duplicatedGroup.idForModeration,
    status,
  };

  return {
    type: MODERATE_IMAGE_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}

export function performDuplicatedGroupQACheckAction(
  duplicated_group_id_array,
  qa_checked,
  refresh_action,
  success_message = '',
  on_success = () => {},
) {
  const image_statuses = duplicated_group_id_array.map(
    (duplicated_group_id) => ({
      duplicated_group_id: duplicated_group_id.idForModeration,
      qa_checked,
    }),
  );
  return {
    type: PERFORM_DUPLICATED_GROUP_MODERATION,
    data: { image_statuses },
    refresh_action,
    success_message,
    on_success,
  };
}

export function qaCheckDuplicatedGroupAndUpdateInPlaceAction(
  duplicatedGroup: ImageModerationModel | AssociatedImage,
  qa_checked,
  success_message = '',
) {
  const data = {
    duplicated_group_id: duplicatedGroup.idForModeration,
    qa_checked,
  };

  return {
    type: MODERATE_IMAGE_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}

export function performDuplicatedGroupValidationAction(
  duplicated_group_id_array,
  validated,
  refresh_action,
  success_message = '',
) {
  const image_statuses = duplicated_group_id_array.map(
    (duplicated_group_id) => ({
      duplicated_group_id: duplicated_group_id.idForModeration,
      validated,
    }),
  );
  return {
    type: PERFORM_DUPLICATED_GROUP_MODERATION,
    data: { image_statuses },
    refresh_action,
    success_message,
  };
}

export function validateDuplicatedGroupAndUpdateInPlaceAction(
  duplicatedGroup: ImageModerationModel | AssociatedImage,
  validated,
  success_message = '',
) {
  const data = {
    duplicated_group_id: duplicatedGroup.idForModeration,
    validated,
  };

  return {
    type: MODERATE_IMAGE_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}

export function loadNextDuplicateGroupToModerateAction(state) {
  return {
    type: LOAD_NEXT_DUPLICATE_GROUP_TO_MODERATE,
    ...state,
  };
}

export function loadPrevDuplicatedGroupToModerateAction(
  state,
  set_loading = () => {},
) {
  return {
    type: LOAD_PREV_DUPLICATE_GROUP_TO_MODERATE,
    ...state,
    set_loading,
  };
}

export function load_associated_pictures(data) {
  return {
    type: LOAD_ASSOCIATED_PICTURES,
    data,
  };
}

export function duplicatedGroupLoadedAction(
  duplicatedGroup: FetchableData<ImageModerationModel>,
  duplicated_group_moderation_index?: number,
  number_of_duplicated_groups_to_moderate?: number,
) {
  return {
    type: UPDATE_DUPLICATE_GROUP_FOR_MODERATION,
    duplicatedGroup,
    duplicated_group_moderation_index,
    number_of_duplicated_groups_to_moderate,
  };
}

export function loadDuplicatedGroupResourcesAction(imageId: number) {
  return {
    type: LOAD_DUPLICATED_GROUP_RESOURCES,
    imageId,
  };
}

export function refreshDuplicatedGroupAction(image_id) {
  return {
    type: REFRESH_DUPLICATED_GROUP,
    image_id,
  };
}

export function updateAssociatedPictures(
  associated_images: FetchableData<Array<AssociatedImage>>,
) {
  return {
    type: UPDATE_ASSOCIATED_PICTURES,
    associated_images,
  };
}

export function moderateSuggestedFeature(
  checked: boolean,
  featureId: number,
  refreshCallback: () => void,
) {
  return {
    type: MODERATE_SUGGESTED_FEATURE,
    checked,
    featureId,
    refreshCallback,
  };
}

export function deleteModerationReason({
  moderationReasonId,
  duplicatedGroupId,
  successCb,
}: {
  moderationReasonId: number;
  duplicatedGroupId: number;
  successCb: () => void;
}) {
  return {
    type: DELETE_MODERATION_REASON,
    moderationReasonId,
    duplicatedGroupId,
    successCb,
  };
}
