export class Stock {
  min: number | null;

  max: number | null;

  constructor(props?: Partial<Stock>) {
    this.min = props?.min ?? null;
    this.max = props?.max ?? null;
  }

  static get defaultValue() {
    return new Stock();
  }
}
