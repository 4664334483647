import { ClusterMemberRaw } from 'product-types/src/domain/account/ClusterMember';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ClusterMembersRequestParams {
  perpage: number;
  offset: number;
  sort_by: string;
  reverse: boolean;
  duplicated_group_id: number | string;
}

export interface ClusterMembersResponse {
  total: number;
  cluster_members: Array<ClusterMemberRaw>;
}

interface ClusterMembersRequest extends EndpointRequests {
  urlParams: {
    id: string;
  };
  params: ClusterMembersRequestParams;
}

const getClusterMembers = new Endpoint<
  ClusterMembersRequest,
  ClusterMembersResponse
>({
  url: '/api/me/clusters/members/:id',
  method: 'GET',
  headers: {},
});

export default getClusterMembers;
