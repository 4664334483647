import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface PrevWebsiteToModerateRequest extends EndpointRequests {
  data: FeedRequestParameter;
}

interface PrevWebsiteToModerateResponse {}

const prevWebsiteToModerate = new Endpoint<
  PrevWebsiteToModerateRequest,
  PrevWebsiteToModerateResponse
>({
  url: '/api/me/accounts_moderation/previous_account_to_moderate',
  method: 'POST',
  headers: {},
});

export default prevWebsiteToModerate;
