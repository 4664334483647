import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { WebsiteCategoriesBreakdownResponseModel } from 'product-types/src/domain/dashboard/websites/DashboardWebsiteCategoryStatModel';

interface WebsiteCategoriesBreakdownRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const getWebsiteCategoriesBreakdown = new Endpoint<
  WebsiteCategoriesBreakdownRequestModel,
  WebsiteCategoriesBreakdownResponseModel
>({
  url: '/api/me/stats/moderation/websites_categories',
  method: 'GET',
  headers: {},
});

export default getWebsiteCategoriesBreakdown;
