import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export type TagType = 'post' | 'account' | 'duplicated_group';
interface CreateTagRequest extends EndpointRequests {
  data: {
    id: number;
    name: string;
    tag_type: TagType;
  };
}

export interface CreateTagResponse {
  message: string;
  object: {
    id: number;
    name: string;
    tag_type: TagType;
    is_hidden: boolean;
  };
}

const createTag = new Endpoint<CreateTagRequest, CreateTagResponse>({
  url: '/api/me/organisation/tags',
  method: 'POST',
  headers: {},
});

export default createTag;
