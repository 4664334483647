import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { DashboardGeographyRawModel } from 'product-types/src/domain/dashboard/geography/DashboardGeography';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

export interface GeographyResponse {
  nb_total_posts: number;
  nb_total_countries: number;
  nb_total_regions: number;
  countries: Array<DashboardGeographyRawModel>;
  regions: Array<DashboardGeographyRawModel>;
}

interface GeographyRequestParams extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface GeographyRequest extends EndpointRequests {
  params: GeographyRequestParams;
}

const geography = new Endpoint<GeographyRequest, GeographyResponse>({
  url: '/api/me/stats/geography',
  method: 'GET',
  headers: {},
});

export default geography;
