import * as Domain from 'product-types/src/domain/Domain';
import { makeArrayUniqueByValue } from 'product-utils/src/array';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  labels: {
    account: Array<Domain.Label.Label>;
    post: Array<Domain.Label.Label>;
    image: Array<Domain.Label.Label>;
  };
}

export enum LabelTypes {
  Null = 'Null',
  AllInfigement = 'AllInfigement',
  All = 'any',
}

export class LabelFilterValue implements FilterValue {
  post: Domain.Label.Label[];

  image: Domain.Label.Label[];

  account: Domain.Label.Label[];

  private _postLabelIds: Set<number>;

  private _imageLabelIds: Set<number>;

  private _accountLabelIds: Set<number>;

  postLabelType: LabelTypes;

  imageLabelType: LabelTypes;

  accountLabelType: LabelTypes;

  constructor(
    params?: Pick<
      LabelFilterValue,
      | 'postLabelType'
      | 'imageLabelType'
      | 'accountLabelType'
      | 'account'
      | 'image'
      | 'post'
    >,
  ) {
    this.post = params?.post || new Array<Domain.Label.Label>();
    this.image = params?.image || new Array<Domain.Label.Label>();
    this.account = params?.account || new Array<Domain.Label.Label>();
    this.postLabelType = params?.postLabelType || LabelTypes.Null;
    this.imageLabelType = params?.imageLabelType || LabelTypes.Null;
    this.accountLabelType = params?.accountLabelType || LabelTypes.Null;
    this._postLabelIds = new Set(this.post.map((label) => label.id));
    this._imageLabelIds = new Set(this.image.map((label) => label.id));
    this._accountLabelIds = new Set(this.account.map((label) => label.id));
  }

  static get defaultValue(): LabelFilterValue {
    return new LabelFilterValue();
  }

  setPostLabel(labels: Domain.Label.Label[], postLabelType = LabelTypes.Null) {
    return new LabelFilterValue({
      post: labels,
      image: this.image,
      account: this.account,
      postLabelType,
      imageLabelType: this.imageLabelType,
      accountLabelType: this.accountLabelType,
    });
  }

  addPostLabel(label: Domain.Label.Label, postLabelType = LabelTypes.Null) {
    return new LabelFilterValue({
      post: this.post.concat(label),
      image: this.image,
      account: this.account,
      postLabelType,
      imageLabelType: this.imageLabelType,
      accountLabelType: this.accountLabelType,
    });
  }

  hasPostLabel(label: Domain.Label.Label) {
    return this._postLabelIds.has(label.id);
  }

  removePostLabel(label: Domain.Label.Label, postLabelType = LabelTypes.Null) {
    return new LabelFilterValue({
      post: this.post.filter((postLabel) => postLabel.id !== label.id),
      image: this.image,
      account: this.account,
      postLabelType,
      imageLabelType: this.imageLabelType,
      accountLabelType: this.accountLabelType,
    });
  }

  setImagetLabel(
    labels: Domain.Label.Label[],
    imageLabelType = LabelTypes.Null,
  ) {
    return new LabelFilterValue({
      post: this.post,
      image: labels,
      account: this.account,
      imageLabelType,
      postLabelType: this.postLabelType,
      accountLabelType: this.accountLabelType,
    });
  }

  addImageLabel(label: Domain.Label.Label, imageLabelType = LabelTypes.Null) {
    return new LabelFilterValue({
      post: this.post,
      image: this.image.concat(label),
      account: this.account,
      imageLabelType,
      postLabelType: this.postLabelType,
      accountLabelType: this.accountLabelType,
    });
  }

  hasImageLabel(label: Domain.Label.Label) {
    return this._imageLabelIds.has(label.id);
  }

  removeImageLabel(
    label: Domain.Label.Label,
    imageLabelType = LabelTypes.Null,
  ) {
    return new LabelFilterValue({
      post: this.post,
      image: this.image.filter((imageLabel) => imageLabel.id !== label.id),
      account: this.account,
      imageLabelType,
      postLabelType: this.postLabelType,
      accountLabelType: this.accountLabelType,
    });
  }

  setAccountLabel(
    labels: Domain.Label.Label[],
    accountLabelType = LabelTypes.Null,
  ) {
    return new LabelFilterValue({
      post: this.post,
      image: this.image,
      account: labels,
      accountLabelType,
      postLabelType: this.postLabelType,
      imageLabelType: this.imageLabelType,
    });
  }

  addAccountLabel(
    label: Domain.Label.Label,
    accountLabelType = LabelTypes.Null,
  ) {
    return new LabelFilterValue({
      post: this.post,
      image: this.image,
      account: this.account.concat(label),
      accountLabelType,
      postLabelType: this.postLabelType,
      imageLabelType: this.imageLabelType,
    });
  }

  removeAccountLabel(
    label: Domain.Label.Label,
    accountLabelType = LabelTypes.Null,
  ) {
    return new LabelFilterValue({
      post: this.post,
      image: this.image,
      account: this.account.filter(
        (accountLabel) => accountLabel.id !== label.id,
      ),
      accountLabelType,
      postLabelType: this.postLabelType,
      imageLabelType: this.imageLabelType,
    });
  }

  hasLabel(type: 'account' | 'post' | 'image', label: Domain.Label.Label) {
    return this[`_${type}LabelIds`].has(label.id);
  }

  hasAccountLabel(label: Domain.Label.Label) {
    return this._accountLabelIds.has(label.id);
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
  ): LabelFilterValue {
    const urlParams = new URLSearchParams(window.location.search);

    const accounts = makeArrayUniqueByValue(
      urlParams.getAll('account_status_type'),
    );
    const images = makeArrayUniqueByValue(urlParams.getAll('hise'));
    const posts = makeArrayUniqueByValue(urlParams.getAll('status_type'));

    const allPostLabelsSelected = this.allLabelsSelected(
      props.labels.post,
      posts,
    );
    const allAccountLabelsSelected = this.allLabelsSelected(
      props.labels.account,
      accounts,
    );
    const allImageLabelsSelected = this.allLabelsSelected(
      props.labels.image,
      images,
    );

    let post = posts
      .map((postValue: string) =>
        props.labels.post.find((item) => item.name === postValue),
      )
      .filter((v) => v !== undefined) as Domain.Label.Label[];
    let postLabelType = LabelTypes.Null;
    let account = accounts
      .map((postValue: string) =>
        props.labels.account.find((item) => item.name === postValue),
      )
      .filter((v) => v !== undefined) as Domain.Label.Label[];
    let accountLabelType = LabelTypes.Null;
    let image = images
      .map((postValue: string) =>
        props.labels.image.find((item) => item.name === postValue),
      )
      .filter((v) => v !== undefined) as Domain.Label.Label[];
    let imageLabelType = LabelTypes.Null;

    if (allPostLabelsSelected) {
      post = [...props.labels.post];
      postLabelType = LabelTypes.All;
    }
    if (allAccountLabelsSelected) {
      account = [...props.labels.account];
      accountLabelType = LabelTypes.All;
    }
    if (allImageLabelsSelected) {
      image = [...props.labels.image];
      imageLabelType = LabelTypes.All;
    }

    return new LabelFilterValue({
      post,
      account,
      image,
      postLabelType,
      accountLabelType,
      imageLabelType,
    });
  }

  static readFromSavedFilter(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): LabelFilterValue {
    const accounts = makeArrayUniqueByValue(
      savedFilter.accountStatusType || [],
    );
    const images = makeArrayUniqueByValue(savedFilter.hise || []);
    const posts = makeArrayUniqueByValue(savedFilter.statusType || []);

    const allPostLabelsSelected = this.allLabelsSelected(
      props.labels.post,
      posts,
    );
    const allAccountLabelsSelected = this.allLabelsSelected(
      props.labels.account,
      accounts,
    );
    const allImageLabelsSelected = this.allLabelsSelected(
      props.labels.image,
      images,
    );

    return new LabelFilterValue({
      post: posts
        .map((postValue: string) =>
          props.labels.post.find((item) => item.name === postValue),
        )
        .filter((v) => v !== undefined) as Domain.Label.Label[],
      image: images
        .map((postValue: string) =>
          props.labels.image.find((item) => item.name === postValue),
        )
        .filter((v) => v !== undefined) as Domain.Label.Label[],
      account: accounts
        .map((postValue: string) =>
          props.labels.account.find((item) => item.name === postValue),
        )
        .filter((v) => v !== undefined) as Domain.Label.Label[],
      postLabelType: allPostLabelsSelected ? LabelTypes.All : LabelTypes.Null,
      imageLabelType: allImageLabelsSelected ? LabelTypes.All : LabelTypes.Null,
      accountLabelType: allAccountLabelsSelected
        ? LabelTypes.All
        : LabelTypes.Null,
    });
  }

  private static allLabelsSelected(
    labels: Domain.Label.Label[],
    selectedLabels: string[],
  ) {
    return (
      labels.length === selectedLabels.length &&
      labels.length > 0 &&
      labels.every((label) => selectedLabels.includes(label.value))
    );
  }
}
