import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { PostFeedResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { FeedRequestParameter } from '../../../../../Feed/Feed';

interface PostHistoricalAnalysisRequestModel extends EndpointRequests {
  params: FeedRequestParameter;
  urlParams: {
    id: string;
  };
}

const historicalAnalysis = new Endpoint<
  PostHistoricalAnalysisRequestModel,
  PostFeedResponseModel
>({
  url: '/api/me/posts/:id/historical_analysis',
  method: 'GET',
  headers: {},
});

export default historicalAnalysis;
