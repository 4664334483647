import { Tag } from 'product-types/src/domain/tag/Tag';
import * as Domain from 'product-types/src/domain/Domain';
import { makeArrayUniqueByKey } from 'product-utils/src/array';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface tagsFilterParams {
  uploadHistory: Domain.Tag.Tag[];
}

export class TagsFilterValue implements FilterValue {
  uploadTagsToInclude: Tag[];

  constructor(params?: Pick<TagsFilterValue, 'uploadTagsToInclude'>) {
    this.uploadTagsToInclude = params?.uploadTagsToInclude || [];
  }

  static get defaultValue(): TagsFilterValue {
    return new TagsFilterValue();
  }

  addUploadTagsToInclude(accounts: Tag[]) {
    return new TagsFilterValue({
      uploadTagsToInclude: makeArrayUniqueByKey(
        this.uploadTagsToInclude.concat(accounts),
        'id',
      ),
    });
  }

  setUploadTagsToInclude(accounts: Tag[]) {
    return new TagsFilterValue({
      uploadTagsToInclude: accounts,
    });
  }

  removeUploadTagsToInclude(account: Tag) {
    return new TagsFilterValue({
      uploadTagsToInclude: this.uploadTagsToInclude.filter(
        (tag) => tag.id !== account.id,
      ),
    });
  }

  static readFilterFromQuery(props: tagsFilterParams): TagsFilterValue {
    const urlParams = new URLSearchParams(window.location.search);
    const uploadTagsToInclude = urlParams
      .getAll('upload_tags_to_include')
      .map((id) => parseInt(id, 10));
    return new TagsFilterValue({
      uploadTagsToInclude: (uploadTagsToInclude || [])
        .map((categoryId: number) =>
          props.uploadHistory.find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
    });
  }

  static readFromSavedFilter(
    savedFilter: SavedFilterModel,
    props: tagsFilterParams,
  ): TagsFilterValue {
    const uploadTagsToInclude = savedFilter.uploadTagsToInclude || [];
    return new TagsFilterValue({
      uploadTagsToInclude: (uploadTagsToInclude || [])
        .map((categoryId: number) =>
          props.uploadHistory.find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
    });
  }
}
