import { createContext } from 'react';
import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import { Filter } from 'types/filters/AtomicFilters/Filter';
import { FiltersContainer } from 'types/filters/MoleculesFilter/MolecileFilter';

export interface IFeedFooterContext {
  refresh?: () => void;
  updateListAfterModeration?: () => any | unknown;
  allListElements?: Array<any>;
  filterContainer: FiltersContainer;
  updateFilter: (newFilter: Filter) => void;
  resetPagination?: () => any | unknown;
  clearFilters?: () => any | unknown;
  fetchingStatus?: FetchableDataState;
}

const defaultAllListElements: Array<any> = [];

export const FeedFooterContext = createContext<IFeedFooterContext>({
  updateListAfterModeration: undefined,
  resetPagination: undefined,
  clearFilters: undefined,
  updateFilter: () => {
    throw new Error('updateFilter function is not implemented');
  },
  filterContainer: {} as FiltersContainer,
  allListElements: defaultAllListElements,
});
