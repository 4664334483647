import {
  GetOptionByUserRole,
  UserFilterRoleElement,
  UserFilterRoleEnum,
} from 'product-types/src/domain/user/UserFilterRole';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { makeArrayUniqueByValue } from 'product-utils/src/array';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';
import { UserFilterValueModel } from './UserFilterValueModel';

export interface readFilterFromQueryProps {
  users: UserLightModel[];
}
export class UserFilterValue implements FilterValue {
  users: Array<UserFilterValueModel>;

  options: Array<UserFilterRoleElement> = [];

  constructor(props: Pick<UserFilterValue, 'users' | 'options'>) {
    this.users = props?.users ?? [];
    this.options = props?.options ?? [];
  }

  static FeedOptions: Array<UserFilterRoleElement> = [
    UserFilterRoleEnum.commenter,
    UserFilterRoleEnum.moderator,
    UserFilterRoleEnum.qa,
    UserFilterRoleEnum.validator,
  ].map(GetOptionByUserRole) as Array<UserFilterRoleElement>;

  static VendorOptions: Array<UserFilterRoleElement> = [
    UserFilterRoleEnum.commenter,
  ].map(GetOptionByUserRole) as Array<UserFilterRoleElement>;

  addUser(user: UserFilterValueModel) {
    if (
      this.users.find((u) => u.user.id === user.user.id && u.role === user.role)
    ) {
      return this;
    }
    return new UserFilterValue({
      users: [...this.users, user],
      options: this.options,
    });
  }

  removeUser(user: UserFilterValueModel) {
    return new UserFilterValue({
      users: this.users.filter((u) => {
        if (u.user.id === user.user.id && u.role === user.role) {
          return false;
        }
        return true;
      }),
      options: this.options,
    });
  }

  static get defaultValue(): UserFilterValue {
    return new UserFilterValue({
      users: [],
      options: UserFilterValue.FeedOptions,
    });
  }

  get smallestModerationStatus() {
    const set = new Set();
    this.users.forEach((user) => set.add(user.role));
    if (set.has(UserFilterRoleEnum.moderator)) {
      return UserFilterRoleEnum.moderator;
    }
    if (set.has(UserFilterRoleEnum.qa)) {
      return UserFilterRoleEnum.qa;
    }
    if (set.has(UserFilterRoleEnum.validator)) {
      return UserFilterRoleEnum.validator;
    }
    return null;
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
    options: Array<UserFilterRoleElement>,
  ): UserFilterValue {
    const urlParams = new URLSearchParams(window.location.search);
    const moderator_ids = makeArrayUniqueByValue(
      urlParams.getAll('moderator_id'),
    ).map((v) => Number(v));
    const qa_checker_ids = makeArrayUniqueByValue(
      urlParams.getAll('qa_checker_id'),
    ).map((v) => Number(v));
    const validator_ids = makeArrayUniqueByValue(
      urlParams.getAll('validator_id'),
    ).map((v) => Number(v));
    const commenter_ids = makeArrayUniqueByValue(
      urlParams.getAll('commenter_id'),
    ).map((v) => Number(v));
    return new UserFilterValue({
      users: [
        ...moderator_ids
          .filter((id) => props.users.find((u) => u.id === id))
          .map(
            (id) =>
              new UserFilterValueModel({
                user: props.users.find((u) => u.id === id) as UserLightModel,
                role: UserFilterRoleEnum.moderator,
              }),
          ),
        ...qa_checker_ids
          .filter((id) => props.users.find((u) => u.id === id))
          .map(
            (id) =>
              new UserFilterValueModel({
                user: props.users.find((u) => u.id === id) as UserLightModel,
                role: UserFilterRoleEnum.qa,
              }),
          ),
        ...validator_ids
          .filter((id) => props.users.find((u) => u.id === id))
          .map(
            (id) =>
              new UserFilterValueModel({
                user: props.users.find((u) => u.id === id) as UserLightModel,
                role: UserFilterRoleEnum.validator,
              }),
          ),
        ...commenter_ids
          .filter((id) => props.users.find((u) => u.id === id))
          .map(
            (id) =>
              new UserFilterValueModel({
                user: props.users.find((u) => u.id === id) as UserLightModel,
                role: UserFilterRoleEnum.commenter,
              }),
          ),
      ],
      options,
    });
  }

  static readFromSavedFilter(
    savedFilter: SavedFilterModel,
    options: Array<UserFilterRoleElement>,
    props: readFilterFromQueryProps,
  ): UserFilterValue {
    const moderatorIds = makeArrayUniqueByValue(savedFilter.moderatorId).map(
      (v) => Number(v),
    );
    const commentorIds = makeArrayUniqueByValue(savedFilter.commenterId).map(
      (v) => Number(v),
    );
    const qaCheckerIds = makeArrayUniqueByValue(savedFilter.qaCheckerId).map(
      (v) => Number(v),
    );
    const validatorIds = makeArrayUniqueByValue(savedFilter.validatorId).map(
      (v) => Number(v),
    );
    return new UserFilterValue({
      users: [
        ...moderatorIds
          .filter((id) => props.users.find((u) => u.id === id))
          .map(
            (id) =>
              new UserFilterValueModel({
                user: props.users.find((u) => u.id === id) as UserLightModel,
                role: UserFilterRoleEnum.moderator,
              }),
          ),
        ...qaCheckerIds
          .filter((id) => props.users.find((u) => u.id === id))
          .map(
            (id) =>
              new UserFilterValueModel({
                user: props.users.find((u) => u.id === id) as UserLightModel,
                role: UserFilterRoleEnum.qa,
              }),
          ),
        ...validatorIds
          .filter((id) => props.users.find((u) => u.id === id))
          .map(
            (id) =>
              new UserFilterValueModel({
                user: props.users.find((u) => u.id === id) as UserLightModel,
                role: UserFilterRoleEnum.validator,
              }),
          ),
        ...commentorIds
          .filter((id) => props.users.find((u) => u.id === id))
          .map(
            (id) =>
              new UserFilterValueModel({
                user: props.users.find((u) => u.id === id) as UserLightModel,
                role: UserFilterRoleEnum.commenter,
              }),
          ),
      ],
      options,
    });
  }
}
