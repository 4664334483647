import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { PostModerationRawModel } from 'product-types/src/domain/post/PostModerationModel';

interface GetPostRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
}

const getPost = new Endpoint<GetPostRequestModel, PostModerationRawModel>({
  url: '/api/me/posts/:id',
  method: 'GET',
  headers: {},
});

export default getPost;
