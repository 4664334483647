import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';
import { UserFilterValueModel } from '../../../types/filters/AtomicFiltersImplementation/UserFilter/UserFilterValueModel';

export const UserFilterValue: DisplayFilterComponent = ({
  filterValue: { name, value },
  onClickRemove,
}: DisplayFilterComponentProps) => {
  const commentedByTag = (
    <Tag
      tag={{
        name: `Commented by ${(value as UserFilterValueModel).user.name}`,
        id: (value as UserFilterValueModel).user.id,
      }}
      iconPrefix={<NaveeIcon.Group />}
      onClickRemove={onClickRemove}
    />
  );
  const validatedByTag = (
    <Tag
      tag={{
        name: `Validated by ${(value as UserFilterValueModel).user.name}`,
        id: (value as UserFilterValueModel).user.id,
      }}
      iconPrefix={<NaveeIcon.Group />}
      onClickRemove={onClickRemove}
    />
  );
  const moderatedByTag = (
    <Tag
      tag={{
        name: `Moderated by ${(value as UserFilterValueModel).user.name}`,
        id: (value as UserFilterValueModel).user.id,
      }}
      iconPrefix={<NaveeIcon.Group />}
      onClickRemove={onClickRemove}
    />
  );
  const checkedByTag = (
    <Tag
      tag={{
        name: `Checked by ${(value as UserFilterValueModel).user.name}`,
        id: (value as UserFilterValueModel).user.id,
      }}
      iconPrefix={<NaveeIcon.Group />}
      onClickRemove={onClickRemove}
    />
  );
  const filterTypeComponentMap = {
    [FilterTypeEnum.commetedBy]: commentedByTag,
    [FilterTypeEnum.validatedBy]: validatedByTag,
    [FilterTypeEnum.moderatedBy]: moderatedByTag,
    [FilterTypeEnum.checkedBy]: checkedByTag,
  };

  return filterTypeComponentMap[name] || null;
};
