import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import downloadImageExcelFile from './downloadImageExcelFile';
import downloadAccountExcelFile from './downloadAccountExcelFile';
import downloadAccountManualExcelFile from './downloadAccountManualExcelFile';
import downloadPostExcelFile from './downloadPostExcelFile';
import downloadPostManualExcelFile from './downloadPostManualExcelFile';

export interface UploadDownloadExcelFileEndpoints extends EndpointContainer {
  downloadImageExcelFile: typeof downloadImageExcelFile;
  downloadAccountExcelFile: typeof downloadAccountExcelFile;
  downloadAccountManualExcelFile: typeof downloadAccountManualExcelFile;
  downloadPostExcelFile: typeof downloadPostExcelFile;
  downloadPostManualExcelFile: typeof downloadPostManualExcelFile;
}

const downloadExcel: UploadDownloadExcelFileEndpoints = {
  downloadImageExcelFile,
  downloadAccountExcelFile,
  downloadAccountManualExcelFile,
  downloadPostExcelFile,
  downloadPostManualExcelFile,
  injectHTTPClient(client: HttpClient) {
    downloadImageExcelFile.injectHTTPClient(client);
    downloadAccountExcelFile.injectHTTPClient(client);
    downloadAccountManualExcelFile.injectHTTPClient(client);
    downloadPostExcelFile.injectHTTPClient(client);
    downloadPostManualExcelFile.injectHTTPClient(client);
  },
};

export default downloadExcel;
