import { v4 as uuid } from 'uuid';
import { Label } from '../label/Label';
import { TagModel } from '../tag/Tag';
import { UploadPostImage, UploadPostImageRaw } from './uploadPostImage';
import { WebsiteHasPosters } from '../website/WebsiteHasPosters';

export interface UploadPostRaw {
  archive_link: string;
  currency: string;
  description?: string;
  images: Array<UploadPostImageRaw>;
  label: string;
  poster_name: string;
  price?: number;
  price_raw?: string;
  stock_count?: number;
  poster_url: string | null;
  poster_website_identifier: string | null;
  tags: Array<string>;
  title: string;
  url: string;
}

export class UploadPost {
  uid: string;

  label: Label | null;

  tags: Array<TagModel>;

  currency: string;

  archiveLink: string;

  url: string;

  manualUpload: boolean;

  description: string;

  images: Array<UploadPostImage>;

  posterName: string;

  posterUrl: string;

  price?: number;

  priceRaw?: string;

  stockCount?: number;

  title: string;

  domain_information?: WebsiteHasPosters;

  constructor(
    prop: Pick<UploadPost, 'label' | 'tags' | 'url'> & Partial<UploadPost>,
  ) {
    this.uid = prop?.uid ?? uuid();
    this.label = prop.label;
    this.tags = prop.tags;
    this.url = prop.url;
    this.manualUpload = prop.manualUpload ?? false;
    this.archiveLink = prop.archiveLink ?? '';
    this.currency = prop.currency ?? '';
    this.description = prop.description ?? '';
    this.images = prop.images ?? [
      new UploadPostImage({
        label: null,
        tags: [],
        url: '',
      }),
    ];
    this.posterName = prop.posterName ?? '';
    this.posterUrl = prop.posterUrl ?? '';
    this.price = prop.price;
    this.priceRaw = prop.priceRaw ?? '';
    this.stockCount = prop.stockCount;
    this.title = prop.title ?? '';
    this.domain_information = prop.domain_information;
  }

  get hasNoPosters(): boolean {
    return this?.domain_information?.has_posters === false;
  }

  toRaw(): UploadPostRaw {
    return {
      label: this.label?.name ?? '',
      tags: this.tags.map((tag) => tag.name),
      url: this.url,
      archive_link: this.archiveLink,
      currency: this.currency,
      poster_url: this.posterUrl,
      poster_website_identifier: null,
      description: this.description,
      images: this.images.map((img) => img.toRaw()),
      poster_name: this.posterName,
      price: this.price,
      price_raw: this.priceRaw,
      stock_count: this.stockCount,
      title: this.title,
    };
  }
}
