import { UserStatsRaw } from 'product-types/src/domain/ModerationStatistics/ModerationStatistics';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UserStatsRequestModel extends EndpointRequests {
  params: {
    timezone_offset: number;
    start_date: string;
    end_date: string;
  };
}

const getUserStats = new Endpoint<UserStatsRequestModel, UserStatsRaw>({
  url: '/api/user/stats',
  method: 'GET',
  headers: {},
});

export default getUserStats;
