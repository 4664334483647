import { Endpoint } from 'types/network/Http/endpoint';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';

export type WebsiteCategoriesResponse = Array<WebsiteCategory>;

const websiteCategories = new Endpoint<any, WebsiteCategoriesResponse>({
  url: '/api/website_categories',
  method: 'GET',
  headers: {},
});

export default websiteCategories;
