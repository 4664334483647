import { Endpoint } from 'types/network/Http/endpoint';
import { ExportTemplateRawModel } from 'product-types/src/domain/export/ExportTemplate';

export interface ExportTemplatesResponse {
  templates: Array<ExportTemplateRawModel>;
}

const exportTemplates = new Endpoint<any, ExportTemplatesResponse>({
  url: '/api/me/posts/export/templates',
  method: 'GET',
  headers: {},
});

export default exportTemplates;
