import { StyledSpin } from 'product-ui/src/components/atoms/Spin/StyledSpin';
import { Flex } from 'antd';
import React from 'react';

export const PageFallback = () => (
  <Flex style={{ height: '100%' }} align="center" justify="center">
    <StyledSpin
      size="large"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  </Flex>
);
