import { GeographySelectOptions } from '../geo/Geo';
import { ModerationReason } from '../moderationReason/ModerationReason';
import { UserLightModel } from '../user/UserLightModel';
import { WebsiteCategory } from '../website/WebsiteCategory';
import { Label } from '../label/Label';
import { TagModel, TagTypeEnum } from '../tag/Tag';
import { WebsiteSuggestion } from '../website/Website';
import { ExportTemplateModel } from '../export/ExportTemplate';
import { ProductCategoryRaw } from '../productCategory';
import { Insight } from '../insight/insight';
import { ContactMainInfo } from '../contact/Contact';
import { Currency } from '../Currency';
import { LanguageRaw } from '../language/language';
import { ScrappingSource } from '../scrappingSource/ScrappingSource';
import { RoleRaw } from '../role/Role';
import { CrawlingDomainRaw } from '../crawlingDomain/CrawlingDomain';
import { SavedFilterModel, SavedFilterRaw } from '../savedFilters/SavedFilters';

type OrganisationLabels = {
  post: Array<Label>;
  image: Array<Label>;
  account: Array<Label>;
  website: Array<Label>;
};

export type FilterPresets = {
  account: Array<SavedFilterModel>;
  image: Array<SavedFilterModel>;
  post: Array<SavedFilterModel>;
  website: Array<SavedFilterModel>;
};

export type FilterPresetsRaw = {
  account: Array<SavedFilterRaw>;
  image: Array<SavedFilterRaw>;
  post: Array<SavedFilterRaw>;
  website: Array<SavedFilterRaw>;
};

type Tags = {
  [TagTypeEnum.account]: Array<TagModel>;
  [TagTypeEnum.vendor]: Array<TagModel>;
  [TagTypeEnum.duplicatedGroup]: Array<TagModel>;
  [TagTypeEnum.post]: Array<TagModel>;
  [TagTypeEnum.uploadHistory]: Array<TagModel>;
};

export class GlobalData {
  categories: Array<ProductCategoryRaw>;

  labels: OrganisationLabels;

  geographyZones: GeographySelectOptions;

  moderationReasons: Array<ModerationReason>;

  insightOptions: Array<Insight>;

  contact_types: Array<ContactMainInfo>;

  currencies: Array<Currency>;

  scraping_sources: Array<ScrappingSource>;

  domains_available_for_scraping: Array<CrawlingDomainRaw>;

  organisation_statuses: { [key: string]: string };

  roles: Array<RoleRaw>;

  languages: Array<LanguageRaw>;

  tags: Tags;

  filters: FilterPresets;

  exportTemplates: {
    post: Array<ExportTemplateModel>;
    image: Array<ExportTemplateModel>;
    website: Array<ExportTemplateModel>;
    account: Array<ExportTemplateModel>;
    cluster: Array<ExportTemplateModel>;
  };

  topWebsites: Array<WebsiteSuggestion>;

  users: Array<UserLightModel>;

  websiteCategories: Array<WebsiteCategory>;

  constructor(
    data: Pick<
      GlobalData,
      | 'categories'
      | 'exportTemplates'
      | 'filters'
      | 'geographyZones'
      | 'insightOptions'
      | 'contact_types'
      | 'organisation_statuses'
      | 'currencies'
      | 'roles'
      | 'scraping_sources'
      | 'domains_available_for_scraping'
      | 'languages'
      | 'labels'
      | 'moderationReasons'
      | 'tags'
      | 'topWebsites'
      | 'users'
      | 'websiteCategories'
    >,
  ) {
    this.categories = data.categories;
    this.labels = data.labels;
    this.geographyZones = data.geographyZones;
    this.moderationReasons = data.moderationReasons;
    this.insightOptions = data.insightOptions;
    this.tags = data.tags;
    this.contact_types = data.contact_types;
    this.currencies = data.currencies;
    this.roles = data.roles;
    this.organisation_statuses = data.organisation_statuses;
    this.domains_available_for_scraping = data.domains_available_for_scraping;
    this.scraping_sources = data.scraping_sources;
    this.languages = data.languages;
    this.filters = data.filters;
    this.exportTemplates = data.exportTemplates;
    this.topWebsites = data.topWebsites;
    this.users = data.users;
    this.websiteCategories = data.websiteCategories;
  }

  get isDataValid() {
    return (
      this.categories !== null &&
      this.labels !== null &&
      this.labels.account !== null &&
      this.labels.image !== null &&
      this.labels.post !== null &&
      this.geographyZones !== null &&
      this.geographyZones.countries !== null &&
      this.geographyZones.zones !== null &&
      this.moderationReasons !== null &&
      this.insightOptions !== null &&
      this.tags !== null &&
      this.contact_types !== null &&
      this.currencies !== null &&
      this.domains_available_for_scraping !== null &&
      this.roles !== null &&
      this.organisation_statuses !== null &&
      this.scraping_sources !== null &&
      this.languages !== null &&
      this.tags.account !== null &&
      this.tags.cluster !== null &&
      this.tags.duplicated_group !== null &&
      this.tags.post !== null &&
      this.tags.upload_history !== null &&
      this.filters !== null &&
      this.filters.account !== null &&
      this.filters.image !== null &&
      this.filters.post !== null &&
      this.filters.website !== null &&
      this.exportTemplates !== null &&
      this.exportTemplates.post !== null &&
      this.exportTemplates.image !== null &&
      this.exportTemplates.website !== null &&
      this.exportTemplates.account !== null &&
      this.exportTemplates.cluster !== null &&
      this.topWebsites !== null &&
      this.users !== null &&
      this.websiteCategories !== null
    );
  }
}
