import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ClusterUpdateRequest extends EndpointRequests {
  urlParams: { id: string };
  data: {
    name: string;
  };
}

const updateCluster = new Endpoint<ClusterUpdateRequest, any>({
  url: '/api/me/clusters/:id',
  method: 'PUT',
  headers: {},
});

export default updateCluster;
