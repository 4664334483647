import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadRequest extends EndpointRequests {
  data: FormData;
}

export interface UploadResponse {
  url: string;
}

const add = new Endpoint<UploadRequest, UploadResponse>({
  url: `/api/me/uploads`,
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
});

export default add;
