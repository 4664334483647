import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { MedianLifetimeResponse } from 'product-types/src/domain/dashboard/DasboardMedianLifetimeStateModel';

interface MedianLifetimeRequestParams extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface MedianLifetimeRequest extends EndpointRequests {
  params: MedianLifetimeRequestParams;
}

const medianLifetime = new Endpoint<
  MedianLifetimeRequest,
  MedianLifetimeResponse
>({
  url: '/api/me/stats/median_lifetime',
  method: 'GET',
  headers: {},
});

export default medianLifetime;
