import { CustomError } from '../Error/CustomError';
import { NetworkError } from '../Error/NetworkError';

export enum FetchableDataState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
  NOT_LOADED = 'NOT_LOADED',
  NOT_FOUND = 'NOT_FOUND',
}

export class FetchableData<T> {
  state: FetchableDataState;

  data: T | null;

  error: CustomError | NetworkError | null;

  abortController: AbortController | null = null;

  constructor(
    prop: Pick<
      FetchableData<T>,
      'state' | 'data' | 'abortController' | 'error'
    >,
  ) {
    this.state = prop.state || FetchableDataState.NOT_LOADED;
    this.data = prop.data || null;
    this.error = prop.error || null;
    this.abortController = prop.abortController || null;
  }

  get isLoading() {
    return this.state === FetchableDataState.LOADING;
  }

  get isLoaded() {
    return this.state === FetchableDataState.LOADED;
  }

  static default<T>() {
    return new FetchableData<T>({
      state: FetchableDataState.NOT_LOADED,
      data: null,
      error: null,
      abortController: null,
    });
  }
}
