/*
 *
 * DashboardPage constants
 *
 */

export const RESET_STATE = 'app/DashboardPage/RESET_STATE';
export const LOAD_WEBSITES = 'app/DashboardPage/LOAD_WEBSITES';
export const LOAD_MORE_WEBSITES = 'app/DashboardPage/LOAD_MORE_WEBSITES';
export const UPDATE_WEBSITES = 'app/DashboardPage/UPDATE_WEBSITES';
export const LOAD_MOST_COUNTERFEITED_CATEGORIES_DATA =
  'app/DashboardPage/LOAD_MOST_COUNTERFEITED_CATEGORIES_DATA';
export const UPDATE_CATEGORIES = 'app/DashboardPage/UPDATE_CATEGORIES';
export const LOAD_DUPLICATED_GROUPS =
  'app/DashboardPage/LOAD_DUPLICATED_GROUPS';
export const LOAD_MORE_DUPLICATED_GROUPS =
  'app/DashboardPage/LOAD_MORE_DUPLICATED_GROUPS';
export const UPDATE_DUPLICATED_GROUPS =
  'app/DashboardPage/UPDATE_DUPLICATED_GROUPS';
export const LOAD_TAKEDOWN_STATE = 'app/DashboardPage/LOAD_TAKEDOWN_STATE';
export const UPDATE_TAKEDOWN_STATE = 'app/DashboardPage/UPDATE_TAKEDOWN_STATE';
export const LOAD_KEY_FIGURES = 'app/DashboardPage/LOAD_KEY_FIGURES';
export const UPDATE_KEY_FIGURES = 'app/DashboardPage/UPDATE_KEY_FIGURES';
export const LOAD_MODERATION_STATISTICS =
  'app/DashboardPage/LOAD_MODERATION_STATISTICS';
export const UPDATE_MODERATION_STATISTICS =
  'app/DashboardPage/UPDATE_MODERATION_STATISTICS';
export const UPDATE_GEOGRAPHICAL_DISTRIBUTION =
  'app/DashboardPage/UPDATE_GEOGRAPHICAL_DISTRIBUTION';
export const UPDATE_KEYWORDS = 'app/DashboardPage/UPDATE_KEYWORDS';
export const UPDATE_INFRIGING_POSTS =
  'app/DashboardPage/UPDATE_INFRIGING_POSTS';
export const UPDATE_INFRIGING_IMAGES =
  'app/DashboardPage/UPDATE_INFRIGING_IMAGES';
export const UPDATE_MODERATED_IMAGES =
  'app/DashboardPage/UPDATE_MODERATED_IMAGES';
export const UPDATE_MODERATED_POSTS =
  'app/DashboardPage/UPDATE_MODERATED_POSTS';
export const UPDATE_TAKEDOWN_SPLIT = 'app/DashboardPage/UPDATE_TAKEDOWN_SPLIT';
export const UPDATE_MODERATED_POSTS_BY_RULES =
  'app/DashboardPage/UPDATE_MODERATED_POSTS_BY_RULES';
export const UPDATE_POSTS_TO_MODERATE =
  'app/DashboardPage/UPDATE_POSTS_TO_MODERATE';
export const UPDATE_WEBSITE_CATEGORY_STAT =
  'app/DashboardPage/UPDATE_WEBSITE_CATEGORY_STAT';
export const UPDATE_VALUE_MONITOR = 'app/DashboardPage/UPDATE_VALUE_MONITOR';

export const LOAD_VALUE_MONITOR = 'app/DashboardPage/LOAD_VALUE_MONITOR';
export const LOAD_MODERATED_POSTS = 'app/DashboardPage/LOAD_MODERATED_POSTS';
export const LOAD_WEBSITE_CATEGORY_STAT =
  'app/DashboardPage/LOAD_WEBSITE_CATEGORY_STAT';
export const LOAD_KEYWORDS = 'app/DashboardPage/LOAD_KEYWORDS';
export const LOAD_LABELS = 'app/DashboardPage/LOAD_LABELS';
export const LOAD_IMAGE_LABELS = 'app/DashboardPage/LOAD_IMAGE_LABELS';
export const LOAD_GEOGRAPHICAL_DISTRIBUTION =
  'app/DashboardPage/LOAD_GEOGRAPHICAL_DISTRIBUTION';
export const LOAD_WORDCLOUD_DATA_ERROR =
  'app/DashboardPage/LOAD_WORDCLOUD_DATA_ERROR';
export const SELECT_DATE = 'app/DashboardPage/SELECT_DATE';

export const SELECT_FILTER = 'app/DashboardPage/SELECT_FILTER';

export const SCHEDULE_CALL = 'app/DashboardPage/SCHEDULE_CALL';
export const CLEAR_SCHEDULE = 'app/DashboardPage/CLEAR_SCHEDULE';
export const RUN_SEARCH = 'app/DashboardPage/RUN_SEARCH';
export const LOAD_EFFECTIVE_TAKEDOWN_STATS =
  'app/DashboardPage/LOAD_EFFECTIVE_TAKEDOWN_STATS';
export const UPDATE_EFFECTIVE_TAKEDOWN_STATS =
  'app/DashboardPage/UPDATE_EFFECTIVE_TAKEDOWN_STATS';
export const LOAD_EFFECTIVE_TAKEDOWN_GRAPH =
  'app/DashboardPage/LOAD_EFFECTIVE_TAKEDOWN_GRAPH';
export const UPDATE_EFFECTIVE_TAKEDOWN_GRAPH =
  'app/DashboardPage/UPDATE_EFFECTIVE_TAKEDOWN_GRAPH';
export const LOAD_MODERATED_POSTS_BREAKDOWN =
  'app/DashboardPage/LOAD_MODERATED_POSTS_BREAKDOWN';
export const UPDATE_MODERATED_POSTS_BREAKDOWN =
  'app/DashboardPage/UPDATE_MODERATED_POSTS_BREAKDOWN';
export const LOAD_TAKEDOWN_SPLIT = 'app/DashboardPage/LOAD_TAKEDOWN_SPLIT';
export const LOAD_WEBSITE_CATEGORIES_BREAKDOWN =
  'app/DashboardPage/LOAD_WEBSITE_CATEGORIES_BREAKDOWN';
export const UPDATE_WEBSITE_CATEGORIES_BREAKDOWN =
  'app/DashboardPage/UPDATE_WEBSITE_CATEGORIES_BREAKDOWN';
export const LOAD_KEYWORDS_SEARCHED =
  'app/DashboardPage/LOAD_KEYWORDS_SEARCHED';
export const UPDATE_KEYWORDS_SEARCHED =
  'app/DashboardPage/UPDATE_KEYWORDS_SEARCHED';
export const LOAD_KEYWORDS_FOUND = 'app/DashboardPage/LOAD_KEYWORDS_FOUND';
export const UPDATE_KEYWORDS_FOUND = 'app/DashboardPage/UPDATE_KEYWORDS_FOUND';
export const LOAD_KEYWORDS_GRAPH = 'app/DashboardPage/LOAD_KEYWORDS_GRAPH';
export const UPDATE_KEYWORDS_GRAPH = 'app/DashboardPage/UPDATE_KEYWORDS_GRAPH';
export const LOAD_TAKEDOWN_SENT = 'app/DashboardPage/LOAD_TAKEDOWN_SENT';
export const UPDATE_TAKEDOWN_SENT = 'app/DashboardPage/UPDATE_TAKEDOWN_SENT';
export const LOAD_GEOGRAPHY = 'app/DashboardPage/LOAD_GEOGRAPHY';
export const UPDATE_GEOGRAPHY = 'app/DashboardPage/UPDATE_GEOGRAPHY';
export const LOAD_MODERATION_STATISTICS_POSTS =
  'app/DashboardPage/LOAD_MODERATION_STATISTICS_POSTS';
export const UPDATE_MODERATION_STATISTICS_POSTS =
  'app/DashboardPage/UPDATE_MODERATION_STATISTICS_POSTS';
export const LOAD_MODERATION_STATISTICS_IMAGES =
  'app/DashboardPage/LOAD_MODERATION_STATISTICS_IMAGES';
export const UPDATE_MODERATION_STATISTICS_IMAGES =
  'app/DashboardPage/UPDATE_MODERATION_STATISTICS_IMAGES';
export const LOAD_MODERATION_STATISTICS_ACCOUNTS =
  'app/DashboardPage/LOAD_MODERATION_STATISTICS_ACCOUNTS';
export const UPDATE_MODERATION_STATISTICS_ACCOUNTS =
  'app/DashboardPage/UPDATE_MODERATION_STATISTICS_ACCOUNTS';
export const LOAD_MODERATION_STATISTICS_ACCOUNTS_LABELS =
  'app/DashboardPage/LOAD_MODERATION_STATISTICS_ACCOUNTS_LABELS';
export const UPDATE_MODERATION_STATISTICS_ACCOUNTS_LABELS =
  'app/DashboardPage/UPDATE_MODERATION_STATISTICS_ACCOUNTS_LABELS';
export const LOAD_MODERATION_STATISTICS_POSTS_LABELS =
  'app/DashboardPage/LOAD_MODERATION_STATISTICS_POSTS_LABELS';
export const UPDATE_MODERATION_STATISTICS_POSTS_LABELS =
  'app/DashboardPage/UPDATE_MODERATION_STATISTICS_POSTS_LABELS';
export const LOAD_MODERATION_STATISTICS_IMAGES_LABELS =
  'app/DashboardPage/LOAD_MODERATION_STATISTICS_IMAGES_LABELS';
export const UPDATE_MODERATION_STATISTICS_IMAGES_LABELS =
  'app/DashboardPage/UPDATE_MODERATION_STATISTICS_IMAGES_LABELS';
export const LOAD_WORLD_GRAPH = 'app/DashboardPage/LOAD_WORLD_GRAPH';
export const UPDATE_WORLD_GRAPH = 'app/DashboardPage/UPDATE_WORLD_GRAPH';
export const LOAD_WORLD_MAP = 'app/DashboardPage/LOAD_WORLD_MAP';
export const UPDATE_WORLD_MAP = 'app/DashboardPage/UPDATE_WORLD_MAP';
export const LOAD_LATEST_DATE = 'app/DashboardPage/LOAD_LATEST_DATE';
export const UPDATE_LATEST_DATE = 'app/DashboardPage/UPDATE_LATEST_DATE';
