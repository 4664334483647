import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import * as Domain from 'product-types/src/domain/Domain';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

export const formatLabel = (
  label: Domain.Label.Label,
  filterType: FilterTypeEnum,
) => {
  if (filterType === FilterTypeEnum.postLabel) {
    return {
      ...label,
      label: `Post: ${label.name}`,
    };
  }
  if (filterType === FilterTypeEnum.imageLabel) {
    return {
      ...label,
      label: `Image: ${label.name}`,
    };
  }
  if (filterType === FilterTypeEnum.accountLabel) {
    return {
      ...label,
      label: `Account: ${label.name}`,
    };
  }
  throw new Error('filter type not specified');
};

export const NewLabelExclusionFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, name, value },
  onClickRemove,
}: DisplayFilterComponentProps) => {
  const accountTag = (
    <Tag
      key={uuid}
      tag={formatLabel(value as Domain.Label.Label, name as FilterTypeEnum)}
      iconPrefix={<NaveeIcon.AccountModIcon />}
      onClickRemove={onClickRemove}
    />
  );
  const imageTag = (
    <Tag
      key={uuid}
      tag={formatLabel(value as Domain.Label.Label, name as FilterTypeEnum)}
      iconPrefix={<NaveeIcon.CardImage width={12} height={12} />}
      onClickRemove={onClickRemove}
    />
  );
  const postTag = (
    <Tag
      key={uuid}
      tag={formatLabel(value as Domain.Label.Label, name as FilterTypeEnum)}
      iconPrefix={<NaveeIcon.DocIcon />}
      onClickRemove={onClickRemove}
    />
  );
  const filterTypeTagMap = {
    [FilterTypeEnum.accountLabel]: accountTag,
    [FilterTypeEnum.imageLabel]: imageTag,
    [FilterTypeEnum.postLabel]: postTag,
  };
  return filterTypeTagMap[name] || null;
};
