import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getById from './getById';

export interface CommentsEndpoints extends EndpointContainer {
  getById: typeof getById;
}

const tags: CommentsEndpoints = {
  getById,
  injectHTTPClient(client: HttpClient) {
    getById.injectHTTPClient(client);
  },
};

export default tags;
