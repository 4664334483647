import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ExportFeedRequestParameter } from 'types/network/Feed/Feed';

interface ExportImagesRequestModel extends EndpointRequests {
  data: ExportFeedRequestParameter;
}

const exportImages = new Endpoint<
  ExportImagesRequestModel,
  NonNullable<unknown>
>({
  url: '/api/me/exports/duplicated_groups/xls',
  method: 'POST',
  headers: {},
});

export default exportImages;
