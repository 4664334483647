import React, { useCallback, useContext } from 'react';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { Flex, Tooltip } from 'antd';
import { AsyncSelect } from 'product-ui/src/components/atoms/AsyncSelect';
import { TagsUploadHistoryFilter } from '../../../types/filters/AtomicFiltersImplementation/TagsUploadHistory/TagsFilter';
import { Filter } from '../../../types/filters/AtomicFilters/Filter';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { TagsFilterValue } from '../../../types/filters/AtomicFiltersImplementation/TagsUploadHistory/TagsFilterValue';

export interface UploadHistoryTagFilterProps {
  value: TagsUploadHistoryFilter;
  onChange: (v: Filter) => void;
}

export const UploadHistoryTagFilter = (props: UploadHistoryTagFilterProps) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const tags = context.tags || {
    uploadHistory: [],
  };

  const tagsToInclude = (tags.uploadHistory || []).filter(
    (tag) =>
      !props.value.value.uploadTagsToInclude.find(
        (exclude) => exclude.id === tag.id,
      ),
  );

  const onSelectPost = useCallback(
    (_, newValue: TagModel) => {
      props.onChange(
        new TagsUploadHistoryFilter({
          ...props.value,
          value: props.value.value.addUploadTagsToInclude([
            new TagModel(newValue),
          ]),
        }),
      );
    },
    [props.value, tags.uploadHistory],
  );

  const onDeselectTag = useCallback(
    (id: number) => {
      const tagToRemove = props.value.value.uploadTagsToInclude.find(
        (t) => t.id === id,
      );
      if (!tagToRemove) {
        throw new Error(
          `Couldn't find tag to remove with id: ${id} in value list: ${props.value.value.uploadTagsToInclude}`,
        );
      }
      props.onChange(
        new TagsUploadHistoryFilter({
          ...props.value,
          value: props.value.value.removeUploadTagsToInclude(tagToRemove),
        }),
      );
    },
    [props.value, props.onChange],
  );

  const debounceedLoadTags = useCallback(
    (search: string) => {
      if (search.length < 3) return Promise.resolve(tagsToInclude);
      return Promise.resolve(
        tagsToInclude?.filter((t) =>
          t.name.toLowerCase().includes(search.toLowerCase()),
        ) || [],
      );
    },
    [tags.uploadHistory],
  );

  const onClear = useCallback(() => {
    props.onChange(
      new TagsUploadHistoryFilter({
        ...props.value,
        value: TagsFilterValue.defaultValue,
      }),
    );
  }, []);

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          width: 244,
          padding: '1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <AsyncSelect
          removeInsertedFromOptions
          mode="multiple"
          debounceTime={0}
          fieldNames={{ label: 'name', value: 'id' }}
          value={props.value.value.uploadTagsToInclude ?? []}
          onSelect={onSelectPost}
          onDeselect={onDeselectTag}
          notFoundContent={() => 'No options'}
          hint={null}
          minAllowedTextLength={0}
          loadOptions={debounceedLoadTags as any}
          placeholder="Tag name"
          onClear={onClear}
          maxTagCount={12}
          maxCount={1000}
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{ pointerEvents: 'none' }}
              title={omittedValues.map(({ label }) => label).join(', ')}
            >
              <span>+{omittedValues.length}</span>
            </Tooltip>
          )}
        />
      </Flex>
    ),
    [
      tags.uploadHistory,
      props.value.value.uploadTagsToInclude,
      tagsToInclude,
      onSelectPost,
      debounceedLoadTags,
    ],
  );

  return (
    <FilterWithMenuWrapper
      text="Tags"
      renderer={renderer}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
    />
  );
};
