export class DomainModel {
  name: string;

  url: string;

  constructor(prop: Pick<DomainModel, 'name' | 'url'>) {
    this.name = prop.name;
    this.url = prop.url;
  }

  static createEmpty(): DomainModel {
    return new DomainModel({
      name: '',
      url: '',
    });
  }
}
