/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { ReactComponent as CheckDate } from '../../../icons/navee-icons/crawlingDate/checkDate.svg';
import { ReactComponent as CrawlingDate } from '../../../icons/navee-icons/crawlingDate/crawlingDate.svg';
import { ReactComponent as LastActivity } from '../../../icons/navee-icons/crawlingDate/lastActivity.svg';
import { ReactComponent as ModerationDate } from '../../../icons/navee-icons/crawlingDate/moderationDate.svg';
import { ReactComponent as TakedownDate } from '../../../icons/navee-icons/crawlingDate/takedownDate.svg';
import { ReactComponent as TakedownSent } from '../../../icons/navee-icons/crawlingDate/takedownSent.svg';
import { ReactComponent as ValidationDate } from '../../../icons/navee-icons/crawlingDate/validationDate.svg';
import { ReactComponent as DateOfTagging } from '../../../icons/navee-icons/crawlingDate/dateOfTagging.svg';
import { ReactComponent as VendorCreatingDate } from '../../../icons/navee-icons/crawlingDate/vendorCreatingDate.svg';
import { ReactComponent as DateOfAdding } from '../../../icons/navee-icons/crawlingDate/dateOfAdding.svg';
import { ReactComponent as PostCrawlingDate } from '../../../icons/navee-icons/crawlingDate/postCrawlingDate.svg';
import { ReactComponent as CommentDate } from '../../../icons/navee-icons/crawlingDate/CommentDate.svg';

const CrawlingDateIconsRender = () => <></>;

CrawlingDateIconsRender.CheckDate = CheckDate;
CrawlingDateIconsRender.CrawlingDate = CrawlingDate;
CrawlingDateIconsRender.LastActivity = LastActivity;
CrawlingDateIconsRender.ModerationDate = ModerationDate;
CrawlingDateIconsRender.TakedownDate = TakedownDate;
CrawlingDateIconsRender.TakedownSent = TakedownSent;
CrawlingDateIconsRender.ValidationDate = ValidationDate;
CrawlingDateIconsRender.DateOfTagging = DateOfTagging;
CrawlingDateIconsRender.DateOfAdding = DateOfAdding;
CrawlingDateIconsRender.VendorCreatingDate = VendorCreatingDate;
CrawlingDateIconsRender.PostCrawlingDate = PostCrawlingDate;
CrawlingDateIconsRender.CommentDate = CommentDate;

export enum CrawlingDateIcons {
  crawlingDate = CrawlingDateIconsRender.CrawlingDate as any as number,
  moderationDate = CrawlingDateIconsRender.ModerationDate as any as number,
  checkDate = CrawlingDateIconsRender.CheckDate as any as number,
  validationDate = CrawlingDateIconsRender.ValidationDate as any as number,
  lastActivity = CrawlingDateIconsRender.LastActivity as any as number,
  takedownSent = CrawlingDateIconsRender.TakedownSent as any as number,
  takedownDate = CrawlingDateIconsRender.TakedownDate as any as number,
  dateOfTagging = CrawlingDateIconsRender.DateOfTagging as any as number,
  dateOfAdding = CrawlingDateIconsRender.DateOfAdding as any as number,
  vendorCreationDate = CrawlingDateIconsRender.VendorCreatingDate as any as number,
  vendorDateOfTagging = CrawlingDateIconsRender.DateOfTagging as any as number,
  postCrawlingDate = CrawlingDateIconsRender.PostCrawlingDate as any as number,
  commentingDate = CrawlingDateIconsRender.CommentDate as any as number,
}
