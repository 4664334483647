import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DeleteTagRequest extends EndpointRequests {
  urlParams: {
    tagId: string;
  };
  data: {
    member_ids: Array<string>;
  };
}

interface DeleteTagResponse {}

const deleteTagMembers = new Endpoint<DeleteTagRequest, DeleteTagResponse>({
  url: '/api/me/organisation/tags/:tagId/members',
  method: 'DELETE',
  headers: {},
});

export default deleteTagMembers;
