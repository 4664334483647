import { NetworkError } from './NetworkError';

export class InternalServerError extends NetworkError {
  constructor(
    message: string = 'Something goes wrong, please retry the fetching later',
    code: number = 500,
  ) {
    super(message, code);
    this.name = 'InternalServerError';
  }
}
