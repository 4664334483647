export interface OrganisationRawModel {
  currency: string;
  default_feed_view_date_range: string;
  enable_post_validation: boolean;
  legal_name: string;
  id: number;
  uid: string;
  infringing_keywords: Array<string>;
  logo_url: string;
  name: string;
  similar_search_request_limit: number;
  similar_search_request_remaining: number;
  website_crawling_limit: number;
  price_monitoring_enabled: boolean | null;
  status: string;
}

export class OrganisationModel {
  currency: string;

  defaultFeedViewDateRange: string;

  enablePostValidation: boolean;

  id: number;

  uid: string;

  infringingKeywords: Array<string>;

  logoUrl: string;

  legalName: string;

  name: string;

  similarSearchRequestLimit: number;

  similarSearchRequestRemaining: number;

  websiteCrawlingLimit: number;

  status: string;

  constructor(
    prop: Pick<
      OrganisationModel,
      | 'currency'
      | 'defaultFeedViewDateRange'
      | 'enablePostValidation'
      | 'id'
      | 'uid'
      | 'infringingKeywords'
      | 'logoUrl'
      | 'legalName'
      | 'name'
      | 'similarSearchRequestLimit'
      | 'similarSearchRequestRemaining'
      | 'websiteCrawlingLimit'
      | 'status'
    >,
  ) {
    this.currency = prop.currency;
    this.defaultFeedViewDateRange = prop.defaultFeedViewDateRange;
    this.enablePostValidation = prop.enablePostValidation;
    this.id = prop.id;
    this.uid = prop.uid;
    this.infringingKeywords = prop.infringingKeywords;
    this.logoUrl = prop.logoUrl;
    this.name = prop.name;
    this.legalName = prop.legalName;
    this.similarSearchRequestRemaining = prop.similarSearchRequestRemaining;
    this.similarSearchRequestLimit = prop.similarSearchRequestLimit;
    this.websiteCrawlingLimit = prop.websiteCrawlingLimit;
    this.status = prop.status;
  }

  get isProduction() {
    return this.status === 'production';
  }

  static createFromRawModel(prop: OrganisationRawModel) {
    return new OrganisationModel({
      currency: prop.currency,
      defaultFeedViewDateRange: prop.default_feed_view_date_range,
      enablePostValidation: prop.enable_post_validation,
      id: prop.id,
      uid: prop.uid,
      infringingKeywords: prop.infringing_keywords,
      legalName: prop.legal_name,
      logoUrl: prop.logo_url,
      name: prop.name,
      similarSearchRequestLimit: prop.similar_search_request_limit,
      similarSearchRequestRemaining: prop.similar_search_request_remaining,
      websiteCrawlingLimit: prop.website_crawling_limit,
      status: prop.status,
    });
  }
}
