import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { DashboardMapGeographyRaw } from 'product-types/src/domain/dashboard/mapGeography/DashboardMapGeographyModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

export interface DashboardWorldMapResponse {
  countries: Array<DashboardMapGeographyRaw>;
}

export interface DashboardWorldMapParams extends CommonDashboardRequestParams {
  limit: number;
  category_id: Array<number> | null;
}

export interface WorldMap extends EndpointRequests {
  params: DashboardWorldMapParams;
}

const worldMap = new Endpoint<WorldMap, DashboardWorldMapResponse>({
  url: '/api/me/stats/geographical_distribution/map',
  method: 'GET',
  headers: {},
});

export default worldMap;
