export interface WebsiteDescriptionRaw {
  account_description_crawling_date: null | Date;
  description: string;
  followers_count: number | null;
  id: number;
  posts_count: number;
  translated_description: string;
}

export class WebsiteDescriptionModel {
  id: number;

  websiteDescriptionCrawlingDate: null | Date;

  description: string;

  followersCount: number | null;

  postsCount: number;

  translatedDescription: string;

  constructor(
    prop: Pick<
      WebsiteDescriptionModel,
      | 'websiteDescriptionCrawlingDate'
      | 'id'
      | 'description'
      | 'followersCount'
      | 'postsCount'
      | 'translatedDescription'
    >,
  ) {
    this.websiteDescriptionCrawlingDate = prop.websiteDescriptionCrawlingDate;
    this.description = prop.description;
    this.id = prop.id;
    this.followersCount = prop.followersCount;
    this.postsCount = prop.postsCount;
    this.translatedDescription = prop.translatedDescription;
  }

  static createFromRawModel(prop: WebsiteDescriptionRaw) {
    return new WebsiteDescriptionModel({
      websiteDescriptionCrawlingDate: prop.account_description_crawling_date,
      description: prop.description,
      followersCount: prop.followers_count,
      id: prop.id,
      postsCount: prop.posts_count,
      translatedDescription: prop.translated_description,
    });
  }
}
