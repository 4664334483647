import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadImageFileRequest extends EndpointRequests {
  data: FormData;
}

interface UploadImageFileResponse {}

const uploadImageFile = new Endpoint<
  UploadImageFileRequest,
  UploadImageFileResponse
>({
  url: `/api/me/uploads/image/file`,
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadImageFile;
