import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { CounterfeitAndPostsResponse } from 'product-types/src/domain/dashboard/DashboardCounterfeitAndPostsStateModel';

interface CounterfeitAndPostsRequestParams
  extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface CounterfeitAndPostsRequest extends EndpointRequests {
  params: CounterfeitAndPostsRequestParams;
}

const counterfeitAndPosts = new Endpoint<
  CounterfeitAndPostsRequest,
  CounterfeitAndPostsResponse
>({
  url: '/api/me/stats/counterfeit_and_posts',
  method: 'GET',
  headers: {},
});

export default counterfeitAndPosts;
