import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import tags from './tags';
import labels from './labels';
import org from './organisation';
import updateTagMembers from './tagMembers';
import createTag from './createTag';
import deleteTagMembers from './deleteTagMembers';

export interface OrganisationEndpoints extends EndpointContainer {
  tags: typeof tags;
  labels: typeof labels;
  org: typeof org;
  createTag: typeof createTag;
  updateTagMembers: typeof updateTagMembers;
  deleteTagMembers: typeof deleteTagMembers;
}

const organisation: OrganisationEndpoints = {
  tags,
  labels,
  org,
  updateTagMembers,
  createTag,
  deleteTagMembers,
  injectHTTPClient(client: HttpClient) {
    tags.injectHTTPClient(client);
    labels.injectHTTPClient(client);
    org.injectHTTPClient(client);
    updateTagMembers.injectHTTPClient(client);
    createTag.injectHTTPClient(client);
    deleteTagMembers.injectHTTPClient(client);
  },
};

export default organisation;
