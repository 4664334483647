import { Endpoint } from 'types/network/Http/endpoint';
import { CrawlingDomainRaw } from 'product-types/src/domain/crawlingDomain/CrawlingDomain';

export type DomainsResponse = {
  domains: Array<CrawlingDomainRaw>;
};

const domains = new Endpoint<any, DomainsResponse>({
  url: '/api/me/crawling_config/domains',
  method: 'GET',
  headers: {},
});

export default domains;
