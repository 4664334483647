import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import {
  makeArrayUniqueByKey,
  makeArrayUniqueByValue,
} from 'product-utils/src/array';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  websites: WebsiteSuggestion[];
  availableWebsiteCategories: WebsiteCategory[];
}

export class WebsiteFilterValue implements FilterValue {
  websiteCategoryInclude: WebsiteCategory[];

  websiteCategoryExclude: WebsiteCategory[];

  websiteInclude: WebsiteSuggestion[];

  websiteExclude: WebsiteSuggestion[];

  constructor(
    params: Pick<
      WebsiteFilterValue,
      | 'websiteCategoryInclude'
      | 'websiteCategoryExclude'
      | 'websiteInclude'
      | 'websiteExclude'
    >,
  ) {
    this.websiteCategoryInclude = params.websiteCategoryInclude || [];
    this.websiteCategoryExclude = params.websiteCategoryExclude || [];
    this.websiteInclude = params.websiteInclude || [];
    this.websiteExclude = params.websiteExclude || [];
  }

  static get defaultValue(): WebsiteFilterValue {
    return new WebsiteFilterValue({
      websiteCategoryInclude: new Array<WebsiteCategory>(),
      websiteCategoryExclude: new Array<WebsiteCategory>(),
      websiteInclude: new Array<WebsiteSuggestion>(),
      websiteExclude: new Array<WebsiteSuggestion>(),
    });
  }

  addIncludedCategory(categories: WebsiteCategory[]) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: makeArrayUniqueByKey(
        this.websiteCategoryInclude.concat(categories),
        'id',
      ),
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: this.websiteInclude,
      websiteExclude: this.websiteExclude,
    });
  }

  setIncludedCategory(categories: WebsiteCategory[]) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: categories,
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: this.websiteInclude,
      websiteExclude: this.websiteExclude,
    });
  }

  hasIncludedCategory(category: WebsiteCategory) {
    return this.websiteCategoryInclude.some(
      (includedCategory) => includedCategory.id === category.id,
    );
  }

  removeIncludedCategory(geography: WebsiteCategory) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude.filter(
        (includedGeography) => includedGeography.id !== geography.id,
      ),
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: this.websiteInclude,
      websiteExclude: this.websiteExclude,
    });
  }

  addExcludedCategory(categories: WebsiteCategory[]) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: this.websiteCategoryExclude.concat(categories),
      websiteInclude: this.websiteInclude,
      websiteExclude: this.websiteExclude,
    });
  }

  setExcludedCategory(categories: WebsiteCategory[]) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: categories,
      websiteInclude: this.websiteInclude,
      websiteExclude: this.websiteExclude,
    });
  }

  removeExcludedCategory(geography: WebsiteCategory) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: this.websiteCategoryExclude.filter(
        (includedGeography) => includedGeography.id !== geography.id,
      ),
      websiteInclude: this.websiteInclude,
      websiteExclude: this.websiteExclude,
    });
  }

  hasExcludedCategory(category: WebsiteCategory) {
    return this.websiteCategoryExclude.some(
      (includedCategory) => includedCategory.id === category.id,
    );
  }

  addIncludedWebsite(websites: WebsiteSuggestion[]) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: makeArrayUniqueByKey(
        this.websiteInclude.concat(websites),
        'id',
      ),
      websiteExclude: this.websiteExclude,
    });
  }

  setIncludedWebsite(websites: WebsiteSuggestion[]) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: makeArrayUniqueByKey(websites, 'id'),
      websiteExclude: this.websiteExclude,
    });
  }

  removeIncludedWebsite(website: WebsiteSuggestion) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: this.websiteInclude.filter(
        (includedWebsite) => includedWebsite.id !== website.id,
      ),
      websiteExclude: this.websiteExclude,
    });
  }

  hasIncludedWebsite(website: WebsiteSuggestion) {
    return this.websiteInclude.some(
      (includedWebsite) => includedWebsite.id === website.id,
    );
  }

  addExcludedWebsite(websites: WebsiteSuggestion[]) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: this.websiteInclude,
      websiteExclude: makeArrayUniqueByKey(
        this.websiteExclude.concat(websites),
        'id',
      ),
    });
  }

  setExcludedWebsite(websites: WebsiteSuggestion[]) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: this.websiteInclude,
      websiteExclude: makeArrayUniqueByKey(websites, 'id'),
    });
  }

  removeExcludedWebsite(website: WebsiteSuggestion) {
    return new WebsiteFilterValue({
      websiteCategoryInclude: this.websiteCategoryInclude,
      websiteCategoryExclude: this.websiteCategoryExclude,
      websiteInclude: this.websiteInclude,
      websiteExclude: this.websiteExclude.filter(
        (excludedWebsite) => excludedWebsite.id !== website.id,
      ),
    });
  }

  hasExcludeWebsite(website: WebsiteSuggestion) {
    return this.websiteExclude.some(
      (excludeWebsite) => excludeWebsite.id === website.id,
    );
  }

  static readFilterFromQuery(
    props?: readFilterFromQueryProps,
  ): WebsiteFilterValue {
    const urlParams = new URLSearchParams(window.location.search);
    const websiteInclude = makeArrayUniqueByValue(
      urlParams.getAll('website_id'),
    );
    const websiteExclude = makeArrayUniqueByValue(
      urlParams.getAll('website_id_to_exclude'),
    );
    const websiteCategoryInclude = makeArrayUniqueByValue(
      urlParams.getAll('website_category_id').map((id) => parseInt(id, 10)),
    );
    const websiteCategoryExclude = makeArrayUniqueByValue(
      urlParams
        .getAll('website_category_id_to_exclude')
        .map((id) => parseInt(id, 10)),
    );
    return new WebsiteFilterValue({
      websiteCategoryInclude: (websiteCategoryInclude || [])
        .map((categoryId: number) =>
          (props?.availableWebsiteCategories || []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .filter((item) => item !== undefined) as WebsiteCategory[],
      websiteCategoryExclude: (websiteCategoryExclude || [])
        .map((categoryId: number) =>
          (props?.availableWebsiteCategories || []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .filter((item) => item !== undefined) as WebsiteCategory[],
      websiteInclude: (websiteInclude || [])
        .map((categoryId: string) =>
          (props?.websites || []).find((website) => website.id === categoryId),
        )
        .filter((item) => item !== undefined) as WebsiteSuggestion[],
      websiteExclude: (websiteExclude || [])
        .map((categoryId: string) =>
          (props?.websites || []).find((website) => website.id === categoryId),
        )
        .filter((item) => item !== undefined) as WebsiteSuggestion[],
    });
  }

  static readFromSavedFilter(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): WebsiteFilterValue {
    const websiteInclude = makeArrayUniqueByValue(savedFilter.websiteId || []);
    const websiteExclude = makeArrayUniqueByValue(
      savedFilter.websiteIdToExclude || [],
    );
    const websiteCategoryInclude = makeArrayUniqueByValue(
      savedFilter.websiteCategoryId || [],
    );
    const websiteCategoryExclude = makeArrayUniqueByValue(
      savedFilter.websiteCategoryIdToExclude || [],
    );
    return new WebsiteFilterValue({
      websiteCategoryInclude: (websiteCategoryInclude || [])
        .map((categoryId: number) =>
          (props?.availableWebsiteCategories || []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .filter((item) => item !== undefined) as WebsiteCategory[],
      websiteCategoryExclude: (websiteCategoryExclude || [])
        .map((categoryId: number) =>
          (props?.availableWebsiteCategories || []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .filter((item) => item !== undefined) as WebsiteCategory[],
      websiteInclude: (websiteInclude || [])
        .map((categoryId: string) =>
          (props?.websites || []).find((website) => website.id === categoryId),
        )
        .filter((item) => item !== undefined) as WebsiteSuggestion[],
      websiteExclude: (websiteExclude || [])
        .map((categoryId: string) =>
          (props?.websites || []).find((website) => website.id === categoryId),
        )
        .filter((item) => item !== undefined) as WebsiteSuggestion[],
    });
  }
}
