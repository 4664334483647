import { LabelRaw } from 'product-types/src/domain/label/Label';
import { Endpoint } from 'types/network/Http/endpoint';

export type ImageLabelsResponse = Array<LabelRaw>;

const imageLabels = new Endpoint<any, ImageLabelsResponse>({
  url: '/api/me/image_labels',
  method: 'GET',
  headers: {},
});

export default imageLabels;
