import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AddClusterAccountRequest extends EndpointRequests {
  urlParams: { clusterId: string };
  data: {
    account_ids: any[];
  };
}

const addAccount = new Endpoint<AddClusterAccountRequest, any>({
  url: '/api/me/clusters/:clusterId/account',
  method: 'POST',
  headers: {},
});

export default addAccount;
