import { CanBeSelectable } from 'product-types/src/common/CanBeSelectable/CanBeSelectable';
import dayjs from 'dayjs';
import { formatDate } from 'product-utils/src/date';
import { UploadType, UploadTypeType } from './UploadType';

export interface UploadHistoryRaw {
  id: number;
  message: null | string;
  object_id: number;
  object_type: string;
  object_url: string;
  retry_count: number;
  tags: Array<string>;
  upload_account_type: null | any;
  upload_date: string;
  upload_status: string;
  upload_method: string;
  user_name: string;
}

export enum UploadStatus {
  Success = 'Success',
  Failed = 'Failed',
  RefreshFailed = 'Refresh failed',
  InProgress = 'In progress',
  RefreshInProgress = 'Refresh in progress',
}

export enum UploadMethod {
  Manual = 'MANUAL',
  Auto = 'AUTOMATIC',
}

export class UploadHistoryModel implements CanBeSelectable {
  id: number;

  rank: number;

  message: null | string;

  uploadType: UploadType;

  retryCount: number;

  tags: Array<string>;

  uploadAccountType: null | any;

  uploadDate: string;

  uploadStatus: UploadStatus;

  uploadMethod: UploadMethod;

  userName: string;

  constructor(
    prop: Pick<
      UploadHistoryModel,
      | 'id'
      | 'message'
      | 'retryCount'
      | 'tags'
      | 'uploadAccountType'
      | 'uploadDate'
      | 'uploadStatus'
      | 'rank'
      | 'uploadType'
      | 'uploadMethod'
      | 'userName'
    >,
  ) {
    this.id = prop.id;
    this.message = prop.message;
    this.uploadType = prop.uploadType;
    this.retryCount = prop.retryCount;
    this.tags = prop.tags;
    this.rank = prop.rank;
    this.uploadAccountType = prop.uploadAccountType;
    this.uploadDate = prop.uploadDate;
    this.uploadStatus = prop.uploadStatus;
    this.uploadMethod = prop.uploadMethod;
    this.userName = prop.userName;
  }

  static fromRaw(raw: UploadHistoryRaw, rank: number): UploadHistoryModel {
    return new UploadHistoryModel({
      id: raw.id,
      message: raw.message,
      retryCount: raw.retry_count,
      tags: raw.tags,
      rank,
      uploadAccountType: raw.upload_account_type,
      uploadDate: raw.upload_date,
      uploadStatus: raw.upload_status as UploadStatus,
      uploadType: new UploadType({
        id: raw.object_id,
        type: raw.object_type as UploadTypeType,
        url: raw.object_url,
      }),
      userName: raw.user_name,
      uploadMethod: raw.upload_method as UploadMethod,
    });
  }

  get uploadDateFormatted() {
    return formatDate(dayjs(this.uploadDate, 'YYYY-MM-DD HH:mm:SS'));
  }

  get color() {
    switch (this.uploadStatus) {
      case UploadStatus.Success:
        return 'var(--mint-green-lighter)';
      case UploadStatus.RefreshFailed:
        return 'var(--blue-6)';
      case UploadStatus.InProgress:
      case UploadStatus.RefreshInProgress:
        return '#FFB800';
      default:
        return '#FF7285';
    }
  }
}
