import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { AccountFeedResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { FeedRequestParameter } from '../../../../../Feed/Feed';

interface AccountFeedRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

const getAccounts = new Endpoint<
  AccountFeedRequestModel,
  AccountFeedResponseModel
>({
  url: '/api/me/accounts',
  method: 'POST',
  headers: {},
});

export default getAccounts;
