import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getToken from './token';

export interface TokenEndpoints extends EndpointContainer {
  getToken: typeof getToken;
}

const token: TokenEndpoints = {
  getToken,
  injectHTTPClient(client: HttpClient) {
    getToken.injectHTTPClient(client);
  },
};

export default token;
