import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getFile from './getFile';
import uploadFile from './uploadFile';
import deleteFile from './deleteFile';

export interface ModerationCommentsEndpoints extends EndpointContainer {
  getFile: typeof getFile;
  uploadFile: typeof uploadFile;
  deleteFile: typeof deleteFile;
}

const moderationComments: ModerationCommentsEndpoints = {
  getFile,
  uploadFile,
  deleteFile,
  injectHTTPClient(client: HttpClient) {
    getFile.injectHTTPClient(client);
    uploadFile.injectHTTPClient(client);
    deleteFile.injectHTTPClient(client);
  },
};

export default moderationComments;
