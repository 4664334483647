import { LabelRaw } from 'product-types/src/domain/label/Label';
import { Endpoint } from '../../../../../../endpoint';

export type OrganisationLabelsResponse = {
  post: Array<LabelRaw>;
  image: Array<LabelRaw>;
  account: Array<LabelRaw>;
};

const post = new Endpoint<any, OrganisationLabelsResponse>({
  url: '/api/me/organisation/labels/',
  method: 'GET',
  headers: {},
});

export default post;
