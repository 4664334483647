import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModeratedPostsBreakdownResponseModel } from 'product-types/src/domain/dashboard/DashboardModeratedPostsBreakdownModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

interface ModeratedPostsBreakdownRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const getModeratedPostsBreakdown = new Endpoint<
  ModeratedPostsBreakdownRequestModel,
  ModeratedPostsBreakdownResponseModel
>({
  url: '/api/me/stats/moderation/posts/breakdown',
  method: 'GET',
  headers: {},
});

export default getModeratedPostsBreakdown;
