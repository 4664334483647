import { v4 as uuid } from 'uuid';
import { Geographic } from 'product-types/src/domain/geo/Geo';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { NewEstimatedGeoFilterValue } from '../../../../components/Filters/NewEstimatedGeoFilter/NewEstimatedGeoFilterValue';
import { NewEstimatedGeoFilter } from '../../../../components/Filters/NewEstimatedGeoFilter/index';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import {
  EstimatedGeoFilterValue,
  readFilterFromQueryProps,
} from './EstimatedGeoValue';

export interface EstimatedGeoFilterQueryValue extends QueryValue {
  geography_to_include: Array<Geographic>;
  geography_to_exclude: Array<Geographic>;
}
export class EstimatedGeoFilter implements Filter {
  uuid: string;

  label: string;

  value: EstimatedGeoFilterValue;

  constructor(
    params?: Partial<Pick<EstimatedGeoFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || EstimatedGeoFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [
      ...this.value.geographyToInclude.map((geography) => ({
        name: FilterTypeEnum.geographyToInclude,
        value: geography,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.geographyToInclude + geography.value,
      })),
      ...this.value.geographyToExclude.map((geography) => ({
        name: FilterTypeEnum.geographyToExclude,
        value: geography,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.geographyToExclude + geography.value,
      })),
    ];
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.geographyToInclude) {
      this.value = this.value.removeIncludedGeography(
        removingFilter.value as Geographic,
      );
    } else if (removingFilter.name === FilterTypeEnum.geographyToExclude) {
      this.value = this.value.removeExcludedGeography(
        removingFilter.value as Geographic,
      );
    }
    return new EstimatedGeoFilter(this);
  }

  get component() {
    return NewEstimatedGeoFilter;
  }

  get displayFilterComponent() {
    return NewEstimatedGeoFilterValue;
  }

  get queryFilterValue(): EstimatedGeoFilterQueryValue {
    return {
      geography_to_include: this.value.geographyToInclude,
      geography_to_exclude: this.value.geographyToExclude,
    };
  }

  static readFilterFromQuery(
    props?: readFilterFromQueryProps,
  ): EstimatedGeoFilter {
    return new EstimatedGeoFilter({
      value: EstimatedGeoFilterValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): EstimatedGeoFilter {
    return new EstimatedGeoFilter({
      value: EstimatedGeoFilterValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
