import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdateAccountRequestModel extends EndpointRequests {
  data: any;
  urlParams: { id: string };
}

const updateAccount = new Endpoint<UpdateAccountRequestModel, any>({
  url: '/api/me/accounts/:id',
  method: 'PUT',
  headers: {},
});

export default updateAccount;
