import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { CommentModel } from 'product-types/src/domain/comment/Comment';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { WebsiteModerationModel } from 'product-types/src/domain/website/WebsiteModerationModel';
import {
  AssociatedImagesWebsite,
  WebsiteFeedDataModel,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { RelatedWebsite } from 'product-types/src/domain/website/RelatedWebsite';
import { Contact } from 'product-types/src/domain/contact/Contact';
import {
  LOAD_WEBSITES_DATA,
  LOAD_WEBSITES_SUCCESS,
  UPDATE_WEBSITE,
  LOAD_IMAGES_WEBSITE,
  UPDATE_WEBSITE_IMAGES,
  REFRESH_WEBSITE,
  LOAD_PREV_WEBSITES_TO_MODERATE,
  LOAD_NEXT_WEBSITES_TO_MODERATE,
  DEL_GEO_WEBSITE,
  SET_GEO_WEBSITE,
  RESET_STATE,
  UPDATE_FILTERS_RELATED_POSTS,
  LOAD_COMMENTS_WEBSITES,
  LOAD_COMMENTS_WEBSITE_SUCCESS,
  UPDATE_RELATED_WEBSITE,
  LOAD_RELATED_WEBSITES,
  UPDATE_RELATED_WEBSITE_FILTERS,
  UPDATE_WEBSITE_MODERATION_QUEUE_INFORMATION,
  UPDATE_RELATED_WEBSITES_TOTAL,
  MODERATE_WEBSITE_AND_UPDATE_IN_PLACE,
  LOAD_WEBSITE_RESOURCES,
  PERFORM_WEBSITE_MODERATION,
  RESET_FILTERS_RELATED_POSTS,
  RECRAWL_WEBSITE,
  ADD_COMMENT_WEBSITE,
  DELETE_COMMENT_WEBSITE,
  UPDATE_CONTACT_INFORMATION_WEBSITE,
} from './constants';

// May be any actions in this reducer can be removed after deep investigation of bussines logic.
// In this time i keep actions for backward compatiblity. We can refactor this part only after deep investigations and discussions with backend

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export const loadWebsitesDataAction = (pageState) => ({
  type: LOAD_WEBSITES_DATA,
  payload: pageState,
});

export const loadWebsitesDataSuccessAction = (
  websitesData: FetchableData<WebsiteFeedDataModel>,
) => ({
  type: LOAD_WEBSITES_SUCCESS,
  websitesData,
});

export const updateContactInformationWebsite = (
  contactInformation: Array<Contact>,
  websiteId: string,
  onSuccess: () => void,
) => ({
  type: UPDATE_CONTACT_INFORMATION_WEBSITE,
  contactInformation,
  websiteId,
  onSuccess,
});

export const updateWebsiteModerationQueue = ({
  number_of_websites_to_moderate,
  website_moderation_index,
}: {
  number_of_websites_to_moderate: number;
  website_moderation_index: number;
}) => ({
  type: UPDATE_WEBSITE_MODERATION_QUEUE_INFORMATION,
  number_of_websites_to_moderate,
  website_moderation_index,
});

export function refreshWebsiteAction({ website_id }) {
  return {
    type: REFRESH_WEBSITE,
    website_id,
  };
}

export function resetFiltersRelatedPosts() {
  return {
    type: RESET_FILTERS_RELATED_POSTS,
  };
}

export function addCommentWebsite({ comment, objectId, fileIds }) {
  return {
    type: ADD_COMMENT_WEBSITE,
    data: { comment, file_ids: fileIds },
    objectId,
  };
}

export function deleteCommentWebsite({ commentId, objectId }) {
  return {
    type: DELETE_COMMENT_WEBSITE,
    commentId,
    objectId,
  };
}

export function recrawlWebsite() {
  return {
    type: RECRAWL_WEBSITE,
  };
}

export function loadWebsiteResourcesAction({
  websiteId,
}: {
  websiteId: string;
}) {
  return {
    type: LOAD_WEBSITE_RESOURCES,
    websiteId,
  };
}

export function updateRelatedPostFilters(payload: TableParams) {
  return {
    type: UPDATE_FILTERS_RELATED_POSTS,
    payload,
  };
}

export function updateWebsite(website: FetchableData<WebsiteModerationModel>) {
  return {
    type: UPDATE_WEBSITE,
    website,
  };
}

export const loadRelatedWebsites = (
  filters: TableParams,
  website_id: string,
) => ({
  type: LOAD_RELATED_WEBSITES,
  filters,
  website_id,
});

export const updateRelatedWebsitesFilters = (filters: TableParams) => ({
  type: UPDATE_RELATED_WEBSITE_FILTERS,
  filters,
});

export const updateRelatedWebsitesTotal = (total: number) => ({
  type: UPDATE_RELATED_WEBSITES_TOTAL,
  total,
});

export const updateRelatedWebsites = (
  relatedWebsites: FetchableData<Array<RelatedWebsite>>,
) => ({
  type: UPDATE_RELATED_WEBSITE,
  relatedWebsites,
});

export function moderateWebsiteAndUpdateInPlaceAction(
  website: WebsiteModerationModel,
  status,
  success_message = '',
) {
  const data = {
    // eslint-disable-next-line no-underscore-dangle
    account_id: website._id,
    status,
  };
  return {
    type: MODERATE_WEBSITE_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}

export function loadNextWebsiteToModerateAction(state) {
  return {
    type: LOAD_NEXT_WEBSITES_TO_MODERATE,
    state,
  };
}

export function loadWebsiteComments(websiteId) {
  return {
    type: LOAD_COMMENTS_WEBSITES,
    websiteId,
  };
}

export function updateWebsiteCommentAction(
  commentsData: FetchableData<Array<CommentModel>>,
) {
  return {
    type: LOAD_COMMENTS_WEBSITE_SUCCESS,
    commentsData,
  };
}

export function loadPrevWebsiteToModerateAction(state) {
  return {
    type: LOAD_PREV_WEBSITES_TO_MODERATE,
    state,
  };
}

export function loadWebsiteImagesAction({
  object_id,
  offset = 0,
  perpage = 10,
}) {
  return {
    type: LOAD_IMAGES_WEBSITE,
    object_id,
    offset,
    perpage,
  };
}

export function updateWebsiteImages(
  images: FetchableData<AssociatedImagesWebsite>,
) {
  return {
    type: UPDATE_WEBSITE_IMAGES,
    images,
  };
}

export function setGeoWebsiteAction({ value, website_id }, refresh_action) {
  return {
    type: SET_GEO_WEBSITE,
    value,
    website_id,
    refresh_action,
  };
}

export function delGeoWebsiteAction({ website_id }, refresh_action) {
  return {
    type: DEL_GEO_WEBSITE,
    website_id,
    refresh_action,
  };
}

export function qaCheckWebsiteAndUpdateInPlaceAction(
  website: WebsiteModerationModel,
  qa_checked,
  success_message = '',
) {
  const data = {
    account_id: website._id,
    qa_checked,
  };
  return {
    type: MODERATE_WEBSITE_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}

export function performWebsiteModerationAction(
  accounts_array,
  status,
  refresh_action,
  success_message = '',
) {
  const account_statuses = accounts_array.map((account) => ({
    account_id: account._id,
    status,
  }));
  return {
    type: PERFORM_WEBSITE_MODERATION,
    data: { account_statuses },
    refresh_action,
    success_message,
  };
}

export function validateWebsiteAndUpdateInPlaceAction(
  website: WebsiteModerationModel,
  validated,
  success_message = '',
) {
  const data = {
    account_id: website._id,
    validated,
  };
  return {
    type: MODERATE_WEBSITE_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}
