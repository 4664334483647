import { UploadPost } from 'product-types/src/domain/uploads/uploadPost';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { Label } from 'product-types/src/domain/label/Label';
import { ExistingUploadItemState } from 'product-types/src/domain/uploads/uploadAccount';
import {
  CHECK_WEBSITE_HAS_POSTERS,
  RESET_STATE,
  UPDATE_EXISTING_ITEMS_POSTS,
  UPDATE_GLOBAL_POST_LABEL,
  UPDATE_LOADING_POST,
  UPDATE_MANUAL_UPLOAD,
  UPDATE_POSTS_TAGS_LIST,
  UPDATE_POST_LIST,
  UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_POST,
  UPLOAD_IMAGES_TO_S3_AND_ATTACH_TO_POST,
  UPLOAD_POSTS_TO_NAVEE,
  UPLOAD_POST_XLSX,
} from './constants';

export const updatePostList = (posts: Array<UploadPost>) => ({
  type: UPDATE_POST_LIST,
  posts,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const checkWebsiteHasPosters = (post: UploadPost) => ({
  type: CHECK_WEBSITE_HAS_POSTERS,
  post,
});

export const updateGlobalPostLabel = (label: Label | null) => ({
  type: UPDATE_GLOBAL_POST_LABEL,
  label,
});

export function setLoadingPostUpload(loading: FetchableData<null>) {
  return {
    type: UPDATE_LOADING_POST,
    loading,
  };
}

export const uploadImagesToS3AndAttachToPost = ({
  file,
  post,
}: {
  file: File;
  post: UploadPost;
}) => ({
  type: UPLOAD_IMAGES_TO_S3_AND_ATTACH_TO_POST,
  file,
  post,
});

export function uploadPostXlsxToNavee(file: File) {
  return {
    type: UPLOAD_POST_XLSX,
    file,
  };
}
export const uploadArchiveLinkToS3AndAttachToPost = ({
  file,
  post,
}: {
  file: File;
  post: UploadPost;
}) => ({
  type: UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_POST,
  file,
  post,
});

export const sendPostsToNavee = (override?: boolean) => ({
  type: UPLOAD_POSTS_TO_NAVEE,
  override,
});

export const updateManualUpload = (manualUpload: boolean) => ({
  type: UPDATE_MANUAL_UPLOAD,
  manualUpload,
});

export function updatePostTags(tags: Array<TagModel>) {
  return {
    type: UPDATE_POSTS_TAGS_LIST,
    tags,
  };
}

export const udpateExistingPosts = (
  existingItems: ExistingUploadItemState | null,
) => ({
  type: UPDATE_EXISTING_ITEMS_POSTS,
  existingItems,
});
