// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Satoshi-Variable.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Satoshi-Variable.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Satoshi-Variable.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Satoshi-VariableItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Satoshi-VariableItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Satoshi-VariableItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./WhyteInktrap-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./WhyteInktrap-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./WhyteInktrap-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./WhyteInktrap-LightItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./WhyteInktrap-LightItalic.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Satoshi';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff'),
         url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
         font-weight: 300 900;
         font-display: swap;
         font-style: normal;
  }

  @font-face {
    font-family: 'Satoshi';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff'),
         url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
         font-weight: 300 900;
         font-display: swap;
         font-style: italic;
  }

  @font-face {
    font-family: 'WhyteInktrap';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff'),
         url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('truetype');
         font-weight: 100 900;
         font-display: swap;
         font-style: normal;
  }

  @font-face {
    font-family: 'WhyteInktrap';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('woff'),
         url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('truetype');
         font-weight: 100 300;
         font-display: swap;
         font-style: italic;
  }

`, "",{"version":3,"sources":["webpack://./src/app/assets/fonts/stylesheet.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB;;mEAEqD;SAChD,oBAAoB;SACpB,kBAAkB;SAClB,kBAAkB;EACzB;;EAEA;IACE,sBAAsB;IACtB;;mEAE2D;SACtD,oBAAoB;SACpB,kBAAkB;SAClB,kBAAkB;EACzB;;EAEA;IACE,2BAA2B;IAC3B;;mEAEuD;SAClD,oBAAoB;SACpB,kBAAkB;SAClB,kBAAkB;EACzB;;EAEA;IACE,2BAA2B;IAC3B;;mEAE6D;SACxD,oBAAoB;SACpB,kBAAkB;SAClB,kBAAkB;EACzB","sourcesContent":["@font-face {\n    font-family: 'Satoshi';\n    src: url('./Satoshi-Variable.woff2') format('woff2'),\n         url('./Satoshi-Variable.woff') format('woff'),\n         url('./Satoshi-Variable.ttf') format('truetype');\n         font-weight: 300 900;\n         font-display: swap;\n         font-style: normal;\n  }\n\n  @font-face {\n    font-family: 'Satoshi';\n    src: url('./Satoshi-VariableItalic.woff2') format('woff2'),\n         url('./Satoshi-VariableItalic.woff') format('woff'),\n         url('./Satoshi-VariableItalic.ttf') format('truetype');\n         font-weight: 300 900;\n         font-display: swap;\n         font-style: italic;\n  }\n\n  @font-face {\n    font-family: 'WhyteInktrap';\n    src: url('./WhyteInktrap-Light.woff2') format('woff2'),\n         url('./WhyteInktrap-Light.woff') format('woff'),\n         url('./WhyteInktrap-Light.ttf') format('truetype');\n         font-weight: 100 900;\n         font-display: swap;\n         font-style: normal;\n  }\n\n  @font-face {\n    font-family: 'WhyteInktrap';\n    src: url('./WhyteInktrap-LightItalic.ttf') format('woff2'),\n         url('./WhyteInktrap-LightItalic.woff') format('woff'),\n         url('./WhyteInktrap-LightItalic.ttf') format('truetype');\n         font-weight: 100 300;\n         font-display: swap;\n         font-style: italic;\n  }\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
