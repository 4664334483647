import * as Domain from 'product-types/src/domain/Domain';
import { GetUploadStatusFilterOptionByValue } from 'product-types/src/domain/uploadStatus/UploadStatus';
import { makeArrayUniqueByKey } from 'product-utils/src/array';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export class UploadStatusValue implements FilterValue {
  selectedUploadStatuses: Array<Domain.UploadStatus.UploadStatus>;

  constructor(params: Pick<UploadStatusValue, 'selectedUploadStatuses'>) {
    this.selectedUploadStatuses = params.selectedUploadStatuses;
  }

  setUploadStatus(value: Domain.UploadStatus.UploadStatus[]) {
    return new UploadStatusValue({
      selectedUploadStatuses: value,
    });
  }

  addUploadStatus(value: Domain.UploadStatus.UploadStatus) {
    return new UploadStatusValue({
      selectedUploadStatuses: makeArrayUniqueByKey(
        this.selectedUploadStatuses.concat([value]),
        'value',
      ),
    });
  }

  removeUploadStatus(value: Domain.UploadStatus.UploadStatus) {
    return new UploadStatusValue({
      selectedUploadStatuses: this.selectedUploadStatuses.filter(
        (newestUploadStatus) => newestUploadStatus.value !== value.value,
      ),
    });
  }

  hasUploadStatus(value: Domain.UploadStatus.UploadStatus) {
    return this.selectedUploadStatuses.some(
      (newestUploadStatus: Domain.UploadStatus.UploadStatus) =>
        newestUploadStatus.value === value.value,
    );
  }

  static get defaultValue(): UploadStatusValue {
    return new UploadStatusValue({
      selectedUploadStatuses: new Array<Domain.UploadStatus.UploadStatus>(),
    });
  }

  static get readFilterFromQuery(): UploadStatusValue {
    const uploadStatusQuery = new URLSearchParams(
      window.location.search,
    ).getAll('upload_status');
    const uploadStatuses = (uploadStatusQuery
      ?.map((us) => GetUploadStatusFilterOptionByValue(us))
      .filter((v) => !!v) || []) as Domain.UploadStatus.UploadStatus[];
    if (uploadStatuses.length) {
      return new UploadStatusValue({
        selectedUploadStatuses: uploadStatuses,
      });
    }
    return UploadStatusValue.defaultValue;
  }

  static readFromSavedFilter(props: SavedFilterModel): UploadStatusValue {
    const uploadStatusQuery = props.uploadStatus;
    const uploadStatuses = (uploadStatusQuery
      ?.map((us) => GetUploadStatusFilterOptionByValue(us))
      .filter((v) => !!v) || []) as Domain.UploadStatus.UploadStatus[];
    if (uploadStatuses.length) {
      return new UploadStatusValue({
        selectedUploadStatuses: uploadStatuses,
      });
    }
    return UploadStatusValue.defaultValue;
  }
}
