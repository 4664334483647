import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { FeedRequestParameter } from '../../../../../Feed/Feed';

interface ExportAccountsRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
  urlParams: {
    id: string;
  };
}

const exportMembers = new Endpoint<
  ExportAccountsRequestModel,
  NonNullable<unknown>
>({
  url: `/api/me/clusters/members/:id/export`,
  method: 'POST',
  headers: {},
});

export default exportMembers;
