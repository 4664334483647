import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdateModerationReasonRequestModel extends EndpointRequests {
  data: {
    moderation_reason_id: number;
    override_moderation_reason_id: number;
    value: any;
    object_id: number;
    location: any;
  };
}

interface UpdateModerationReasonResponse {}

const updateModerationReason = new Endpoint<
  UpdateModerationReasonRequestModel,
  UpdateModerationReasonResponse
>({
  url: '/api/me/moderation_reason',
  method: 'POST',
  headers: {},
});

export default updateModerationReason;
