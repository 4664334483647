import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  users: UserLightModel[];
}

export class UploadedByFilterValue implements FilterValue {
  selectedUsers: Array<UserLightModel>;

  constructor(props: { selectedUsers?: Array<UserLightModel> }) {
    this.selectedUsers = props?.selectedUsers || [];
  }

  addUser(user: UserLightModel) {
    if (this.selectedUsers.find((u) => u.id === user.id)) {
      return this;
    }
    return new UploadedByFilterValue({
      selectedUsers: [...this.selectedUsers, user],
    });
  }

  removeUser(user: UserLightModel) {
    return new UploadedByFilterValue({
      selectedUsers: this.selectedUsers.filter((u) => u.id !== user.id),
    });
  }

  static get defaultValue(): UploadedByFilterValue {
    return new UploadedByFilterValue({});
  }

  static readFilterFromQuery({
    users,
  }: readFilterFromQueryProps): UploadedByFilterValue {
    const uploadTypeQuery = new URLSearchParams(window.location.search)
      .getAll('uploader_ids')
      .map((id) => parseInt(id, 10))
      .filter((id) => !Number.isNaN(id));

    return new UploadedByFilterValue({
      selectedUsers:
        users.filter((user) => uploadTypeQuery.includes(user.id)) ?? [],
    });
  }
}
