/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import { Tabs } from 'product-ui/src/components/atoms/Tabs/Tabs';
import { Flex } from 'antd';
import { useSelector } from 'react-redux';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import { AppState } from '../../../store/storeAccess';
import { AllContactTypesOption } from '../../../types/filters/AtomicFiltersImplementation/AccountInformation/AccountInformationValue';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import { AccountInformationFilter as AccountInformationFilterModel } from '../../../types/filters/AtomicFiltersImplementation/AccountInformation/AccountInformation';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import StyledSlider from '../ProductInformationFilter/StyledSlider';

export interface AccountInformationFilterProps extends FilterParams {
  value: AccountInformationFilterModel;
  onChange: (v: Filter) => void;
}

export const AccountInformationFilter = (
  props: AccountInformationFilterProps,
) => {
  const contactTypes = useSelector(
    (state: AppState) =>
      state.filters_bar?.optionsLoadedData?.availableContactTypes?.data,
    (a, b) => a?.length === b?.length,
  );
  const onSelectFollowersFilter = useCallback(
    (followers: [string | number | null, string | number | null]) => {
      const minFolowersCount =
        typeof followers[0] === 'number'
          ? followers[0]
          : parseFloat(followers[0] ?? '');
      const maxFollowersCount =
        typeof followers[1] === 'number'
          ? followers[1]
          : parseFloat(followers[1] ?? '');
      props.onChange(
        new AccountInformationFilterModel({
          ...props.value,
          value: props.value.value.setFollowers(
            new Domain.Followers.Followers({
              min: Math.abs(minFolowersCount) || null,
              max: Math.abs(maxFollowersCount) || null,
            }),
          ),
        }),
      );
    },
    [props.value, props.onChange],
  );

  const handleClickAllContactTypes = useCallback(() => {
    if (props.value.value.hasAllContactTypes()) {
      props.onChange(
        new AccountInformationFilterModel({
          ...props.value,
          value: props.value.value.setContactTypes([]),
        }),
      );
    } else {
      props.onChange(
        new AccountInformationFilterModel({
          ...props.value,
          value: props.value.value.setContactTypes(
            (contactTypes?.map((contact) => contact) ?? []).concat([
              AllContactTypesOption,
            ]),
          ),
        }),
      );
    }
  }, [props.onChange, props.value, contactTypes, props.value.value]);

  const handleChangeContactType = useCallback(
    (value: boolean, contact: ContactMainInfo) => {
      if (value) {
        const newTypes = props.value.value.hasContactType(contact)
          ? props.value.value.contactTypes
          : [...props.value.value.contactTypes, contact];
        if (newTypes.length === contactTypes?.length) {
          props.onChange(
            new AccountInformationFilterModel({
              ...props.value,
              value: props.value.value.setContactTypes(
                newTypes.concat([AllContactTypesOption]),
              ),
            }),
          );
        } else {
          props.onChange(
            new AccountInformationFilterModel({
              ...props.value,
              value: props.value.value.setContactTypes(newTypes),
            }),
          );
        }
      } else if (props.value.value.hasAllContactTypes()) {
        props.onChange(
          new AccountInformationFilterModel({
            ...props.value,
            value: props.value.value.setContactTypes(
              (contactTypes || []).filter(
                (contactType) =>
                  contactType.value !== contact.value &&
                  contactType.value !== AllContactTypesOption.value,
              ),
            ),
          }),
        );
      } else {
        props.onChange(
          new AccountInformationFilterModel({
            ...props.value,
            value: props.value.value.setContactTypes(
              props.value.value.contactTypes.filter(
                (contactType) => contactType.value !== contact.value,
              ),
            ),
          }),
        );
      }
    },
    [props.onChange, props.value, contactTypes, props.value.value],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          width: 320,
          maxHeight: 440,
          minHeight: 60,
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <Tabs
          id="price-filter-container"
          defaultActiveKey="1"
          style={{ width: '100%' }}
          animated={false}
          items={[
            {
              key: '1',
              label: 'CONTACT INFO',
              children: (
                <Flex
                  vertical
                  gap="1rem"
                  style={{
                    padding: '0 1.5rem 1.5rem',
                    maxHeight: '366px',
                    overflowY: 'auto',
                  }}
                >
                  <Checkbox
                    label="All"
                    checked={props.value.value.hasAllContactTypes()}
                    onChange={handleClickAllContactTypes}
                  />
                  {contactTypes?.map((contact) => (
                    <Checkbox
                      label={contact.label}
                      checked={props.value.value.hasContactType(contact)}
                      onChange={(value) =>
                        handleChangeContactType(value, contact)
                      }
                    />
                  ))}
                </Flex>
              ),
            },
            {
              key: '2',
              label: 'FOLLOWERS',
              children: (
                <Flex
                  vertical
                  gap="1rem"
                  style={{
                    padding: '0 1.5rem 1.5rem',
                  }}
                >
                  <StyledSlider
                    ids={[
                      'followers-from-range-input',
                      'followers-to-range-input',
                    ]}
                    value={[
                      props.value.value.followers.min,
                      props.value.value.followers.max,
                    ]}
                    suffix=""
                    step={1}
                    precision={0}
                    onChange={onSelectFollowersFilter}
                    max={1000000}
                  />
                </Flex>
              ),
            },
          ]}
        />
      </Flex>
    ),
    [
      props.value.value,
      onSelectFollowersFilter,
      contactTypes,
      handleChangeContactType,
      handleClickAllContactTypes,
    ],
  );

  return (
    <FilterWithMenuWrapper
      text="Account Information"
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
      renderer={renderer}
      dataTestId="accountInformationFilter"
    />
  );
};
