import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getWebsites from './getWebsites';
import getModerationWebsite from './getModerationWebsite';
import comments from './comments';
import websitesModeration from './websitesModeration';
import relatedWebsites from './relatedWebsites';
import hasPosters from './hasPosters';

export interface WebsiteEndpoints extends EndpointContainer {
  getWebsites: typeof getWebsites;
  hasPosters: typeof hasPosters;
  getModerationWebsite: typeof getModerationWebsite;
  comments: typeof comments;
  websitesModeration: typeof websitesModeration;
  relatedWebsites: typeof relatedWebsites;
}

const websites: WebsiteEndpoints = {
  getWebsites,
  hasPosters,
  comments,
  getModerationWebsite,
  websitesModeration,
  relatedWebsites,
  injectHTTPClient(client: HttpClient) {
    getWebsites.injectHTTPClient(client);
    hasPosters.injectHTTPClient(client);
    getModerationWebsite.injectHTTPClient(client);
    comments.injectHTTPClient(client);
    websitesModeration.injectHTTPClient(client);
    relatedWebsites.injectHTTPClient(client);
  },
};

export default websites;
