import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { TakedownResponse } from 'product-types/src/domain/dashboard/DashboardTakedownStateModel';

interface TakedownRequestParams extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface TakedownRequest extends EndpointRequests {
  params: TakedownRequestParams;
}

const takedown = new Endpoint<TakedownRequest, TakedownResponse>({
  url: '/api/me/stats/takedown',
  method: 'GET',
  headers: {},
  numberOfRetries: 3,
});

export default takedown;
