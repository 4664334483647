import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface WebsiteSearchRequest extends EndpointRequests {
  params: {
    q: string;
  };
}

interface WebsiteSearchResponse {
  results: Array<{
    label: string;
    value: string;
  }>;
}

const websiteSearch = new Endpoint<WebsiteSearchRequest, WebsiteSearchResponse>(
  {
    url: '/api/me/website_search',
    method: 'GET',
    headers: {},
  },
);

export default websiteSearch;
