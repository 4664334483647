import {
  UploadType,
  UploadTypeDictionary,
  GetUploadTypeFilterOptionByValue,
} from 'product-types/src/domain/uploadType/UploadType';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export class UploadTypeFilterValue implements FilterValue {
  uploadType: UploadType;

  constructor(params?: UploadType) {
    this.uploadType = params || UploadTypeDictionary.all;
  }

  static get defaultValue(): UploadTypeFilterValue {
    return new UploadTypeFilterValue();
  }

  static get readFilterFromQuery(): UploadTypeFilterValue {
    const uploadTypeQuery = new URLSearchParams(window.location.search)
      .getAll('upload_type')
      .join(',');
    const uploadType = GetUploadTypeFilterOptionByValue(uploadTypeQuery);
    if (uploadType) {
      return new UploadTypeFilterValue(uploadType);
    }
    return UploadTypeFilterValue.defaultValue;
  }
}
