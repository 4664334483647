import { SelectProps } from 'antd';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import Button from 'product-ui/src/components/atoms/Button/Button';
import React, { useCallback } from 'react';
import { Select } from 'product-ui/src/components/atoms/Select';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import Tag from 'product-ui/src/components/atoms/Tag';
import { StyledWrapper } from './styled';

interface WebsiteCategoryTabProps {
  onSelectCategory: (
    operation: 'include' | 'exclude',
    categories: Array<WebsiteCategory>,
  ) => void;
  onClearCategories: (operation: 'include' | 'exclude') => void;
  websiteCategoryInclude: Array<WebsiteCategory>;
  websiteCategoryExclude: Array<WebsiteCategory>;
  applyFilters: () => void;
  onRemoveCategory: (operation, category: WebsiteCategory) => void;
  categories: WebsiteCategory[];
  showApplyButton?: boolean;
  maxTagCount: SelectProps['maxTagCount'];
  maxTagPlaceholder: SelectProps['maxTagPlaceholder'];
}
export const WebsiteCategoryTab = ({
  onSelectCategory,
  applyFilters,
  onClearCategories,
  onRemoveCategory,
  websiteCategoryInclude,
  websiteCategoryExclude,
  categories,
  showApplyButton,
  maxTagCount,
  maxTagPlaceholder,
}: WebsiteCategoryTabProps) => {
  const tagRender = useCallback(
    (type: 'include' | 'exclude') => (props: any) => (
      <Tag
        tag={
          new TagModel({
            id: props.value,
            name: props.label,
            tagType: 'website',
          })
        }
        style={{
          marginRight: '0.25rem',
          marginBottom: '0.25rem',
        }}
        disableLoading
        showRemoveIcon
        onClickRemove={() => {
          const category =
            type === 'include'
              ? websiteCategoryInclude.find((wb) => wb.id === props.value)
              : websiteCategoryExclude.find((wb) => wb.id === props.value);
          if (category) {
            onRemoveCategory(type, category);
          }
        }}
      />
    ),
    [websiteCategoryInclude, websiteCategoryExclude, onRemoveCategory],
  );
  return (
    <StyledWrapper vertical gap="1rem">
      <Select
        fieldNames={{ value: 'id', label: 'name' }}
        value={websiteCategoryInclude.map((c) => ({
          label: c.name,
          value: c.id,
        }))}
        tagRender={tagRender('include')}
        optionFilterProp="name"
        onSelect={(_, ctgs: WebsiteCategory) => {
          onSelectCategory(
            'include',
            [ctgs].map((c) => ({
              id: c.id,
              name: c.name,
              category_rank: c.category_rank,
            })),
          );
        }}
        onDeselect={(deselectedId: number) => {
          onSelectCategory(
            'include',
            websiteCategoryInclude.filter((c) => c.id !== deselectedId),
          );
        }}
        onClear={() => onClearCategories('include')}
        placeholder="Included Websites Categories..."
        notFoundContent="No options"
        mode="multiple"
        options={(categories || []).filter(
          (c) =>
            websiteCategoryExclude.find((w) => w.id === c.id) === undefined &&
            websiteCategoryInclude.find((w) => w.id === c.id) === undefined,
        )}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={maxTagPlaceholder}
      />
      <Select
        fieldNames={{ value: 'id', label: 'name' }}
        value={websiteCategoryExclude.map((c) => ({
          label: c.name,
          value: c.id,
        }))}
        tagRender={tagRender('exclude')}
        onSelect={(_, ctgs: WebsiteCategory) => {
          onSelectCategory(
            'exclude',
            [ctgs].map((c) => ({
              id: c.id,
              name: c.name,
              category_rank: c.category_rank,
            })),
          );
        }}
        onDeselect={(deselectedId: number) => {
          onSelectCategory(
            'exclude',
            websiteCategoryExclude.filter((c) => c.id !== deselectedId),
          );
        }}
        optionFilterProp="name"
        onClear={() => onClearCategories('exclude')}
        placeholder="Excluded Websites Categories..."
        notFoundContent="No options"
        mode="multiple"
        options={(categories || []).filter(
          (c) =>
            websiteCategoryExclude.find((w) => w.id === c.id) === undefined &&
            websiteCategoryInclude.find((w) => w.id === c.id) === undefined,
        )}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={maxTagPlaceholder}
      />
      {showApplyButton && applyFilters ? (
        <Button onClick={applyFilters} size="Small" label="Apply" />
      ) : null}
    </StyledWrapper>
  );
};
