export const LOAD_WEBSITES_DATA = 'app/WebsiteView/LOAD_WEBSITES_DATA';
export const LOAD_WEBSITES_SUCCESS = 'app/WebsiteView/LOAD_WEBSITES_SUCCESS';
export const LOAD_NEXT_WEBSITES_TO_MODERATE =
  'app/WebsiteView/LOAD_NEXT_WEBSITES_TO_MODERATE';
export const LOAD_PREV_WEBSITES_TO_MODERATE =
  'app/WebsiteView/LOAD_PREV_WEBSITES_TO_MODERATE';
export const LOAD_COMMENTS_WEBSITES = 'app/WebsiteView/LOAD_COMMENTS_WEBSITES';
export const LOAD_COMMENTS_WEBSITE_SUCCESS =
  'app/WebsiteView/LOAD_COMMENTS_WEBSITE_SUCCESS';
export const UPDATE_WEBSITE_IMAGES = 'app/WebsiteView/UPDATE_WEBSITE_IMAGES';
export const RECRAWL_WEBSITE = 'app/WebsiteView/RECRAWL_WEBSITE';
export const RESET_FILTERS_RELATED_POSTS =
  'app/WebsiteView/RESET_FILTERS_RELATED_POSTS';
export const LOAD_IMAGES_WEBSITE = 'app/WebsiteView/LOAD_IMAGES_WEBSITE';
export const REFRESH_WEBSITE = 'app/WebsiteView/REFRESH_WEBSITE';
export const LOAD_WEBSITE_RESOURCES = 'app/WebsiteView/LOAD_WEBSITE_RESOURCES';
export const UPDATE_WEBSITE = 'app/WebsiteView/UPDATE_WEBSITE';
export const UPDATE_RELATED_WEBSITE_FILTERS =
  'app/WebsiteView/UPDATE_RELATED_WEBSITE_FILTERS';
export const UPDATE_CONTACT_INFORMATION_WEBSITE =
  'app/WebsiteView/UPDATE_CONTACT_INFORMATION_WEBSITE';
export const UPDATE_RELATED_WEBSITE = 'app/WebsiteView/UPDATE_RELATED_WEBSITE';
export const UPDATE_RELATED_WEBSITES_TOTAL =
  'app/WebsiteView/UPDATE_RELATED_WEBSITES_TOTAL';
export const LOAD_RELATED_WEBSITES = 'app/WebsiteView/LOAD_RELATED_WEBSITES';
export const ADD_COMMENT_WEBSITE = 'app/WebsiteView/ADD_COMMENT_WEBSITE';
export const DELETE_COMMENT_WEBSITE = 'app/WebsiteView/DELETE_COMMENT_WEBSITE';
export const LOAD_RELATED_POSTS_WEBSITE_SUCCESS =
  'app/WebsiteView/LOAD_RELATED_POSTS_WEBSITE_SUCCESS';
export const PERFORM_WEBSITE_MODERATION =
  'app/WebsiteView/PERFORM_WEBSITE_MODERATION';
export const MODERATE_WEBSITE_AND_UPDATE_IN_PLACE =
  'app/WebsiteView/MODERATE_WEBSITE_AND_UPDATE_IN_PLACE';
export const SET_GEO_WEBSITE = 'app/WebsiteView/SET_GEO_WEBSITE';
export const DEL_GEO_WEBSITE = 'app/WebsiteView/DEL_GEO_WEBSITE';
export const UPDATE_WEBSITE_MODERATION_QUEUE_INFORMATION =
  'app/WebsiteView/UPDATE_WEBSITE_MODERATION_QUEUE_INFORMATION';
export const WEBSITE_VIEW_TYPE = 'WebsiteView/Type';
export const RESET_STATE = 'app/WebsiteView/RESET_STATE';
export const UPDATE_FILTERS_RELATED_POSTS =
  'app/WebsiteView/UPDATE_FILTERS_RELATED_POSTS';
