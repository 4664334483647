import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { KeywordsFoundResponseModel } from 'product-types/src/domain/dashboard/DashboardKeywordsFoundModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

interface KeywordsFoundRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const getKeywordsFound = new Endpoint<
  KeywordsFoundRequestModel,
  KeywordsFoundResponseModel
>({
  url: '/api/me/stats/keywords/found',
  method: 'GET',
  headers: {},
});

export default getKeywordsFound;
