import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import posts from './posts';
import images from './images';
import accounts from './accounts';
import accountsLabels from './accountsLabels';
import imagesLabels from './imagesLabels';
import postsLabels from './postsLabels';

export interface ModerationStatisticsEndpoints extends EndpointContainer {
  posts: typeof posts;
  images: typeof images;
  accounts: typeof accounts;
  postsLabels: typeof postsLabels;
  imagesLabels: typeof imagesLabels;
  accountsLabels: typeof accountsLabels;
}

const moderationStatistics: ModerationStatisticsEndpoints = {
  posts,
  images,
  accounts,
  postsLabels,
  imagesLabels,
  accountsLabels,
  injectHTTPClient(client: HttpClient) {
    posts.injectHTTPClient(client);
    images.injectHTTPClient(client);
    accounts.injectHTTPClient(client);
    postsLabels.injectHTTPClient(client);
    imagesLabels.injectHTTPClient(client);
    accountsLabels.injectHTTPClient(client);
  },
};

export default moderationStatistics;
