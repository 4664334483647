import React from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import { getWebsiteCategoryIcon } from 'product-ui/src/components/atoms/NaveeIcon/getWebsiteCategoryIcon';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

export const NewWebsiteFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, name, value },
  onClickRemove,
}: DisplayFilterComponentProps) => {
  const websiteTag = (
    <Tag
      tag={
        new TagModel({
          name: (value as Domain.Website.WebsiteSuggestion).label,
          id: (value as Domain.Website.WebsiteSuggestion).id,
          tagType: 'website',
        })
      }
      key={uuid}
      iconPrefix={<NaveeIcon.WorldWww width={12} height={12} />}
      onClickRemove={onClickRemove}
      excluded={name === FilterTypeEnum.websiteExclude}
    />
  );
  const categoryTag = (
    <Tag
      tag={{
        name: (value as Domain.WebsiteCategory.WebsiteCategory).name,
      }}
      key={uuid}
      iconPrefix={
        getWebsiteCategoryIcon(
          (value as Domain.WebsiteCategory.WebsiteCategory).name,
        ) ?? undefined
      }
      onClickRemove={onClickRemove}
      excluded={name === FilterTypeEnum.categoryExclude}
    />
  );
  const filterTypeComponentMap = {
    [FilterTypeEnum.website]: websiteTag,
    [FilterTypeEnum.websiteExclude]: websiteTag,
    [FilterTypeEnum.category]: categoryTag,
    [FilterTypeEnum.categoryExclude]: categoryTag,
  };

  return filterTypeComponentMap[name] || null;
};
