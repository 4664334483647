export class ModerationReasonLocation {
  x: number;

  y: number;

  height: number;

  data: {
    index: number;
  };

  width: number;

  new: boolean;

  isChanging: boolean;

  constructor(
    prop: Pick<
      ModerationReasonLocation,
      'height' | 'isChanging' | 'new' | 'width' | 'x' | 'y'
    >,
  ) {
    this.height = prop.height;
    this.isChanging = prop.isChanging;
    this.new = prop.new;
    this.width = prop.width;
    this.x = prop.x;
    this.y = prop.y;
    this.data = {
      index: 0,
    };
  }

  static fixStringifiedJson = (string) =>
    string
      .replaceAll("'", '"')
      .replace(/False/g, 'false')
      .replace(/True/g, 'true');

  static staticCreateFromString(prop: string) {
    const parsedProp = JSON.parse(
      ModerationReasonLocation.fixStringifiedJson(prop),
    );
    return new ModerationReasonLocation({
      height: parsedProp.height,
      isChanging: parsedProp.isChanging,
      new: parsedProp.new,
      width: parsedProp.width,
      x: parsedProp.x,
      y: parsedProp.y,
    });
  }
}
