import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
} from '../../../types/filters/AtomicFilters/Filter';

export const NewUploadedByFilterValue: DisplayFilterComponent = ({
  filterValue: { value },
  onClickRemove,
}: DisplayFilterComponentProps) => (
  <Tag
    tag={{
      name: `Uploaded by by ${(value as UserLightModel).name}`,
      id: (value as UserLightModel).id,
    }}
    iconPrefix={<NaveeIcon.Group />}
    onClickRemove={onClickRemove}
  />
);
