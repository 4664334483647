import { v4 as uuid } from 'uuid';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { NewWebsiteFilterValue } from '../../../../components/Filters/NewWebsiteFilter/NewWebsiteFilterValue';
import { NewWebsiteFilter } from '../../../../components/Filters/NewWebsiteFilter/index';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import {
  WebsiteFilterValue,
  readFilterFromQueryProps,
} from './WebsiteFilterValue';

export interface WebsiteFilterQueryValue extends QueryValue {
  website_id: Array<WebsiteSuggestion>;
  website_id_to_exclude: Array<WebsiteSuggestion>;
  website_category_id: Array<WebsiteCategory>;
  website_category_id_to_exclude: Array<WebsiteCategory>;
}
export class WebsiteFilter implements Filter {
  uuid: string;

  label: string;

  value: WebsiteFilterValue;

  constructor(
    params?: Partial<Pick<WebsiteFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || WebsiteFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [
      ...this.value.websiteInclude.map((website) => ({
        name: FilterTypeEnum.website,
        value: website,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.website + website.id,
      })),
      ...this.value.websiteExclude.map((website) => ({
        name: FilterTypeEnum.websiteExclude,
        value: website,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.websiteExclude + website.id,
      })),
      ...this.value.websiteCategoryInclude.map((category) => ({
        name: FilterTypeEnum.category,
        value: category,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.category + category.id,
      })),
      ...this.value.websiteCategoryExclude.map((category) => ({
        name: FilterTypeEnum.categoryExclude,
        value: category,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.categoryExclude + category.id,
      })),
    ];
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.website) {
      this.value = this.value.removeIncludedWebsite(
        removingFilter.value as WebsiteSuggestion,
      );
    } else if (removingFilter.name === FilterTypeEnum.websiteExclude) {
      this.value = this.value.removeExcludedWebsite(
        removingFilter.value as WebsiteSuggestion,
      );
    } else if (removingFilter.name === FilterTypeEnum.category) {
      this.value = this.value.removeIncludedCategory(
        removingFilter.value as WebsiteCategory,
      );
    } else if (removingFilter.name === FilterTypeEnum.categoryExclude) {
      this.value = this.value.removeExcludedCategory(
        removingFilter.value as WebsiteCategory,
      );
    }
    return new WebsiteFilter(this);
  }

  get component() {
    return NewWebsiteFilter;
  }

  get displayFilterComponent() {
    return NewWebsiteFilterValue;
  }

  get queryFilterValue(): WebsiteFilterQueryValue {
    return {
      website_id: this.value.websiteInclude,
      website_id_to_exclude: this.value.websiteExclude,
      website_category_id: this.value.websiteCategoryInclude,
      website_category_id_to_exclude: this.value.websiteCategoryExclude,
    };
  }

  static readFilterFromQuery(props?: readFilterFromQueryProps): WebsiteFilter {
    return new WebsiteFilter({
      value: WebsiteFilterValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): WebsiteFilter {
    return new WebsiteFilter({
      value: WebsiteFilterValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
