import { v4 as uuid } from 'uuid';
import { Tag } from 'product-types/src/domain/tag/Tag';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { NewTagsFilter } from '../../../../components/Filters/NewTagsFilter/index';
import { NewTagsFilterValue } from '../../../../components/Filters/NewTagsFilter/NewTagsFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { TagsFilterValue, tagsFilterParams } from './TagsFilterValue';

export interface TagsFilterQueryValue extends QueryValue {
  account_tags_to_exclude: Array<Tag>;
  account_tags_to_include: Array<Tag>;
  post_tags_to_exclude: Array<Tag>;
  post_tags_to_include: Array<Tag>;
  duplicated_group_tags_to_exclude: Array<Tag>;
  duplicated_group_tags_to_include: Array<Tag>;
  cluster_tags_to_exclude: Array<Tag>;
  cluster_tags_to_include: Array<Tag>;
  post_tag_name_contains_to_include: Array<Tag>;
  image_tag_name_contains_to_include: Array<Tag>;
  account_tag_name_contains_to_include: Array<Tag>;
  cluster_tag_name_contains_to_include: Array<Tag>;
  post_tag_name_contains_to_exclude: Array<Tag>;
  image_tag_name_contains_to_exclude: Array<Tag>;
  account_tag_name_contains_to_exclude: Array<Tag>;
  cluster_tag_name_contains_to_exclude: Array<Tag>;
}

export class TagsFilter implements Filter {
  uuid: string;

  label: string;

  value: TagsFilterValue;

  constructor(params?: Partial<Pick<TagsFilter, 'uuid' | 'label' | 'value'>>) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || TagsFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [
      ...this.value.accountExclude.map((account) => {
        if (account.__isNew__) {
          return {
            name: FilterTypeEnum.accountTagsExcludeSearch,
            value: account,
            uuid: this.uuid,
            key:
              this.uuid + FilterTypeEnum.accountTagsExcludeSearch + account.id,
          };
        }
        return {
          name: FilterTypeEnum.accountTagsExclude,
          value: account,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.accountTagsExclude + account.id,
        };
      }),
      ...this.value.accountInclude.map((account) => {
        if (account.__isNew__) {
          return {
            name: FilterTypeEnum.accountTagsIncludeSearch,
            value: account,
            uuid: this.uuid,
            key:
              this.uuid + FilterTypeEnum.accountTagsIncludeSearch + account.id,
          };
        }
        return {
          name: FilterTypeEnum.accountTagsInclude,
          value: account,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.accountTagsInclude + account.id,
        };
      }),
      ...this.value.postExclude.map((post) => {
        if (post.__isNew__) {
          return {
            name: FilterTypeEnum.postTagsExcludeSearch,
            value: post,
            uuid: this.uuid,
            key: this.uuid + FilterTypeEnum.postTagsExcludeSearch + post.id,
          };
        }
        return {
          name: FilterTypeEnum.postTagsExclude,
          value: post,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.postTagsExclude + post.id,
        };
      }),
      ...this.value.postInclude.map((post) => {
        if (post.__isNew__) {
          return {
            name: FilterTypeEnum.postTagsIncludeSearch,
            value: post,
            uuid: this.uuid,
            key: this.uuid + FilterTypeEnum.postTagsIncludeSearch + post.id,
          };
        }
        return {
          name: FilterTypeEnum.postTagsInclude,
          value: post,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.postTagsInclude + post.id,
        };
      }),
      ...this.value.duplicatedGroupExclude.map((duplicatedGroup) => {
        if (duplicatedGroup.__isNew__) {
          return {
            name: FilterTypeEnum.duplicatedGroupTagsExcludeSearch,
            value: duplicatedGroup,
            uuid: this.uuid,
            key:
              this.uuid +
              FilterTypeEnum.duplicatedGroupTagsExcludeSearch +
              duplicatedGroup.id,
          };
        }
        return {
          name: FilterTypeEnum.duplicatedGroupTagsExclude,
          value: duplicatedGroup,
          uuid: this.uuid,
          key:
            this.uuid +
            FilterTypeEnum.duplicatedGroupTagsExclude +
            duplicatedGroup.id,
        };
      }),
      ...this.value.duplicatedGroupInclude.map((duplicatedGroup) => {
        if (duplicatedGroup.__isNew__) {
          return {
            name: FilterTypeEnum.duplicatedGroupTagsIncludeSearch,
            value: duplicatedGroup,
            uuid: this.uuid,
            key:
              this.uuid +
              FilterTypeEnum.duplicatedGroupTagsIncludeSearch +
              duplicatedGroup.id,
          };
        }
        return {
          name: FilterTypeEnum.duplicatedGroupTagsInclude,
          value: duplicatedGroup,
          uuid: this.uuid,
          key:
            this.uuid +
            FilterTypeEnum.duplicatedGroupTagsInclude +
            duplicatedGroup.id,
        };
      }),
      ...this.value.vendorExclude.map((vendor) => {
        if (vendor.__isNew__) {
          return {
            name: FilterTypeEnum.vendorTagsExcludeSearch,
            value: vendor,
            uuid: this.uuid,
            key: this.uuid + FilterTypeEnum.vendorTagsExcludeSearch + vendor.id,
          };
        }
        return {
          name: FilterTypeEnum.vendorTagsExclude,
          value: vendor,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.vendorTagsExclude + vendor.id,
        };
      }),
      ...this.value.vendorInclude.map((vendor) => {
        if (vendor.__isNew__) {
          return {
            name: FilterTypeEnum.vendorrTagsIncludeSearch,
            value: vendor,
            uuid: this.uuid,
            key:
              this.uuid + FilterTypeEnum.vendorrTagsIncludeSearch + vendor.id,
          };
        }
        return {
          name: FilterTypeEnum.vendorrTagsInclude,
          value: vendor,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.vendorrTagsInclude + vendor.id,
        };
      }),
    ];
  }

  get displayFilterComponent() {
    return NewTagsFilterValue;
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (
      removingFilter.name === FilterTypeEnum.accountTagsExclude ||
      removingFilter.name === FilterTypeEnum.accountTagsExcludeSearch
    ) {
      this.value = this.value.removeAccountExclude(removingFilter.value as Tag);
    } else if (
      removingFilter.name === FilterTypeEnum.accountTagsInclude ||
      removingFilter.name === FilterTypeEnum.accountTagsIncludeSearch
    ) {
      this.value = this.value.removeAccountInclude(removingFilter.value as Tag);
    } else if (
      removingFilter.name === FilterTypeEnum.postTagsExclude ||
      removingFilter.name === FilterTypeEnum.postTagsExcludeSearch
    ) {
      this.value = this.value.removePostExclude(removingFilter.value as Tag);
    } else if (
      removingFilter.name === FilterTypeEnum.postTagsInclude ||
      removingFilter.name === FilterTypeEnum.postTagsIncludeSearch
    ) {
      this.value = this.value.removePostInclude(removingFilter.value as Tag);
    } else if (
      removingFilter.name === FilterTypeEnum.duplicatedGroupTagsExclude ||
      removingFilter.name === FilterTypeEnum.duplicatedGroupTagsExcludeSearch
    ) {
      this.value = this.value.removeDuplicatedGroupExclude(
        removingFilter.value as Tag,
      );
    } else if (
      removingFilter.name === FilterTypeEnum.duplicatedGroupTagsInclude ||
      removingFilter.name === FilterTypeEnum.duplicatedGroupTagsIncludeSearch
    ) {
      this.value = this.value.removeDuplicatedGroupInclude(
        removingFilter.value as Tag,
      );
    } else if (
      removingFilter.name === FilterTypeEnum.vendorTagsExclude ||
      removingFilter.name === FilterTypeEnum.vendorTagsExcludeSearch
    ) {
      this.value = this.value.removeVendorExclude(removingFilter.value as Tag);
    } else if (
      removingFilter.name === FilterTypeEnum.vendorrTagsInclude ||
      removingFilter.name === FilterTypeEnum.vendorrTagsIncludeSearch
    ) {
      this.value = this.value.removeVendorInclude(removingFilter.value as Tag);
    }
    return new TagsFilter(this);
  }

  get component() {
    return NewTagsFilter;
  }

  get queryFilterValue(): TagsFilterQueryValue {
    return {
      account_tags_to_exclude: this.value.accountExclude.filter(
        (p) => !p.__isNew__,
      ),
      account_tags_to_include: this.value.accountInclude.filter(
        (p) => !p.__isNew__,
      ),
      post_tags_to_exclude: this.value.postExclude.filter((p) => !p.__isNew__),
      post_tags_to_include: this.value.postInclude.filter((p) => !p.__isNew__),
      duplicated_group_tags_to_exclude:
        this.value.duplicatedGroupExclude.filter((p) => !p.__isNew__),
      duplicated_group_tags_to_include:
        this.value.duplicatedGroupInclude.filter((p) => !p.__isNew__),
      cluster_tags_to_exclude: this.value.vendorExclude.filter(
        (p) => !p.__isNew__,
      ),
      cluster_tags_to_include: this.value.vendorInclude.filter(
        (p) => !p.__isNew__,
      ),
      post_tag_name_contains_to_include: this.value.postInclude.filter(
        (p) => p.__isNew__,
      ),
      image_tag_name_contains_to_include:
        this.value.duplicatedGroupInclude.filter((p) => p.__isNew__),
      account_tag_name_contains_to_include: this.value.accountInclude.filter(
        (p) => p.__isNew__,
      ),
      cluster_tag_name_contains_to_include: this.value.vendorInclude.filter(
        (p) => p.__isNew__,
      ),
      post_tag_name_contains_to_exclude: this.value.postExclude.filter(
        (p) => p.__isNew__,
      ),
      image_tag_name_contains_to_exclude:
        this.value.duplicatedGroupExclude.filter((p) => p.__isNew__),
      account_tag_name_contains_to_exclude: this.value.accountExclude.filter(
        (p) => p.__isNew__,
      ),
      cluster_tag_name_contains_to_exclude: this.value.vendorExclude.filter(
        (p) => p.__isNew__,
      ),
    };
  }

  static readFilterFromQuery(props?: tagsFilterParams): TagsFilter {
    return new TagsFilter({
      value: TagsFilterValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    props?: tagsFilterParams,
  ): TagsFilter {
    return new TagsFilter({
      value: TagsFilterValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
