import { useEffect } from 'react';
import {
  GetNumberOfActiveTabs,
  GetNumberOfActiveTabsType,
} from 'types/sharedWorker/messages/GetNumberOfActiveTabs';
import { Message } from 'types/sharedWorker/messages/Message';
import { sharedWorkerClient } from 'workers/shared';

export const useBeforeUnload = () => {
  useEffect(() => {
    const onbeforeunloadFn = () => {
      const listener = (message: Message) => {
        if (message.type === GetNumberOfActiveTabsType) {
          if (message.payload?.data === 1) {
            document.cookie =
              'connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
          }
        }
      };
      sharedWorkerClient.removeMessageListener(listener);
      setTimeout(() => {
        sharedWorkerClient.removeMessageListener(listener);
      }, 200);
      sharedWorkerClient.addMessageListener(listener);
      sharedWorkerClient.sendMessage(
        new GetNumberOfActiveTabs({
          payload: {
            data: 0,
          },
        }),
      );
    };

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, []);
};
