import { LabelSaveModel } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdateImageLabelRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
  data: LabelSaveModel;
}

interface UploadImageLabelResponse {}

const updateImageLabel = new Endpoint<
  UpdateImageLabelRequestModel,
  UploadImageLabelResponse
>({
  url: '/api/me/organisation/labels/image/:id',
  method: 'PUT',
  headers: {},
});

export default updateImageLabel;
