import {
  GeographySelectOptions,
  Geographic,
} from 'product-types/src/domain/geo/Geo';
import {
  makeArrayUniqueByKey,
  makeArrayUniqueByValue,
} from 'product-utils/src/array';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  geos: GeographySelectOptions;
}

export class EstimatedGeoFilterValue implements FilterValue {
  geographyToInclude: Array<Geographic>;

  geographyToExclude: Array<Geographic>;

  constructor(
    params: Pick<
      EstimatedGeoFilterValue,
      'geographyToInclude' | 'geographyToExclude'
    >,
  ) {
    this.geographyToInclude = params.geographyToInclude;
    this.geographyToExclude = params.geographyToExclude;
  }

  setIncludeGeography(value: Geographic[]) {
    return new EstimatedGeoFilterValue({
      geographyToInclude: value,
      geographyToExclude: this.geographyToExclude,
    });
  }

  addIncludeGeography(value: Geographic[]) {
    return new EstimatedGeoFilterValue({
      geographyToInclude: makeArrayUniqueByKey(
        this.geographyToInclude.concat(value),
        'value',
      ),
      geographyToExclude: this.geographyToExclude,
    });
  }

  hasIncludedGeography(value: Geographic) {
    return this.geographyToInclude.some(
      (includedGeography) => includedGeography.key === value.key,
    );
  }

  clearIncludeGeography() {
    return new EstimatedGeoFilterValue({
      geographyToInclude: [],
      geographyToExclude: this.geographyToExclude,
    });
  }

  setExcludeGeography(value: Geographic[]) {
    return new EstimatedGeoFilterValue({
      geographyToInclude: this.geographyToInclude,
      geographyToExclude: value,
    });
  }

  addExcludeGeography(value: Geographic[]) {
    return new EstimatedGeoFilterValue({
      geographyToInclude: this.geographyToInclude,
      geographyToExclude: makeArrayUniqueByKey(
        this.geographyToExclude.concat(value),
        'value',
      ),
    });
  }

  clearExcludeGeography() {
    return new EstimatedGeoFilterValue({
      geographyToInclude: this.geographyToInclude,
      geographyToExclude: [],
    });
  }

  removeIncludedGeography(value: Geographic) {
    return new EstimatedGeoFilterValue({
      geographyToInclude: this.geographyToInclude.filter(
        (includedGeography) => includedGeography.value !== value.value,
      ),
      geographyToExclude: this.geographyToExclude,
    });
  }

  hasExcludedGeography(value: Geographic) {
    return this.geographyToExclude.some(
      (excludedGeography) => excludedGeography.key === value.key,
    );
  }

  removeExcludedGeography(value: Geographic) {
    return new EstimatedGeoFilterValue({
      geographyToInclude: this.geographyToInclude,
      geographyToExclude: this.geographyToExclude.filter(
        (excludedGeography) => excludedGeography.value !== value.value,
      ),
    });
  }

  static get defaultValue(): EstimatedGeoFilterValue {
    return new EstimatedGeoFilterValue({
      geographyToInclude: new Array<Geographic>(),
      geographyToExclude: new Array<Geographic>(),
    });
  }

  static readFilterFromQuery(
    props?: readFilterFromQueryProps,
  ): EstimatedGeoFilterValue {
    const urlParams = new URLSearchParams(window.location.search);
    const geographyToInclude = makeArrayUniqueByValue(
      urlParams.getAll('geography_to_include'),
    );
    const geographyToExclude = makeArrayUniqueByValue(
      urlParams.getAll('geography_to_exclude'),
    );
    const listOfGeo = props
      ? [...(props?.geos?.countries || []), ...(props?.geos?.zones || [])]
      : [];
    return new EstimatedGeoFilterValue({
      geographyToInclude: (geographyToInclude || [])
        .map((geoToInclude: string) =>
          listOfGeo.find((geo) => geo.value === geoToInclude),
        )
        .filter((item) => item !== undefined) as Geographic[],
      geographyToExclude: (geographyToExclude || [])
        .map((getToExclude: string) =>
          listOfGeo.find((geo) => geo.value === getToExclude),
        )
        .filter((item) => item !== undefined) as Geographic[],
    });
  }

  static readFromSavedFilter(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): EstimatedGeoFilterValue {
    const geographyToInclude = makeArrayUniqueByValue(
      savedFilter.geographyToInclude || [],
    );
    const geographyToExclude = makeArrayUniqueByValue(
      savedFilter.geographyToExclude || [],
    );
    const listOfGeo = props
      ? [...(props?.geos?.countries || []), ...(props?.geos?.zones || [])]
      : [];
    return new EstimatedGeoFilterValue({
      geographyToInclude: (geographyToInclude || [])
        .map((geoToInclude: string) =>
          listOfGeo.find((geo) => geo.value === geoToInclude),
        )
        .filter((item) => item !== undefined) as Geographic[],
      geographyToExclude: (geographyToExclude || [])
        .map((getToExclude: string) =>
          listOfGeo.find((geo) => geo.value === getToExclude),
        )
        .filter((item) => item !== undefined) as Geographic[],
    });
  }
}
