import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import setContacts from './setContacts';
import getAccounts from './getAccounts';
import getModerationAccount from './getModerationAccount';
import accountImages from './accountImages';
import accountsModeration from './accountsModeration';
import comments from './comments';
import updateAccount from './updateAccount';
import exportAccounts from './exportAccounts';
import relatedAccounts from './relatedAccounts';
import updateTakedownStatus from './updateTakedownStatus';
import search from './search';
import accountsPostsStats from './accountsPostsStats';

export interface AccountEndpoints extends EndpointContainer {
  setContacts: typeof setContacts;
  search: typeof search;
  getAccounts: typeof getAccounts;
  exportAccounts: typeof exportAccounts;
  getModerationAccount: typeof getModerationAccount;
  accountsModeration: typeof accountsModeration;
  accountImages: typeof accountImages;
  comments: typeof comments;
  updateAccount: typeof updateAccount;
  relatedAccounts: typeof relatedAccounts;
  updateTakedownStatus: typeof updateTakedownStatus;
  accountsPostsStats: typeof accountsPostsStats;
}

const accounts: AccountEndpoints = {
  setContacts,
  search,
  getAccounts,
  exportAccounts,
  getModerationAccount,
  accountImages,
  accountsModeration,
  comments,
  updateAccount,
  relatedAccounts,
  updateTakedownStatus,
  accountsPostsStats,
  injectHTTPClient(client: HttpClient) {
    setContacts.injectHTTPClient(client);
    search.injectHTTPClient(client);
    getAccounts.injectHTTPClient(client);
    exportAccounts.injectHTTPClient(client);
    getModerationAccount.injectHTTPClient(client);
    accountsModeration.injectHTTPClient(client);
    accountImages.injectHTTPClient(client);
    comments.injectHTTPClient(client);
    updateAccount.injectHTTPClient(client);
    relatedAccounts.injectHTTPClient(client);
    updateTakedownStatus.injectHTTPClient(client);
    accountsPostsStats.injectHTTPClient(client);
  },
};

export default accounts;
