import { Endpoint } from 'types/network/Http/endpoint';

const downloadPostManualExcelFile = new Endpoint<
  NonNullable<unknown>,
  NonNullable<unknown>
>({
  url: '/api/me/uploads/post/manual/file',
  method: 'GET',
  headers: {},
});

export default downloadPostManualExcelFile;
