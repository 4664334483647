import { Popover } from 'antd';
import React, { useState } from 'react';
import UserMenuContent from './UserMenuContent';
import UserMenuPopover from './UserMenuPopover';

interface UserMenuProps {
  collapsed: boolean;
}

export default function UserMenu({ collapsed }: UserMenuProps) {
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      overlayClassName="navee-sidebar-actions-block"
      placement="rightBottom"
      onOpenChange={setVisible}
      content={<UserMenuPopover visible={visible} />}
      trigger="click"
    >
      <UserMenuContent collapsed={collapsed} />
    </Popover>
  );
}
