import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationPostsResponseModel } from 'product-types/src/domain/dashboard/DashboardModerationStatisticsPostsModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

interface ModerationPostsRequestModel extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const getModerationPosts = new Endpoint<
  ModerationPostsRequestModel,
  ModerationPostsResponseModel
>({
  url: '/api/me/stats/moderation/posts',
  method: 'GET',
  headers: {},
});

export default getModerationPosts;
