import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ClusterModerationRawModel } from 'product-types/src/domain/cluster/ClusterModerationModer';

interface ClusterRequestModel extends EndpointRequests {
  urlParams: { id: string };
}

const cluster = new Endpoint<ClusterRequestModel, ClusterModerationRawModel>({
  url: '/api/me/clusters/:id',
  method: 'GET',
  headers: {},
});

export default cluster;
