import * as timeago from 'timeago.js';

export const format_time_ago = (timestamp: Date) =>
  timeago.format(timestamp.toISOString());

export const format_time_ago_legacy = (timestamp: number) =>
  timeago.format(
    new Date(parseInt((timestamp * 1000).toString(), 10)).toISOString(),
  );

export const formatTimestamp = (
  timestamp,
  customOptions = {},
  defaultOptions = {
    timeZone: 'GMT',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
) => {
  const date = new Date(timestamp);
  const options = { ...defaultOptions, ...customOptions };
  const formattedDate = date.toLocaleString('en-US', options as any);
  const gmtOffset = date.getTimezoneOffset() / 60; // Get the GMT offset in hours
  const gmtInfo = `GMT${gmtOffset >= 0 ? '+' : '-'}${Math.abs(gmtOffset)}`;

  return {
    formattedDate,
    gmtInfo,
  };
};
