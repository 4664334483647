import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

export interface ModeratedPostsResponse {
  posts_auto_moderated: number;
  posts_moderated: number;
}

interface ModeratedPostsRequestParams extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface ModeratedPostsRequest extends EndpointRequests {
  params: ModeratedPostsRequestParams;
}

const moderatedPosts = new Endpoint<
  ModeratedPostsRequest,
  ModeratedPostsResponse
>({
  url: '/api/me/stats/moderated_posts',
  method: 'GET',
  headers: {},
});

export default moderatedPosts;
