import { Endpoint } from 'types/network/Http/endpoint';
import { UserLightRawModel } from 'product-types/src/domain/user/UserLightModel';

export type UsersResponse = Array<UserLightRawModel>;

const getNaveeUsers = new Endpoint<any, UsersResponse>({
  url: '/api/me/navee_users',
  method: 'GET',
  headers: {},
});

export default getNaveeUsers;
