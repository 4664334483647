import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const NaveeSidebar = styled(Sider)`
  width: 222px;
  border-right: 1px solid #ebebf2;
  background: #ffffff;
  box-shadow: 0px 1px 0px #ebebf2;
  font-weight: 300;
  font-size: 15px;

  .ant-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #a1a0ae;
  }
  .ant-menu-item {
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    border-radius: 0;
    margin: 0px;
    margin-inline: 0px;
    width: 100%;
    height: 56px;
    padding: 16px 24px;
    justify-content: center;
    &.ant-menu-item-selected {
      background: var(--green-tints-green-1, #f0fff8);
      border-right: 2px solid var(--primary-green, #6bffb9);
      .navee_svg {
        path {
          fill: var(--primary-black);
        }
      }
    }
    .ant-menu-title-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 0.5rem;
      padding-top: 3px;
      overflow: unset !important;
      & [class*='TypographyStyled'] {
        overflow: hidden;
      }
    }

    .navee_svg {
      font-size: 24px;
      flex-shrink: 0;

      path {
        fill: var(--neutral-grey-500);
      }

      &.menu {
        path {
          fill: var(--neutral-grey-600);
        }
      }
    }

    .anticon {
      display: flex !important;
    }

    span {
      font-weight: 300;
      font-size: 15px;
      color: var(--black-mate);
    }

    &:active {
      background: none;
    }
  }

  .ant-menu-item-selected {
    background: var(--mint-green);

    .navee_svg {
      path {
        fill: var(--mint-green);
      }
    }

    span {
      color: var(--black-mate) !important;
      font-weight: 700;
      font-size: 15px;
    }
  }

  .ant-menu-item-disabled {
    cursor: auto;
  }

  .user-menu-block {
    margin-bottom: 16px;
    .anticon {
      margin: 0;
    }
  }

  .logo-block {
    height: 4.5rem;
    padding: 0.5rem 1.5rem;
    display: flex;
    margin-bottom: 1.5rem;

    .ant-menu-title-content {
      padding-top: 0;
    }
  }

  .upload-btn-block {
    margin-top: 16px;
    margin-bottom: 32px !important;
    padding-left: 16px !important;
    svg {
      fill: black;
    }
  }
`;

export const UserSettingsBlock = styled.div`
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: #e6f7ff;
  }
`;
