/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Label } from 'product-types/src/domain/label/Label';
import { makeArrayUniqueByKey } from 'product-utils/src/array';
import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import { Flex } from 'antd';
import { FeedFooterContext } from '../../../layout/FeedFooter/context';
import {
  LabelFilterValue,
  LabelTypes,
} from '../../../types/filters/AtomicFiltersImplementation/Label/LabelFilterValue';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import { LabelFilter } from '../../../types/filters/AtomicFiltersImplementation/Label/LabelFilter';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import './style.css';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { ExclusionFilter } from './ExclusionFilter';

type LabelType = 'post' | 'account' | 'image';

export interface LabelFilterProps extends FilterParams {
  value: LabelFilter;
  onChange: (v: Filter) => void;
}

export const NewLabelExclusionFilter = (props: LabelFilterProps) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const [forceClose, updateForceClose] = useState({});
  const contextFooter = useContext(FeedFooterContext);
  const labels = context.labels || {
    account: [],
    post: [],
    image: [],
  };

  useEffect(() => {
    if (contextFooter.fetchingStatus === FetchableDataState.LOADING) {
      updateForceClose({});
    }
  }, [contextFooter.fetchingStatus]);

  const handleCheckboxChange = useCallback(
    (label, type: LabelType, checked: boolean) => {
      let labelFilterValue: LabelFilterValue;
      const setLabelFnMap = {
        post: props.value.value.setPostLabel,
        account: props.value.value.setAccountLabel,
        image: props.value.value.setImagetLabel,
      };
      const addLabelFnMap = {
        post: props.value.value.addPostLabel,
        account: props.value.value.addAccountLabel,
        image: props.value.value.addImageLabel,
      };
      const removeLabelFnMap = {
        post: props.value.value.removePostLabel,
        account: props.value.value.removeAccountLabel,
        image: props.value.value.removeImageLabel,
      };

      if (checked) {
        const lastCheckedItem =
          labels[type].length - 1 === props.value.value[type].length;
        labelFilterValue = lastCheckedItem
          ? setLabelFnMap[type].bind(props.value.value)(
              labels[type],
              LabelTypes.All,
            )
          : addLabelFnMap[type].bind(props.value.value)(label);
      } else {
        labelFilterValue = removeLabelFnMap[type].bind(props.value.value)(
          label,
        );
      }

      props.onChange(
        new LabelFilter({ ...props.value, value: labelFilterValue }),
      );
    },
    [props.value, props.onChange, labels],
  );

  const handleAllCheckboxChange = (checked: boolean, type: LabelType) => {
    const newLabels: Label[] = checked ? labels[type] : [];
    const labelType = checked ? LabelTypes.All : LabelTypes.Null;
    switch (type) {
      case 'post':
        props.onChange(
          new LabelFilter({
            ...props.value,
            value: props.value.value.setPostLabel(newLabels, labelType),
          }),
        );
        break;
      case 'account':
        props.onChange(
          new LabelFilter({
            ...props.value,
            value: props.value.value.setAccountLabel(newLabels, labelType),
          }),
        );
        break;
      case 'image':
        props.onChange(
          new LabelFilter({
            ...props.value,
            value: props.value.value.setImagetLabel(newLabels, labelType),
          }),
        );
        break;
      default:
        break;
    }
  };

  const [allPostInfringementChecked, countOfPostInfrigementLabels] =
    useMemo(() => {
      const postLabels = new Set(props.value.value.post);
      const allInfrigementPostLabels = labels.post.filter(
        (label) => label.is_actionable_infringement,
      );
      return [
        allInfrigementPostLabels.every((label) => postLabels.has(label)) &&
          allInfrigementPostLabels.length,
        allInfrigementPostLabels.length,
      ];
    }, [labels.post, props.value.value.post]);

  const [allImageInfrigementChecked, countOfImageInfrigementLabels] =
    useMemo(() => {
      const imageLabels = new Set(props.value.value.image);
      const allInfrigementImageLabels = labels.image.filter(
        (label) => label.is_actionable_infringement,
      );
      return [
        allInfrigementImageLabels.every((label) => imageLabels.has(label)) &&
          allInfrigementImageLabels.length,
        allInfrigementImageLabels.length,
      ];
    }, [labels.image, props.value.value.image]);

  const [allAccountInfigingmentChecked, countOfAccountInfrigementLabels] =
    useMemo(() => {
      const accountLabels = new Set(props.value.value.account);
      const allInfrigementAccountLabels = labels.account.filter(
        (label) => label.is_infringement,
      );
      return [
        allInfrigementAccountLabels.every((label) =>
          accountLabels.has(label),
        ) && allInfrigementAccountLabels.length,
        allInfrigementAccountLabels.length,
      ];
    }, [labels.account, props.value.value.account]);

  const handleCheckAllInfirement = (checked: boolean, type: LabelType) => {
    let newLabels: Label[] = new Array<Label>();
    if (type === 'post' || type === 'image') {
      newLabels = checked
        ? makeArrayUniqueByKey(
            [
              ...props.value.value[type],
              ...labels[type].filter(
                (label) => label.is_actionable_infringement,
              ),
            ] as Array<never>,
            'id',
          )
        : [];
    } else {
      newLabels = checked
        ? makeArrayUniqueByKey(
            [
              ...props.value.value[type],
              ...labels[type].filter((label) => label.is_infringement),
            ] as Array<never>,
            'id',
          )
        : [];
    }
    const labelType = checked ? LabelTypes.AllInfigement : LabelTypes.Null;
    switch (type) {
      case 'post':
        props.onChange(
          new LabelFilter({
            ...props.value,
            value: props.value.value.setPostLabel(newLabels, labelType),
          }),
        );
        break;
      case 'account':
        props.onChange(
          new LabelFilter({
            ...props.value,
            value: props.value.value.setAccountLabel(newLabels, labelType),
          }),
        );
        break;
      case 'image':
        props.onChange(
          new LabelFilter({
            ...props.value,
            value: props.value.value.setImagetLabel(newLabels, labelType),
          }),
        );
        break;
      default:
        break;
    }
  };

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          padding: '1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <ExclusionFilter
          label="Post"
          type="post"
          hasLabel={(type, label) => props.value.value.hasLabel(type, label)}
          countInfringementLabels={countOfPostInfrigementLabels}
          selectedLabels={props.value.value.post}
          allLabels={labels.post}
          handleAllCheckboxChange={handleAllCheckboxChange}
          handleCheckAllInfirement={handleCheckAllInfirement}
          handleCheckboxChange={handleCheckboxChange}
          allInfingementChecked={allPostInfringementChecked}
        />
        <ExclusionFilter
          label="Image"
          type="image"
          hasLabel={(type, label) => props.value.value.hasLabel(type, label)}
          countInfringementLabels={countOfImageInfrigementLabels}
          selectedLabels={props.value.value.image}
          allLabels={labels.image}
          handleAllCheckboxChange={handleAllCheckboxChange}
          handleCheckAllInfirement={handleCheckAllInfirement}
          handleCheckboxChange={handleCheckboxChange}
          allInfingementChecked={allImageInfrigementChecked}
        />
        <ExclusionFilter
          label="Account/Website"
          type="account"
          hasLabel={(type, label) => props.value.value.hasLabel(type, label)}
          countInfringementLabels={countOfAccountInfrigementLabels}
          selectedLabels={props.value.value.account}
          allLabels={labels.account}
          handleAllCheckboxChange={handleAllCheckboxChange}
          handleCheckAllInfirement={handleCheckAllInfirement}
          handleCheckboxChange={handleCheckboxChange}
          allInfingementChecked={allAccountInfigingmentChecked}
        />
      </Flex>
    ),
    [
      props.value.value,
      handleAllCheckboxChange,
      handleCheckAllInfirement,
      handleCheckboxChange,
      labels.account,
      labels.image,
      labels.post,
    ],
  );
  return (
    <FilterWithMenuWrapper
      text="Label"
      forceClose={forceClose}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
      dataTestId="label-filter"
      renderer={renderer}
    />
  );
};
