import styled from 'styled-components';
import { Spin } from './Spin';

export const StyledSpin = styled(Spin)`
  text-align: center;
  border-radius: 4px;
  padding: 30px 50px;
  margin: auto;
  width: 100%;
  height: 100%;
`;
