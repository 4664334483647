import { useEffect, useRef } from 'react';

type KeyboardInteraction = (key: KeyboardEvent) => boolean;

interface useKeyboardScrollingProps {
  scrollableNode: HTMLElement | null;
}

export const pageDown: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'PageDown';
export const pageUp: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'PageUp';
export const arrowDown: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'ArrowDown';
export const arrowUp: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'ArrowUp';
export const homeKey: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'Home';
export const endKey: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'End';

export const useKeyboardScrolling = ({
  scrollableNode,
}: useKeyboardScrollingProps) => {
  const functionRef = useRef<(e: KeyboardEvent) => void>((event) => {
    if (scrollableNode === null) {
      return;
    }
    if (homeKey(event)) {
      scrollableNode.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else if (endKey(event)) {
      scrollableNode.scrollTo({
        top: scrollableNode.scrollHeight - scrollableNode.clientHeight,
        behavior: 'smooth',
      });
    } else if (pageDown(event)) {
      scrollableNode.scrollTo({
        top: Math.min(
          scrollableNode.scrollTop + scrollableNode.clientHeight,
          scrollableNode.scrollHeight,
        ),
        behavior: 'smooth',
      });
    } else if (pageUp(event)) {
      scrollableNode.scrollTo({
        top: Math.max(
          scrollableNode.scrollTop - scrollableNode.clientHeight,
          0,
        ),
        behavior: 'smooth',
      });
    } else if (arrowDown(event)) {
      scrollableNode.scrollTo({
        top: Math.min(
          scrollableNode.scrollTop + 50,
          scrollableNode.scrollHeight,
        ),
        behavior: 'smooth',
      });
    } else if (arrowUp(event)) {
      scrollableNode.scrollTo({
        top: Math.max(scrollableNode.scrollTop - 50, 0),
        behavior: 'smooth',
      });
    }
  });
  useEffect(() => {
    functionRef.current = (event) => {
      if (scrollableNode === null) {
        return;
      }
      if (homeKey(event)) {
        scrollableNode.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else if (endKey(event)) {
        scrollableNode.scrollTo({
          top: scrollableNode.scrollHeight - scrollableNode.clientHeight,
          behavior: 'smooth',
        });
      } else if (pageDown(event)) {
        scrollableNode.scrollTo({
          top: Math.min(
            scrollableNode.scrollTop + scrollableNode.clientHeight,
            scrollableNode.scrollHeight,
          ),
          behavior: 'smooth',
        });
      } else if (pageUp(event)) {
        scrollableNode.scrollTo({
          top: Math.max(
            scrollableNode.scrollTop - scrollableNode.clientHeight,
            0,
          ),
          behavior: 'smooth',
        });
      } else if (arrowDown(event)) {
        scrollableNode.scrollTo({
          top: Math.min(
            scrollableNode.scrollTop + 50,
            scrollableNode.scrollHeight,
          ),
          behavior: 'smooth',
        });
      } else if (arrowUp(event)) {
        scrollableNode.scrollTo({
          top: Math.max(scrollableNode.scrollTop - 50, 0),
          behavior: 'smooth',
        });
      }
    };
  }, [scrollableNode]);

  useEffect(() => {
    const func = (ev: KeyboardEvent) => functionRef.current(ev);
    document.addEventListener('keydown', func);
    return () => {
      document.removeEventListener('keydown', func);
    };
  }, []);
};
