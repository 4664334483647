export class Occurrences {
  post: number;

  account: number;

  vendor: number;

  constructor(props?: Pick<Occurrences, 'account' | 'post' | 'vendor'>) {
    this.post = props?.post ?? 0;
    this.account = props?.account ?? 0;
    this.vendor = props?.vendor ?? 0;
  }

  static get default() {
    return new Occurrences();
  }
}
