export enum UploadTypeEnum {
  all = '',
  post = 'post',
  duplicatedGroup = 'duplicated_group',
  account = 'poster,website',
}

export interface UploadType {
  value: UploadTypeEnum;
  label: string;
}

export const UploadTypeDictionary = {
  all: {
    label: 'All',
    value: UploadTypeEnum.all,
  },
  post: {
    label: 'Post',
    value: UploadTypeEnum.post,
  },
  duplicatedGroup: {
    label: 'Image',
    value: UploadTypeEnum.duplicatedGroup,
  },
  account: {
    label: 'Account',
    value: UploadTypeEnum.account,
  },
};

export const UploadTypeOptions: UploadType[] =
  Object.values(UploadTypeDictionary);

export const GetUploadTypeFilterOptionByValue = (value) =>
  UploadTypeOptions.find((uploadType) => uploadType.value === value);
