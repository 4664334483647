import { produce } from 'immer';
import { CanBeSelectable } from 'product-types/src/common/CanBeSelectable/CanBeSelectable';
import { CanBeModeratable } from 'product-types/src/common/Moderatable/Moderatable';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import {
  RESET_STATE,
  SET_FOOTER_FIELD,
  SET_ELEMENTS_TYPE,
  TOGGLE_ELEMENT,
  DESELECT_ELEMENTS,
  SELECT_ELEMENTS,
  UPDATE_SELECTED_TAGS,
} from './constants';

export enum SelectedElementTypeEnum {
  Image = 'image',
  None = 'none',
  Post = 'post',
  Cluster = 'cluster',
  Account = 'account',
  Website = 'website',
  UploadHistory = 'uploadHistory',
}

export type SelectedElementType = CanBeSelectable & CanBeModeratable;

export interface FeedFooterInitialState {
  type: SelectedElementTypeEnum;
  selectedElements: Array<SelectedElementType>;
  selectedElementsIds: Set<number | string>;
  tags_input: string;
  selected_tags: Array<TagModel>;
  tags_loading: boolean;
}

export const createInitialState: () => FeedFooterInitialState = () => ({
  selectedElements: [],
  type: SelectedElementTypeEnum.None,
  selectedElementsIds: new Set(),
  tags_input: '',
  selected_tags: [],
  tags_loading: false,
});

/* eslint-disable default-case, no-param-reassign */
const feedFooterReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case SET_ELEMENTS_TYPE: {
        draft.type = action.payload as SelectedElementTypeEnum;
        draft.selectedElements = [];
        draft.selectedElementsIds = new Set();
        break;
      }
      case TOGGLE_ELEMENT: {
        if (draft.selectedElementsIds.has(action.payload.id)) {
          draft.selectedElements = draft.selectedElements.filter(
            ({ id }) => id !== action.payload.id,
          );
        } else {
          draft.selectedElements = [
            ...draft.selectedElements,
            action.payload as SelectedElementType,
          ];
        }
        draft.selectedElementsIds = new Set(
          draft.selectedElements.map(({ id }) => id),
        );
        break;
      }
      case SELECT_ELEMENTS: {
        draft.selectedElements = [
          ...(action.payload as Array<SelectedElementType>),
        ];
        draft.selectedElementsIds = new Set(
          draft.selectedElements.map(({ id }) => id),
        );
        break;
      }
      case UPDATE_SELECTED_TAGS: {
        draft.selected_tags = action.tags;
        break;
      }
      case DESELECT_ELEMENTS: {
        draft.type = SelectedElementTypeEnum.None;
        draft.selectedElements = [];
        draft.selectedElementsIds = new Set();
        draft.selected_tags = [];
        draft.tags_input = '';
        break;
      }
      case SET_FOOTER_FIELD:
        draft[action.field] = action.value;
        break;
    }
  });

export default feedFooterReducer;
