import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

export interface DashboardWordCloudRequestParams
  extends CommonDashboardRequestParams {
  perpage: number;
  offset: number;
  reverse: boolean;
  sort_by: string;
  category_id: Array<number> | null;
}

export interface WordCloudRequest extends EndpointRequests {
  params: DashboardWordCloudRequestParams;
}

const xls = new Endpoint<WordCloudRequest, Blob>({
  url: '/api/me/exports/dashboard/xls',
  method: 'GET',
  headers: {
    responseType: 'blob',
  },
});

export default xls;
