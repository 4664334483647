import styled, { css } from 'styled-components';

export const SecondaryCheckboxStyles = (prefix = 'ant') => css`
  .${prefix}-checkbox-checked {
    .${prefix}-checkbox-inner {
      background-color: var(--primary-green);
      border: 2px solid var(--primary-green) !important;

      &::after {
        width: 6px;
        height: 10px;
      }
    }
  }

  &:hover {
    .${prefix}-checkbox-inner {
      border: 2px solid var(--primary-green) !important;
    }
  }

  .${prefix}-checkbox-inner::after {
    margin-left: 2px;
    border-color: black;
    border-radius: 1px;
  }

  .${prefix}-checkbox-indeterminate {
    .${prefix}-checkbox-inner {
      border-color: var(--primary-black);
      &::after {
        margin-left: 0;
        background-color: var(--primary-green);
        border-radius: 3px;
      }
    }
  }
`;

export const CheckboxBaseStyles = (prefix = 'ant', isRadio = false) => css`
  & .${prefix}-checkbox {
    width: 24px;
    height: 24px;
  }

  ${isRadio &&
  `.${prefix}-checkbox-wrapper {
    display: flex;
    align-items: center;
  }`}

  .${prefix}-checkbox-inner {
    width: 24px;
    height: 24px;
    ${!isRadio && 'border-radius: 0.5em;'};
    border-color: #c7c7c7;
    &:hover {
      border-color: #6bffb9;
    }
    &:focus {
      border-color: #6bffb9;
    }
  }

  .${prefix}-checkbox-checked .${prefix}-checkbox-inner {
    background-color: #1dcc70;
    border: none;
    ::after {
      border-color: 'black';
    }
  }

  .${prefix}-checkbox-input:focus + .${prefix}-checkbox-inner {
    border-color: #6bffb9;
  }
  .${prefix}-checkbox-indeterminate .${prefix}-checkbox-inner::after {
    background-color: #1dcc70;
    width: 50%;
    margin: 0;
    height: 50%;
  }

  &:hover {
    border-color: #6bffb9;
    .${prefix}-checkbox-inner {
      border-color: #6bffb9 !important;
    }
    .${prefix}-checkbox-checked {
      border-color: #6bffb9;

      .${prefix}-checkbox-inner {
        background-color: #6bffb9 !important;
      }

      &::after {
        border-color: #6bffb9;
      }
    }
  }

  .${prefix}-checkbox-inner::after {
    margin-left: 3px;
  }
  .${prefix}-checkbox-checked::after {
    border: none;
  }
`;

export const StyledCheckbox = styled.div<{ isRadio?: boolean }>`
  ${({ isRadio = false }) => CheckboxBaseStyles('ant', isRadio)};

  ${SecondaryCheckboxStyles()};
`;
