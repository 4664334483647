import { LabelSaveModel } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SavePostLabelRequestModel extends EndpointRequests {
  data: LabelSaveModel & { is_infringement: boolean };
}

interface SavePostLabelResponse {}

const savePostLabel = new Endpoint<
  SavePostLabelRequestModel,
  SavePostLabelResponse
>({
  url: '/api/me/organisation/labels/post',
  method: 'POST',
  headers: {},
});

export default savePostLabel;
