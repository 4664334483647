export const SET_FOOTER_INFO = 'app/FeedFooter/SET_FOOTER_INFO';
export const SET_FOOTER_FIELD = 'app/FeedFooter/SET_FOOTER_FIELD';
export const ADD_MEMBERS_TO_TAG = 'app/FeedFooter/ADD_MEMBERS_TO_TAG';
export const REMOVE_OBJECTS_FROM_TAG = 'app/FeedFooter/REMOVE_OBJECTS_FROM_TAG';
export const ADD_TAGS_TO_LIST = 'app/FeedFooter/ADD_TAGS_TO_LIST';
export const RESET_STATE = 'app/FeedFooter/RESET_STATE';

export const TOGGLE_ELEMENT = 'app/FeedFooter/TOGGLE_ELEMENT';
export const SET_ELEMENTS_TYPE = 'app/FeedFooter/SET_ELEMENTS_TYPE';
export const DESELECT_ELEMENTS = 'app/FeedFooter/DESELECT_ELEMENTS';
export const SELECT_ELEMENTS = 'app/FeedFooter/SELECT_ELEMENTS';
export const UPDATE_SELECTED_TAGS = 'app/FeedFooter/UPDATE_SELECTED_TAGS';
