import React, { forwardRef } from 'react';
import { InputProps } from 'antd/lib/input';
import { Input as AntdInput, InputRef } from 'antd';
import AntdTextArea, {
  TextAreaRef,
  TextAreaProps,
} from 'antd/lib/input/TextArea';
import { Testable } from '../Interfaces/Testable';

export const Input = forwardRef<InputRef, InputProps & Testable>(
  ({ dataTestId, ...props }, ref) => (
    <AntdInput ref={ref} {...props} data-testid={dataTestId} />
  ),
);

export const TextArea = forwardRef<TextAreaRef, TextAreaProps & Testable>(
  ({ dataTestId, ...props }, ref) => (
    <AntdTextArea ref={ref} {...props} data-testid={dataTestId} />
  ),
);
