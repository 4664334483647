import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import formatNumber from 'product-utils/src/typography';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

export const ProductInformationFilterValue: DisplayFilterComponent = (
  props: DisplayFilterComponentProps,
) => {
  const {
    filterValue: { name, value },
    onClickRemove,
  } = props;
  const priceTag =
    value !== '' ? (
      <Tag
        tag={{
          name:
            name === FilterTypeEnum.minimumPrice
              ? `Minimum Price: ${formatNumber(value as number)}`
              : `Maximum Price: ${formatNumber(value as number)}`,
          id: '',
        }}
        iconPrefix={<NaveeIcon.DollarIcon />}
        onClickRemove={onClickRemove}
      />
    ) : null;
  const stockTag =
    value !== '' ? (
      <Tag
        tag={{
          name:
            name === FilterTypeEnum.minimumStock
              ? `Min Stock: ${formatNumber(value as number)}`
              : `Max Stock: ${formatNumber(value as number)}`,
          id: '',
        }}
        iconPrefix={<NaveeIcon.ShadowFollower width={12} height={12} />}
        onClickRemove={onClickRemove}
      />
    ) : null;
  const filterTypeTagMap = {
    [FilterTypeEnum.minimumPrice]: priceTag,
    [FilterTypeEnum.maximumPrice]: priceTag,
    [FilterTypeEnum.maximumStock]: stockTag,
    [FilterTypeEnum.minimumStock]: stockTag,
  };

  return filterTypeTagMap[name] || null;
};
