import { GeographySelectOptions } from 'product-types/src/domain/geo/Geo';
import { createContext } from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { DisplayingFilterValue } from 'types/filters/AtomicFilters/DisplayingFilterValue';
import { FiltersContainer } from 'types/filters/MoleculesFilter/MolecileFilter';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { SearchItemOption } from '../../types/filters/AtomicFiltersImplementation/Search/SearchItem';

export interface NewFilterProviderContext {
  geographySelectOptions?: GeographySelectOptions | null;
  availableWebsiteCategories?: Array<Domain.WebsiteCategory.WebsiteCategory> | null;
  labels?: {
    post: Domain.Label.Label[];
    image: Domain.Label.Label[];
    account: Domain.Label.Label[];
  };
  tags?: {
    account?: Domain.Tag.TagModel[];
    post?: Domain.Tag.TagModel[];
    duplicatedGroup?: Domain.Tag.TagModel[];
    vendor?: Domain.Tag.TagModel[];
    uploadHistory?: Domain.Tag.TagModel[];
  };
  users?: Array<UserLightModel> | null;
  availableCategories?: Domain.ProductCategory.ProductCategoryTree | null;
  moderationReasons?: Array<Domain.ModerationReason.ModerationReason> | null;
  websites?: Array<WebsiteSuggestion>;
  onSelectFilter: (arg0: DisplayingFilterValue) => any;
  onChange: (arg0: any) => any;
  showApplyButton?: boolean;
  savedFilters?: Array<SavedFilterModel>;
  applyFilters?: () => void;
  filters?: FiltersContainer;
  searchBarOptions: {
    AUTOCOMPLETE_ID_OPTION: Array<SearchItemOption>;
    AUTOCOMPLETE_OPTION: Array<SearchItemOption>;
    AUTOCOMPLETE_STRING_OPTION: Array<SearchItemOption>;
  };
}

export const FilterProviderContext = createContext<NewFilterProviderContext>({
  searchBarOptions: {
    AUTOCOMPLETE_ID_OPTION: [],
    AUTOCOMPLETE_OPTION: [],
    AUTOCOMPLETE_STRING_OPTION: [],
  },
  onSelectFilter: (val) => {
    console.trace(val);
    throw new Error('Pleace specify onSelectFilter function');
  },
  onChange: (val) => {
    console.trace(val);
    throw new Error('Pleace specify onSelectFilter function');
  },
});
