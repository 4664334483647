export class Price {
  min: number | null;

  max: number | null;

  constructor(props?: Partial<Pick<Price, 'max' | 'min'>>) {
    this.max = props?.max ?? null;
    this.min = props?.min ?? null;
  }

  static get defaultValue() {
    return new Price();
  }
}
