import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { DashboardWordCloudResponse } from 'product-types/src/domain/dashboard/DashboardKeywordModel';

export interface DashboardWordCloudRequestParams
  extends CommonDashboardRequestParams {
  limit: number;
  category_id: Array<number> | null;
}

export interface WordCloudRequest extends EndpointRequests {
  params: DashboardWordCloudRequestParams;
}

const wordCloud = new Endpoint<WordCloudRequest, DashboardWordCloudResponse>({
  url: '/api/me/stats/word_cloud',
  method: 'GET',
  headers: {},
});

export default wordCloud;
