import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import graph from './graph';
import stats from './stats';

export interface EffectiveTakedownsEndpoints extends EndpointContainer {
  graph: typeof graph;
  stats: typeof stats;
}

const effectiveTakedowns: EffectiveTakedownsEndpoints = {
  graph,
  stats,
  injectHTTPClient(client: HttpClient) {
    graph.injectHTTPClient(client);
    stats.injectHTTPClient(client);
  },
};

export default effectiveTakedowns;
