import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import {
  AssociatedPicturesCluster,
  ClusterDataModel,
  ClusterMembers,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import { CommentModel } from 'product-types/src/domain/comment/Comment';
import { ClusterModerationModel } from 'product-types/src/domain/cluster/ClusterModerationModer';
import {
  LOAD_CLUSTER,
  LOAD_CLUSTERS,
  LOAD_CLUSTERS_SUCCESS,
  LOAD_CLUSTER_SUCCESS,
  LOAD_COMMENTS_CLUSTER,
  SAVE_CLUSTER_TITLE,
  LOAD_CONTACT_INFO_CLUSTER,
  START_DELETE_VENDORS_CLUSTER,
  DELETE_VENDORS_CLUSTER_SUCCESS,
  DELETE_VENDORS_CLUSTER_ERROR,
  MERGE_CLUSTERS,
  START_ADD_ACCOUNTS,
  START_ADD_VENDOR,
  ADD_VENDOR_ERROR,
  RESET_STATE,
  UPDATE_FILTERS_RELATED_POSTS,
  LOAD_VENDORS_CLUSTER,
  LOAD_VENDORS_CLUSTER_SUCCESS,
  LOAD_ASSOCIATED_IMAGES,
  UPDATE_ASSOCIATED_IMAGES,
  UPDATE_LOADING,
  UPDATE_CLUSTER_COMMENTS,
  UPDATE_FILTERS_ASSOCIATED_POSTERS_WEBSITES,
} from './constants';

// May be any actions in this reducer can be removed after deep investigation of bussines logic.
// In this time i keep actions for backward compatiblity. We can refactor this part only after deep investigations and discussions with backend

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export const loadAssociatedAccountsAction = (pageState) => ({
  type: LOAD_VENDORS_CLUSTER,
  ...pageState,
});

export const updateAssociatedAccounts = (
  accounts: FetchableData<ClusterMembers>,
) => ({
  type: LOAD_VENDORS_CLUSTER_SUCCESS,
  accounts,
});

export const loadAssociatedImages = (
  payload: Pagination,
  id: number | string,
) => ({
  type: LOAD_ASSOCIATED_IMAGES,
  payload,
  id,
});

export const updateAssociatedImages = (
  associatedImages: FetchableData<AssociatedPicturesCluster>,
) => ({
  type: UPDATE_ASSOCIATED_IMAGES,
  associatedImages,
});

export function loadClustersAction(state, successAction = null) {
  return {
    type: LOAD_CLUSTERS,
    ...state,
    successAction,
  };
}

export function updateRelatedPostFilters(payload: TableParams) {
  return {
    type: UPDATE_FILTERS_RELATED_POSTS,
    payload,
  };
}

export function updateAssociatedPostersWebsitesFilters(payload: TableParams) {
  return {
    type: UPDATE_FILTERS_ASSOCIATED_POSTERS_WEBSITES,
    payload,
  };
}

export function loadClustersSuccessAction(
  clusters_data: FetchableData<ClusterDataModel>,
) {
  return {
    type: LOAD_CLUSTERS_SUCCESS,
    clusters_data,
  };
}

export function loadClusterAction({ id }) {
  return {
    type: LOAD_CLUSTER,
    id,
  };
}

export function loadClusterSuccessAction({
  currentCluster,
}: {
  currentCluster: FetchableData<ClusterModerationModel>;
}) {
  return {
    type: LOAD_CLUSTER_SUCCESS,
    currentCluster,
  };
}

export function updateClusterCommentAction(
  commentsData: FetchableData<Array<CommentModel>>,
) {
  return {
    type: UPDATE_CLUSTER_COMMENTS,
    commentsData,
  };
}

export function loadClusterComments(clusterId: string | number) {
  return {
    type: LOAD_COMMENTS_CLUSTER,
    clusterId,
  };
}

export function saveClusterTitleAction({ title, id }) {
  return {
    type: SAVE_CLUSTER_TITLE,
    title,
    id,
  };
}

export function loadContactInfoAction() {
  return {
    type: LOAD_CONTACT_INFO_CLUSTER,
  };
}

export const startDeleteCluster = (payload: {
  cluster_member_id: number | string;
  origin_id: number | string;
  callback: () => void;
}) => ({
  type: START_DELETE_VENDORS_CLUSTER,
  payload,
});

export const deleteClusterSuccess = (id) => ({
  type: DELETE_VENDORS_CLUSTER_SUCCESS,
  id,
});

export const deleteClusterError = () => ({
  type: DELETE_VENDORS_CLUSTER_ERROR,
});

export interface MergeClustersActionParams {
  data: Array<number>;
  successAction: () => void;
}
export const mergeClusters = ({
  data,
  successAction,
}: MergeClustersActionParams) => ({
  type: MERGE_CLUSTERS,
  data,
  successAction,
});

export const handleAddAccounts = (data, cluster_id, success_action) => ({
  type: START_ADD_ACCOUNTS,
  data,
  cluster_id,
  success_action,
});

export const handleAddVendor = (data, cluster_name, success_action) => ({
  type: START_ADD_VENDOR,
  data,
  cluster_name,
  success_action,
});

export const updateLoading = (is_loading = false) => ({
  type: UPDATE_LOADING,
  is_loading,
});

export const addVendorError = () => ({
  type: ADD_VENDOR_ERROR,
});
