import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SaveGoalsRequest extends EndpointRequests {
  data: { goals: any };
}

interface SaveGoalsResponse {}

const saveGoals = new Endpoint<SaveGoalsRequest, SaveGoalsResponse>({
  url: '/api/me/goal',
  method: 'POST',
  headers: {},
});

export default saveGoals;
