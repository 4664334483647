import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ModerateAccountRequest extends EndpointRequests {}

const moderateAccount = new Endpoint<ModerateAccountRequest, any>({
  url: '/api/me/accounts_moderation',
  method: 'PUT',
  headers: {},
});

export default moderateAccount;
