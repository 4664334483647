import React, { forwardRef, useCallback, useRef } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Labellable } from 'product-ui/src/components/atoms/Interfaces/Labellable';
import { Changable } from 'product-ui/src/components/atoms/Interfaces/Changable';
import { Disablable } from 'product-ui/src/components/atoms/Interfaces/Disablable';
import HasChildren from 'product-ui/src/components/atoms/Interfaces/HasChildren';
import { Stylable } from 'product-ui/src/components/atoms/Interfaces/Stylable';
import { CheckboxRef, Checkbox as AntdChecbox } from 'antd';
import { useGetClassesFromObject } from '../Typography/hooks';
import { Testable } from '../Interfaces/Testable';

interface CheckboxProps
  extends Changable<boolean, CheckboxChangeEvent>,
    Labellable,
    Disablable,
    HasChildren,
    Stylable {
  id?: string;
  variant?: 'primary' | 'secondary';
  indeterminate?: boolean;
  checked?: boolean;
  blockEventPropagination?: boolean;
  onDoubleClick?: () => void;
}

type CompoundedComponent = React.ForwardRefExoticComponent<
  CheckboxProps & React.RefAttributes<CheckboxRef> & Testable
> & {
  Group: typeof AntdChecbox.Group;
};

const Checkbox: CompoundedComponent = forwardRef<
  CheckboxRef,
  CheckboxProps & Testable
>((props, ref) => {
  const handlingDoubleCLick = useRef(false);
  const variant = props.variant ?? 'primary';
  const { onDoubleClick = undefined } = props;

  const changeHandler = useCallback(
    (e: CheckboxChangeEvent) => {
      if (handlingDoubleCLick.current) {
        handlingDoubleCLick.current = false;
        return;
      }
      if (props.onChange) {
        props.onChange(e.target.checked, e);
      }
      if (props.onClick) {
        props.onClick(e);
      }
    },
    [props.onChange, props.onClick],
  );

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (props.blockEventPropagination) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        const isDoubleClick = e.detail === 2;
        if (isDoubleClick && onDoubleClick) {
          handlingDoubleCLick.current = true;
          onDoubleClick();
        }
      }
    },
    [props.blockEventPropagination],
  );

  const classes = useGetClassesFromObject({
    [variant]: true,
    [props.className ?? '']: !!props.className,
  });

  return (
    <AntdChecbox
      ref={ref}
      id={props.id}
      onClick={clickHandler}
      onChange={changeHandler}
      indeterminate={props.indeterminate}
      disabled={props.disabled}
      data-testid={props.dataTestId}
      checked={props.checked}
      style={props.style}
      className={classes()}
    >
      {props.children ?? props.label}
    </AntdChecbox>
  );
}) as any;

Checkbox.Group = AntdChecbox.Group;

export default Checkbox;
