import React from 'react';
import { Flex, Space } from 'antd';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import { CrawlingDateOption } from '../../../types/filters/AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilterValue';

const CrawlingDateSelectOption = (
  { label, value, icon: Icon }: CrawlingDateOption,
  selectValue: CrawlingDateOption,
  onClick,
) => (
  <Flex
    aria-label={label}
    onClick={onClick}
    gap="0.5rem"
    align="center"
    justify="flex-start"
    style={{
      cursor: 'pointer',
      padding: '0.25rem 0.75rem',
    }}
  >
    <StyledCheckbox>
      <Checkbox className="checkbox" checked={selectValue.value === value} />
    </StyledCheckbox>
    <Space>
      {typeof Icon === 'function' ? <Icon className="svg" /> : Icon}
      {label}
    </Space>
  </Flex>
);

export { CrawlingDateSelectOption };
