import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getNotifications from './getNotifications';
import updateNotifications from './updateNotifications';

export interface NotificationsEndpoints extends EndpointContainer {
  getNotifications: typeof getNotifications;
  updateNotifications: typeof updateNotifications;
}

const notifications: NotificationsEndpoints = {
  getNotifications,
  updateNotifications,
  injectHTTPClient(client: HttpClient) {
    getNotifications.injectHTTPClient(client);
    updateNotifications.injectHTTPClient(client);
  },
};

export default notifications;
