import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

export interface PostsToModerateResponse {
  posts_to_moderate: number;
}

interface PostsToModerateRequestParams extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface PostsToModerateRequest extends EndpointRequests {
  params: PostsToModerateRequestParams;
}

const postsToModerate = new Endpoint<
  PostsToModerateRequest,
  PostsToModerateResponse
>({
  url: '/api/me/stats/posts_to_moderate',
  method: 'GET',
  headers: {},
});

export default postsToModerate;
