/*
 *
 * UploadHistory actions
 *
 */

import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { UploadHistoryResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  RESET_STATE,
  LOAD_UPLOAD_HISTORY_DATA,
  LOAD_UPLOAD_HISTORY_DATA_SUCCESS,
} from './constants';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function loadUploadHistoryData({ params }) {
  return {
    type: LOAD_UPLOAD_HISTORY_DATA,
    params,
  };
}

export function updateUploadHistoryData(
  uploadHistory: FetchableData<UploadHistoryResponseModel>,
) {
  return {
    type: LOAD_UPLOAD_HISTORY_DATA_SUCCESS,
    uploadHistory,
  };
}
