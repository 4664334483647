import { WebsiteHasPosters } from 'product-types/src/domain/website/WebsiteHasPosters';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface HasPosterRequestModel extends EndpointRequests {
  params: {
    post_url: string;
  };
}
const hasPosters = new Endpoint<HasPosterRequestModel, WebsiteHasPosters>({
  url: '/api/me/websites/has_posters',
  method: 'GET',
  headers: {},
});

export default hasPosters;
