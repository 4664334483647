import { ModerationReasonRaw } from 'product-types/src/domain/moderationReason/ModerationReason';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UnCheckImageReasonRequest extends EndpointRequests {
  data: {
    moderation_reason_id: number;
  };
}

const unCheckImageReason = new Endpoint<
  UnCheckImageReasonRequest,
  ModerationReasonRaw
>({
  url: '/api/me/moderation_reason/uncheck_image_moderation_reason',
  method: 'POST',
  headers: {},
});

export default unCheckImageReason;
