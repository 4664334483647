import { AccountSuggestionRawModel } from 'product-types/src/domain/account/AccountSuggestion';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AccountResponseModel {
  [key: string]: Omit<AccountSuggestionRawModel, 'main_id'>;
}

interface AccountRequestModel extends EndpointRequests {
  params: {
    search_text: string;
  };
}

const account = new Endpoint<AccountRequestModel, AccountResponseModel>({
  url: '/api/me/clusters/account',
  method: 'GET',
  headers: {},
});

export default account;
