import { NetworkError } from './NetworkError';

export class TimeoutError extends NetworkError {
  constructor(
    message: string = 'The calculation is taking some time, please wait 1 min and retry to load the data',
    code: number = 504,
  ) {
    super(message, code);
    this.name = 'TimeoutError';
  }
}
