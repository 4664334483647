import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { PostModerationRawModel } from 'product-types/src/domain/post/PostModerationModel';

interface UpdatePostRequestModel extends EndpointRequests {
  data: any;
  urlParams: { id: string };
}

const updatePost = new Endpoint<UpdatePostRequestModel, PostModerationRawModel>(
  {
    url: '/api/me/posts/:id',
    method: 'PUT',
    headers: {},
  },
);

export default updatePost;
