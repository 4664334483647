import { v4 as uuid } from 'uuid';
import { NewUploadedByFilter } from 'components/Filters/NewUploadedByFilter';
import { NewUploadedByFilterValue } from 'components/Filters/NewUploadedByFilter/NewUploadedByFilterValue';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter } from '../../AtomicFilters/Filter';
import {
  UploadedByFilterValue,
  readFilterFromQueryProps,
} from './UploadedByFilterValue';

export interface UploadedByFilterQueryValue extends QueryValue {
  uploader_ids?: Array<number>;
}

export class UploadedByFilter implements Filter {
  uuid: string;

  label: string;

  value: UploadedByFilterValue;

  constructor(
    params?: Partial<Pick<UploadedByFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || UploadedByFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return this.value.selectedUsers.map((u) => ({
      name: u.name,
      value: u,
      uuid: this.uuid,
      key: this.uuid + u.id,
    }));
  }

  get component() {
    return NewUploadedByFilter;
  }

  get displayFilterComponent() {
    return NewUploadedByFilterValue;
  }

  get queryFilterValue(): UploadedByFilterQueryValue {
    return {
      uploader_ids: this.value.selectedUsers.map((u) => u.id),
    };
  }

  removeFilterValue(removableUser: any) {
    this.value = this.value.removeUser(removableUser.value);
    return new UploadedByFilter(this);
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
  ): UploadedByFilter {
    return new UploadedByFilter({
      value: UploadedByFilterValue.readFilterFromQuery(props),
    });
  }
}
