import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';

import Typography from 'product-ui/src/components/atoms/Typography/index';
import { UserFilterValueModel } from '../../../types/filters/AtomicFiltersImplementation/UserFilter/UserFilterValueModel';

interface UserFilterPreviewProps {
  user: UserFilterValueModel;
  onRemove: (user: UserFilterValueModel) => void;
}
export const UserFilterPreview = (props: UserFilterPreviewProps) => (
  <div
    className="user-preview"
    data-testid={props.user.user.id}
    style={{
      display: 'flex',
      alignItems: 'center',
      paddingRight: '0.5rem',
    }}
  >
    <img
      alt={props.user.user.name}
      src={props.user.user.profilePictureLink}
      height={24}
      width={24}
    />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5rem 1rem',
        flexGrow: 1,
      }}
    >
      <Typography variant="small" fontWeight="regular">
        {props.user.user.name}
      </Typography>
      <Typography variant="xsmall" fontWeight="light">
        {props.user.role}
      </Typography>
    </div>
    <NaveeIcon.Cross
      style={{
        cursor: 'pointer',
        color: 'var(--black-mate)',
      }}
      onClick={() => {
        props.onRemove(props.user);
      }}
    />
  </div>
);
