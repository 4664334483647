import { Tag, TagModel } from 'product-types/src/domain/tag/Tag';
import * as Domain from 'product-types/src/domain/Domain';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface tagsFilterParams {
  account: Domain.Tag.Tag[];
  post: Domain.Tag.Tag[];
  duplicatedGroup: Domain.Tag.Tag[];
  vendor: Domain.Tag.Tag[];
}

export class TagsFilterValue implements FilterValue {
  accountInclude: Tag[];

  accountExclude: Tag[];

  postInclude: Tag[];

  postExclude: Tag[];

  duplicatedGroupInclude: Tag[];

  duplicatedGroupExclude: Tag[];

  vendorInclude: Tag[];

  vendorExclude: Tag[];

  constructor(
    params?: Pick<
      TagsFilterValue,
      | 'accountInclude'
      | 'accountExclude'
      | 'postInclude'
      | 'postExclude'
      | 'duplicatedGroupInclude'
      | 'duplicatedGroupExclude'
      | 'vendorInclude'
      | 'vendorExclude'
    >,
  ) {
    const maxTagCount = 1000;
    this.accountInclude = (params?.accountInclude || []).slice(0, maxTagCount);
    this.accountExclude = (params?.accountExclude || []).slice(0, maxTagCount);
    this.postInclude = (params?.postInclude || []).slice(0, maxTagCount);
    this.postExclude = (params?.postExclude || []).slice(0, maxTagCount);
    this.duplicatedGroupInclude = (params?.duplicatedGroupInclude || []).slice(
      0,
      maxTagCount,
    );
    this.duplicatedGroupExclude = (params?.duplicatedGroupExclude || []).slice(
      0,
      maxTagCount,
    );
    this.vendorInclude = (params?.vendorInclude || []).slice(0, maxTagCount);
    this.vendorExclude = (params?.vendorExclude || []).slice(0, maxTagCount);
  }

  static get defaultValue(): TagsFilterValue {
    return new TagsFilterValue();
  }

  addAccountInclude(accounts: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude.concat(accounts),
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  setAccountInclude(accounts: Tag[]) {
    return new TagsFilterValue({
      accountInclude: accounts,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  removeAccountInclude(account: Tag) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude.filter(
        (tag) => tag.id !== account.id,
      ),
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  addAccountExclude(accounts: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude.concat(accounts),
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  setAccountExclude(accounts: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: accounts,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  removeAccountExclude(account: Tag) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude.filter(
        (tag) => tag.id !== account.id,
      ),
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  addPostInclude(posts: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude.concat(posts),
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  setPostInclude(posts: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: posts,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  removePostInclude(post: Tag) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude.filter((tag) => tag.id !== post.id),
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  addPostExclude(posts: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude.concat(posts),
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  setPostExclude(posts: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: posts,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  removePostExclude(post: Tag) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude.filter((tag) => tag.id !== post.id),
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  addDuplicatedGroupInclude(duplicatedGroups: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude:
        this.duplicatedGroupInclude.concat(duplicatedGroups),
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  setDuplicatedGroupInclude(duplicatedGroups: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: duplicatedGroups,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  removeDuplicatedGroupInclude(duplicatedGroup: Tag) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude.filter(
        (tag) => tag.id !== duplicatedGroup.id,
      ),
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  addDuplicatedGroupExclude(duplicatedGroups: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude:
        this.duplicatedGroupExclude.concat(duplicatedGroups),
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  setDuplicatedGroupExclude(duplicatedGroups: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: duplicatedGroups,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  removeDuplicatedGroupExclude(duplicatedGroup: Tag) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude.filter(
        (tag) => tag.id !== duplicatedGroup.id,
      ),
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude,
    });
  }

  addVendorInclude(vendors: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude.concat(vendors),
      vendorExclude: this.vendorExclude,
    });
  }

  setVendorInclude(vendors: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: vendors,
      vendorExclude: this.vendorExclude,
    });
  }

  removeVendorInclude(vendor: Tag) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude.filter((tag) => tag.id !== vendor.id),
      vendorExclude: this.vendorExclude,
    });
  }

  addVendorExclude(vendors: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude.concat(vendors),
    });
  }

  setVendorExclude(vendors: Tag[]) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: vendors,
    });
  }

  removeVendorExclude(vendor: Tag) {
    return new TagsFilterValue({
      accountInclude: this.accountInclude,
      accountExclude: this.accountExclude,
      postInclude: this.postInclude,
      postExclude: this.postExclude,
      duplicatedGroupInclude: this.duplicatedGroupInclude,
      duplicatedGroupExclude: this.duplicatedGroupExclude,
      vendorInclude: this.vendorInclude,
      vendorExclude: this.vendorExclude.filter((tag) => tag.id !== vendor.id),
    });
  }

  static readFilterFromQuery(props?: tagsFilterParams): TagsFilterValue {
    const urlParams = new URLSearchParams(window.location.search);
    const accountTagsExclude = urlParams
      .getAll('account_tags_to_exclude')
      .map((id) => parseInt(id, 10));
    const accountTagsInclude = urlParams
      .getAll('account_tags_to_include')
      .map((id) => parseInt(id, 10));
    const postTagsExclude = urlParams
      .getAll('post_tags_to_exclude')
      .map((id) => parseInt(id, 10));
    const postTagsInclude = urlParams
      .getAll('post_tags_to_include')
      .map((id) => parseInt(id, 10));
    const dupGroupTagsExclude = urlParams
      .getAll('duplicated_group_tags_to_exclude')
      .map((id) => parseInt(id, 10));
    const dupGroupTagsInclude = urlParams
      .getAll('duplicated_group_tags_to_include')
      .map((id) => parseInt(id, 10));
    const vendorTagsExclude = urlParams
      .getAll('cluster_tags_to_exclude')
      .map((id) => parseInt(id, 10));
    const vendorTagsInclude = urlParams
      .getAll('cluster_tags_to_include')
      .map((id) => parseInt(id, 10));

    const accountTagsExcludeSearch = urlParams.getAll(
      'account_tag_name_contains_to_exclude',
    );
    const accountTagsIncludeSearch = urlParams.getAll(
      'account_tag_name_contains_to_include',
    );
    const postTagsExcludeSearch = urlParams.getAll(
      'post_tag_name_contains_to_exclude',
    );
    const postTagsIncludeSearch = urlParams.getAll(
      'post_tag_name_contains_to_include',
    );
    const dupGroupTagsExcludeSearch = urlParams.getAll(
      'image_tag_name_contains_to_exclude',
    );
    const dupGroupTagsIncludeSearch = urlParams.getAll(
      'image_tag_name_contains_to_include',
    );
    const vendorTagsExcludeSearch = urlParams.getAll(
      'cluster_tag_name_contains_to_exclude',
    );
    const vendorTagsIncludeSearch = urlParams.getAll(
      'cluster_tag_name_contains_to_include',
    );

    return new TagsFilterValue({
      accountInclude: (accountTagsInclude || [])
        .map((categoryId: number) =>
          (props?.account ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          accountTagsIncludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      accountExclude: (accountTagsExclude || [])
        .map((categoryId: number) =>
          (props?.account ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          accountTagsExcludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      postInclude: (postTagsInclude || [])
        .map((categoryId: number) =>
          (props?.post ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          postTagsIncludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      postExclude: (postTagsExclude || [])
        .map((categoryId: number) =>
          (props?.post ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          postTagsExcludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      duplicatedGroupInclude: (dupGroupTagsInclude || [])
        .map((categoryId: number) =>
          (props?.duplicatedGroup ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          dupGroupTagsIncludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      duplicatedGroupExclude: (dupGroupTagsExclude || [])
        .map((categoryId: number) =>
          (props?.duplicatedGroup ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          dupGroupTagsExcludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      vendorInclude: (vendorTagsInclude || [])
        .map((categoryId: number) =>
          (props?.vendor ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          vendorTagsIncludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      vendorExclude: (vendorTagsExclude || [])
        .map((categoryId: number) =>
          (props?.vendor ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          vendorTagsExcludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
    });
  }

  static readFromSavedFilter(
    savedFilter: SavedFilterModel,
    props?: tagsFilterParams,
  ): TagsFilterValue {
    const accountTagsExclude = savedFilter.accountTagsToExclude || [];
    const accountTagsInclude = savedFilter.accountTagsToInclude || [];
    const postTagsExclude = savedFilter.postTagsToExclude || [];
    const postTagsInclude = savedFilter.postTagsToInclude || [];
    const dupGroupTagsExclude = savedFilter.duplicatedGroupTagsToExclude || [];
    const dupGroupTagsInclude = savedFilter.duplicatedGroupTagsToInclude || [];
    const vendorTagsExclude = savedFilter.clusterTagsToExclude || [];
    const vendorTagsInclude = savedFilter.clusterTagsToInclude || [];

    const accountTagsExcludeSearch =
      savedFilter.accountTagNameContainsToExclude || [];
    const accountTagsIncludeSearch =
      savedFilter.accountTagNameContainsToInclude || [];
    const postTagsExcludeSearch =
      savedFilter.postTagNameContainsToExclude || [];

    const postTagsIncludeSearch =
      savedFilter.postTagNameContainsToInclude || [];
    const dupGroupTagsExcludeSearch =
      savedFilter.imageTagNameContainsToExclude || [];
    const dupGroupTagsIncludeSearch =
      savedFilter.imageTagNameContainsToInclude || [];
    const vendorTagsExcludeSearch =
      savedFilter.clusterTagNameContainsToExclude || [];
    const vendorTagsIncludeSearch =
      savedFilter.clusterTagNameContainsToInclude || [];
    return new TagsFilterValue({
      accountInclude: (accountTagsInclude || [])
        .map((categoryId: number) =>
          (props?.account ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          accountTagsIncludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      accountExclude: (accountTagsExclude || [])
        .map((categoryId: number) =>
          (props?.account ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          accountTagsExcludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      postInclude: (postTagsInclude || [])
        .map((categoryId: number) =>
          (props?.post ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          postTagsIncludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      postExclude: (postTagsExclude || [])
        .map((categoryId: number) =>
          (props?.post ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          postTagsExcludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      duplicatedGroupInclude: (dupGroupTagsInclude || [])
        .map((categoryId: number) =>
          (props?.duplicatedGroup ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          dupGroupTagsIncludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      duplicatedGroupExclude: (dupGroupTagsExclude || [])
        .map((categoryId: number) =>
          (props?.duplicatedGroup ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          dupGroupTagsExcludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      vendorInclude: (vendorTagsInclude || [])
        .map((categoryId: number) =>
          (props?.vendor ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          vendorTagsIncludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
      vendorExclude: (vendorTagsExclude || [])
        .map((categoryId: number) =>
          (props?.vendor ?? []).find(
            (websiteCategory) => websiteCategory.id === categoryId,
          ),
        )
        .concat(
          vendorTagsExcludeSearch.map(
            (name) =>
              new TagModel({
                id: name,
                name,
                tagType: 'account',
                __isNew__: true,
              }),
          ),
        )
        .filter((item) => item !== undefined) as Domain.Tag.Tag[],
    });
  }
}
