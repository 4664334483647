import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { AccountModerationRawModel } from 'product-types/src/domain/account/AccountModerationModel';

interface GetModerationAccountRequestModel extends EndpointRequests {
  urlParams: { id: string };
}

const getModerationAccount = new Endpoint<
  GetModerationAccountRequestModel,
  AccountModerationRawModel
>({
  url: '/api/me/accounts_moderation/:id',
  method: 'GET',
  headers: {},
});

export default getModerationAccount;
