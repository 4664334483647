import { TagModel } from 'product-types/src/domain/tag/Tag';
import {
  ExistingUploadItemState,
  UploadAccount,
} from 'product-types/src/domain/uploads/uploadAccount';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { Label } from 'product-types/src/domain/label/Label';
import {
  RESET_STATE,
  SEND_ACCOUNTS_TO_NAVEE,
  UPDATE_ACCOUNT_LIST,
  UPDATE_ACCOUNT_TAGS_LIST,
  UPDATE_GLOBAL_ACCOUNT_LABEL,
  UPDATE_LOADING_ACCOUNT,
  UPDATE_MANUAL_UPLOAD,
  UPDATE_EXISTING_ITEMS_ACCOUTNS,
  UPLOAD_ACCOUNT_XLSX,
  UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_ACCOUNT,
} from './constants';

export const updateManualUploadAccount = (manualUpload: boolean) => ({
  type: UPDATE_MANUAL_UPLOAD,
  manualUpload,
});

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function updateGlobalAccountLabel(label: Label | null) {
  return {
    type: UPDATE_GLOBAL_ACCOUNT_LABEL,
    label,
  };
}
export function uploadAccountsXslx(file: File) {
  return {
    type: UPLOAD_ACCOUNT_XLSX,
    file,
  };
}

export function setLoadingAccountUpload(loading: FetchableData<null>) {
  return {
    type: UPDATE_LOADING_ACCOUNT,
    loading,
  };
}

export function updateAccountTags(tags: Array<TagModel>) {
  return {
    type: UPDATE_ACCOUNT_TAGS_LIST,
    tags,
  };
}

export const udpateExistingAccounts = (
  existingItems: ExistingUploadItemState | null,
) => ({
  type: UPDATE_EXISTING_ITEMS_ACCOUTNS,
  existingItems,
});

export const updateAccountList = (accounts: Array<UploadAccount>) => ({
  type: UPDATE_ACCOUNT_LIST,
  accounts,
});

export const sendAccountsToNavee = (override?: boolean) => ({
  type: SEND_ACCOUNTS_TO_NAVEE,
  override,
});
export const uploadArchiveLinkToS3AndAttachToAccount = ({
  file,
  account,
}: {
  file: File;
  account: UploadAccount;
}) => ({
  type: UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_ACCOUNT,
  file,
  account,
});
