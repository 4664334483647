import styled from 'styled-components';

export const newLabelContainerDefaultStyles = {
  width: 204,
};

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 3px;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .ant-checkbox {
      top: 0 !important;
      & + span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &:not(:first-child) {
      margin-left: 0;
    }
  }
`;
