import { UploadAccountRaw } from 'product-types/src/domain/uploads/uploadAccount';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadAccountRequest extends EndpointRequests {
  data: {
    accounts: Array<UploadAccountRaw>;
    global_label: string;
    global_tags: Array<string>;
    global_vendor: string;
    override: boolean | null;
  };
}

export interface UploadAccountResponse {
  existing_items_number: number | null;
  message: string;
  title: string;
}

const uploadAccount = new Endpoint<UploadAccountRequest, UploadAccountResponse>(
  {
    url: '/api/me/uploads/account',
    method: 'POST',
    headers: {},
  },
);

export default uploadAccount;
