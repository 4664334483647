import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdateTagMembersRequest extends EndpointRequests {
  urlParams: {
    id: string;
  };
  data: {
    member_ids: Array<string>;
  };
}

interface UpdateTagMembersResponse {}

const updateTagMembers = new Endpoint<
  UpdateTagMembersRequest,
  UpdateTagMembersResponse
>({
  url: '/api/me/organisation/tags/:id/members',
  method: 'POST',
  headers: {},
});

export default updateTagMembers;
