import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface OrganisationRequestModel extends EndpointRequests {}

interface OrganisationResponse {}

const organisation = new Endpoint<
  OrganisationRequestModel,
  OrganisationResponse
>({
  url: '/api/me/organisation',
  method: 'GET',
  headers: {},
});

export default organisation;
