import React from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';

const StyledSuggestion = styled.div`
  display: flex;
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    .name {
      color: #43425d;
      opacity: 0.9;
    }
    .email {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      letter-spacing: 0.03em;
      color: #727185;
    }
  }
  .ant-avatar {
    border-radius: 10px;
  }
`;

type Props = {
  image: string;
  name: string;
  email: string;
};

export function Suggestion({ image, name, email }: Props) {
  return (
    <StyledSuggestion>
      <div className="left">
        <Avatar src={image} size={44} shape="square" />
      </div>
      <div className="right">
        <div className="name">{name}</div>
        <div className="email">{email}</div>
      </div>
    </StyledSuggestion>
  );
}
