import React, { useMemo, useState } from 'react';
import { SelectProps } from 'antd/es/select';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { Flex } from 'antd';
import { Select } from '../Select';
import NaveeIcon from '../NaveeIcon/NaveeIcon';
import { Testable } from '../Interfaces/Testable';

interface Props extends Omit<SelectProps & Testable, 'options'> {
  value: Array<TagModel>;
  tags: Array<TagModel>;
  onChange: (tags: Array<TagModel>) => void;
}

interface Tag {
  label: string;
  value: number;
}

export const prioritizeSearchResults = (
  filteredOptions: Array<{ label: string; value: string | number }>,
  normalizedSearch: string,
) =>
  filteredOptions.sort((a, b) => {
    if (
      a.label.startsWith(normalizedSearch) &&
      !b.label.startsWith(normalizedSearch)
    ) {
      return -1;
    }
    if (
      !a.label.startsWith(normalizedSearch) &&
      b.label.startsWith(normalizedSearch)
    ) {
      return 1;
    }
    return a.label.localeCompare(b.label);
  });

export const CreatableTagSelect = (props: Props) => {
  const [searchValue, setSearchValue] = useState('');

  const [selectedTagIdSet, selectedTagNameSet] = useMemo(
    () => [
      new Set((props.value ?? []).map((tag) => tag.id)),
      new Set((props.value ?? []).map((tag) => tag.name)),
    ],
    [props.value],
  );

  const selectOptions = useMemo(() => {
    const normalizedSearch = searchValue.trim().toLowerCase();
    const availableOptions: Array<Tag> = (
      props.tags.filter((tag) => !tag.isHidden) ?? []
    ).map(({ id, name }) => ({ label: name, value: +id }));

    const filteredOptions = availableOptions
      .filter((option) => !selectedTagIdSet.has(option.value))
      .filter((option) => option.label.toLowerCase().includes(normalizedSearch))
      .map((option) => ({ ...option, value: option.value.toString() }));
    return prioritizeSearchResults(filteredOptions, normalizedSearch);
  }, [props.tags, selectedTagIdSet, searchValue]);

  const showCreateOption = useMemo(() => {
    const notInSelectedTags = !selectedTagNameSet.has(searchValue);
    const notInSelectOptions = !selectOptions
      .map((option) => option.label)
      .includes(searchValue);
    const notHidden = !props.tags.find(
      (tag) => tag.name === searchValue && tag.isHidden,
    );
    return (
      !!searchValue && notInSelectOptions && notInSelectedTags && notHidden
    );
  }, [searchValue, selectOptions]);

  const handleTagChange: SelectProps['onChange'] = (newValues) => {
    const foundTagIds = newValues.map(Number);
    const foundTags = props.tags.filter((t) =>
      foundTagIds.includes(t.id as number),
    );
    const newTagsToAdd = newValues.filter((t) => {
      const parsedTag = parseInt(t, 10);
      if (foundTags.find((tag) => tag.id === parsedTag)) return false;
      return true;
    });
    const tags = [
      ...foundTags,
      ...newTagsToAdd.map(
        (tag) =>
          new TagModel({
            id: tag,
            tagType: '',
            name: tag,
            __isNew__: true,
          }),
      ),
    ];
    props.onChange!(tags);
    setSearchValue('');
  };

  return (
    <Select
      mode="multiple"
      filterOption={false}
      {...props}
      onSearch={setSearchValue}
      placeholder={
        <Flex align="center" gap="0.5em">
          <NaveeIcon.Tag />
          {props.placeholder || 'Select tags'}
        </Flex>
      }
      onChange={handleTagChange}
    >
      {selectOptions.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
      {showCreateOption && (
        <Select.Option key={searchValue} value={searchValue}>
          Create {`"${searchValue}"`}
        </Select.Option>
      )}
    </Select>
  );
};
