export enum ModerationStatusEnum {
  unmoderated = 'unmoderated',
  moderated = 'moderated',
  checked = 'checked',
  validated = 'validated',
}

export interface ModerationStatus {
  value: ModerationStatusEnum;
  label: string;
}

export const ModerationStatusDictionary = {
  unmoderated: {
    label: 'Un-Moderated',
    value: ModerationStatusEnum.unmoderated,
  },
  moderated: {
    label: 'Moderated',
    value: ModerationStatusEnum.moderated,
  },
  checked: {
    label: 'Checked',
    value: ModerationStatusEnum.checked,
  },
  validated: {
    label: 'Validated',
    value: ModerationStatusEnum.validated,
  },
};

export const ModerationStatusOptions: ModerationStatus[] = Object.values(
  ModerationStatusDictionary,
);

export const GetTakeModerationStatusFilterOptionByValue = (value) =>
  ModerationStatusOptions.find((status) => status.value === value);
