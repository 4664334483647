import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import {
  NotificationModel,
  NotificationRawModel,
  NotificationType,
} from 'product-types/src/domain/notification/Notification';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import { AppState } from 'store/storeAccess';
import {
  loadNotifications as loadNotificationsAction,
  loadNotificationProps,
  updateNotifications,
  updateNotificationsHome,
} from './actions';
import ProductMonitor from '../../types/network/Http/productMonitor';
import {
  LOAD_MORE_NOTIFICATIONS,
  LOAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_HOME,
  SET_NOTIFICATIONS_AS_VIEWED,
} from './constants';

function* setNotificationsAsViewed(payload) {
  const { notification_type } = payload;
  try {
    yield call(
      ProductMonitor.endpoints.notifications.updateNotifications.call.bind(
        ProductMonitor.endpoints.notifications.updateNotifications,
      ),
      { data: { viewed: true } },
    );
    yield put(
      loadNotificationsAction({
        viewed: true,
        notification_type,
        offset: 0,
        perpage: 20,
      }),
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

function* loadNotifications(action: loadNotificationProps) {
  yield put(
    updateNotifications(
      new FetchableData<Array<NotificationModel>>({
        abortController: null,
        data: null,
        error: null,
        state: FetchableDataState.LOADING,
      }),
    ),
  );

  try {
    const notifications: Array<NotificationRawModel> = yield call(
      ProductMonitor.endpoints.notifications.getNotifications.call.bind(
        ProductMonitor.endpoints.notifications.getNotifications,
      ),
      {
        params: {
          notification_type: (action.notification_type ??
            NotificationType.comment) as string,
          viewed: action.viewed ?? false,
          offset: action.offset ?? 0,
          perpage: action.perpage ?? 10,
        },
      },
    );
    const newFetchableData = new FetchableData<Array<NotificationModel>>({
      abortController: null,
      data: notifications.map((notification) =>
        NotificationModel.createFromRaw(notification),
      ),
      error: null,
      state: FetchableDataState.LOADED,
    });
    yield put(updateNotifications(newFetchableData));
    if (action.notification_type === NotificationType.comment) {
      yield put(updateNotificationsHome(newFetchableData));
    }
  } catch (err) {
    console.error(err);
    yield put(
      updateNotifications(
        new FetchableData<Array<NotificationModel>>({
          abortController: null,
          data: null,
          error: CustomErrorFactory.create(err),
          state: FetchableDataState.ERROR,
        }),
      ),
    );
  }
}

function* loadNotificationsHome(action: loadNotificationProps) {
  yield put(
    updateNotificationsHome(
      new FetchableData<Array<NotificationModel>>({
        abortController: null,
        data: null,
        error: null,
        state: FetchableDataState.LOADING,
      }),
    ),
  );

  try {
    const notifications: Array<NotificationRawModel> = yield call(
      ProductMonitor.endpoints.notifications.getNotifications.call.bind(
        ProductMonitor.endpoints.notifications.getNotifications,
      ),
      {
        params: {
          notification_type: (action.notification_type ??
            NotificationType.comment) as string,
          viewed: action.viewed ?? false,
          offset: action.offset ?? 0,
          perpage: action.perpage ?? 10,
        },
      },
    );
    yield put(
      updateNotificationsHome(
        new FetchableData<Array<NotificationModel>>({
          abortController: null,
          data: notifications.map((notification) =>
            NotificationModel.createFromRaw(notification),
          ),
          error: null,
          state: FetchableDataState.LOADED,
        }),
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(
      updateNotificationsHome(
        new FetchableData<Array<NotificationModel>>({
          abortController: null,
          data: null,
          error: CustomErrorFactory.create(err),
          state: FetchableDataState.ERROR,
        }),
      ),
    );
  }
}

function* loadMoreNotifications(action: loadNotificationProps) {
  const oldNotifications: FetchableData<Array<NotificationModel>> =
    yield select((state: AppState) => state.notificationsState?.notifications);
  yield put(
    updateNotifications(
      new FetchableData<Array<NotificationModel>>({
        abortController: null,
        data: oldNotifications.data,
        error: null,
        state: FetchableDataState.LOADING,
      }),
    ),
  );

  try {
    const notifications = yield call(
      ProductMonitor.endpoints.notifications.getNotifications.call.bind(
        ProductMonitor.endpoints.notifications.getNotifications,
      ),
      {
        params: {
          notification_type: (action.notification_type ??
            NotificationType.comment) as string,
          viewed: action.viewed ?? false,
          offset: action.offset ?? 0,
          perpage: action.perpage ?? 10,
        },
      },
    );
    yield put(
      updateNotifications(
        new FetchableData<Array<NotificationModel>>({
          abortController: null,
          data: (oldNotifications.data || [])?.concat(
            notifications.map((notification) =>
              NotificationModel.createFromRaw(notification),
            ),
          ),
          error: null,
          state: FetchableDataState.LOADED,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateNotifications(
        new FetchableData<Array<NotificationModel>>({
          abortController: null,
          data: null,
          error: CustomErrorFactory.create(err),
          state: FetchableDataState.ERROR,
        }),
      ),
    );
  }
}

// Individual exports for testing
export default function* notificationsPageSaga() {
  yield takeLatest(SET_NOTIFICATIONS_AS_VIEWED, setNotificationsAsViewed);
  yield takeLatest(LOAD_NOTIFICATIONS, loadNotifications);
  yield takeLatest(LOAD_NOTIFICATIONS_HOME, loadNotificationsHome);
  yield takeLatest(LOAD_MORE_NOTIFICATIONS, loadMoreNotifications);
}
