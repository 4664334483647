import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ClusterAccountDeleteRequest extends EndpointRequests {
  urlParams: { id: string };
}

const deleteAccount = new Endpoint<ClusterAccountDeleteRequest, any>({
  url: '/api/me/clusters/account/:id',
  method: 'DELETE',
  headers: {},
});

export default deleteAccount;
