import React, { useCallback } from 'react';
import {
  UploadType,
  UploadTypeOptions,
} from 'product-types/src/domain/uploadType/UploadType';
import { Flex } from 'antd';
import { RadioButton } from 'product-ui/src/components/atoms/RadioButton/RadioButton';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import { UploadTypeFilter } from '../../../types/filters/AtomicFiltersImplementation/UploadType/UploadTypeFilter';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import { UploadTypeFilterValue } from '../../../types/filters/AtomicFiltersImplementation/UploadType/UploadTypeFilterValue';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';

export interface UploadTypeFilterProps extends FilterParams {
  value: UploadTypeFilter;
  onChange: (v: Filter) => void;
}

export const NewUploadTypeFilter = (props: UploadTypeFilterProps) => {
  const onChange = (event: UploadType) => {
    props.onChange(
      new UploadTypeFilter({
        ...props.value,
        value: new UploadTypeFilterValue(event),
      }),
    );
  };

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        style={{
          padding: '1rem 0.25rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 160,
          minHeight: 60,
        }}
      >
        {UploadTypeOptions.map((option) => (
          <Flex
            style={{
              padding: '0.25rem 0.75rem',
            }}
          >
            <StyledCheckbox isRadio>
              <RadioButton
                onChange={() => onChange(option)}
                checked={props.value.value.uploadType.value === option.value}
                label={option.label}
              />
            </StyledCheckbox>
          </Flex>
        ))}
      </Flex>
    ),
    [props.value.value.uploadType.value, onChange],
  );
  return (
    <FilterWithMenuWrapper
      text="Upload Type"
      renderer={renderer}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
    />
  );
};
