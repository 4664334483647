import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & input {
    font-size: 16px;
    line-height: 24px;
    &:not(:placeholder-shown) {
      font-weight: 500;
    }
  }
  & .ant-select-disabled .ant-select-selection-item {
    font-weight: 400;
  }
  & label {
    font-size: 12px !important;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 0.24px;
    color: var(--neutral-grey-70) !important;
  }
  & .ant-form-item-label {
    padding-bottom: 4px;
  }
`;
