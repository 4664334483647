import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { NotificationModel } from 'product-types/src/domain/notification/Notification';
import {
  LOAD_MORE_NOTIFICATIONS,
  LOAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_HOME,
  RESET_STATE,
  SET_NOTIFICATIONS_AS_VIEWED,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_HOME,
} from './constants';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export interface loadNotificationProps {
  viewed: boolean;
  notification_type?: string;
  offset?: number;
  perpage?: number;
}

export function loadNotifications({
  viewed,
  notification_type,
  offset,
  perpage,
}: loadNotificationProps) {
  return {
    type: LOAD_NOTIFICATIONS,
    viewed,
    offset,
    notification_type,
    perpage,
  };
}

export function loadNotificationsHome({
  viewed,
  notification_type,
  offset,
  perpage,
}: loadNotificationProps) {
  return {
    type: LOAD_NOTIFICATIONS_HOME,
    viewed,
    offset,
    notification_type,
    perpage,
  };
}

export function loadMoreNotifications({
  viewed,
  notification_type,
  offset,
  perpage,
}: loadNotificationProps) {
  return {
    type: LOAD_MORE_NOTIFICATIONS,
    viewed,
    offset,
    notification_type,
    perpage,
  };
}

export function updateNotifications(
  notifications: FetchableData<Array<NotificationModel>>,
) {
  return {
    type: UPDATE_NOTIFICATIONS,
    notifications,
  };
}

export function updateNotificationsHome(
  notificationsHome: FetchableData<Array<NotificationModel>>,
) {
  return {
    type: UPDATE_NOTIFICATIONS_HOME,
    notificationsHome,
  };
}

export function setNotificationAsViewedAction({ notification_type }) {
  return {
    type: SET_NOTIFICATIONS_AS_VIEWED,
    notification_type,
  };
}
