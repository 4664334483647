import React, { useCallback, useEffect, useMemo } from 'react';
import { Menu, Dropdown, Badge, Empty, Avatar } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Typography from 'product-ui/src/components/atoms/Typography';
import { useLocation } from 'react-router-dom';
import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import { Spin } from 'product-ui/src/components/atoms/Spin/Spin';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { NotificationModel } from 'product-types/src/domain/notification/Notification';
import Button from 'product-ui/src/components/atoms/Button/Button';
import useNavigation from '../../../hooks/useNavigation';
import { replace_user_id_with_user_and_dropdown } from '../../../utils/users';
import { loadNotificationsHome } from '../../../containers/NotificationsPage/actions';
import { AppState } from '../../../store/storeAccess';

const StyledMenu = styled(Menu)`
  width: 306px;
  position: relative;
  bottom: 5px;
  right: 0px;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 0 12px #c7c2c2;
  & li {
    display: flex;
    align-items: center;
    width: 290px;
    min-height: 63px;
    border-radius: 10px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);
    margin: 12px 8px;
    padding: 5px;
    .avatar {
      padding: 2px;
      width: 40px;
    }
  }
  & li .text {
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    white-space: normal;
  }
  & li .time {
    font-size: 13px;
    font-weight: 500;
    color: #a1a0ae;
  }
  & .link {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 19px 0;
    color: var(--dodger-blue);
  }
  & li img {
    max-width: 64px;
    max-height: 64px;
    width: auto;
    height: auto;
  }
`;

const EmptyMenu = styled(Menu.Item)`
  justify-content: center;
  height: 146px !important;
`;

const DropdownMenu = () => {
  const notifications = useSelector(
    (state: AppState) => state.notificationsState.notificationsHome,
  );
  const users = useSelector(
    (state: AppState) => state.filters_bar.optionsLoadedData.users.data,
  );
  const { calcLink, navigate } = useNavigation();
  return (
    <StyledMenu>
      <Spin spinning={notifications.state === FetchableDataState.LOADING}>
        {(notifications?.data || []).length ? (
          (notifications?.data ?? []).map((notification: NotificationModel) => (
            <Menu.Item
              key={notification.id}
              onClick={(event) => {
                event.domEvent.stopPropagation();
                event.domEvent.preventDefault();
                window.open(calcLink(notification.href), '_blank');
              }}
            >
              <div className="avatar">
                <Avatar size={34} src={notification.pictureUrl} />
              </div>

              <div className="not-div" style={{ marginLeft: 10 }}>
                <div className="text">
                  {replace_user_id_with_user_and_dropdown(
                    notification.text,
                    users,
                  )}
                </div>
                <div className="time">{notification.createdAtFormatted}</div>
              </div>
            </Menu.Item>
          ))
        ) : (
          <EmptyMenu key="no-notifications">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No new notifications"
            />
          </EmptyMenu>
        )}
      </Spin>
      <Button
        type="link"
        onClick={() => {
          navigate('/notifications');
        }}
        label="See all notifications"
      />
    </StyledMenu>
  );
};

interface NotificationMenuItemProps {
  collapsed: boolean;
}

const NotificationMenuItem = ({ collapsed }: NotificationMenuItemProps) => {
  const notifications = useSelector(
    (state: AppState) => state.notificationsState.notificationsHome,
  );
  const { currentUser } = useSelector((state: AppState) => state.loginPage);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const someNotificationsNotViewed = useMemo(
    () =>
      (notifications?.data || []).some(
        (notification: NotificationModel) => !notification.viewed,
      ),
    [notifications],
  );

  const checkNotifications = useCallback(() => {
    if (!currentUser || currentUser.state !== FetchableDataState.LOADED) {
      return;
    }
    dispatch(loadNotificationsHome({ viewed: false }));
  }, [currentUser, dispatch]);

  useEffect(() => {
    checkNotifications();

    const interval = setInterval(() => {
      checkNotifications();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Dropdown dropdownRender={() => DropdownMenu()} trigger={['hover']}>
      <>
        <Badge dot={someNotificationsNotViewed} offset={[0, 2]}>
          <NaveeIcon.Notification className="navee_svg" />
        </Badge>
        {!collapsed && (
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            fontWeight={pathname.includes('notification') ? 'bold' : 'medium'}
            color={
              pathname.includes('notification')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Notification
          </Typography>
        )}
      </>
    </Dropdown>
  );
};

export default NotificationMenuItem;
