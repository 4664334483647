import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import moderateAccount from './moderateAccount';
import prevAccountToModerate from './prevAccountToModerate';
import nextAccountToModerate from './nextAccountToModerate';
import moderateAndReturnAccount from './moderateAndReturnAccount';

export interface AccountsModerationEndpoints extends EndpointContainer {
  moderateAccount: typeof moderateAccount;
  moderateAndReturnAccount: typeof moderateAndReturnAccount;
  nextAccountToModerate: typeof nextAccountToModerate;
  prevAccountToModerate: typeof prevAccountToModerate;
}

const accountsModeration: AccountsModerationEndpoints = {
  moderateAccount,
  moderateAndReturnAccount,
  nextAccountToModerate,
  prevAccountToModerate,
  injectHTTPClient(client: HttpClient) {
    moderateAccount.injectHTTPClient(client);
    moderateAndReturnAccount.injectHTTPClient(client);
    nextAccountToModerate.injectHTTPClient(client);
    prevAccountToModerate.injectHTTPClient(client);
  },
};

export default accountsModeration;
