import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

export const NewFeatureLabelFilterValue: DisplayFilterComponent = ({
  filterValue: { name, value },
  onClickRemove,
}: DisplayFilterComponentProps) => {
  const postTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      iconPrefix={<NaveeIcon.PostFilter />}
      onClickRemove={onClickRemove}
      showRemoveIcon
    />
  );
  const imageTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      iconPrefix={<NaveeIcon.ImageFilter />}
      onClickRemove={onClickRemove}
      showRemoveIcon
    />
  );
  const filterTypeTagMap = {
    [FilterTypeEnum.featureLabelPost]: postTag,
    [FilterTypeEnum.featureLabelImage]: imageTag,
  };
  return filterTypeTagMap[name] || null;
};
