// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-person .ant-select-selector {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
  

.role-select .ant-select-selector {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/Filters/UserFilter/style.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,uCAAuC;AACzC;;;AAGA;EACE,qCAAqC;EACrC,wCAAwC;AAC1C","sourcesContent":[".search-person .ant-select-selector {\n  border-top-left-radius: 0 !important;\n  border-bottom-left-radius: 0 !important;\n}\n  \n\n.role-select .ant-select-selector {\n  border-top-right-radius: 0 !important;\n  border-bottom-right-radius: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
