import styled from 'styled-components';

export const PictureUpload = styled.div<{
  loading: boolean;
  hasPhoto: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 120px;
  overflow: hidden;
  text-align: ${({ loading }) => (loading ? 'left' : 'center')};
  border: 1px solid var(--primary-border);
  border-radius: 8px;

  button,
  button:hover,
  button:focus {
    width: 100%;
    height: 40px !important;
    border-radius: 0;
    background-color: var(--mint-green-lighter10) !important;
    align-items: center;
    color: var(--black-mate);
    display: flex;
    justify-content: center;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0.25rem 0 !important;
    transform: ${(props) =>
      props.hasPhoto ? 'translate3d(0, 100%, 0)' : 'translate3d(0, 0, 0)'};
    transition: transform 0.3s;
    ${(props) => (props.loading ? 'display: none;' : '')};
  }

  .pic-container {
    display: block;
    height: 80px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: white;
    ${(props) => (!props.loading ? 'align-items: flex-end;' : '')};

    &:hover {
      button {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .ant-upload.ant-upload-select {
    width: 100%;
  }
  .ant-form-item {
    width: 100%;
  }
  .ant-upload-list {
    display: none;
  }
  .ant-btn.ant-btn-loading {
    position: absolute;
  }
`;
