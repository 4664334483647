import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { DuplicatedGroupDataModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { QueryValue } from 'product-types/src/network/Query/Query';
import {
  LOAD_IMAGE_VIEW_DATA,
  RESET_STATE,
  UPDATE_DUPLICATED_GROUPS,
  UPDATE_DUPLICATED_GROUPS_COUNT,
} from './constants';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export const loadDuplicatedGroups = ({
  pageState,
  abortController,
}: {
  pageState: QueryValue;
  abortController: AbortController;
}) => ({
  type: LOAD_IMAGE_VIEW_DATA,
  pageState,
  abortController,
});

export const updateDuplicatedGroups = (
  imageViewData: FetchableData<DuplicatedGroupDataModel>,
) => ({
  type: UPDATE_DUPLICATED_GROUPS,
  imageViewData,
});

export const updateDuplicatedGroupsCount = (count: FetchableData<number>) => ({
  type: UPDATE_DUPLICATED_GROUPS_COUNT,
  count,
});
