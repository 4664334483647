import { FilterValue } from '../../AtomicFilters/FilterValue';

export class SimpleSearchFilterValue implements FilterValue {
  search: string;

  constructor(params: Pick<SimpleSearchFilterValue, 'search'>) {
    this.search = params.search || '';
  }

  static get defaultValue(): SimpleSearchFilterValue {
    return new SimpleSearchFilterValue({
      search: '',
    });
  }

  setSearch(search: string) {
    return new SimpleSearchFilterValue({ search });
  }
}
