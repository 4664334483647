import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommentRaw } from 'product-types/src/domain/comment/Comment';

interface ClusterCommentsRequest extends EndpointRequests {
  urlParams: { id: string };
}

interface ClusterCommentsResponse {
  comments: Array<CommentRaw>;
}

const clusterComments = new Endpoint<
  ClusterCommentsRequest,
  ClusterCommentsResponse
>({
  url: '/api/me/clusters/:id/comments',
  method: 'GET',
  headers: {},
});

export default clusterComments;
