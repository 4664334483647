import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import image from './image/index';
import post from './post/index';
import all from './all/index';

export interface OrganisationLabels extends EndpointContainer {
  image: typeof image;
  post: typeof post;
  all: typeof all;
}

const labels: OrganisationLabels = {
  image,
  post,
  all,
  injectHTTPClient(client: HttpClient) {
    image.injectHTTPClient(client);
    post.injectHTTPClient(client);
    all.injectHTTPClient(client);
  },
};

export default labels;
