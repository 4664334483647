import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdateNotificationsRequestModel extends EndpointRequests {
  data: {
    viewed: boolean;
  };
}

interface UpdateNotificationsResponseModel {
  id: any;
  message: string;
}

const updateNotifications = new Endpoint<
  UpdateNotificationsRequestModel,
  UpdateNotificationsResponseModel
>({
  url: '/api/me/notifications',
  method: 'PATCH',
  headers: {},
});

export default updateNotifications;
