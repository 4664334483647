import { takeEvery, call, put, select } from 'redux-saga/effects';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import {
  SearchImagesResponse,
  SearchedImageModel,
} from 'product-types/src/domain/duplicatedGroup/SearchedImage';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { AppState } from 'store/storeAccess';
import ProductMonitor from 'types/network/Http/productMonitor';
import { ImageModerationRawModel } from 'product-types/src/domain/image/ImageModerationModel';
import { notification } from 'antd';
import { loadGlobalDataAction } from 'layout/FiltersBar/actions';
import {
  addSimilarSearchForImage,
  resetState,
  setLoadingImage,
  updateImages,
} from './actions';
import {
  RUN_SIMILAR_SEARCH_FOR_IMAGE,
  SEARCH_IMAGE_BY_FILE,
  SEARCH_IMAGE_BY_ID,
  SEARCH_IMAGE_BY_URL,
  UPLOAD_IMAGE_TO_NAVEE,
  UPLOAD_IMAGE_XLSX,
} from './constants';
import { UploadImageState } from './reducer';

function* performImageSearchByURL(action) {
  yield put(
    setLoadingImage(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  const formData = new FormData();
  const { url } = action;
  formData.append('image_url', url);
  // write regexp to check if url contains only numbers
  try {
    const images: Array<SearchedImageModel> = yield select(
      (state: AppState) => state.uploadsImage?.images,
    );
    const image: SearchImagesResponse = yield call(
      ProductMonitor.endpoints.images.searchImages.call.bind(
        ProductMonitor.endpoints.images.searchImages,
      ),
      { data: formData, suppressToastrOnError: true },
    );
    yield put(
      updateImages(
        images.concat(
          new SearchedImageModel({
            duplicatedGroupId: image.duplicated_group_id,
            imageFound: image.image_found,
            imageId: image.image_id,
            imageLink: image.image_link,
            fileName: action.url.substring(action.url.lastIndexOf('/') + 1),
          }),
        ),
      ),
    );
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.ERROR,
          error: CustomErrorFactory.create(err),
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* performImageSearchByFile(action) {
  yield put(
    setLoadingImage(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  // write regexp to check if url contains only numbers
  try {
    const formData = new FormData();
    formData.append('image_file', action.file);
    const image: SearchImagesResponse = yield call(
      ProductMonitor.endpoints.images.searchImages.call.bind(
        ProductMonitor.endpoints.images.searchImages,
      ),
      { data: formData, suppressToastrOnError: true },
    );
    const images: Array<SearchedImageModel> = yield select(
      (state: AppState) => state.uploadsImage?.images,
    );
    yield put(
      updateImages(
        images.concat(
          new SearchedImageModel({
            duplicatedGroupId: image.duplicated_group_id,
            imageFound: image.image_found,
            imageId: image.image_id,
            imageLink: image.image_link,
            fileName: action.file.name,
          }),
        ),
      ),
    );
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.ERROR,
          error: CustomErrorFactory.create(err),
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* uploadXlsxToNavee(action) {
  yield put(
    setLoadingImage(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  // write regexp to check if url contains only numbers
  try {
    const formData = new FormData();
    formData.append('images', action.file);
    const result = yield call(
      ProductMonitor.endpoints.upload.uploadImageFile.call.bind(
        ProductMonitor.endpoints.upload.uploadImageFile,
      ),
      { data: formData, suppressToastrOnError: true },
    );
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
    notification.success({
      message: `Excel file processed: ${result?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(resetState());
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.ERROR,
          error: CustomErrorFactory.create(err),
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* performImageSearchByID(action) {
  yield put(
    setLoadingImage(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const images: Array<SearchedImageModel> = yield select(
      (state: AppState) => state.uploadsImage?.images,
    );
    const image: ImageModerationRawModel = yield call(
      ProductMonitor.endpoints.images.getImage.call.bind(
        ProductMonitor.endpoints.images.getImage,
      ),
      {
        urlParams: {
          id: action.id,
        },
        suppressToastrOnError: true,
      },
    );
    yield put(
      updateImages(
        images.concat(
          new SearchedImageModel({
            duplicatedGroupId: image.id,
            imageFound: true,
            imageId: image.image_id,
            imageLink: image.image_url,
            fileName: `IM#${action.id}`,
          }),
        ),
      ),
    );
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  } catch (err: any) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.ERROR,
          error: CustomErrorFactory.create(err),
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* runSimilarSearchForImage(action) {
  yield put(
    setLoadingImage(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    yield call(
      ProductMonitor.endpoints.search.getSimilarImages.call.bind(
        ProductMonitor.endpoints.search.getSimilarImages,
      ),
      {
        data: {
          image_url: action.image.imageLink,
        },
      },
    );
    yield put(addSimilarSearchForImage(action.image.uid, []));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* uploadImageToNavee() {
  yield put(
    setLoadingImage(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const data: UploadImageState = yield select(
      (state: AppState) => state.uploadsImage,
    );
    const allTags = new Set(
      data.tags.filter((tag) => tag.__isNew__).map((t) => t.name),
    );
    /* eslint-disable no-restricted-syntax */
    for (const tag of allTags) {
      yield call(
        ProductMonitor.endpoints.me.organisation.createTag.call.bind(
          ProductMonitor.endpoints.me.organisation.createTag,
        ),
        { data: { tag_type: 'duplicated_group', name: tag, id: 0 } },
      );
    }
    const result = yield call(
      ProductMonitor.endpoints.upload.uploadImage.call.bind(
        ProductMonitor.endpoints.upload.uploadImage,
      ),
      {
        data: {
          images: data.images.map((img) => ({
            url: img.imageLink,
            label: img.imageLabel?.value ?? '',
          })),
          global_tags: data.tags.map((t) => t.name),
          global_label: data.globalLabel?.value ?? '',
        },
      },
    );
    notification.success({
      message: result?.message,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(resetState());
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
  } finally {
    yield put(
      setLoadingImage(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
    yield put(loadGlobalDataAction());
  }
}

// Individual exports for testing
export default function* uploadsImageSaga() {
  yield takeEvery(SEARCH_IMAGE_BY_URL, performImageSearchByURL);
  yield takeEvery(SEARCH_IMAGE_BY_ID, performImageSearchByID);
  yield takeEvery(UPLOAD_IMAGE_TO_NAVEE, uploadImageToNavee);
  yield takeEvery(RUN_SIMILAR_SEARCH_FOR_IMAGE, runSimilarSearchForImage);
  yield takeEvery(SEARCH_IMAGE_BY_FILE, performImageSearchByFile);
  yield takeEvery(UPLOAD_IMAGE_XLSX, uploadXlsxToNavee);
}
