import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import formatNumber from 'product-utils/src/typography';
import Tag from 'product-ui/src/components/atoms/Tag';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

export const AccountInformationFilterValue: DisplayFilterComponent = (
  props: DisplayFilterComponentProps,
) => {
  const {
    filterValue: { name, value },
    onClickRemove,
  } = props;
  const followersTag =
    value !== '' ? (
      <Tag
        tag={{
          name:
            name === FilterTypeEnum.minimumFollowersCount
              ? `Min Followers: ${formatNumber(value as number)}`
              : `Max Followers: ${formatNumber(value as number)}`,
          id: '',
        }}
        iconPrefix={<NaveeIcon.ShadowFollower width={12} height={12} />}
        onClickRemove={onClickRemove}
      />
    ) : null;

  const contactType =
    value !== '' ? (
      <Tag
        tag={{
          name: (value as ContactMainInfo).label,
          id: value,
        }}
        iconPrefix={<NaveeIcon.ShadowFollower width={12} height={12} />}
        onClickRemove={onClickRemove}
      />
    ) : null;
  const filterTypeTagMap = {
    [FilterTypeEnum.minimumFollowersCount]: followersTag,
    [FilterTypeEnum.maximumFollowersCount]: followersTag,
    [FilterTypeEnum.contactTypes]: contactType,
  };

  return filterTypeTagMap[name] || null;
};
