export const MODERATE_SUGGESTED_FEATURE =
  'app/ImageModeation/MODERATE_SUGGESTED_FEATURE';
export const PERFORM_DUPLICATED_GROUP_MODERATION =
  'app/ImageModeation/PERFORM_DUPLICATED_GROUP_MODERATION';
export const MODERATE_IMAGE_AND_UPDATE_IN_PLACE =
  'app/ImageModeation/MODERATE_IMAGE_AND_UPDATE_IN_PLACE';
export const LOAD_NEXT_DUPLICATE_GROUP_TO_MODERATE =
  'app/ImageModeation/LOAD_NEXT_DUPLICATE_GROUP_TO_MODERATE';
export const LOAD_PREV_DUPLICATE_GROUP_TO_MODERATE =
  'app/ImageModeation/LOAD_PREV_DUPLICATE_GROUP_TO_MODERATE';
export const LOAD_ASSOCIATED_PICTURES =
  'app/ImageModeation/LOAD_ASSOCIATED_PICTURES';
export const REFRESH_DUPLICATED_GROUP =
  'app/ImageModeation/REFRESH_DUPLICATED_GROUP';
export const LOAD_DUPLICATED_GROUP_RESOURCES =
  'app/ImageModeation/LOAD_DUPLICATED_GROUP_RESOURCES';
export const UPDATE_ASSOCIATED_PICTURES =
  'app/ImageModeation/UPDATE_ASSOCIATED_PICTURES';
export const UPDATE_DUPLICATE_GROUP_FOR_MODERATION =
  'app/ImageModeation/UPDATE_DUPLICATE_GROUP_FOR_MODERATION';
export const DELETE_MODERATION_REASON =
  'app/ImageModeation/DELETE_MODERATION_REASON';
