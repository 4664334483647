import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ModerateWebsiteRequest extends EndpointRequests {
  data: any;
}

const moderateAndReturnAccount = new Endpoint<ModerateWebsiteRequest, any>({
  url: '/api/me/accounts_moderation/moderate',
  method: 'PUT',
  headers: {},
});

export default moderateAndReturnAccount;
