import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface PrevAccountToModerateRequest extends EndpointRequests {
  data: FeedRequestParameter;
}

interface PrevAccountToModerateResponse {}

const prevAccountToModerate = new Endpoint<
  PrevAccountToModerateRequest,
  PrevAccountToModerateResponse
>({
  url: '/api/me/accounts_moderation/previous_account_to_moderate',
  method: 'POST',
  headers: {},
});

export default prevAccountToModerate;
