import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { AccountAssociatedImageRaw } from 'product-types/src/domain/image/AssociatedImage';

interface AccountImagesRequestModel extends EndpointRequests {
  urlParams: { id: string };
}

const getAccountImages = new Endpoint<
  AccountImagesRequestModel,
  Array<AccountAssociatedImageRaw>
>({
  url: '/api/me/accounts/:id/images',
  method: 'GET',
  headers: {},
});

export default getAccountImages;
