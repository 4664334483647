import { v4 as uuid } from 'uuid';
import { get_start_date_based_on_interval } from 'product-utils/src/date';
import { CrawlingDateValueEnum } from 'product-types/src/domain/date/CrawlingDate';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { DateFilterValueEnum } from 'product-types/src/domain/date/Date';
import dayjs from 'dayjs';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { CrawlingDateFilterValue as CrawlingDateFilterValueComponent } from '../../../../components/Filters/CrawlingDateFilter/CrawlingDateFilterValue';
import { CrawlingDateFilterComponent } from '../../../../components/Filters/CrawlingDateFilter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { CrawlingDateFilterValue } from './CrawlingDateFilterValue';

export interface CrawlingDateFilterQueryValue extends QueryValue {
  start_date?: string | null;
  end_date?: string | null;
  date_filter_type: CrawlingDateValueEnum;
  interval: DateFilterValueEnum;
}

export class CrawlingDateFilter implements Filter {
  uuid: string;

  label: string;

  value: CrawlingDateFilterValue;

  startDateFormat: string;

  endDateFormat: string;

  constructor(
    params?: Partial<Pick<CrawlingDateFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || CrawlingDateFilterValue.defaultValue;

    this.startDateFormat = 'yyyy-MM-dd';
    this.endDateFormat = 'yyyy-MM-dd';
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    if (this.value.interval?.value === DateFilterValueEnum.exactDates) {
      const { startDate, endDate } = this.value;
      return [
        this.value.startDate &&
          this.value.startDate.isValid() && {
            name: FilterTypeEnum.intervalWithIcon,
            value: {
              label: [startDate, endDate]
                .filter((v) => v.isValid())
                .map((v) => dayjs(v).format('DD/MM/YYYY'))
                .join(' - ')
                .concat(` (${this.value.crawlingDate.label})`),
              icon: this.value.crawlingDate.icon,
            },
            uuid: this.uuid,
            key: `${this.uuid}${this.value.startDate.toISOString()}startDate`,
          },
      ].filter((v) => !!v) as DisplayingFilterValue[];
    }
    return [
      {
        name: FilterTypeEnum.intervalWithIcon,
        value: {
          label: (this.value.interval?.label ?? '').concat(
            ` (${this.value.crawlingDate.label})`,
          ),
          icon: this.value.crawlingDate.icon,
        },
        uuid: this.uuid,
        key: this.uuid + this.value.interval,
      },
    ];
  }

  get component() {
    return CrawlingDateFilterComponent;
  }

  get displayFilterComponent() {
    return CrawlingDateFilterValueComponent;
  }

  get queryFilterValue(): CrawlingDateFilterQueryValue {
    if (this.value.interval?.value === DateFilterValueEnum.exactDates) {
      return {
        start_date: dayjs(this.value.startDate).isValid()
          ? dayjs(this.value.startDate).format('YYYY-MM-DD')
          : null,
        end_date: dayjs(this.value.endDate).isValid()
          ? dayjs(this.value.endDate).format('YYYY-MM-DD')
          : null,
        date_filter_type: this.value.crawlingDate.value,
        interval: this.value.interval?.value,
      };
    }
    if (this.value.interval?.value === DateFilterValueEnum.untilToday) {
      return {
        interval: this.value.interval?.value,
        date_filter_type: this.value.crawlingDate.value,
      };
    }
    return {
      // todo:  rewrite get_start_date_based_on_interval function with enum and typescript
      start_date: dayjs(
        get_start_date_based_on_interval(this.value.interval?.value),
      ).isValid()
        ? dayjs(
            get_start_date_based_on_interval(this.value.interval?.value),
          ).format('YYYY-MM-DD')
        : null,
      date_filter_type: this.value.crawlingDate.value,
      interval: this.value.interval?.value,
    };
  }

  removeFilterValue() {
    this.value = CrawlingDateFilterValue.defaultValue;
  }

  static readFilterFromQuery(
    value: CrawlingDateFilterValue,
  ): CrawlingDateFilter {
    return new CrawlingDateFilter({
      value: CrawlingDateFilterValue.readFilterFromQuery(value),
    });
  }

  static readFilterFromSavedFitler(
    props: SavedFilterModel,
    value: CrawlingDateFilterValue,
  ): CrawlingDateFilter {
    return new CrawlingDateFilter({
      value: CrawlingDateFilterValue.readFromSavedFilter(props, value),
    });
  }
}
