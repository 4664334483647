import { MessageModel } from './Message';

interface CloseTabMessagePayload {
  data: any;
}

export const CloseTabMessageType = 'CloseTabMessage';

export class CloseTabMessage extends MessageModel {
  type = CloseTabMessageType;

  payload?: CloseTabMessagePayload;

  constructor(data: Omit<CloseTabMessage, 'type'>) {
    super({
      type: CloseTabMessageType,
      payload: data.payload,
    });
  }

  toString(): string {
    return super.toString();
  }
}
