import { v4 as uuid } from 'uuid';
import { SimpleSearchFilterComponent } from 'components/Filters/SimpleSearchFilter';
import { Filter } from '../../AtomicFilters/Filter';
import { SimpleSearchFilterValue } from './SimpleSearchFilterValue';

export class SimpleSearchFilter implements Filter {
  uuid: string;

  label: string;

  value: SimpleSearchFilterValue;

  constructor(
    params?: Partial<Pick<SimpleSearchFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || SimpleSearchFilterValue.defaultValue;
  }

  removeFilterValue() {
    throw new Error('Method not implemented.');
  }

  get queryFilterValue() {
    return {};
  }

  get displayingFilterValue() {
    return [];
  }

  get displayFilterComponent() {
    return null;
  }

  get component() {
    return SimpleSearchFilterComponent;
  }
}
