import { v4 as uuid } from 'uuid';
import { Label } from 'product-types/src/domain/label/Label';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { NewLabelExclusionFilter } from '../../../../components/Filters/NewLabelExclusionFilter';
import { NewLabelExclusionFilterValue } from '../../../../components/Filters/NewLabelExclusionFilter/NewLabelExclusionFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import {
  LabelFilterValue,
  LabelTypes,
  readFilterFromQueryProps,
} from './LabelFilterValue';

export interface LabelFilterQueryValue extends QueryValue {
  account_status_type: Array<Label>;
  hise: Array<Label>;
  status_type: Array<Label>;
}

export class LabelFilter implements Filter {
  uuid: string;

  label: string;

  value: LabelFilterValue;

  constructor(params?: Partial<Pick<LabelFilter, 'uuid' | 'label' | 'value'>>) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || LabelFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    const result: DisplayingFilterValue[] = [];
    if (this.value.postLabelType === LabelTypes.All) {
      result.push({
        name: FilterTypeEnum.postLabel,
        value: { name: 'All Labels - Post' },
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.postLabel + LabelTypes.All,
      });
    } else {
      result.push(
        ...this.value.post.map((label) => ({
          name: FilterTypeEnum.postLabel,
          value: label,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.postLabel + (label as Label).name,
        })),
      );
    }
    if (this.value.imageLabelType === LabelTypes.All) {
      result.push({
        name: FilterTypeEnum.imageLabel,
        value: { name: 'All Labels - Image' },
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.imageLabel + LabelTypes.All,
      });
    } else {
      result.push(
        ...this.value.image.map((label) => ({
          name: FilterTypeEnum.imageLabel,
          value: label,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.imageLabel + (label as Label).name,
        })),
      );
    }
    if (this.value.accountLabelType === LabelTypes.All) {
      result.push({
        name: FilterTypeEnum.accountLabel,
        value: { name: 'All Labels - Account' },
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.accountLabel + LabelTypes.All,
      });
    } else {
      result.push(
        ...this.value.account.map((label) => ({
          name: FilterTypeEnum.accountLabel,
          value: label,
          uuid: this.uuid,
          key: this.uuid + FilterTypeEnum.accountLabel + (label as Label).name,
        })),
      );
    }
    return result;
  }

  get component() {
    return NewLabelExclusionFilter;
  }

  get displayFilterComponent() {
    return NewLabelExclusionFilterValue;
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.imageLabel) {
      this.value =
        this.value.imageLabelType === LabelTypes.All
          ? new LabelFilterValue({
              ...this.value,
              image: [],
              imageLabelType: LabelTypes.Null,
            })
          : this.value.removeImageLabel(removingFilter.value as Label);
    } else if (removingFilter.name === FilterTypeEnum.postLabel) {
      this.value =
        this.value.postLabelType === LabelTypes.All
          ? new LabelFilterValue({
              ...this.value,
              post: [],
              postLabelType: LabelTypes.Null,
            })
          : this.value.removePostLabel(removingFilter.value as Label);
    } else if (removingFilter.name === FilterTypeEnum.accountLabel) {
      this.value =
        this.value.accountLabelType === LabelTypes.All
          ? new LabelFilterValue({
              ...this.value,
              account: [],
              accountLabelType: LabelTypes.Null,
            })
          : this.value.removeAccountLabel(removingFilter.value as Label);
    }
    return new LabelFilter(this);
  }

  get queryFilterValue(): LabelFilterQueryValue {
    return {
      account_status_type: this.value.account,
      hise: this.value.image,
      status_type: this.value.post,
    };
  }

  static readFilterFromQuery(props: readFilterFromQueryProps): LabelFilter {
    return new LabelFilter({
      value: LabelFilterValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): LabelFilter {
    return new LabelFilter({
      value: LabelFilterValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
