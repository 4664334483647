import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { SearchedImageModel } from 'product-types/src/domain/duplicatedGroup/SearchedImage';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { Label } from 'product-types/src/domain/label/Label';
import {
  ADD_SIMILAR_SEARCH_FOR_IMAGE,
  RESET_STATE,
  RUN_SIMILAR_SEARCH_FOR_IMAGE,
  SEARCH_IMAGE_BY_FILE,
  SEARCH_IMAGE_BY_ID,
  SEARCH_IMAGE_BY_URL,
  UPDATE_GLOBAL_IMAGE_LABEL,
  UPDATE_IMAGES_LIST,
  UPDATE_IMAGES_TAGS_LIST,
  UPDATE_LOADING_IMAGE,
  UPLOAD_IMAGE_TO_NAVEE,
  UPLOAD_IMAGE_XLSX,
} from './constants';

export function setLoadingImage(loading: FetchableData<null>) {
  return {
    type: UPDATE_LOADING_IMAGE,
    loading,
  };
}

export const updateGlobalImageLabel = (label: Label | null) => ({
  type: UPDATE_GLOBAL_IMAGE_LABEL,
  label,
});

export function updateImages(images: Array<SearchedImageModel>) {
  return {
    type: UPDATE_IMAGES_LIST,
    images,
  };
}

export function updateImagesTags(tags: Array<TagModel>) {
  return {
    type: UPDATE_IMAGES_TAGS_LIST,
    tags,
  };
}

export function performImageSearchByFile(file: File) {
  return {
    type: SEARCH_IMAGE_BY_FILE,
    file,
  };
}

export function uploadXlsxToNavee(file: File) {
  return {
    type: UPLOAD_IMAGE_XLSX,
    file,
  };
}

export function searchImageByURL(url: string) {
  return {
    type: SEARCH_IMAGE_BY_URL,
    url,
  };
}

export function searchImageByID(id: string) {
  return {
    type: SEARCH_IMAGE_BY_ID,
    id,
  };
}

export function runSimilarSearchForImage(image: SearchedImageModel) {
  return {
    type: RUN_SIMILAR_SEARCH_FOR_IMAGE,
    image,
  };
}

export function sendImagesToNavee() {
  return {
    type: UPLOAD_IMAGE_TO_NAVEE,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function addSimilarSearchForImage(
  uid: string,
  images: Array<SearchedImageModel>,
) {
  return {
    type: ADD_SIMILAR_SEARCH_FOR_IMAGE,
    uid,
    images,
  };
}
