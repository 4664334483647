import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import {
  readDashboardFilter,
  readClustersFilter,
  readUploadHistoryFilter,
  loadGlobalDataAction,
} from 'layout/FiltersBar/actions';
import { AppState } from '../../../store/storeAccess';
import { getIsAuthenticatedFromLocalStorage } from '../../LoginPage/reducer';
import { useBrowserNavigation } from '../../../hooks/useBrowserNavigation';

export function useLoadSystemWideResources() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: AppState) => state.loginPage);
  const authenticated = getIsAuthenticatedFromLocalStorage();
  useEffect(() => {
    if (!authenticated || currentUser.state !== FetchableDataState.LOADED) {
      return;
    }
    // THIS DISPATACHES ARE TIMLY SHOULD BE REMOVED IN NEXT COMMITS
    // WE SHOULD USE SEPARATE SLICE FOR LOADABLE DATA
    dispatch(loadGlobalDataAction());
  }, [authenticated, currentUser.state]);
}

export function useRefreshFiltersOnNavigation() {
  const dispatch = useDispatch();

  useBrowserNavigation((location) => {
    switch (location.pathname) {
      case '/dashboard':
        dispatch(readDashboardFilter());
        break;
      case '/cluster':
        dispatch(readClustersFilter());
        break;
      case '/upload/history':
        dispatch(readUploadHistoryFilter());
        break;
      default:
    }
  });
}
