import { AccountSuggestionRawModel } from 'product-types/src/domain/account/AccountSuggestion';
import { ContactForSaving } from 'product-types/src/domain/contact/Contact';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
interface AccountResponseModel {
  [key: string]: Omit<AccountSuggestionRawModel, 'main_id'>;
}

interface AccountRequestModel extends EndpointRequests {
  data: {
    contacts: Array<ContactForSaving>;
  };
  urlParams: {
    accountId: string;
  };
}

const setContacts = new Endpoint<AccountRequestModel, any>({
  url: '/api/me/accounts/:accountId/contact',
  method: 'PUT',
  headers: {},
});

export default setContacts;
