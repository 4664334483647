import {
  SavedFilterRaw,
  SavedFilterType,
} from 'product-types/src/domain/savedFilters/SavedFilters';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface FilterPresetsParams {
  filter_type: SavedFilterType;
}

interface FilterPresetsRequest extends EndpointRequests {
  params: FilterPresetsParams;
}

type FilterPresetsResponse = Array<SavedFilterRaw>;

const filterPresets = new Endpoint<FilterPresetsRequest, FilterPresetsResponse>(
  {
    url: '/api/me/filter_presets',
    method: 'GET',
    headers: {},
  },
);

export default filterPresets;
