import dayjs from 'dayjs';
import { format_time_ago_legacy } from 'product-utils/src/time';
import {
  CommentType,
  ModerationCommentModel,
  ModerationCommentRaw,
} from './ModerationComment';

export enum NotificationType {
  comment = 'comment',
  upload = 'upload',
}

export interface NotificationRawModel {
  id: number;
  created_at: string;
  text: string;
  viewed: boolean;
  illustration_picture_url: string;
  notification_type: string;
  moderation_comment: ModerationCommentRaw;
}

export class NotificationModel {
  id: number;

  createdAt: dayjs.Dayjs;

  text: string;

  viewed: boolean;

  pictureUrl: string;

  notificationType: NotificationType;

  moderationComment: ModerationCommentModel | null;

  constructor(
    prop: Pick<
      NotificationModel,
      | 'createdAt'
      | 'id'
      | 'moderationComment'
      | 'notificationType'
      | 'pictureUrl'
      | 'text'
      | 'viewed'
    >,
  ) {
    this.createdAt = prop.createdAt;
    this.id = prop.id;
    this.moderationComment = prop.moderationComment;
    this.notificationType = prop.notificationType;
    this.pictureUrl = prop.pictureUrl;
    this.text = prop.text;
    this.viewed = prop.viewed;
  }

  get createdAtFormatted(): string {
    return format_time_ago_legacy(this.createdAt.unix());
  }

  get href(): string {
    if (
      this.notificationType === NotificationType.comment &&
      this.moderationComment
    ) {
      switch (this.moderationComment.commentType) {
        case CommentType.account: {
          const typeOfAccount =
            this.moderationComment.commentParentId[0] === 'p'
              ? 'account'
              : 'website';
          return `/${typeOfAccount}/${
            this.moderationComment.commentParentId.split('::')[1]
          }#${this.moderationComment.id}`;
        }
        case CommentType.duplicatedGroup: {
          return `/image/${this.moderationComment.commentParentId}#${this.moderationComment.id}`;
        }
        default: {
          return `/${this.moderationComment.commentType}/${this.moderationComment.commentParentId}#${this.moderationComment.id}`;
        }
      }
    } else {
      return '/upload/history';
    }
  }

  static createFromRaw(raw: NotificationRawModel): NotificationModel {
    return new NotificationModel({
      id: raw.id,
      createdAt: dayjs(parseInt(raw.created_at, 10) * 1000),
      text: raw.text,
      viewed: raw.viewed,
      pictureUrl: raw.illustration_picture_url,
      notificationType: raw.notification_type as NotificationType,
      moderationComment: raw.moderation_comment
        ? ModerationCommentModel.createFromRaw(raw.moderation_comment)
        : null,
    });
  }
}
