import { v4 as uuid } from 'uuid';
import SavedFiltersFilter from 'components/Filters/SavedFiltersFilter/index';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { FiltersContainer } from '../../MoleculesFilter/MolecileFilter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter } from '../../AtomicFilters/Filter';

export class SavedFilter implements Filter {
  uuid: string;

  label: string;

  value: any;

  filters?: FiltersContainer;

  constructor(
    params?: Partial<Pick<SavedFilter, 'uuid' | 'label' | 'value' | 'filters'>>,
    filters?: FiltersContainer,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value;
    this.filters = filters || undefined;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [];
  }

  get component() {
    return SavedFiltersFilter;
  }

  displayFilterComponent() {
    return null;
  }

  get queryFilterValue(): QueryValue {
    return {} as QueryValue;
  }

  static readFilterFromQuery(): SavedFilter {
    return new SavedFilter();
  }

  removeFilterValue() {
    return this;
  }
}
