export enum TakeDownStatusValueEnum {
  true = 'true',
  false = 'false',
  null = '',
}

export const mapTakedownValue = (value: 'true' | 'false' | '') => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    case '':
      return null;
    default:
      throw new Error(`Unknown value ${value}`);
  }
};

export interface TakedownStatus {
  label: string;
  value: 'true' | 'false' | '';
}

const TakedownStatusDirectory = {
  true: {
    value: TakeDownStatusValueEnum.true,
    label: 'Down',
  },
  false: {
    value: TakeDownStatusValueEnum.false,
    label: 'Active',
  },
  null: {
    value: TakeDownStatusValueEnum.null,
    label: 'Unknown',
  },
};

export const TakeDownStatuses: TakedownStatus[] = Object.values(
  TakedownStatusDirectory,
);

export const GetTakeDownStatusFilterOptionByValue = (value) =>
  TakeDownStatuses.find((takeDown) => takeDown.value === value);
