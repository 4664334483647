/*
 *
 * NotificationsPage reducer
 *
 */
import { produce } from 'immer';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { NotificationModel } from 'product-types/src/domain/notification/Notification';
import { UPDATE_NOTIFICATIONS, UPDATE_NOTIFICATIONS_HOME } from './constants';

export const createInitialState = () => ({
  notifications: FetchableData.default<Array<NotificationModel>>(),
  notificationsHome: FetchableData.default<Array<NotificationModel>>(),
});

export type NotificationsPageState = ReturnType<typeof createInitialState>;

/* eslint-disable default-case, no-param-reassign */
const notificationsPageReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_NOTIFICATIONS: {
        draft.notifications = action.notifications;
        break;
      }
      case UPDATE_NOTIFICATIONS_HOME: {
        draft.notificationsHome = action.notificationsHome;
        break;
      }
      default:
        break;
    }
  });

export default notificationsPageReducer;
