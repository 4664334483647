import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { LinkHandlingState } from 'containers/LoginPage/reducer';
import { useSelector } from 'react-redux';

import ConfirmSelectionOrganisation from '../ConfirmSelectionOrganisation/loadable';
import SelectOrganisation from '../SelectOrganisation/loadable';
import LoginPage from '../LoginPage/loadable';
import SetPasswordPage from '../SetPassword/loadable';
import ResetPasswordPage from '../ResetPassword/loadable';

import DashboardPage from '../DashboardPage/loadable';
// import TagPostsPage from '../TagPostsPage/loadable';
import NotFoundPage from '../NotFoundPage/loadable';

import UploadImage from '../Uploads/Image/loadable';
import UploadPost from '../Uploads/Post/loadable';
import UploadAccount from '../Uploads/Account/loadable';
import UploadHistory from '../UploadHistory/loadable';

import PostViewContainer from '../PostViewContainer/loadable';
import ImageViewContainer from '../ImageView/loadable';
import AccountViewContainer from '../AccountViewContainer/loadable';
import WebsiteViewContainer from '../WebsiteViewContainer/loadable';
import ClusterViewContainer from '../ClusterViewContainer/loadable';

import PostModerationContainer from '../PostModerationContainer/loadable';
import ImageModerationContainer from '../ImageModerationContainer/loadable';
import AccountModerationContainer from '../AccountModerationContainer/loadable';
import WebsiteModerationContainer from '../WebsiteModerationContainer/loadable';
import ClusterModerationContainer from '../ClusterModerationContainer/loadable';

import NotificationsPage from '../NotificationsPage/loadable';
import SettingsPage from '../SettingsPage/loadable';

import { AppState } from '../../store/storeAccess';
import UserLoadingSkeleton from './components/UserLoadingSkeleton';
import PrivateRoute from './components/PrivateRoute';
import RouteForUnauthorized from './components/RouteForUnauthorized';

export const NOT_HANDLED_LINKS = [
  '/',
  '/login',
  '/select-organisation',
  '/confirm-select-organisation',
];
export default function Router() {
  const linkHandled = useSelector(
    (state: AppState) => state.loginPage?.linkHandled,
  );
  const organisationUid = useSelector(
    (state: AppState) => state.loginPage?.currentUser?.data?.organisation?.uid,
  );

  return (
    <Switch>
      <RouteForUnauthorized path="/login" component={LoginPage} />
      <Route exact path="/set-password" render={SetPasswordPage} />
      <Route exact path="/reset-password" render={ResetPasswordPage} />
      <PrivateRoute
        exact
        path="/confirm-select-organisation"
        component={ConfirmSelectionOrganisation}
      />
      <PrivateRoute
        exact
        path="/select-organisation"
        component={SelectOrganisation}
      />
      {linkHandled !== LinkHandlingState.handled && <UserLoadingSkeleton />}
      <Redirect exact from="/" to={`/${organisationUid}/post`} />
      <PrivateRoute exact path="/:orgId/dashboard" component={DashboardPage} />
      <PrivateRoute path="/:orgId/settings" component={SettingsPage} />
      {/* <PrivateRoute exact path="/:orgId/tag_posts" component={TagPostsPage} /> */}
      <PrivateRoute
        exact
        path="/:orgId/image/:id/:moderation?"
        component={ImageModerationContainer}
      />
      <PrivateRoute
        exact
        path="/:orgId/post/:id/:moderation?"
        component={PostModerationContainer}
      />
      <PrivateRoute
        exact
        path="/:orgId/account/:id/:moderation?"
        component={AccountModerationContainer}
      />
      <PrivateRoute
        exact
        path="/:orgId/website/:id/:moderation?"
        component={WebsiteModerationContainer}
      />
      <PrivateRoute
        exact
        path="/:orgId/cluster/:id"
        component={ClusterModerationContainer}
      />
      <PrivateRoute
        exact
        path="/:orgId/upload/add/image"
        component={UploadImage}
      />
      <PrivateRoute
        exact
        path="/:orgId/upload/add/post"
        component={UploadPost}
      />
      <PrivateRoute
        exact
        path="/:orgId/upload/add/account"
        component={UploadAccount}
      />
      <PrivateRoute
        exact
        path="/:orgId/notifications"
        component={NotificationsPage}
      />
      <PrivateRoute
        exact
        path="/:orgId/upload/history/:filter_option?"
        component={UploadHistory}
      />
      <PrivateRoute
        exact
        path="/:orgId/account"
        component={AccountViewContainer}
      />
      <PrivateRoute
        exact
        path="/:orgId/website"
        component={WebsiteViewContainer}
      />
      <PrivateRoute
        exact
        path="/:orgId/cluster"
        component={ClusterViewContainer}
      />
      <PrivateRoute exact path="/:orgId/image" component={ImageViewContainer} />
      <PrivateRoute exact path="/:orgId/post" component={PostViewContainer} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
