import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';

export function useBrowserNavigation(
  cb: (location: Location, direction?: 'FORWARD' | 'BACKWARD') => void,
  navAction: 'POP' | 'PUSH' | 'REPLACE' = 'POP',
  deps: unknown[] = [],
) {
  const history = useHistory();

  const locationKeys = useRef<Array<string | undefined>>([]);
  const lastLocationKey = useRef<string | undefined>();

  useEffect(
    () =>
      history.listen((location, action) => {
        if (history.action === 'PUSH') {
          locationKeys.current = [location.key];
        }
        if (action === navAction) {
          cb(location);
        }

        if (history.action === 'POP') {
          if (locationKeys.current[1] === location.key) {
            const [, ...newKeys] = locationKeys.current;
            locationKeys.current = newKeys;
            lastLocationKey.current = location.key;
            cb(location, 'FORWARD');
          } else {
            locationKeys.current = [location.key, ...locationKeys.current];
            lastLocationKey.current = location.key;
            cb(location, 'BACKWARD');
          }
        }

        if (history.action === 'REPLACE') {
          const index = locationKeys.current.findIndex(
            (key) => key === lastLocationKey.current,
          );
          if (index !== -1) {
            locationKeys.current[index] = location.key;
          }
          if (locationKeys.current.length === 0) {
            locationKeys.current = [location.key];
          }
        }

        lastLocationKey.current = location.key;
      }),
    [history, cb, navAction, locationKeys, lastLocationKey, ...deps],
  );
}
