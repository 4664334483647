export class ListedBrandInfo {
  hasInfo: boolean;

  brand: string;

  constructor(prop: Pick<ListedBrandInfo, 'hasInfo' | 'brand'>) {
    this.hasInfo = prop.hasInfo;
    this.brand = prop.brand;
  }
}
