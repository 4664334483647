export class Cluster {
  id: number;

  contactInfo: number;

  description: Array<string>;

  estimatedGeo: null | string;

  imageLink: string;

  infringementPercentage: number;

  moderationPercentage: number;

  name: string;

  rank: number;

  totalPosts: number;

  constructor(
    prop: Pick<
      Cluster,
      | 'id'
      | 'contactInfo'
      | 'description'
      | 'estimatedGeo'
      | 'imageLink'
      | 'infringementPercentage'
      | 'moderationPercentage'
      | 'name'
      | 'rank'
      | 'totalPosts'
    >,
  ) {
    this.id = prop.id;
    this.contactInfo = prop.contactInfo;
    this.description = prop.description;
    this.estimatedGeo = prop.estimatedGeo;
    this.imageLink = prop.imageLink;
    this.infringementPercentage = prop.infringementPercentage;
    this.moderationPercentage = prop.moderationPercentage;
    this.name = prop.name;
    this.rank = prop.rank;
    this.totalPosts = prop.totalPosts;
  }
}
