import { UploadAccountRaw } from 'product-types/src/domain/uploads/uploadAccount';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ManualAccountUploadRequestModel extends EndpointRequests {
  data: {
    accounts: Array<UploadAccountRaw>;
    global_label: string;
    global_tags: Array<string>;
    global_vendor: string;
    override: boolean | null;
  };
}

export interface ManualAccountUploadResponse {
  existing_items_number: number | null;
  message: string;
  title: string;
}

const uploadAccountManual = new Endpoint<
  ManualAccountUploadRequestModel,
  ManualAccountUploadResponse
>({
  url: `/api/me/uploads/account/manual`,
  method: 'POST',
  headers: {},
});

export default uploadAccountManual;
