import { HashtagRaw } from 'product-types/src/domain/hashtag/Hashtag';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface SaveKeywoardResponseModel {
  keywords: Array<HashtagRaw>;
}

interface SaveHashtagRequestModel extends EndpointRequests {
  data: {
    keywords: Array<HashtagRaw>;
  };
}

const saveHashtags = new Endpoint<
  SaveHashtagRequestModel,
  SaveKeywoardResponseModel
>({
  url: '/api/me/crawling_config/keywords',
  method: 'POST',
  headers: {},
});

export default saveHashtags;
