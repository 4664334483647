import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { AccountAssociatedImageRawImageView } from 'product-types/src/domain/image/AssociatedImage';

interface AssociatedPicturesRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
  params: {
    offset: number;
    perpage: number;
  };
}

export interface AssociatedPicturesResponseModel {
  total: number;
  duplicated_groups: Array<AccountAssociatedImageRawImageView>;
}

const associatedPictures = new Endpoint<
  AssociatedPicturesRequestModel,
  AssociatedPicturesResponseModel
>({
  url: '/api/me/duplicated_groups/:id/associated_pictures',
  method: 'GET',
  headers: {},
});

export default associatedPictures;
