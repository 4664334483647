export interface FeatureFlagRaw {
  is_ai: boolean;
  label: string;
  value: boolean;
}

export class FeatureFlagModel {
  isAi: boolean;

  label: string;

  value: boolean;

  constructor(props: Pick<FeatureFlagModel, 'isAi' | 'label' | 'value'>) {
    this.isAi = props.isAi;
    this.label = props.label;
    this.value = props.value;
  }

  static fromRaw(raw: FeatureFlagRaw): FeatureFlagModel {
    return new FeatureFlagModel({
      isAi: raw.is_ai,
      label: raw.label,
      value: raw.value,
    });
  }
}
