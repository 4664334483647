/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import {
  disablePageScroll,
  enablePageScroll,
  addLockableSelector,
  clearQueueScrollLocks,
} from 'scroll-lock';

export const ScrollContext = createContext<ScrollProviderContext>(
  {} as ScrollProviderContext,
);

export interface ScrollProviderContext {
  filtersRefs: any[];
  setFiltersRefs: () => void;
  references: any;
  addReference: (arg1: string) => void;
  removeReference: (arg1: string) => void;
  lockScroll: (arg1: string) => void;
  unLockScroll: (arg1: string) => void;
  hasReference: (arg1: string) => boolean;
}

const ScrollProvider = ({ children }: { children: any }) => {
  const [filtersRefs, setFiltersRefs] = useState({});
  const [references, setReferences] = useState({});
  const selector = '#scrollableNode';
  addLockableSelector(selector);

  const addReference = (referenceName) => {
    setReferences((prevReferences) => ({
      ...prevReferences,
      [referenceName]: false,
    }));
  };

  const hasReference = (uuid: string) => Reflect.has(references, uuid);

  const removeReference = (referenceName) => {
    setReferences((prevReferences) =>
      Reflect.ownKeys(prevReferences).reduce((acc, key) => {
        if (key === referenceName) {
          return acc;
        }
        return {
          ...acc,
          [key]: prevReferences[key],
        };
      }, {}),
    );
  };

  useEffect(() => {
    if (Object.keys(references).some((key) => references[key])) {
      disablePageScroll();
    } else {
      clearQueueScrollLocks();
      enablePageScroll();
    }
  }, [references]);

  const lockScroll = (uuid: string) => {
    if (Reflect.has(references, uuid)) {
      setReferences((prevReferences) => ({
        ...prevReferences,
        [uuid]: true,
      }));
    } else {
      throw Error(`lockScroll ${uuid} is not registered in scroll provider`);
    }
  };

  const unLockScroll = (uuid: string) => {
    if (Reflect.has(references, uuid)) {
      setReferences((prevReferences) => ({
        ...prevReferences,
        [uuid]: false,
      }));
    } else {
      throw Error(`unLockScroll ${uuid} is not registered in scroll provider`);
    }
  };

  const Context = useMemo(
    () => ({
      filtersRefs,
      setFiltersRefs,
      references: JSON.parse(JSON.stringify(references)),
      addReference,
      removeReference,
      lockScroll,
      unLockScroll,
      hasReference,
    }),
    [filtersRefs, references],
  );

  return (
    <ScrollContext.Provider value={Context as ScrollProviderContext}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
