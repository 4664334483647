import { Alert, Flex, Modal } from 'antd';
import { saveFilterAction } from 'layout/FiltersBar/actions';
import Button, {
  ButtonVariant,
} from 'product-ui/src/components/atoms/Button/Button';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import Typography from 'product-ui/src/components/atoms/Typography';
import { removeEmptyProperties } from 'product-utils/src/object';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/storeAccess';
import Network from 'types/network';

interface AddNewFilterModalProps {
  showAddNewFilterModal: boolean;
  setShowAddNewFilterModal: (show: boolean) => void;
}

const modalStyles = {
  mask: {
    zIndex: 1100,
  },
  header: {
    marginBottom: '16px',
  },
  content: {
    padding: '24px',
  },
  footer: {
    background: 'white',
  },
};

const wrapProps = {
  style: {
    zIndex: 1150,
  },
};

export const AddNewFilterModal = ({
  showAddNewFilterModal,
  setShowAddNewFilterModal,
}: AddNewFilterModalProps) => {
  const [saveFiltername, setSaveFiltername] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const url = new URL(window.location.href);
  const current_screen = url.pathname.split('/')[2];
  const dispatch = useDispatch();
  const feed = useSelector((state: AppState) => state.filters_bar?.feed);
  const clusters = useSelector(
    (state: AppState) => state.filters_bar?.clusters,
  );

  const saveFilterHandler = useCallback(() => {
    let requestParameters = {};
    switch (current_screen) {
      case 'post':
        requestParameters = Network.Post.getRequestParameter({
          ...feed.queryParams,
          followers_count: [
            feed.queryParams.minimum_followers_count,
            feed.queryParams.maximum_followers_count,
          ],
        });
        break;
      case 'image':
        requestParameters = Network.Feed.getRequestParameter({
          ...feed.queryParams,
          followers_count: [
            feed.queryParams.minimum_followers_count,
            feed.queryParams.maximum_followers_count,
          ],
        });
        break;
      case 'account':
      case 'website':
        requestParameters = Network.Account.getRequestParameter({
          ...feed.queryParams,
          followers_count: [
            feed.queryParams.minimum_followers_count,
            feed.queryParams.maximum_followers_count,
          ],
          isAccountView: current_screen === 'account',
        });
        break;
      default:
        throw new Error('unknown page name');
    }
    dispatch(
      saveFilterAction(
        {
          ...removeEmptyProperties(requestParameters),
          name: saveFiltername,
          is_default: isDefault,
          filter_type: current_screen,
        },
        setErrorMessage,
        setShowAddNewFilterModal,
        current_screen,
      ),
    );
  }, [current_screen, feed, clusters, saveFiltername, isDefault]);

  return (
    <Modal
      title="Add Saved Filter"
      open={showAddNewFilterModal}
      destroyOnClose
      centered
      width={600}
      wrapProps={wrapProps}
      styles={modalStyles}
      onCancel={() => {
        setShowAddNewFilterModal(false);
      }}
      footer={
        <Flex justify="center" gap="0.5em">
          <Button
            label="Cancel"
            dataTestId="cancel"
            variant={ButtonVariant.Outline}
            onClick={() => {
              setShowAddNewFilterModal(false);
            }}
          />
          <Button
            label="Save Filter"
            dataTestId="saveFilter"
            variant={ButtonVariant.Primary}
            disabled={saveFiltername === ''}
            onClick={saveFilterHandler}
          />
        </Flex>
      }
    >
      <Flex vertical align="stretch" gap="0.25rem">
        <label htmlFor="filterName">
          <Typography
            variant="xsmall"
            fontWeight="bold"
            letterSpacing="0.24px"
            color="var(--neutral-grey-70)"
            textTransform="uppercase"
          >
            Filter name
          </Typography>
        </label>
        <Input
          id="filterName"
          placeholder="Please enter filter configuration name here"
          dataTestId="filterName"
          value={saveFiltername}
          onChange={(e) => {
            setSaveFiltername(e.target.value);
          }}
        />
        <Checkbox
          label="Set as default"
          dataTestId="default"
          checked={isDefault}
          onChange={setIsDefault}
        />
        {errorMessage && (
          <Alert
            style={{ marginTop: 10, textAlign: 'center' }}
            message={errorMessage}
            type="error"
          />
        )}
      </Flex>
    </Modal>
  );
};
