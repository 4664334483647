import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { OrganisationRawModel } from 'product-types/src/domain/organisation/Organisation';

interface UpdateOrganisationRequestModel extends EndpointRequests {
  data: {
    default_feed_view_date_range: string;
    kpis: OrganisationRawModel['kpis'];
    display_name: string;
    logo: string;
  };
}

interface UpdateOrganisationResponse {}

const updateOrganisation = new Endpoint<
  UpdateOrganisationRequestModel,
  UpdateOrganisationResponse
>({
  url: '/api/organisation/',
  method: 'PUT',
  headers: {},
});

export default updateOrganisation;
