import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { ValueSavedResponse } from 'product-types/src/domain/dashboard/DashboardRealValueSavedStateModel';

interface ValueSavedRequestParams extends CommonDashboardRequestParams {
  category_id: Array<number> | null;
}

export interface ValueSavedRequest extends EndpointRequests {
  params: ValueSavedRequestParams;
}

const valueSaved = new Endpoint<ValueSavedRequest, ValueSavedResponse>({
  url: '/api/me/stats/value_saved',
  method: 'GET',
  headers: {},
});

export default valueSaved;
