import { MessageModel } from './Message';

interface SwitchOrganisationMessagePayload {
  data: {
    organisationId: string;
  };
}

export const SwitchOrganisationMessageType = 'SwitchOrganisationMessageType';

export class SwitchOrganisationMessage extends MessageModel {
  type = SwitchOrganisationMessageType;

  payload?: SwitchOrganisationMessagePayload;

  constructor(data: Omit<SwitchOrganisationMessage, 'type'>) {
    super({
      type: SwitchOrganisationMessageType,
      payload: data.payload,
    });
  }

  toString(): string {
    return super.toString();
  }
}
