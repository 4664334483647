import { CloseTabMessage, CloseTabMessageType } from './CloseTabMessage';
import {
  GetCurrentOrganisation,
  GetCurrentOrganisationType,
} from './GetCurrentOrganisation';
import { GetMainInfoAndMe, GetMainInfoAndMeType } from './GetMainInfoAndMe';
import {
  GetNumberOfActiveTabs,
  GetNumberOfActiveTabsType,
} from './GetNumberOfActiveTabs';
import { LogoutMessage, LogoutMessageType } from './LogoutMessage';
import { Message, MessageModel } from './Message';
import {
  SwitchOrganisationMessage,
  SwitchOrganisationMessageType,
} from './SwitchOrganisationMessage';

export default function MessageFactory(str: string) {
  const parsedMessage: Message = JSON.parse(str);
  switch (parsedMessage.type) {
    case CloseTabMessageType: {
      return new CloseTabMessage(parsedMessage);
    }
    case LogoutMessageType: {
      return new LogoutMessage(parsedMessage);
    }
    case GetNumberOfActiveTabsType: {
      return new GetNumberOfActiveTabs(parsedMessage);
    }
    case SwitchOrganisationMessageType: {
      return new SwitchOrganisationMessage(parsedMessage);
    }
    case GetCurrentOrganisationType: {
      return new GetCurrentOrganisation(parsedMessage);
    }
    case GetMainInfoAndMeType: {
      return new GetMainInfoAndMe(parsedMessage);
    }
    default: {
      return new MessageModel(parsedMessage);
    }
  }
}
