import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { DashboardValueMonitorResponse } from 'product-types/src/domain/dashboard/DashboardValueMonitorModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

export interface ValueStatDashboardValueMonitorRequestParams
  extends CommonDashboardRequestParams {
  perpage: number;
  offset: number;
  reverse: boolean;
  sort_by: string;
  category_id: Array<number> | null;
}

export interface ValueMonitorRequest extends EndpointRequests {
  params: ValueStatDashboardValueMonitorRequestParams;
}

const valueMonitor = new Endpoint<
  ValueMonitorRequest,
  DashboardValueMonitorResponse
>({
  url: '/api/me/stats/value_monitor',
  method: 'GET',
  headers: {},
});

export default valueMonitor;
