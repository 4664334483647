import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { ClusterResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';

interface ClusterFeedRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

const getClusters = new Endpoint<ClusterFeedRequestModel, ClusterResponseModel>(
  {
    url: '/api/me/clusters',
    method: 'POST',
    headers: {},
  },
);

export default getClusters;
