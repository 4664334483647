import React, { useEffect } from 'react';
import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import { Route, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LinkHandlingState } from 'containers/LoginPage/reducer';
import { push } from 'connected-react-router';
import { Flex } from 'antd';
import { fetchUserModel } from '../../../LoginPage/actions';
import { AppState } from '../../../../store/storeAccess';
import { BasicPageLayoutSpinner } from '../../../../layout/BasicPageLayout';

export default function UserLoadingSkeleton() {
  const loginPage = useSelector((state: AppState) => state.loginPage);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      loginPage.isAuthenticated &&
      loginPage.linkHandled !== LinkHandlingState.noNeedToHandle &&
      loginPage.currentUser.state === FetchableDataState.NOT_LOADED
    ) {
      dispatch(
        fetchUserModel({
          redirectTo: location.pathname + location.search,
        }),
      );
    }
  }, []);

  if (!loginPage.isAuthenticated) {
    dispatch(
      push({
        pathname: '/login',
        state: {
          redirectTo: window.location.pathname + window.location.search,
        },
      }),
    );
    return null;
  }

  return (
    <Route>
      <Flex className="user-loading-container">
        <BasicPageLayoutSpinner spinning></BasicPageLayoutSpinner>
      </Flex>
    </Route>
  );
}
