import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import duplicatedGroup from './duplicatedGroup';
import add from './add';
import retry from './retry';
import history from './history';
import uploadAccount from './uploadAccount';
import uploadAccountManual from './uploadAccountManual';
import uploadImage from './uploadImage';
import uploadImageFile from './uploadImageFile';
import uploadPost from './uploadPost';
import uploadPostManual from './uploadPostManual';
import uploadAccountFile from './uploadAccountFile';
import uploadAccountFileManual from './uploadAccountFileManual';
import uploadPostFile from './uploadPostFile';
import uploadPostFileManual from './uploadPostFileManual';
import exportUploadHistory from './uploadHistoryExport';
import downloadExcel from './downloadExcelFile';

export interface UploadEndpoints extends EndpointContainer {
  duplicatedGroup: typeof duplicatedGroup;
  history: typeof history;
  exportUploadHistory: typeof exportUploadHistory;
  retry: typeof retry;
  uploadPost: typeof uploadPost;
  uploadPostManual: typeof uploadPostManual;
  uploadPostFile: typeof uploadPostFile;
  uploadPostFileManual: typeof uploadPostFileManual;
  uploadImage: typeof uploadImage;
  uploadImageFile: typeof uploadImageFile;
  uploadAccount: typeof uploadAccount;
  uploadAccountManual: typeof uploadAccountManual;
  uploadAccountFile: typeof uploadAccountFile;
  uploadAccountFileManual: typeof uploadAccountFileManual;
  add: typeof add;
  downloadExcel: typeof downloadExcel;
}

const upload: UploadEndpoints = {
  duplicatedGroup,
  history,
  exportUploadHistory,
  retry,
  uploadPost,
  uploadPostManual,
  uploadPostFile,
  uploadPostFileManual,
  uploadImage,
  uploadImageFile,
  uploadAccount,
  uploadAccountManual,
  uploadAccountFile,
  uploadAccountFileManual,
  add,
  downloadExcel,
  injectHTTPClient(client: HttpClient) {
    duplicatedGroup.injectHTTPClient(client);
    history.injectHTTPClient(client);
    exportUploadHistory.injectHTTPClient(client);
    retry.injectHTTPClient(client);
    uploadPost.injectHTTPClient(client);
    uploadPostManual.injectHTTPClient(client);
    uploadPostFile.injectHTTPClient(client);
    uploadPostFileManual.injectHTTPClient(client);
    uploadImage.injectHTTPClient(client);
    uploadImageFile.injectHTTPClient(client);
    uploadAccount.injectHTTPClient(client);
    uploadAccountManual.injectHTTPClient(client);
    uploadAccountFile.injectHTTPClient(client);
    uploadAccountFileManual.injectHTTPClient(client);
    add.injectHTTPClient(client);
    downloadExcel.injectHTTPClient(client);
  },
};

export default upload;
