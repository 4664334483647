import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ImageModerationUpdateRequest extends EndpointRequests {
  data: any;
}

interface ImageModerationResponse {
  message: string;
}

const imageModeration = new Endpoint<
  ImageModerationUpdateRequest,
  ImageModerationResponse
>({
  url: '/api/me/duplicated_groups_moderation/',
  method: 'PUT',
  headers: {},
});

export default imageModeration;
