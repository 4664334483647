import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationReasonRaw } from 'product-types/src/domain/moderationReason/ModerationReason';

interface ModerationRequestModel extends EndpointRequests {}

export type ModerationReasonResponse = Array<ModerationReasonRaw>;

const moderationReason = new Endpoint<
  ModerationRequestModel,
  ModerationReasonResponse
>({
  url: '/api/me/moderation_reason',
  method: 'GET',
  headers: {},
});

export default moderationReason;
