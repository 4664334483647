import { ExportFeedRequestParameter } from 'types/network/Feed/Feed';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ExportClustersRequestModel extends EndpointRequests {
  data: ExportFeedRequestParameter;
}

const exportClusters = new Endpoint<
  ExportClustersRequestModel,
  NonNullable<unknown>
>({
  url: '/api/me/clusters/export',
  method: 'POST',
  headers: {},
});

export default exportClusters;
