import { CanBeSelectable } from 'product-types/src/common/CanBeSelectable/CanBeSelectable';
import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { Contact, ContactType } from 'product-types/src/domain/contact/Contact';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';

export interface ClusterFeedRawModel {
  contact_info: number;
  contact_information?: Array<{
    contact_type: string;
    contact: string;
    id: number | string;
  }>;
  description: Array<string>;
  estimated_geo: Array<string> | null;
  id: number;
  image_link: string;
  infringement_percentage: number;
  moderation_percentage: number;
  name: string;
  post_categories: Array<number> | null;
  rank: number;
  total_accounts: number | null;
  total_posts: number;
  total_product_categories: number;
  website_categories_counts: Array<WebsiteCategory>;
  tags: Array<TagRaw>;
}

export class ClusterFeedModel
  implements CanBeSelectable, HasModerationPage, Magnifiable
{
  id: number;

  name: string;

  description: Array<string>;

  imageLink: string;

  infringementPercentage: number;

  moderationPercentage: number;

  rank: number;

  totalPosts: number;

  contactInfo: number;

  contactInformation: Array<Contact>;

  estimatedGeo: Array<string> | null;

  postCategories: Array<number> | null;

  totalAccounts: number | null;

  totalProductCategories: number;

  websiteCategoriesCounts: Array<WebsiteCategory>;

  tags: Array<TagModel>;

  constructor(
    prop: Pick<
      ClusterFeedModel,
      | 'tags'
      | 'id'
      | 'name'
      | 'description'
      | 'imageLink'
      | 'infringementPercentage'
      | 'moderationPercentage'
      | 'rank'
      | 'totalPosts'
      | 'contactInfo'
      | 'contactInformation'
      | 'estimatedGeo'
      | 'postCategories'
      | 'totalAccounts'
      | 'totalProductCategories'
      | 'websiteCategoriesCounts'
    >,
  ) {
    this.id = prop.id;
    this.name = prop.name;
    this.description = prop.description;
    this.imageLink = prop.imageLink;
    this.infringementPercentage = prop.infringementPercentage;
    this.moderationPercentage = prop.moderationPercentage;
    this.rank = prop.rank;
    this.totalPosts = prop.totalPosts;
    this.contactInfo = prop.contactInfo;
    this.contactInformation = prop.contactInformation;
    this.estimatedGeo = prop.estimatedGeo;
    this.postCategories = prop.postCategories;
    this.tags = prop.tags;
    this.totalAccounts = prop.totalAccounts;
    this.totalProductCategories = prop.totalProductCategories;
    this.websiteCategoriesCounts = prop.websiteCategoriesCounts;
  }

  get midSizeImage() {
    return this.imageLink;
  }

  get queryParam() {
    return `cluster_id=${this.id}`;
  }

  get linkToModerationPage() {
    return `/cluster/${this.id}`;
  }

  static createFromClusterFeedRawModel(prop: ClusterFeedRawModel) {
    return new ClusterFeedModel({
      id: prop.id,
      name: prop.name,
      description: prop.description,
      imageLink: prop.image_link,
      infringementPercentage: prop.infringement_percentage,
      moderationPercentage: prop.moderation_percentage,
      rank: prop.rank,
      totalPosts: prop.total_posts,
      contactInfo: prop.contact_info,
      contactInformation:
        prop.contact_information?.map(
          (c) =>
            new Contact({
              contact: c.contact,
              id: c.id,
              key: c.contact_type,
              type: c.contact_type as ContactType,
            }),
        ) || [],
      estimatedGeo: prop.estimated_geo,
      postCategories: prop.post_categories,
      totalAccounts: prop.total_accounts,
      totalProductCategories: prop.total_product_categories,
      websiteCategoriesCounts: prop.website_categories_counts,
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
    });
  }
}
