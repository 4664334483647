/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/storeAccess';

interface PrivateRouteProps {
  component: React.JSXElementConstructor<any>;
  path: string;
  exact?: boolean;
}

export default function PrivateRoute({
  component: Component,
  path,
  exact,
}: PrivateRouteProps) {
  const loginPage = useSelector((state: AppState) => state.loginPage);
  return (
    <Route
      path={path}
      exact={exact ?? false}
      render={() =>
        loginPage.isAuthenticated ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                redirectTo: window.location.pathname + window.location.search,
                message: 'Your session token has expired, please login again',
              },
            }}
          />
        )
      }
    />
  );
}
