import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled(Flex)`
  max-height: 620px;
  overflow: auto;
  padding: 0.5rem 1.5rem 2.5rem;
  .ant-select-selection-overflow-item-rest {
    margin-bottom: auto;
    .ant-select-selection-item {
      margin: 0;
      height: 28px !important;
      .ant-select-selection-item-content {
        line-height: 26px;
      }
    }
  }
`;
