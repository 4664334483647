import React from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
} from '../../../types/filters/AtomicFilters/Filter';

export const UploadStatusFilterValue: DisplayFilterComponent = ({
  filterValue: { value },
  onClickRemove,
}: DisplayFilterComponentProps) => (
  <Tag
    tag={{
      id: (value as Domain.UploadStatus.UploadStatus).value,
      name: (value as Domain.UploadStatus.UploadStatus).label,
    }}
    iconPrefix={<NaveeIcon.StatusPal width={12} height={12} />}
    onClickRemove={onClickRemove}
  />
);
