import { Flex } from 'antd';
import { StyledModalFilters } from 'components/Modals/styled-components';
import { deleteFiltersAction } from 'layout/FiltersBar/actions';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import Button, {
  ButtonVariant,
} from 'product-ui/src/components/atoms/Button/Button';
import Typography from 'product-ui/src/components/atoms/Typography';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';

interface DeleteFilterModalProps {
  filterToDelete: SavedFilterModel | null;
  setFilterToDelete: Dispatch<SetStateAction<SavedFilterModel | null>>;
}
const modalStyles = {
  mask: {
    zIndex: 1100,
  },
  header: {
    marginBottom: '16px',
  },
  content: {
    padding: '24px',
  },
  footer: {
    background: 'white',
  },
};

const wrapProps = {
  style: {
    zIndex: 1150,
  },
};

export const DeleteFilterModal = ({
  filterToDelete,
  setFilterToDelete,
}: DeleteFilterModalProps) => {
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const current_screen = url.pathname.split('/')[2];

  const deleteFilterHandler = useCallback(() => {
    if (!filterToDelete) {
      return;
    }
    dispatch(deleteFiltersAction({ id: filterToDelete.id }, current_screen));
    setFilterToDelete(null);
  }, [filterToDelete]);

  return (
    <StyledModalFilters
      open={!!filterToDelete}
      destroyOnClose
      centered
      width={480}
      wrapProps={wrapProps}
      styles={modalStyles}
      onCancel={() => {
        setFilterToDelete(null);
      }}
      footer={
        <Flex justify="flex-end" gap="0.5em">
          <Button
            label="Cancel"
            dataTestId="cancel"
            variant={ButtonVariant.Outline}
            style={{ flex: 1 }}
            onClick={() => {
              setFilterToDelete(null);
            }}
          />
          <Button
            label="Delete"
            dataTestId="delete"
            variant={ButtonVariant.Red}
            style={{ flex: 1 }}
            onClick={deleteFilterHandler}
          />
        </Flex>
      }
    >
      <Flex
        align="stretch"
        justify="center"
        style={{
          marginBottom: '32px',
        }}
      >
        <Typography
          variant="h7"
          fontWeight="bold"
          color="var(--neutral-grey-800)"
          style={{ textAlign: 'center' }}
        >
          Are you sure want to remove saved filter <br /> “
          {filterToDelete?.name}”?
        </Typography>
      </Flex>
    </StyledModalFilters>
  );
};
