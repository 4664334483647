import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getSimilarImages from './similarImages';

export interface SearchEndpoints extends EndpointContainer {
  getSimilarImages: typeof getSimilarImages;
}

const search: SearchEndpoints = {
  getSimilarImages,
  injectHTTPClient(client: HttpClient) {
    getSimilarImages.injectHTTPClient(client);
  },
};

export default search;
