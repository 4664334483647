import dayjs from 'dayjs';
import { ModerationReasonLocation } from './ModerationReasonLocation';

export enum SuspiciousPriority {
  suspicious = 'SUSPICIOUS',
  obviousCounterfeit = 'OBVIOUS_COUNTERFEIT',
  highlySuspicious = 'HIGHLY_SUSPICIOUS',
}

export type GroupedReasons = {
  [key in SuspiciousPriority]: Array<ModerationReason>;
};

export interface ModerationReasonRaw {
  id: number;
  moderation_reason_id: number;
  path: string;
  name: string;
  type: string;
  value: boolean | null;
  comment: null | string;
  color: string;
  original_image_url: string;
  original_image_id: null;
  priority: SuspiciousPriority;
  location: string;
  automatically_generated: boolean;
  check_date: null | string;
  checked: null | boolean;
  checked_by_user_id: null | number;
  found_in_image_id: null | number;
  found_in_post_id: null | number;
}

export class ModerationReason {
  id: number | null;

  moderationReasonId: number;

  path: string;

  name: string;

  type: string;

  value: boolean | null;

  comment: string | null;

  color: string;

  originalImageUrl: string | null;

  originalImageId: number | null;

  originalPostId: number | null;

  location: ModerationReasonLocation | null;

  checked: boolean | null;

  checkedUserId: any;

  checkDate: dayjs.Dayjs | null;

  priority: SuspiciousPriority;

  automaticallyGenerated: boolean;

  lastPathPart: string;

  constructor(
    prop: Pick<
      ModerationReason,
      | 'id'
      | 'path'
      | 'name'
      | 'color'
      | 'comment'
      | 'originalImageId'
      | 'originalImageUrl'
      | 'location'
      | 'type'
      | 'value'
      | 'checked'
      | 'checkedUserId'
      | 'checkDate'
      | 'automaticallyGenerated'
      | 'originalPostId'
      | 'priority'
      | 'moderationReasonId'
      | 'lastPathPart'
    >,
  ) {
    this.id = prop.id;
    this.path = prop.path;
    this.name = prop.name;
    this.value = prop.value;
    this.type = prop.type;
    this.comment = prop.comment;
    this.color = prop.color;
    this.originalImageUrl = prop.originalImageUrl;
    this.originalImageId = prop.originalImageId;
    this.originalPostId = prop.originalPostId;
    this.location = prop.location;
    this.checked = prop.checked;
    this.checkedUserId = prop.checkedUserId;
    this.checkDate = prop.checkDate;
    this.automaticallyGenerated = prop.automaticallyGenerated;
    this.priority = prop.priority;
    this.moderationReasonId = prop.moderationReasonId;
    this.lastPathPart = prop.lastPathPart;
  }

  get suggestedByAI() {
    return this.automaticallyGenerated && !this.checked && !this.originalPostId;
  }

  static createReasonFromRaw(prop: ModerationReasonRaw) {
    return new ModerationReason({
      id: prop.id,
      path: prop.path,
      color: prop.color,
      comment: prop.comment,
      location:
        (prop.location &&
          ModerationReasonLocation.staticCreateFromString(prop.location)) ||
        null,
      name: prop.name,
      originalImageId: prop.found_in_image_id,
      originalImageUrl: prop.original_image_url,
      originalPostId: prop.found_in_post_id,
      type: prop.type,
      value: prop.value,
      checked: prop.checked,
      checkedUserId: prop.checked_by_user_id,
      checkDate: prop.check_date ? dayjs(prop.check_date) : null,
      automaticallyGenerated: prop.automatically_generated,
      priority: prop.priority,
      moderationReasonId: prop.moderation_reason_id,
      lastPathPart: prop.path.split('/').pop() ?? '',
    });
  }

  static groupReasonsByPriority(reasons: Array<ModerationReason>) {
    const priorityOrder = [
      'OBVIOUS_COUNTERFEIT',
      'COUNTERFEIT',
      'HIGHLY_SUSPICIOUS',
      'SUSPICIOUS',
      'LOGO',
      'BRAND',
      'PRODUCT_INFORMATION',
      'INFO',
      'OTHER',
    ];
    const sortedReasons = reasons.sort((a, b) => {
      const priorityA = priorityOrder.indexOf(a.priority);
      const priorityB = priorityOrder.indexOf(b.priority);

      if (priorityA !== -1 && priorityB !== -1) {
        return priorityA - priorityB;
      }

      if (priorityA !== -1) return -1;
      if (priorityB !== -1) return 1;

      return 0;
    });
    return sortedReasons.reduce((acc, curr) => {
      if (!(curr.priority in acc)) {
        acc[curr.priority] = [];
      }
      acc[curr.priority] = [...acc[curr.priority], curr];
      return acc;
    }, {} as GroupedReasons);
  }
}

export const moderationReasonSort = (
  a: ModerationReason,
  b: ModerationReason,
) => {
  if (a.automaticallyGenerated && !a.checked && !b.automaticallyGenerated) {
    return -1;
  }
  if (!a.automaticallyGenerated && b.automaticallyGenerated && !b.checked) {
    return 1;
  }
  return 0;
};
