import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface RetryUploadRequest extends EndpointRequests {
  data: {
    upload_id: any;
  };
}

interface RetryUploadResponse {}

const retryUpload = new Endpoint<RetryUploadRequest, RetryUploadResponse>({
  url: `/api/me/uploads/retry`,
  method: 'POST',
  headers: {},
});

export default retryUpload;
