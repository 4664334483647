import { Endpoint } from 'types/network/Http/endpoint';
import { Country } from 'product-types/src/domain/geo/Geo';

export type GeographyResponse = Array<Country>;

const geography = new Endpoint<any, GeographyResponse>({
  url: '/api/me/geography',
  method: 'GET',
  headers: {},
});

export default geography;
