import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { DashboardWebsitesResponse } from 'product-types/src/domain/dashboard/DashboardWebsiteStateModel';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';

export interface DashboardWebsitesRequestParams
  extends CommonDashboardRequestParams {
  perpage: number;
  offset: number;
  reverse: boolean;
  sort_by: string;
  category_id: Array<number> | null;
}

export interface WebsiteRequest extends EndpointRequests {
  params: DashboardWebsitesRequestParams;
}

const websites = new Endpoint<WebsiteRequest, DashboardWebsitesResponse>({
  url: '/api/me/stats/websites',
  method: 'GET',
  headers: {},
});

export default websites;
