/* eslint-disable no-plusplus */
export const r_sort = (a, b, field, asc) => {
  const reverse = asc ? 1 : -1;
  if (a[field] > b[field]) {
    return 1 * reverse;
  }
  if (b[field] > a[field]) {
    return -1 * reverse;
  }

  return 0;
};

export const makeArrayUniqueByValue = (array) => {
  const set = new Set();
  return [
    ...(array || [])
      .reduce((accumulator, currentValue) => {
        accumulator.add(currentValue);
        return accumulator;
      }, set)
      .keys(),
  ];
};

export const makeArrayUniqueByKey = (array: Array<any> = [], key = '') => {
  const map = new Map();
  return [
    ...array
      .reduce((accumulator, currentValue) => {
        accumulator.set(currentValue[key], currentValue);
        return accumulator;
      }, map)
      .values(),
  ];
};

export const intersectionAandBByKey = (array1, array2, key) => {
  const uniqueValuesFromB = array2.reduce((accumulator, currentValue) => {
    accumulator.add(currentValue[key]);
    return accumulator;
  }, new Set());
  return array1.filter((valueFromB) => uniqueValuesFromB.has(valueFromB[key]));
};

export const chunk = (array: Array<any>, size = 1) => {
  const chunkSize = Math.max(size, 0);
  const length = array == null ? 0 : array.length;
  if (!length || chunkSize < 1) {
    return [];
  }
  let index = 0;
  let resIndex = 0;
  const result = new Array(Math.ceil(length / chunkSize));

  while (index < length) {
    result[resIndex++] = array.slice(index, (index += chunkSize));
  }
  return result;
};

export const unionBy = (
  array1: Array<any>,
  array2: Array<any>,
  keyCb: (item: any) => string | number,
) => {
  const map = new Map();
  array1.forEach((item) => map.set(keyCb(item), item));
  array2.forEach((item) => map.set(keyCb(item), item));
  return Array.from(map.values());
};
