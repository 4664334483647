import React, { CSSProperties, FC } from 'react';
import { Flex } from 'antd';
import Typography from 'product-ui/src/components/atoms/Typography';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Link, {
  DefaultLinkIcon,
} from 'product-ui/src/components/atoms/Link/Link';
import { useHistory } from 'react-router-dom';

type Props = {
  style?: CSSProperties;
};

export const ErrorFallback: FC<Props> = ({ style = {} }) => {
  const history = useHistory();

  const goBack = () => {
    if (window.history.length > 2) {
      history.goBack();
    } else {
      window.location.reload();
    }
  };

  return (
    <Flex
      align="center"
      style={{
        width: '25rem',
        padding: '1.5rem 0.5rem',
        margin: '0 auto',
        height: '100%',
        ...style,
      }}
    >
      <Flex justify="center" align="center" vertical gap="0.5rem">
        <NaveeIcon.ErrorPage />
        <Flex justify="center" align="center" vertical gap="1rem">
          <Flex vertical align="center" gap="0.5rem">
            <Typography
              variant="h7"
              color="var(--primary-black)"
              fontWeight="bold"
            >
              An error has occurred.
            </Typography>
            <Typography
              variant="medium"
              color="var(--primary-black)"
              style={{
                textAlign: 'center',
              }}
            >
              This error has been logged and our tech team is
              <br />
              onto a fix! Please{' '}
              <Link
                style={{ display: 'inline-flex' }}
                postIcon={<DefaultLinkIcon />}
                onClick={goBack}
              >
                go back
              </Link>
              , try{' '}
              <Link
                style={{ display: 'inline-flex' }}
                onClick={() => window.location.reload()}
                postIcon={<DefaultLinkIcon />}
              >
                refreshing
              </Link>{' '}
              or contact us if you need support.
            </Typography>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
