import React from 'react';
import { OptionWrapper } from '../Select/OptionWrapper';

type Props = {
  label: string;
  count: string | number;
};

export const OptionWithCount = (props: Props) => (
  <OptionWrapper>
    {props.label}
    {props.count && <div className="option-count">{props.count}</div>}
  </OptionWrapper>
);
