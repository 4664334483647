import { Endpoint } from 'types/network/Http/endpoint';

const downloadPostExcelFile = new Endpoint<
  NonNullable<unknown>,
  NonNullable<unknown>
>({
  url: '/api/me/uploads/post/file',
  method: 'GET',
  headers: {},
});

export default downloadPostExcelFile;
