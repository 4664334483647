import * as Domain from 'product-types/src/domain/Domain';

export interface ResponseAdvancedStatistics {
  accounts: Transform.ArrayResponseWithCount<Domain.Account.Id>;
  accounts_from_contacts: Transform.ArrayResponseWithCount<{
    account_name: string;
    id: Domain.Account.Id;
    website_name: string;
  }>;
  extracted_contacts: {
    contact: string;
    count: number;
    contact_type: Domain.Contact.ContactType;
  }[];
  images: Transform.ArrayResponseWithCount<Domain.Image.Id>;
  posts: Transform.ArrayResponseWithCount<Domain.Post.Id>;
  websites: Transform.ArrayResponseWithCount<Domain.Website.Id>;
}
export class RequestParamsAdvancedStatistics {
  post_ids: Array<any>;

  image_ids: Array<any>;

  account_ids: Array<any>;

  account_names: Array<any>;

  vendor_ids: Array<any>;

  vendor_names: Array<any>;

  constructor(props: Partial<RequestParamsAdvancedStatistics>) {
    this.post_ids = (props.post_ids || []).map((id) => parseInt(id, 10));
    this.image_ids = (props.image_ids || []).map((id) => parseInt(id, 10));
    this.account_ids = props.account_ids || [];
    this.account_names = props.account_names || [];
    this.vendor_ids = (props.vendor_ids || []).map((id) => parseInt(id, 10));
    this.vendor_names = props.vendor_names || [];
  }

  static createFromQuery(
    feed: FeedFilterModel,
  ): RequestParamsAdvancedStatistics {
    return new RequestParamsAdvancedStatistics({
      post_ids: (feed.searchFilter as SearchFilter)?.queryFilterValue?.post_id,
      image_ids: (feed.searchFilter as SearchFilter)?.queryFilterValue
        ?.duplicated_group_id,
      account_names: (feed.searchFilter as SearchFilter)?.queryFilterValue
        ?.poster_names,
      vendor_ids: (feed.searchFilter as SearchFilter)?.queryFilterValue
        ?.cluster_id,
      vendor_names: (feed.searchFilter as SearchFilter)?.queryFilterValue
        ?.vendor_names,
      account_ids: (
        (feed.searchFilter as SearchFilter)?.queryFilterValue
          ?.account_website_id || []
      )
        .map((id) => `w::${id}`)
        .concat(
          (
            (feed.searchFilter as SearchFilter)?.queryFilterValue
              ?.account_poster_id || []
          ).map((id) => `p::${id}`),
        ),
    });
  }
}
