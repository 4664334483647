import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import moderate from './moderate';
import nextPostToModerate from './nextPostToModerate';
import prevPostToModerate from './prevPostToModerate';
import moderateAndReturnPost from './moderateAndReturnPost';

export interface PostModerationEndpoints extends EndpointContainer {
  moderate: typeof moderate;
  moderateAndReturnPost: typeof moderateAndReturnPost;
  nextPostToModerate: typeof nextPostToModerate;
  prevPostToModerate: typeof prevPostToModerate;
}

const postModeration: PostModerationEndpoints = {
  moderate,
  moderateAndReturnPost,
  nextPostToModerate,
  prevPostToModerate,
  injectHTTPClient(client: HttpClient) {
    moderate.injectHTTPClient(client);
    moderateAndReturnPost.injectHTTPClient(client);
    nextPostToModerate.injectHTTPClient(client);
    prevPostToModerate.injectHTTPClient(client);
  },
};

export default postModeration;
