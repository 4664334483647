import { WebsiteFeedRawModel } from 'product-types/src/domain/website/WebsiteFeedModel';
import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface WebsiteFeedRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

export interface WebsiteFeedResponseModel {
  currency_iso: string;
  accounts: Array<WebsiteFeedRawModel>;
  total: number;
}

const getWebsites = new Endpoint<
  WebsiteFeedRequestModel,
  WebsiteFeedResponseModel
>({
  url: '/api/me/accounts',
  method: 'POST',
  headers: {},
});

export default getWebsites;
