import { ResponseAdvancedStatistics } from 'types/network/AdvancedStatistics/AdvancedStatistics';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AdvancedStatsRequest extends EndpointRequests {
  data: {
    account_ids: Array<string>;
    account_names: Array<string>;
    image_ids: Array<number>;
    post_ids: Array<number>;
    vendor_ids: Array<number>;
    vendor_names: Array<string>;
  };
}

const advancedStats = new Endpoint<
  AdvancedStatsRequest,
  ResponseAdvancedStatistics
>({
  url: '/api/me/advanced_stats',
  method: 'POST',
  headers: {},
});

export default advancedStats;
