import { createSelector } from 'reselect';
import { LoginPageState, createInitialState } from './reducer';

/**
 * Direct selector to the loginPage state domain
 */

const selectLoginPageDomain = (state) =>
  state.loginPage || createInitialState();

/**
 * Other specific selectors
 */

/**
 * Default selector used by LoginPage
 */

const makeSelectLoginPage = () =>
  createSelector(
    selectLoginPageDomain,
    (loginState: LoginPageState) => loginState,
  );

export default makeSelectLoginPage;
export { selectLoginPageDomain };
