import { Endpoint } from 'types/network/Http/endpoint';

export interface LatestRefreshDateResponse {
  last_refresh_date: string;
}

const latestRefreshDate = new Endpoint<any, LatestRefreshDateResponse>({
  url: '/api/me/stats/last_refresh_date',
  method: 'GET',
  headers: {},
});

export default latestRefreshDate;
