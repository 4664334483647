import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { PostFeedResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { FeedRequestParameter } from '../../../../../Feed/Feed';

interface PostFeedRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

const getPosts = new Endpoint<PostFeedRequestModel, PostFeedResponseModel>({
  url: '/api/me/posts',
  method: 'POST',
  headers: {},
});

export default getPosts;
