import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AccountResponseModel {
  message: string;
  code: string;
}

interface AccountRequestModel extends EndpointRequests {
  data: {
    cluster_name: string;
    account_ids: string[];
    organisation_id?: number;
    user_id?: number;
  };
}

const create = new Endpoint<AccountRequestModel, AccountResponseModel>({
  url: '/api/me/clusters/create',
  method: 'POST',
  headers: {},
});

export default create;
