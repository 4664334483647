import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface GetFileRequest extends EndpointRequests {
  params: {
    id: number;
  };
}

interface GetFileResponse {}

const getFile = new Endpoint<GetFileRequest, GetFileResponse>({
  url: '/api/me/moderation_comment/files',
  method: 'GET',
  headers: {},
});

export default getFile;
