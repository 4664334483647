import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import * as Domain from 'product-types/src/domain/Domain';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { NewTakedownStatusFilter } from '../../../../components/Filters/NewTakedownStatusFilter';
import { NewTakedownStatusFilterValue } from '../../../../components/Filters/NewTakedownStatusFilter/NewTakedownStatusFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { TakedownStatusValue } from './TakedownStatusValue';

export interface TakedownStatusFilterQueryValue extends QueryValue {
  taken_down: Array<Domain.TakedownStatus.TakedownStatus>;
}

export class TakedownStatusFilter implements Filter {
  uuid: string;

  label: string;

  value: TakedownStatusValue;

  constructor(
    params?: Partial<Pick<TakedownStatusFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || TakedownStatusValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return this.value.takenDown.map(
      (takenDownType: Domain.TakedownStatus.TakedownStatus) => ({
        name: FilterTypeEnum.takenDown,
        value: takenDownType,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.takenDown + takenDownType.value,
      }),
    );
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.takenDown) {
      this.value = this.value.removeTakedown(
        removingFilter.value as Domain.TakedownStatus.TakedownStatus,
      );
    }
    return new TakedownStatusFilter(this);
  }

  get component() {
    return NewTakedownStatusFilter;
  }

  get displayFilterComponent() {
    return NewTakedownStatusFilterValue;
  }

  get queryFilterValue(): TakedownStatusFilterQueryValue {
    return {
      taken_down: this.value.takenDown,
    };
  }

  static readFilterFromQuery(): TakedownStatusFilter {
    return new TakedownStatusFilter({
      value: TakedownStatusValue.readFilterFromQuery,
    });
  }

  static readFilterFromSavedFitler(
    props: SavedFilterModel,
  ): TakedownStatusFilter {
    return new TakedownStatusFilter({
      value: TakedownStatusValue.readFromSavedFilter(props),
    });
  }
}
