// eslint-disable-next-line max-classes-per-file
import { NetworkError } from './NetworkError';

export class CustomError {
  message: string;

  name: string = 'CustomError';

  constructor(message: string) {
    this.message = message;
  }
}

export class CustomErrorFactory {
  static create(error: NetworkError | unknown): NetworkError | CustomError {
    if (error instanceof NetworkError) {
      return error;
    }

    return new CustomError((error as Error).message);
  }
}
