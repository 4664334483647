/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';
import { Props, Select } from './Select';

type InsertedAreRemovedProps = Props & {
  optionRemovalCriteria?: (opt) => boolean;
};

export const withInsertedAreRemoved =
  (WrappedComponent: React.ComponentType<Props>) =>
  (props: InsertedAreRemovedProps) => {
    const filteredOptions = useMemo(() => {
      const result = (props.options ?? []).filter(
        props.optionRemovalCriteria ??
          ((opt) =>
            !(props.value ?? [])
              .map((o) => o[props.fieldNames?.value ?? 'value'])
              .includes(opt[props.fieldNames?.value ?? 'value'])),
      );
      return result;
    }, [
      props.options,
      props.value,
      props.fieldNames,
      props.optionRemovalCriteria,
    ]);

    // @ts-ignore
    return <WrappedComponent {...props} options={filteredOptions} />;
  };

export const SelectWithInsertedAreRemoved = withInsertedAreRemoved(Select);
