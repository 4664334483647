/* eslint-disable */
const hasOwnProperty = Object.prototype.hasOwnProperty;

const pick = (obj: NonNullable<Object>, props: Array<string>) => {
  return props.reduce((acc, prop) => {
    if (obj[prop] !== undefined) {
      acc[prop] = obj[prop];
    }
    return acc;
  }, {} as any);
};

const intersection = (arr1: Array<string>, arr2: Array<string>) => {
  return arr1.filter((value) => arr2.includes(value));
};

const conformsTo = (object: any, source: any) => {
  if (source === null) {
    return true;
  }
  const keys = Object.keys(source);
  let length = keys.length;
  if (object == null) {
    return !length;
  }
  object = Object(object);
  while (length--) {
    const key = keys[length];
    const predicate = source[key];
    const value = object[key];

    if ((value === undefined && !(key in object)) || !predicate(value)) {
      return false;
    }
  }
  return true;
};

const has = (object: any, key: PropertyKey) => {
  return object != null && hasOwnProperty.call(object, key);
};

const base64ToJson = (base64String) => {
  const decodedString = atob(base64String).toString();
  const utf8DecodedJson = decodeURIComponent(decodedString); // emoji-friendly
  return JSON.parse(utf8DecodedJson);
};

const haveSameIds = (objectsArray: Array<any>, idExtractor = (c) => c) => {
  if (objectsArray.length === 0) {
    return true;
  }

  const referenceId = idExtractor(objectsArray[0]);

  return objectsArray.every((obj) => idExtractor(obj) === referenceId);
};

const removeEmptyProperties = <T = any>(obj: any) =>
  // eslint-disable-next-line no-unused-vars
  Object.fromEntries(
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, v]) => {
      const values_to_exclude = [null, '', undefined];
      if (values_to_exclude.includes(v as any)) {
        return false;
      }
      if (Array.isArray(v) && v.length === 0) {
        return false;
      }
      return true;
    }),
  ) as T;

export {
  has,
  pick,
  intersection,
  conformsTo,
  base64ToJson,
  haveSameIds,
  removeEmptyProperties,
};
