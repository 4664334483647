import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import add from './add';
import get from './get';
import deleteComment from './delete';

export interface AccountCommentsEndpoints extends EndpointContainer {
  add: typeof add;
  get: typeof get;
  delete: typeof deleteComment;
}

const accountComments: AccountCommentsEndpoints = {
  add,
  get,
  delete: deleteComment,
  injectHTTPClient(client: HttpClient) {
    add.injectHTTPClient(client);
    get.injectHTTPClient(client);
    deleteComment.injectHTTPClient(client);
  },
};

export default accountComments;
