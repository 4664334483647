import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import updateOrganisation from './updateOrganisation';
import createOrganisation from './createOrganisation';
import uploadNewLogo from './uploadNewLogo';

export interface OrganisationEndpoints extends EndpointContainer {
  updateOrganisation: typeof updateOrganisation;
  createOrganisation: typeof createOrganisation;
  uploadNewLogo: typeof uploadNewLogo;
}

const organisation: OrganisationEndpoints = {
  updateOrganisation,
  createOrganisation,
  uploadNewLogo,
  injectHTTPClient(client: HttpClient) {
    updateOrganisation.injectHTTPClient(client);
    createOrganisation.injectHTTPClient(client);
    uploadNewLogo.injectHTTPClient(client);
  },
};

export default organisation;
