import { LabelRaw } from 'product-types/src/domain/label/Label';
import { Endpoint } from 'types/network/Http/endpoint';

export type PostLabelsResponse = Array<LabelRaw>;

const postLabels = new Endpoint<any, PostLabelsResponse>({
  url: '/api/me/labels',
  method: 'GET',
  headers: {},
});

export default postLabels;
