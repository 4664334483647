import { UserCompleteRaw } from 'product-types/src/domain/user/UserComplete';
import { Endpoint, EndpointRequests } from '../../../../endpoint';

interface MeRequestModel extends EndpointRequests {
  params: {
    timezone_offset: number;
    start_date: string;
    end_date: string;
  };
}

const me = new Endpoint<MeRequestModel, UserCompleteRaw>({
  url: '/api/me',
  method: 'GET',
  headers: {},
});

export default me;
