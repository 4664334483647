import { KeywordRaw } from 'product-types/src/domain/keyword/Keyword';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface UploadKeywordsFileResponseModel {
  keywords: Array<KeywordRaw>;
}

interface UploadKeywordsFileRequestModel extends EndpointRequests {
  data: FormData;
}

const uploadKeywordsFile = new Endpoint<
  UploadKeywordsFileRequestModel,
  UploadKeywordsFileResponseModel
>({
  url: '/api/me/crawling_config/keywords/file',
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadKeywordsFile;
