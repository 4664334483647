// todo: remove this interface and use CategoryModel instead
export interface Category {
  id: number;
  illustration_picture_url: string;
  mean_price: null | number;
  name: string;
  organisation_id: null | number;
}

export class CategoryModel {
  id: number;

  illustrationPictureUrl: string;

  meanPrice: null | number;

  name: string;

  organisationId: null | number;

  constructor(
    prop: Pick<
      CategoryModel,
      'id' | 'illustrationPictureUrl' | 'meanPrice' | 'name' | 'organisationId'
    >,
  ) {
    this.id = prop.id;
    this.illustrationPictureUrl = prop.illustrationPictureUrl;
    this.meanPrice = prop.meanPrice;
    this.name = prop.name;
    this.organisationId = prop.organisationId;
  }

  static createFromRawModel(
    prop: Category | null | undefined = {
      id: 0,
      illustration_picture_url: '',
      mean_price: null,
      name: '',
      organisation_id: null,
    },
  ) {
    if (!prop)
      return new CategoryModel({
        id: 0,
        illustrationPictureUrl: '',
        meanPrice: null,
        name: '',
        organisationId: null,
      });
    return new CategoryModel({
      id: prop.id,
      illustrationPictureUrl: prop.illustration_picture_url,
      meanPrice: prop.mean_price,
      name: prop.name,
      organisationId: prop.organisation_id,
    });
  }

  toRawModel(): Category {
    return {
      id: this.id,
      illustration_picture_url: this.illustrationPictureUrl,
      mean_price: this.meanPrice,
      name: this.name,
      organisation_id: this.organisationId,
    };
  }

  static createEmpty(): CategoryModel {
    return new CategoryModel({
      id: 0,
      illustrationPictureUrl: '',
      meanPrice: null,
      name: '',
      organisationId: null,
    });
  }
}
