import { UploadPostRaw } from 'product-types/src/domain/uploads/uploadPost';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadPostRequest extends EndpointRequests {
  data: {
    global_label: string;
    global_tags: string[];
    override: boolean | null;
    posts: Array<UploadPostRaw>;
  };
}

export interface UploadPostResponse {
  existing_items_number: number | null;
  message: string;
  title: string;
}

const uploadPost = new Endpoint<UploadPostRequest, UploadPostResponse>({
  url: '/api/me/uploads/post',
  method: 'POST',
  headers: {},
});

export default uploadPost;
