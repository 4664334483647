import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getPrevImageToModerate from './prevImageToModerate';
import getNextImageToModerate from './nextImageToModerate';
import moderateImage from './moderate';
import getImageModeration from './imageModeration';
import moderateAndReturnImage from './moderateAndReturnImage';

export interface ImageModerationEndpoints extends EndpointContainer {
  getPrevImageToModerate: typeof getPrevImageToModerate;
  getNextImageToModerate: typeof getNextImageToModerate;
  moderateImage: typeof moderateImage;
  moderateAndReturnImage: typeof moderateAndReturnImage;
  getImageModeration: typeof getImageModeration;
}

const imageModeration: ImageModerationEndpoints = {
  getPrevImageToModerate,
  getNextImageToModerate,
  moderateImage,
  moderateAndReturnImage,
  getImageModeration,
  injectHTTPClient(client: HttpClient) {
    getPrevImageToModerate.injectHTTPClient(client);
    getNextImageToModerate.injectHTTPClient(client);
    moderateImage.injectHTTPClient(client);
    moderateAndReturnImage.injectHTTPClient(client);
    getImageModeration.injectHTTPClient(client);
  },
};

export default imageModeration;
