import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface ExportUploadHistoryRequestModel extends EndpointRequests {
  get: unknown;
}

const exportUploadHistory = new Endpoint<
  ExportUploadHistoryRequestModel,
  NonNullable<unknown>
>({
  url: '/api/me/upload_history/export',
  method: 'POST',
  headers: {},
});

export default exportUploadHistory;
