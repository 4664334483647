import MessageFactory from '../../types/sharedWorker/messages/MessageFactory';
import { Message } from '../../types/sharedWorker/messages/Message';
import { CloseTabMessage } from '../../types/sharedWorker/messages/CloseTabMessage';

export class SharedWorkerClient {
  worker: SharedWorker;

  onMessageListeners: Array<(ev: Message) => void>;

  constructor() {
    if (process.env.NODE_ENV === 'production') {
      this.worker = new SharedWorker('/shared-worker.js', {
        type: 'module',
      });
    } else {
      this.worker = new SharedWorker(new URL('./worker.ts', import.meta.url), {
        type: 'module',
      });
    }

    this.onMessageListeners = [this.defaultOnMessage.bind(this)];

    this.worker.port.onmessage = async (event: MessageEvent<string>) => {
      try {
        const message = MessageFactory(event.data);
        this.onMessageListeners.forEach((listener) => listener(message));
      } catch (e) {
        console.error('shared worker client error', e);
      }
    };

    window.addEventListener('unload', () => {
      this.worker.port.postMessage(
        new CloseTabMessage({ payload: { data: 'unload' } }).toString(),
      );
      this.worker.removeEventListener('message', this.defaultOnMessage);
      this.worker.port.close();
    });
  }

  sendMessage(message: Message) {
    this.worker.port.postMessage(message.toString());
  }

  defaultOnMessage(message: Message) {
    console.info('Worker said:', message);
  }

  addMessageListener(callback: (ev: Message) => void) {
    this.onMessageListeners.push(callback);
  }

  removeMessageListener(callback: (ev: Message) => void) {
    this.onMessageListeners = this.onMessageListeners.filter(
      (listener) => listener !== callback,
    );
  }
}
