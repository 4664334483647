import { TranslateKeyword } from 'product-types/src/domain/keyword/TranslateKeyword';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface TranslateKeywordResponseModel {
  keywords: Array<TranslateKeyword>;
}

interface TranslateKeywordRequestModel extends EndpointRequests {
  keywords: Array<TranslateKeyword>;
}

const translateKeyword = new Endpoint<
  TranslateKeywordRequestModel,
  TranslateKeywordResponseModel
>({
  url: '/api/me/crawling_config/keywords/translate',
  method: 'POST',
  headers: {},
});

export default translateKeyword;
