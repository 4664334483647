import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { MostCounterfeitedCategoriesResponse } from 'product-types/src/domain/dashboard/DashboardCategoriesStateModel';

export interface MostCounterfeitedCategoriesRequest extends EndpointRequests {
  params: CommonDashboardRequestParams;
}

const mostCounterfeitedCategories = new Endpoint<
  MostCounterfeitedCategoriesRequest,
  MostCounterfeitedCategoriesResponse
>({
  url: '/api/me/stats/categories',
  method: 'GET',
  headers: {},
});

export default mostCounterfeitedCategories;
