import { MessageModel } from './Message';

interface GetNumberOfActiveTabsPayload {
  data: number;
}

export const GetNumberOfActiveTabsType = 'GetNumberOfActiveTabs';

export class GetNumberOfActiveTabs extends MessageModel {
  type = GetNumberOfActiveTabsType;

  payload?: GetNumberOfActiveTabsPayload;

  constructor(data: Omit<GetNumberOfActiveTabs, 'type'>) {
    super({
      type: GetNumberOfActiveTabsType,
      payload: data.payload,
    });
  }

  toString(): string {
    return super.toString();
  }
}
