import React, { memo } from 'react';
import {
  TypographyProps,
  DefaultStylesForVariant,
  DefaultTagForVariant,
  FontWeight,
} from './TypographyProps';

const Typography = React.forwardRef(
  (
    {
      children,
      letterSpacing = 'initial',
      textTransform,
      color = '--black-mate',
      fontWeight = 'regular',
      hidden,
      variant = 'medium',
      opacity = 1,
      textDecoration,
      dataTestId,
      style,
      onClick,
      tag,
    }: TypographyProps,
    ref,
  ) =>
    React.createElement(
      tag || DefaultTagForVariant[variant],
      {
        'data-testid': dataTestId,
        hidden,
        ref,
        onClick,
        style: {
          fontFamily: 'Satoshi',
          opacity,
          color,
          fontWeight: FontWeight[fontWeight],
          letterSpacing,
          textDecoration,
          textTransform,
          ...DefaultStylesForVariant[variant],
          ...style,
        },
      },
      children,
    ),
);

export default memo(Typography, (prevProps, nextProps) => {
  if (prevProps.children !== nextProps.children) {
    return false;
  }
  if (JSON.stringify(prevProps.style) !== JSON.stringify(nextProps.style)) {
    return false;
  }
  return (
    prevProps.className === nextProps.className &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.hidden === nextProps.hidden &&
    prevProps.variant === nextProps.variant &&
    prevProps.color === nextProps.color &&
    prevProps.fontWeight === nextProps.fontWeight &&
    prevProps.letterSpacing === nextProps.letterSpacing &&
    prevProps.textTransform === nextProps.textTransform &&
    prevProps.opacity === nextProps.opacity &&
    prevProps.textDecoration === nextProps.textDecoration &&
    prevProps.tag === nextProps.tag
  );
});
