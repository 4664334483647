import { TypographyProps } from '../Typography/TypographyProps';

export enum Target {
  blank = '_blank',
  self = '_self',
  parent = '_parent',
}

export interface HyperLinkProps extends TypographyProps {
  href?: string;
  target?: Target;
  disabled?: boolean;
  passEventsDuringDisabled?: boolean;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
  onClick?: (e: any) => void;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
}
