export type Id = number | string;

export enum TagTypeEnum {
  account = 'account',
  post = 'post',
  duplicatedGroup = 'duplicated_group',
  vendor = 'cluster',
  uploadHistory = 'upload_history',
}

export interface TagRaw {
  id: Id;
  name: string;
  is_hidden: boolean;
  tag_type: string;
}

// todo: refactor all tags
export interface Tag {
  id: Id;
  name: string;
  isHidden?: boolean;
  tagType: string;
  __isNew__?: boolean;
}

export class TagModel implements Tag {
  id: Id;

  name: string;

  isHidden?: boolean;

  tagType: string;

  __isNew__?: boolean;

  constructor(
    tag: Pick<Tag, '__isNew__' | 'id' | 'name' | 'tagType' | 'isHidden'>,
  ) {
    this.id = tag.id;
    this.name = tag.name;
    this.isHidden = tag.isHidden;
    this.tagType = tag.tagType;
    this.__isNew__ = tag.__isNew__;
  }

  static createFromRawModel(tag: TagRaw) {
    return new TagModel({
      id: tag.id,
      name: tag.name,
      isHidden: tag.is_hidden,
      tagType: tag.tag_type,
    });
  }

  toRawModel(): TagRaw {
    return {
      id: this.id,
      name: this.name,
      is_hidden: this.isHidden ?? false,
      tag_type: this.tagType,
    };
  }

  get value() {
    return this.id;
  }

  get label() {
    return this.name;
  }
}
