import { Endpoint } from 'types/network/Http/endpoint';
import { Zone } from 'product-types/src/domain/geo/Geo';

export type GeographyZoneResponse = Array<Zone>;

const geographyZones = new Endpoint<any, GeographyZoneResponse>({
  url: '/api/me/geography_zones',
  method: 'GET',
  headers: {},
});

export default geographyZones;
