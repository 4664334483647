/*
 *
 * LoginPage actions
 *
 */

import {
  LoginOrganisation,
  LoginOrganisationRaw,
} from 'product-types/src/domain/organisation/LoginOrganisation';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { UserLoginModel } from 'product-types/src/domain/user/UserLoginModel';
import { UserComplete } from 'product-types/src/domain/user/UserComplete';
import { sharedWorkerClient } from '../../workers/shared';
import { LogoutMessage } from '../../types/sharedWorker/messages/LogoutMessage';
import {
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CLEAR_ERROR,
  FETCH_ORGANISATIONS,
  FETCH_USER_MODEL,
  INITIALIZE_APP_STATE,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGOUT,
  PROCESS_LINK,
  RESET_STATE,
  SWITCH_ORGANISATION,
  UPDATE_AUTHENTIFICATION,
  UPDATE_LINK_HANDLED,
  UPDATE_ORGANISATIONS,
  UPDATE_TARGET_ORGANISATION,
  UPDATE_USER_INFO,
  UPDATE_USER_MODEL,
} from './constants';
import { LinkHandlingState } from './reducer';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function changeEmail(email) {
  return {
    type: CHANGE_EMAIL,
    email,
  };
}

export function changePassword(password) {
  return {
    type: CHANGE_PASSWORD,
    password,
  };
}

export function updateOrganisations(
  organisations: FetchableData<Array<LoginOrganisation>>,
) {
  return {
    type: UPDATE_ORGANISATIONS,
    organisations,
  };
}

export function updateUserInfo(userInfo: FetchableData<UserLoginModel>) {
  return {
    type: UPDATE_USER_INFO,
    userInfo,
  };
}

export function switchOrganisation({
  orgId,
  redirectTo,
}: {
  orgId: string;
  redirectTo: string;
}) {
  return {
    type: SWITCH_ORGANISATION,
    orgId,
    redirectTo,
  };
}

export function loginRequest({ redirectTo }: { redirectTo: string }) {
  return {
    type: LOGIN_REQUEST,
    redirectTo,
  };
}

export function processLink({
  redirectTo,
  user_organisations,
}: {
  redirectTo: string;
  user_organisations: Array<LoginOrganisationRaw>;
}) {
  return {
    type: PROCESS_LINK,
    redirectTo,
    user_organisations,
  };
}

export function fetchOrganisations() {
  return {
    type: FETCH_ORGANISATIONS,
  };
}

export function updateTargetOrganisation(
  targetOrganisation: LoginOrganisation | null,
) {
  return {
    type: UPDATE_TARGET_ORGANISATION,
    targetOrganisation,
  };
}

export function fetchUserModel({ redirectTo }: { redirectTo: string }) {
  return {
    type: FETCH_USER_MODEL,
    redirectTo,
  };
}

export function updateUserModel(userModel: FetchableData<UserComplete>) {
  return {
    type: UPDATE_USER_MODEL,
    userModel,
  };
}

export function logout() {
  sharedWorkerClient.sendMessage(new LogoutMessage({}));
  return {
    type: LOGOUT,
  };
}

export function initializeAppState() {
  return {
    type: INITIALIZE_APP_STATE,
  };
}

export function updateAuthentification() {
  return {
    type: UPDATE_AUTHENTIFICATION,
  };
}

export function updateLinkHandled(handled: LinkHandlingState) {
  return {
    type: UPDATE_LINK_HANDLED,
    handled,
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}
