import { SearchImagesResponse } from 'product-types/src/domain/duplicatedGroup/SearchedImage';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SearchImagesRequestModel extends EndpointRequests {
  data: FormData;
}

const searchImages = new Endpoint<
  SearchImagesRequestModel,
  SearchImagesResponse
>({
  url: '/api/me/search/images',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
});

export default searchImages;
