import { ClisterSuggestRawModel } from 'product-types/src/domain/cluster/ClusterSuggestModel';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface SearchClusterResponseModel {
  results: Array<ClisterSuggestRawModel>;
}

interface SearchRequestModel extends EndpointRequests {
  params: {
    q: string;
  };
}

const search = new Endpoint<SearchRequestModel, SearchClusterResponseModel>({
  url: '/api/me/clusters/search',
  method: 'GET',
  headers: {},
});

export default search;
