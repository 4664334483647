import { AccountAssociatedImageRaw } from 'product-types/src/domain/image/AssociatedImage';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface AccountAssociatedPicturesResponseModel {
  associated_pictures: Array<AccountAssociatedImageRaw>;
}

export interface AccountAssociatedPicturesRequestModel
  extends EndpointRequests {}

const pictures = new Endpoint<
  AccountAssociatedPicturesRequestModel,
  AccountAssociatedPicturesResponseModel
>({
  url: '/api/me/clusters/pictures',
  method: 'GET',
  headers: {},
});

export default pictures;
