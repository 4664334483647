/* eslint-disable no-param-reassign */
/* eslint-disable default-case */

// May be any actions in this reducer can be removed after deep investigation of bussines logic.
// In this time i keep actions for backward compatiblity. We can refactor this part only after deep investigations and discussions with backend

import { produce } from 'immer';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { CommentModel } from 'product-types/src/domain/comment/Comment';
import { WebsiteModerationModel } from 'product-types/src/domain/website/WebsiteModerationModel';
import {
  WebsiteFeedDataModel,
  PostFeedDataModel,
  AssociatedImagesWebsite,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  Sorting,
  SortingDirectionEnum,
} from 'product-types/src/common/Sorting/Sorting';
import { RelatedWebsite } from 'product-types/src/domain/website/RelatedWebsite';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import {
  ADD_WEBSITES_SUCCESS,
  DELETE_VENDORS_CLUSTER_SUCCESS,
} from '../ClusterViewContainer/constants';
import {
  LOAD_WEBSITES_SUCCESS,
  LOAD_COMMENTS_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_IMAGES,
  LOAD_RELATED_POSTS_WEBSITE_SUCCESS,
  UPDATE_WEBSITE,
  SET_GEO_WEBSITE,
  DEL_GEO_WEBSITE,
  LOAD_NEXT_WEBSITES_TO_MODERATE,
  LOAD_PREV_WEBSITES_TO_MODERATE,
  RESET_STATE,
  UPDATE_FILTERS_RELATED_POSTS,
  UPDATE_RELATED_WEBSITE,
  UPDATE_RELATED_WEBSITE_FILTERS,
  UPDATE_WEBSITE_MODERATION_QUEUE_INFORMATION,
  UPDATE_RELATED_WEBSITES_TOTAL,
  RESET_FILTERS_RELATED_POSTS,
} from './constants';

// todo: refactor state and make all sections like related posts
const createInitialState = () => ({
  relatedPosts: {
    loading: false,
    filters: new TableParams({
      pagination: new Pagination({
        offset: 0,
        page: 1,
        perpage: 10,
      }),
      sorting: new Sorting({
        key: 'related_posts_default_sorter',
        order: SortingDirectionEnum.asc,
      }),
    }),
    data: FetchableData.default<PostFeedDataModel>(),
  },
  websitesData: FetchableData.default<WebsiteFeedDataModel>(),
  websiteModerationData: {
    website: FetchableData.default<WebsiteModerationModel>(),
    relatedWebsites: {
      data: FetchableData.default<Array<RelatedWebsite>>(),
      filters: new TableParams({
        pagination: new Pagination({
          offset: 0,
          page: 1,
          perpage: 3,
        }),
        sorting: new Sorting({
          key: 'id',
          order: SortingDirectionEnum.asc,
        }),
      }),
      total: 0,
    },
    numberOfWebsitesToModerate: 0,
    websiteModerationIndex: 0,
  },
  websiteImages: FetchableData.default<AssociatedImagesWebsite>(),
  comments: FetchableData.default<Array<CommentModel>>(),
  timestamp: new Date().getTime(),
});

export type WebsiteViewState = ReturnType<typeof createInitialState>;

const websiteViewContainerReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case RESET_FILTERS_RELATED_POSTS: {
        draft.relatedPosts.filters = new TableParams({
          pagination: new Pagination({
            offset: 0,
            page: 1,
            perpage: 10,
          }),
          sorting: new Sorting({
            key: 'related_posts_default_sorter',
            order: SortingDirectionEnum.asc,
          }),
        });
        break;
      }
      case LOAD_WEBSITES_SUCCESS:
        draft.websitesData = action.websitesData;
        break;
      case UPDATE_WEBSITE_IMAGES:
        draft.websiteImages = action.images;
        break;
      case LOAD_COMMENTS_WEBSITE_SUCCESS:
        draft.comments = action.commentsData;
        break;
      case UPDATE_WEBSITE:
        draft.websiteModerationData.website = action.website;
        draft.timestamp = new Date().getTime();
        break;
      case UPDATE_RELATED_WEBSITE: {
        draft.websiteModerationData.relatedWebsites.data =
          action.relatedWebsites;
        break;
      }
      case UPDATE_RELATED_WEBSITES_TOTAL: {
        draft.websiteModerationData.relatedWebsites.total = action.total;
        break;
      }
      case UPDATE_RELATED_WEBSITE_FILTERS: {
        draft.websiteModerationData.relatedWebsites.filters = action.filters;
        break;
      }
      case UPDATE_WEBSITE_MODERATION_QUEUE_INFORMATION: {
        draft.websiteModerationData.numberOfWebsitesToModerate =
          action.number_of_websites_to_moderate;
        draft.websiteModerationData.websiteModerationIndex =
          action.website_moderation_index;
        break;
      }
      case LOAD_RELATED_POSTS_WEBSITE_SUCCESS:
        draft.relatedPosts.data = action.postsData;
        break;
      case UPDATE_FILTERS_RELATED_POSTS:
        draft.relatedPosts.filters = action.payload;
        break;
      case ADD_WEBSITES_SUCCESS:
      case DELETE_VENDORS_CLUSTER_SUCCESS:
        draft.timestamp = new Date().getTime();
        break;
      case SET_GEO_WEBSITE:
        break;
      case DEL_GEO_WEBSITE:
        break;
      case LOAD_NEXT_WEBSITES_TO_MODERATE:
      case LOAD_PREV_WEBSITES_TO_MODERATE:
        draft.relatedPosts.filters.pagination.offset = 0;
        draft.relatedPosts.filters.pagination.perpage = 10;
        break;
    }
  });

export default websiteViewContainerReducer;
