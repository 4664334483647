import { Endpoint } from 'types/network/Http/endpoint';

const downloadAccountManualExcelFile = new Endpoint<
  NonNullable<unknown>,
  NonNullable<unknown>
>({
  url: '/api/me/uploads/account/manual/file',
  method: 'GET',
  headers: {},
});

export default downloadAccountManualExcelFile;
