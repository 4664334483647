import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { TagRaw } from 'product-types/src/domain/tag/Tag';

export type TagsReasonResponse = Array<TagRaw>;

interface getTagsRequestModel extends EndpointRequests {
  urlParams: {
    tagType: string;
  };
}

const tags = new Endpoint<getTagsRequestModel, TagsReasonResponse>({
  url: '/api/me/organisation/tags/:tagType',
  method: 'GET',
  headers: {},
});

export default tags;
