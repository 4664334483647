import { HttpClient } from '../../client/client';
import { EndpointContainer } from '../../endpoint';
import dashboard from './dashboard';
import clusters from './clusters';
import accounts from './accounts';
import common from './common';
import me from './me';
import images from './images';
import posts from './posts';
import upload from './upload';
import naveeAdmin from './naveeAdmin';
import users from './users';
import websites from './websites';
import token from './token';
import tags from './tags';
import notifications from './notifications';
import organisation from './organisation';
import cralwingConfig from './crawlingConfiguration';
import search from './search';
import logout from './logout';
import moderationComments from './moderationComment';
import setPassword from './setPassword';
import resetPassword from './resetPassword';

export interface ProductMonitorEndpoints extends EndpointContainer {
  dashboard: typeof dashboard;
  clusters: typeof clusters;
  cralwingConfig: typeof cralwingConfig;
  websites: typeof websites;
  accounts: typeof accounts;
  common: typeof common;
  me: typeof me;
  organisation: typeof organisation;
  token: typeof token;
  tags: typeof tags;
  notifications: typeof notifications;
  users: typeof users;
  images: typeof images;
  posts: typeof posts;
  moderationComments: typeof moderationComments;
  upload: typeof upload;
  search: typeof search;
  naveeAdmin: typeof naveeAdmin;
  logout: typeof logout;
  setPassword: typeof setPassword;
  resetPassword: typeof resetPassword;
}

const endpoints: ProductMonitorEndpoints = {
  dashboard,
  cralwingConfig,
  accounts,
  websites,
  me,
  organisation,
  token,
  notifications,
  users,
  common,
  tags,
  clusters,
  images,
  posts,
  moderationComments,
  upload,
  logout,
  search,
  naveeAdmin,
  setPassword,
  resetPassword,
  injectHTTPClient(client: HttpClient) {
    dashboard.injectHTTPClient(client);
    accounts.injectHTTPClient(client);
    cralwingConfig.injectHTTPClient(client);
    websites.injectHTTPClient(client);
    logout.injectHTTPClient(client);
    clusters.injectHTTPClient(client);
    common.injectHTTPClient(client);
    me.injectHTTPClient(client);
    tags.injectHTTPClient(client);
    organisation.injectHTTPClient(client);
    token.injectHTTPClient(client);
    notifications.injectHTTPClient(client);
    users.injectHTTPClient(client);
    images.injectHTTPClient(client);
    posts.injectHTTPClient(client);
    moderationComments.injectHTTPClient(client);
    upload.injectHTTPClient(client);
    search.injectHTTPClient(client);
    naveeAdmin.injectHTTPClient(client);
    setPassword.injectHTTPClient(client);
    resetPassword.injectHTTPClient(client);
  },
};

export default endpoints;
