export const RESET_STATE = 'app/containers/Uploads/Image/RESET_STATE';
export const SEARCH_IMAGE_BY_URL =
  'app/containers/Uploads/Image/SEARCH_IMAGE_BY_URL';
export const SEARCH_IMAGE_BY_FILE =
  'app/containers/Uploads/Image/SEARCH_IMAGE_BY_FILE';
export const SEARCH_IMAGE_BY_ID =
  'app/containers/Uploads/Image/SEARCH_IMAGE_BY_ID';
export const UPDATE_LOADING_IMAGE =
  'app/containers/Uploads/Image/UPDATE_LOADING_IMAGE';
export const UPDATE_IMAGES_LIST =
  'app/containers/Uploads/Image/UPDATE_IMAGES_LIST';
export const UPDATE_IMAGES_TAGS_LIST =
  'app/containers/Uploads/Image/UPDATE_IMAGES_TAGS_LIST';
export const UPLOAD_IMAGE_TO_NAVEE =
  'app/containers/Uploads/Image/UPLOAD_IMAGE_TO_NAVEE';
export const UPLOAD_IMAGE_XLSX =
  'app/containers/Uploads/Image/UPLOAD_IMAGE_XLSX';
export const UPDATE_GLOBAL_IMAGE_LABEL =
  'app/containers/Uploads/Image/UPDATE_GLOBAL_IMAGE_LABEL';
export const RUN_SIMILAR_SEARCH_FOR_IMAGE =
  'app/containers/Uploads/Image/RUN_SIMILAR_SEARCH_FOR_IMAGE';
export const ADD_SIMILAR_SEARCH_FOR_IMAGE =
  'app/containers/Uploads/Image/ADD_SIMILAR_SEARCH_FOR_IMAGE';
