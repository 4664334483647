import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { Filter } from '../../AtomicFilters/Filter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { SearchValue, readFilterFromQueryProps } from './SearchValue';

export interface SearchQueryValue extends QueryValue {
  poster_names?: Array<string | number>;
  vendor_names?: Array<string | number>;
  post_url?: Array<string | number>;
  poster_urls?: Array<string | number>;
  comments_to_include?: Array<string | number>;
  comments_to_exclude?: Array<string | number>;
  keywords_to_include?: Array<string | number>;
  keywords_to_exclude?: Array<string | number>;
  account_website_id?: Array<string | number>;
  account_poster_id?: Array<string | number>;
  contact_info_to_include?: Array<string | number>;
  contact_info_to_exclude?: Array<string | number>;
  related_to_account_id?: Array<string | number>;
  cluster_id?: Array<string | number>;
  duplicated_group_id?: Array<string | number>;
  post_id?: Array<string | number>;
  domain_names?: Array<string | number>;
}

export class SearchFilter implements Filter {
  uuid: string;

  label: string;

  value: SearchValue;

  constructor(
    params?: Partial<Pick<SearchFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || SearchValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [];
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    const { value }: { value: SearchValue } = removingFilter as any;
    this.value = this.value.remove(value.values[0]);
  }

  displayFilterComponent() {
    return null;
  }

  component() {
    return null;
  }

  get queryFilterValue(): SearchQueryValue {
    return this.value.queryFilterValue as SearchQueryValue;
  }

  static readFilterFromQuery(): SearchFilter {
    return new SearchFilter({
      value: SearchValue.readFilterFromQuery,
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): SearchFilter {
    return new SearchFilter({
      value: SearchValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
