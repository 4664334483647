import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ExportFeedRequestParameter } from 'types/network/Feed/Feed';

interface ExportAccountsRequestModel extends EndpointRequests {
  data: ExportFeedRequestParameter;
}

const exportAccounts = new Endpoint<
  ExportAccountsRequestModel,
  NonNullable<unknown>
>({
  url: '/api/me/accounts/export/xls',
  method: 'POST',
  headers: {},
});

export default exportAccounts;
