import { RelatedAccountRaw } from 'product-types/src/domain/account/RelatedAccount';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface RelatedAccoutnsRequest extends EndpointRequests {
  urlParams: { id: string };
  params: any;
}

export interface RelatedAccountsResponse {
  accounts?: Array<RelatedAccountRaw>;
  total?: number;
}

const relatedAccounts = new Endpoint<
  RelatedAccoutnsRequest,
  RelatedAccountsResponse
>({
  url: '/api/me/accounts/:id/related_accounts',
  method: 'GET',
  headers: {},
});

export default relatedAccounts;
