export interface Message {
  type: string;
  payload?: MessagePayload;
}

export interface MessagePayload {
  data: any;
}

export class MessageModel implements Message {
  type: string;

  payload?: MessagePayload;

  constructor(data: Message) {
    this.type = data.type;
    this.payload = data.payload;
  }

  toString() {
    return JSON.stringify(this);
  }
}
